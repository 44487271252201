export const toHumanReadable = (text) => {
  return text
    .replace(/cis/g, "")
    .replace(/ConfigurationInfo/g, "Configurations")
    .replace(/SELinuxConfigureInfo/g, "SE LinuxConfigurations")
    .replace(/configureFirewall/g, "firewallConfigurations")
    .replace(/configureSSHServer/g, "SSH Configurations")
    .replace(/userAccountsEnvironment/g, "usersAccountConfigurations")
    .replace(/loggingAndAuditing/g, "Logging and Auditing")
    .replace(/configurePrivilegeEscalation/g, "privilegeEscalationConfigurations")
    .replace(/baseAndOsServices/g, "Base and OS Services")
    .replace(/desktopConf/g, "Desktop Configurations")
    .replace(/configurePam/g, "PAM Configurations")
    .replace(/configureUfw/g, "UFW Configurations")
    .replace(/configureIptablesSoftwares/g, "IPTables Configurations")
    .replace(/isMCSTranslation/g, "isMCS Translation")
    .replace(/enabledInGrub/g, "Enabled in Grub")
    .replace(/Execstack/g, "Exec Stack")
    .replace(/Execheap/g, "Exec Heap")
    .replace(/Execmod/g, "Exec Mod")
    .replace(/RhnsdDisabled/g, "RHNSD Disabled")
    .replace(/isSeLinux/g, "isSE Linux")
    .replace(/isGpgCheckIn/g, "isGPG Check in")
    .replace(/isGpgCheckFor/g, "isGPG Check for")
    .replace(/isGpgIn/g, "is GPG in")
    .replace(/isGpgFor/g, "is GPG for")
    .replace(/isNftables/g, "isNFTables")
    .replace(/isIpForwarding/g, "isIP Forwarding")
    .replace(/isSendPcket/g, "isSendPacket")
    .replace(/Ipv6Router/g, "IPv6 Router")
    .replace(/Ipv6Disabled/g, "IPv6 Disabled")
    .replace(/Info/g, "")
    .replace(/OnEtcSshdSshdConfig/g, "on /etc/ssh/sshd.conf")
    .replace(/OnEtcMotd/g, " on /etc/motd")
    .replace(/OnEtcIssueNet/g, " on /etc/issue.net")
    .replace(/OnEtcIssue/g, " on /etc/issue")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/\bFor\b/g, "for")
    .replace(/\bAnd\b/g, "and")
    .replace(/\bOr\b/g, "or")
    .replace(/\bIp\b/g, "IP")
    .replace(/\bIcmp\b/g, "ICMP")
    .replace(/\bTcp\b/g, "TCP")
    .replace(/\bUdp\b/g, "UDP")
    .replace(/\bIcmpv6\b/g, "ICMPv6")
    .replace(/\bTcp6\b/g, "TCPv6")
    .replace(/\bUdp6\b/g, "UDPv6")
    .replace(/\bTtl\b/g, "TTL")
    .replace(/\bTtl6\b/g, "TTLv6")
    .replace(/\bSyn\b/g, "SYN")
    .replace(/\bDccp\b/g, "DCCP")
    .replace(/\bSctp\b/g, "SCTP")
    .replace(/\bRds\b/g, "RDS")
    .replace(/\bTipc\b/g, "TIPC")
    .replace(/\bSsh\b/g, "SSH")
    .replace(/\bIn\b/g, "in")
    .replace(/\bConfigure \b/g, "")
    .replace(/Pam/g, "Privilege Access Management (PAM)")
    .replace(/ Conf\b/g, " Configurations")
    .replace(/ Os \b/g, " OS ")
    .replace(/Get User and Group/g, "User and Group")
    .replace(/SSHServer/g, "SSH Server")
    .replace(/Additional Process Hardening/g, "Process Hardening Additional");
};