// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import Chart from "react-apexcharts";
// import Header from "../../layouts/Header";

// function Test() {
//   const [skin, setSkin] = useState(
//     localStorage.getItem("skin-mode") ? "dark" : ""
//   );
//   const [viewG, setViewG] = useState("departments");
//   const [currentDataG, setCurrentDataG] = useState([]);
//   const [orgID, setOrgID] = useState(null);
//   const { user } = useSelector((state) => state.authSlice);
//   console.log(currentDataG, "currentDataG");
//   const linuxApi = axios.create({
//     baseURL: "https://dev.cyberauditor.in/api/v1/linux/pcinfo",
//   });

//   const dataGraphRRR = [
//     {
//       "_id": "6703bb10ec76033e237f6ba0",
//       "total": 4,
//       "safe": 4,
//       "unsafe": 4,
//       "unresponsive": 2,
//       "departmentName": "cmd 3"
//   },
//   {
//       "_id": "6703b5dfec76033e237e9f34",
//       "total": 1,
//       "safe": 3,
//       "unsafe": 1,
//       "unresponsive": 0,
//       "departmentName": "cmd2"
//   }
// ]
//   // Function to fetch departments data
//   const fetchDepartments = async () => {
//     try {
//       const response = await linuxApi.get(
//         `/org-department-dashboard/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setCurrentDataG(response.data.data);
//       // setCurrentDataG(dataGraphRRR);
//       setViewG("departments");
//     } catch (error) {
//       console.error("Error fetching departments:", error);
//     }
//   };

//   // Function to fetch sections data based on departmentId
//   const fetchSections = async (departmentId) => {
//     try {
//       const response = await linuxApi.get(
//         `/org-section-dashboard/${user?.data?.user?.userId}?department=${departmentId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setCurrentDataG(response.data.data);
//       setViewG("sections");
//     } catch (error) {
//       console.error("Error fetching sections:", error);
//     }
//   };

//   // Function to fetch subsections data based on departmentId and sectionId
//   const fetchSubSections = async (departmentId, sectionId) => {
//     try {
//       const response = await linuxApi.get(
//         `/org-sub-section-dashboard/${user?.data?.user?.userId}?department=${departmentId}&section=${sectionId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       setCurrentDataG(response.data.data);
//       setViewG("subsections");
//     } catch (error) {
//       console.error("Error fetching sub-sections:", error);
//     }
//   };

//   // Initial fetch for departments data
//   useEffect(() => {
//     if (user?.data?.accessToken) {
//       fetchDepartments();
//     }
//   }, [user?.data?.accessToken]);

//   // Set up chart options and handle click events to navigate between levels
//   // const chartOptionsG = {
//   //   chart: {
//   //     type: "bar",
//   //     width: 600,
//   //     events: {
//   //       dataPointSelection: (event, chartContext, { dataPointIndex }) => {
//   //         const selectedItem = currentDataG[dataPointIndex];
//   //         if (viewG === "departments") {
//   //           fetchSections(selectedItem._id);
//   //           setOrgID(selectedItem._id);
//   //         } else if (viewG === "sections") {
//   //           fetchSubSections(orgID, selectedItem.section);
//   //         }
//   //       },
//   //     },
//   //   },
//   //   plotOptions: {
//   //     bar: {
//   //       columnWidth: "10%",
//   //       borderRadius: 4,
//   //     },
//   //   },
//   //   colors: ["#28a745", "#dc3545"],
//   //   xaxis: {
//   //     categories: currentDataG.map((item) =>
//   //       viewG === "departments"
//   //         ? item.departmentName
//   //         : viewG === "sections"
//   //         ? item.sectionName
//   //         : item.subSectionName
//   //     ),
//   //   },
//   //   title: {
//   //     text: viewG.charAt(0).toUpperCase() + viewG.slice(1),
//   //     align: "center",
//   //   },
//   // };
//   const chartOptionsG = {
//     chart: {
//       type: "bar",
//       width: 600,
//       stacked: true,  // Enable stacked bar chart
//       events: {
//         dataPointSelection: (event, chartContext, { dataPointIndex }) => {
//           const selectedItem = currentDataG[dataPointIndex];
//           if (viewG === "departments") {
//             fetchSections(selectedItem._id);
//             setOrgID(selectedItem._id);
//           } else if (viewG === "sections") {
//             fetchSubSections(orgID, selectedItem.section);
//           }
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: "10%",
//         borderRadius: 4,
//         horizontal: false,  // Keeps the bars vertical
//       },
//     },
//     colors: ["#28a745", "#dc3545"],
//     xaxis: {
//       categories: currentDataG.map((item) =>
//         viewG === "departments"
//           ? item.departmentName
//           : viewG === "sections"
//           ? item.sectionName
//           : item.subSectionName
//       ),
//     },
//     title: {
//       text: viewG.charAt(0).toUpperCase() + viewG.slice(1),
//       align: "center",
//     },
//   };

//   // Set up series data
//   const chartSeriesG = [
//     {
//       name: "Compliant",
//       data: currentDataG.map((item) => item.safe),
//     },
//     {
//       name: "Non-Compliant",
//       data: currentDataG.map((item) => item.unsafe),
//     },
//   ];

//   // Function to go back to the previous view
//   const handleBackG = () => {
//     if (viewG === "subsections") {
//       fetchSections(orgID);
//     } else if (viewG === "sections") {
//       fetchDepartments();
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <>

//         <div className="card rounded cardStyle d-flex flex-column">
//           {viewG !== "departments" && (
//             <button
//               className="btn btn-sm btn-info align-self-start  ms-3 mt-1"
//               onClick={handleBackG}
//             >
//               Back
//             </button>
//           )}
//           <Chart
//             options={chartOptionsG}
//             series={chartSeriesG}
//             type="bar"
//             height={400}
//           />
//         </div>
//       </>
//     </React.Fragment>
//   );
// }

// export default Test;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Chart from "react-apexcharts";
import Header from "../../layouts/Header";
import { linuxApi } from "../../api/axiosSet";
import { useSkinMode } from "../../components/SkinModeContext";

function Test() {
  const [skin, setSkin] = useState(
    localStorage.getItem("skin-mode") ? "dark" : ""
  );
  const [viewG, setViewG] = useState("departments");
  const [currentDataG, setCurrentDataG] = useState([]);
  const [orgID, setOrgID] = useState(null);
  const { user } = useSelector((state) => state.authSlice);
  const { skinMode } = useSkinMode(); // Access the skin mode state

  if (skinMode === "dark") {
    document.body.classList.add("dark-skin"); // Add a class to the body
  } else {
    document.body.classList.remove("dark-skin"); // Remove it when it's not dark mode
  }
  // const dataGraphRRR = [
  //   {
  //     _id: "6703bb10ec76033e237f6ba0",
  //     total: 4,
  //     safe: 4,
  //     unsafe: 4,
  //     unresponsive: 2,
  //     departmentName: "cmd 3",
  //   },
  //   {
  //     _id: "6703b5dfec76033e237e9f34",
  //     total: 1,
  //     safe: 2,
  //     unsafe: 3,
  //     unresponsive: 0,
  //     departmentName: "cmd2",
  //   },
  //   {
  //     _id: "6703b5dfec76033e237e9f34",
  //     total: 1,
  //     safe: 2,
  //     unsafe: 7,
  //     unresponsive: 0,
  //     departmentName: "cmd2",
  //   },
  //   {
  //     _id: "6703b5dfec76033e237e9f34",
  //     total: 1,
  //     safe: 3,
  //     unsafe: 1,
  //     unresponsive: 0,
  //     departmentName: "cmd2",
  //   },
  //    {
  //     _id: "6703b5dfec76033e237e9f34",
  //     total: 1,
  //     safe: 3,
  //     unsafe: 1,
  //     unresponsive: 0,
  //     departmentName: "cmd2",
  //   },
  // ];

  // Function to fetch departments data
  const fetchDepartments = async () => {
    try {
      const response = await linuxApi.get(
        `/pcinfo/org-department-dashboard/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setCurrentDataG(response.data.data);
      // setCurrentDataG(dataGraphRRR);
      setViewG("departments");
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const data = [
    {
      section: "6703bbce320e873ca61bc5ce",
      total: 1,
      safe: 4,
      unsafe: 3,
      unresponsive: 0,
      sectionName: "N5 A1",
    },
    {
      section: "6703bbce320e873ca61bc5c2",
      total: 1,
      safe: 3,
      unsafe: 4,
      unresponsive: 0,
      sectionName: "N5 A1",
    },
    {
      section: "6703bbce320e873ca61bc5ce",
      total: 1,
      safe: 6,
      unsafe: 4,
      unresponsive: 0,
      sectionName: "N5 A1",
    },
    {
      section: "6703bbce320e873ca61bc5c2",
      total: 1,
      safe: 4,
      unsafe: 6,
      unresponsive: 0,
      sectionName: "N5 A1",
    },
  ];

  // Function to fetch sections data based on departmentId
  const fetchSections = async (departmentId) => {
    try {
      const response = await linuxApi.get(
        `/pcinfo/org-section-dashboard/${user?.data?.user?.userId}?department=${departmentId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      
     setCurrentDataG(response.data.data);
      setViewG("sections");
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const dataSubsection = [
    {
      "subSection": "6703bba6ec76033e237f7961",
      "total": 1,
      "safe": 3,
      "unsafe": 4,
      "unresponsive": 0,
      "subSectionName": "N2 A12"
  },
    {
     
      "subSection": "6703bba6ec76033e237f7964",
      "total": 1,
      "safe": 2,
      "unsafe": 4,
      "unresponsive": 0,
      "subSectionName": "N4 13"
    },

    {
     
      "subSection": "6703bba6ec76033e237f7964",
      "total": 1,
      "safe": 6,
      "unsafe": 3,
      "unresponsive": 0,
      "subSectionName": "N4 13"
    },
    {
     
      "subSection": "6703bba6ec76033e237f7964",
      "total": 1,
      "safe": 2,
      "unsafe": 4,
      "unresponsive": 0,
      "subSectionName": "N4 13"
    },
  ];
  // Function to fetch subsections data based on departmentId and sectionId
  const fetchSubSections = async (departmentId, sectionId) => {
    try {
      const response = await linuxApi.get(
        `/pcinfo/org-sub-section-dashboard/${user?.data?.user?.userId}?department=${departmentId}&section=${sectionId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setCurrentDataG(response.data.data);
      setViewG("subsections");
    } catch (error) {
      console.error("Error fetching sub-sections:", error);
    }
  };

  // Initial fetch for departments data
  useEffect(() => {
    if (user?.data?.accessToken) {
      fetchDepartments();
    }
  }, [user?.data?.accessToken]);

  // Set up chart options and handle click events to navigate between levels
  // const chartOptionsG = {
  //   chart: {
  //     type: "bar",
  //     width: 600,
  //     stacked: true,
  //     events: {
  //       dataPointSelection: (event, chartContext, { dataPointIndex }) => {
  //         const selectedItem = currentDataG[dataPointIndex];
  //         if (viewG === "departments") {
  //           fetchSections(selectedItem._id);
  //           setOrgID(selectedItem._id);
  //         } else if (viewG === "sections") {
  //           fetchSubSections(orgID, selectedItem.section);
  //         }
  //       },
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "10%",
  //       borderRadius: 4,
  //       horizontal: false,
  //     },
  //   },
  //   colors: viewG === "departments" ? ["#28a745", "#dc3545"] : 
  //           viewG === "sections" ? ["#007bff", "#6c757d"] : 
  //           ["#6f42c1", "#ffc107"], // different colors for each level
  //   xaxis: {
  //     categories: currentDataG.map((item) =>
  //       viewG === "departments"
  //         ? item.departmentName
  //         : viewG === "sections"
  //         ? item.sectionName
  //         : item.subSectionName
  //     ),
  //   },
  //   title: {
  //     text: viewG.charAt(0).toUpperCase() + viewG.slice(1),
  //     align: "center",
  //   },
  // };
  const chartOptionsG = {
    chart: {
      type: "bar",
      width: 600,
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          const selectedItem = currentDataG[dataPointIndex];
          if (viewG === "departments") {
            fetchSections(selectedItem._id);
            setOrgID(selectedItem._id);
          } else if (viewG === "sections") {
            fetchSubSections(orgID, selectedItem.section);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
        // borderRadius: 4,
        horizontal: false,
      },
    },
    colors: viewG === "departments" ? ["#28a745", "#dc3545"] :
            viewG === "sections" ? ["#007bff", "#6c757d"] :
            ["#6f42c1", "#ffc107"], // different colors for each level
    xaxis: {
      categories: currentDataG.map((item) =>
        viewG === "departments"
          ? item.departmentName
          : viewG === "sections"
          ? item.sectionName
          : item.subSectionName
      ),
    },
    title: {
      text: viewG.charAt(0).toUpperCase() + viewG.slice(1),
      align: "center",
    },
    tooltip: {
      enabled: false,  // Make sure this is false to disable tooltip
      custom: function({ seriesIndex, dataPointIndex, w }) {
        return '';  // Ensures no content is returned for the tooltip.
      },
      style: {
        boxShadow: 'none', // Remove the shadow
      },
      onDatasetHover: {
        highlightDataSeries: false, // Optional: Disable hover highlighting of data series if desired
      },
    },
  };
  
  // Set up series data
  const chartSeriesG = [
    {
      name: "Compliant",
      data: currentDataG.map((item) => item.safe),
    },
    {
      name: "Non-Compliant",
      data: currentDataG.map((item) => item.unsafe),
    },
  ];

  // Function to go back to the previous view
  const handleBackG = () => {
    if (viewG === "subsections") {
      fetchSections(orgID);
    } else if (viewG === "sections") {
      fetchDepartments();
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      {/* <div className="card rounded cardStyle d-flex flex-column">
        {viewG !== "departments" && (
          <button
            className="btn btn-sm btn-info align-self-start ms-3 mt-1"
            onClick={handleBackG}
          >
            Back
          </button>
        )}
        <Chart options={chartOptionsG} series={chartSeriesG} type="bar" height={400} />
      </div> */}
      <div className="card rounded cardStyle d-flex flex-column">
        {viewG !== "departments" && (
          <button
            className="btn btn-sm btn-info align-self-start ms-3 mt-1"
            onClick={handleBackG}
          >
            Back
          </button>
        )}

        <div
          className="chart-container"
          style={{ position: "relative", height: "400px" }}
        >
          <Chart
            options={chartOptionsG}
            series={chartSeriesG}
            type="bar"
            height="100%"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Test;
