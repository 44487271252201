// persistConfig.js
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authSlice from "./slices/AuthSlice";

const authPersistConfig = {
  key: "authSlice",
  storage: storage,
  whitelist: ["user", "isAuthenticated"],
};

export const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authSlice
);
