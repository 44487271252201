import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Footer from "../../layouts/Footer";
// import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";
import integrity from "../../assets/img/Alerts/integration.png";
import jsPDF from "jspdf";
import { notifyApi, summaryApi } from "../../api/axiosSet";
import { useSkinMode } from "../../components/SkinModeContext";

import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";

import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";
import Chart from "react-apexcharts";
import usbIcon from "../../assets/img/Alerts/usbIcon.png";
import usbviolation from "../../assets/img/Alerts/usbviolation.png";
import fipsnot from "../../assets/img/Alerts/fipsnot.png";
import secure from "../../assets/img/Alerts/secure.png";
import openport from "../../assets/img/Alerts/openport.png";
import publicshare from "../../assets/img/Alerts/publicshare.png";
import tpm_icon from "../../assets/img/Alerts/tpm_icon.png";
import oldPassword from "../../assets/img/Alerts/oldPassword.png";
import multRoot from "../../assets/img/Alerts/multRoot.png";
import unreccg from "../../assets/img/Alerts/unreccg.png";
import zenWorks from "../../assets/img/Alerts/zenWorks.png";
import unrecog from "../../assets/img/Alerts/unrecog.png";
import oshardingset from "../../assets/img/Alerts/oshardingset.png";
import edricon from "../../assets/img/Alerts/edricon.png";
import ssl from "../../assets/img/Alerts/ssl.png";
import edr from "../../assets/img/Alerts/edr.png";
import zen from "../../assets/img/Alerts/zen.png";
import unknow from "../../assets/img/Alerts/unknow.png";
import bluetooth from "../../assets/img/Alerts/bluetooth.png";
import windows_rdp_gateway from "../../assets/img/Alerts/windows_rdp_gateway.png";
import Ram_OOM_Theoryc from "../../assets/img/Alerts/Ram_OOM_Theoryc.jpg";
import battery from "../../assets/img/Alerts/battery.png";
import cddrive from "../../assets/img/Alerts/cddrive.ico";
import hardingSet from "../../assets/img/Alerts/hardingSet.png";
import wifi from "../../assets/img/Alerts/wifi.png";
import os_not_patch from "../../assets/img/Alerts/os_not_patch.png";
import virus from "../../assets/img/Alerts/virus.png";
import ram from "../../assets/img/Alerts/ram.png";
import cpu from "../../assets/img/Alerts/cpu.png";
import IssueAlert from "../../components/common/IssueAlert";
import useFetchWindowSummaryData from "../../hooks/windowsHooks/useFetchWindowSummary";
import PageLoader from "../../components/common/Loader/PageLoader";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  FIRWALL_OFF,
  MALWARE_DETECTION_ENDPOINT,
  NAC_NAP,
  OS_PATCHED,
  USB_VOILATION,
  WINDOW_NOT_ACTIVATED,
} from "../../api/endPoints/windowAlertEndPoint";
import LinuxClients from "../../components/common/clients/linuxClients/LinuxClients";
import useFetchLinuxSummary from "../../hooks/linuxhooks/useFetchLinuxSummary";
import {
  BIOS_BATTERY,
  BLUETOOTH_ENABLED,
  CD_DRIVE_ENABLED,
  IS_HIGH_CPU_USAGE,
  IS_HIGH_RAM_USAGE,
  LINUX_BLACKLIST,
  LINUX_FIREWALL_OFF,
  LINUX_NIC,
  LINUX_NOT_ACTIVATED,
  LINUX_OS_NOT_PATCH,
  MULTIPLE_PASSWORD,
  NAC_DISABLED,
  OLD_PASSWORD,
  OPEN_NETWORK_CONNECTION,
  OUT_OF_DOMAIN,
  RDP_ENABLED,
  SCANNER_INSTALLED,
  SHARED_FOLDER,
  TOTAL_USB_VOILATION,
  UNKNOWN_SERVICE,
  WIFI_ENABLED,
  TOTAL_LINUX_CLIENT,
  TOTAL_SAFE_LINUX,
  TOTAL_UNRESPONSIVE_LINUX,
  TOTAL_UNSAFE_LINUX,
  UNWANTED_PORT,
  NETWORK_VIOLATION,
  NETWORK_COMMUNICATION_VIOLATION,
  TPM_DISABLED,
  IS_FIPS_DISABLE,
  IS_SECURE_BOOT_DISABLED,
  IS_PROCESS_HARDENING_INFO,
  ZEN_NOT_INSTALLED,
  BLACKLISTED_PROGRAM_ENDPOINTS,
  CERTIFICATE_CLIENT_NOT_INSTALLED,
  OS_NOT_HARDENED,
  FILE_INTERGRITY_FAILED,
} from "../../api/endPoints/linuxAlertEndPoint";
import CustomToaster from "../../components/common/CustomToaster";
import { Input } from "antd";
import { showToaster } from "../../feature/slices/toastSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export default function LinuxDetail() {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);
  const {
    loading,
    linuxSummary,
    linuxAlertSummary,
    handleReq = "",
    totalDashboard,
  } = useFetchLinuxSummary();
  const [lineGraph, setLineGraph] = useState();
  const [monthGraph, setMonthGraph] = useState();
  const [monthGraphTwo, setMonthGraphTwo] = useState([]);
  const prevResponseRef = useRef(null); // Store the previous response
  const prevMonthResponseRef = useRef(null); // Ref for MonthResponse
  const prevDeptResponseRef = useRef(null); // Ref for MonthTwoResponse
  const prevSectionRespRef = useRef(null);
  const [view, setView] = useState("Department");
  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);
  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);
  const location = useLocation();
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const [departmentGraph, setDepartmentGraph] = useState([]);
  const [sectionGraph, setSectionGraph] = useState([]);
  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  // const seriesEight = [
  //   {
  //     type: "column",
  //     data: [
  //       [0, 2],
  //       [1, 3],
  //       [2, 5],
  //       [3, 7],
  //       [4, 12],
  //       [5, 17],
  //       [6, 10],
  //       [7, 14],
  //       [8, 15],
  //       [9, 12],
  //       [10, 8],
  //     ],
  //   },
  //   {
  //     type: "column",
  //     data: [
  //       [0, 12],
  //       [1, 7],
  //       [2, 4],
  //       [3, 5],
  //       [4, 8],
  //       [5, 10],
  //       [6, 4],
  //       [7, 7],
  //       [8, 11],
  //       [9, 9],
  //       [10, 5],
  //     ],
  //   },
  // ];

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (handleReq === "LINUX_USB_TRIGGER") {
    dispatch(
      showToaster({
        title: "USB",
        message: "USB VIOLATION",
        color: "warning",
      })
    );
  }

  if (handleReq === "LINUX_INSTALLATION_TRIGGER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "New Device Register",
        color: "success",
      })
    );
  }

  if (handleReq === "NO_DEVICE_REGISTER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "No Device Register",
        color: "danger",
      })
    );
  }

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SUMMARY_URL}/graph-data/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        const departmentCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts;
        const sectionCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts;
        const newResponse = response.data.response.sevenDayData;
        const monthResponse = response.data.graphData.monthGraph;
        console.log(departmentCount, "departmentCount------");
        console.log(sectionCount, "sectionCount------");
        // Check if the new response is different from the previous response
        if (
          JSON.stringify(prevResponseRef.current) !==
          JSON.stringify(newResponse)
        ) {
          setLineGraph(newResponse); // Update the state with new data
          prevResponseRef.current = newResponse; // Save the new response in ref
          console.log(newResponse, "Data updated");
        } else {
          console.log("Data is the same, skipping update");
        }

        // Check if the new MonthResponse is different from the previous monthResponse
        if (
          JSON.stringify(prevMonthResponseRef.current) !==
          JSON.stringify(monthResponse)
        ) {
          setMonthGraph(monthResponse); // Update the state with new data
          prevMonthResponseRef.current = monthResponse; // Save the new response in ref
          console.log(monthResponse, "date");
        } else {
          console.log("Month data is the same, skipping update");
        }

        if (
          JSON.stringify(prevDeptResponseRef.current) !==
          JSON.stringify(departmentCount)
        ) {
          setDeptSafeUnsafe(departmentCount); // Update the state with new data
          setDepartmentGraph(departmentCount);
          prevDeptResponseRef.current = departmentCount; // Save the new response in ref
          console.log(departmentCount, "date");
        } else {
          console.log("Month data is the same, skipping update");
        }

        if (
          JSON.stringify(prevSectionRespRef.current) !==
          JSON.stringify(sectionCount)
        ) {
          setSectionSafeUnsafe(sectionCount); // Update the state with new data
          setSectionGraph(sectionCount);
          prevSectionRespRef.current = sectionCount; // Save the new response in ref
          console.log(sectionCount, "date");
        } else {
          console.log("Month data is the same, skipping update");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [tooltipData, setTooltipData] = useState([]);

  const fetchtooltipData = async () => {
    try {
      const response = await notifyApi.get(`/cards`, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });
      setTooltipData(response?.data?.data);
    } catch {}
  };

  useEffect(() => {
    fetchData();
    fetchtooltipData();
  }, []); // Run once when the component mounts

  const formatTimestampGraph = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleString("en-US", options);
  };
  if (skinMode === "dark") {
    document.body.classList.add('dark-skin'); // Add a class to the body
  } else {
    document.body.classList.remove('dark-skin'); // Remove it when it's not dark mode
  }
  // graph code here.....

  const departmentResponse = [
    { department: "cmd 4", safeCount: 10, unsafeCount: 20 },
    { department: "Unknown", safeCount: 1, unsafeCount: 3 },
    { department: "cmd2", safeCount: 1, unsafeCount: 1 },
    { department: "cmd 3", safeCount: 0, unsafeCount: 2 },
  ];

  const sectionResponse = [
    { section: "node2", safeCount: 50, unsafeCount: 20 },
    { section: "N2 A1", safeCount: 0, unsafeCount: 2 },
    { section: "Unknown", safeCount: 2, unsafeCount: 4 },
  ];

  // Clean data to replace "Unknown" with "N/A"
  const cleanData = (data, type) =>
    data.map((item) => ({
      ...item,
      [type]: item[type] === "Unknown" ? "N/A" : item[type],
    }));

  // Simulate fetching the data and setting the state
  useEffect(() => {
    setDepartmentGraph(cleanData(departmentGraph, "department"));
    setSectionGraph(cleanData(sectionGraph, "section"));
  }, []);

  // Determine which data set to use based on the view (Department or Section)
  const graphData = view === "Department" ? departmentGraph : sectionGraph;

  // Extract safe and unsafe data for the graph
  const safeData = graphData.map((item) => item.safeCount);
  const unsafeData = graphData.map((item) => item.unsafeCount);
  const categories = graphData.map((item) =>
    view === "Department" ? item.department : item.section
  );

  // ApexCharts options
  const chartOptions = {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    colors: ["#28a745", "#dc3545"], // Green for safe, red for unsafe
    plotOptions: {
      bar: {
        columnWidth: "10%",
        endingShape: "rounded",
      },
    },
    xaxis: {
      categories: categories, // Departments or Sections
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      title: {
        // text: "Count",
      },
    },
    legend: { show: true },
    tooltip: { enabled: true },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
    },
  };

  const chartSeries = [
    {
      name: "Safe",
      data: safeData, // Safe counts
    },
    {
      name: "Unsafe",
      data: unsafeData, // Unsafe counts
    },
  ];

  // Handle toggle between Department and Section view
  const toggleView = () => {
    setView(view === "Department" ? "Section" : "Department");
  };

  const lineGrapht = [
    {
      date: "2024-09-08",
      getAntivirusNotInstalledCount: 30,
      AntivirusNotUpdatedCount: 25,
      getAntivirusMalfunctionCount: 23,
      getEDRNotInstalledCount: 22,
      firewallOffPCCount: 20,
      getNetworkViolationPCCount: 18,
      getPCsCountWithUnwantedPorts: 16,
      getPCsCountWithUnwhitelistedUSB: 14,
      tpmNotEnableedCount: 12,
      isSecureBootNotEnableedCount: 10,
      isOsNotHardenedCount: 8,
      adminPasswordAgeGreaterThanThresholdCount: 6,
      multipleAdminCount: 4,
      getNotPatchedOSCount: 2,
    },
    {
      date: "2024-09-09",
      getAntivirusNotInstalledCount: 20,
      AntivirusNotUpdatedCount: 30,
      getAntivirusMalfunctionCount: 25,
      getEDRNotInstalledCount: 23,
      firewallOffPCCount: 21,
      getNetworkViolationPCCount: 19,
      getPCsCountWithUnwantedPorts: 16,
      getPCsCountWithUnwhitelistedUSB: 14,
      tpmNotEnableedCount: 12,
      isSecureBootNotEnableedCount: 13,
      isOsNotHardenedCount: 11,
      adminPasswordAgeGreaterThanThresholdCount: 9,
      multipleAdminCount: 7,
      getNotPatchedOSCount: 3,
    },
    {
      date: "2024-09-10",
      getAntivirusNotInstalledCount: 28,
      AntivirusNotUpdatedCount: 27,
      getAntivirusMalfunctionCount: 25,
      getEDRNotInstalledCount: 20,
      firewallOffPCCount: 14,
      getNetworkViolationPCCount: 18,
      getPCsCountWithUnwantedPorts: 16,
      getPCsCountWithUnwhitelistedUSB: 8,
      tpmNotEnableedCount: 6,
      isSecureBootNotEnableedCount: 4,
      isOsNotHardenedCount: 11,
      adminPasswordAgeGreaterThanThresholdCount: 3,
      multipleAdminCount: 15,
      getNotPatchedOSCount: 7,
    },
    {
      date: "2024-09-11",
      getAntivirusNotInstalledCount: 8,
      AntivirusNotUpdatedCount: 10,
      getAntivirusMalfunctionCount: 12,
      getEDRNotInstalledCount: 15,
      firewallOffPCCount: 18,
      getNetworkViolationPCCount: 20,
      getPCsCountWithUnwantedPorts: 26,
      getPCsCountWithUnwhitelistedUSB: 29,
      tpmNotEnableedCount: 24,
      isSecureBootNotEnableedCount: 34,
      isOsNotHardenedCount: 31,
      adminPasswordAgeGreaterThanThresholdCount: 13,
      multipleAdminCount: 21,
      getNotPatchedOSCount: 12,
    },
    {
      date: "2024-09-12",
      getAntivirusNotInstalledCount: 11,
      AntivirusNotUpdatedCount: 21,
      getAntivirusMalfunctionCount: 25,
      getEDRNotInstalledCount: 29,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 9,
      getPCsCountWithUnwantedPorts: 13,
      getPCsCountWithUnwhitelistedUSB: 17,
      tpmNotEnableedCount: 27,
      isSecureBootNotEnableedCount: 21,
      isOsNotHardenedCount: 24,
      adminPasswordAgeGreaterThanThresholdCount: 11,
      multipleAdminCount: 18,
      getNotPatchedOSCount: 30,
    },
    {
      date: "2024-09-13",
      getAntivirusNotInstalledCount: 4,
      AntivirusNotUpdatedCount: 6,
      getAntivirusMalfunctionCount: 8,
      getEDRNotInstalledCount: 10,
      firewallOffPCCount: 12,
      getNetworkViolationPCCount: 14,
      getPCsCountWithUnwantedPorts: 16,
      getPCsCountWithUnwhitelistedUSB: 18,
      tpmNotEnableedCount: 20,
      isSecureBootNotEnableedCount: 22,
      isOsNotHardenedCount: 24,
      adminPasswordAgeGreaterThanThresholdCount: 26,
      multipleAdminCount: 28,
      getNotPatchedOSCount: 30,
    },
    {
      date: "2024-09-14",
      getAntivirusNotInstalledCount: 0,
      AntivirusNotUpdatedCount: 0,
      getAntivirusMalfunctionCount: 0,
      getEDRNotInstalledCount: 9,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 4,
      getPCsCountWithUnwantedPorts: 8,
      getPCsCountWithUnwhitelistedUSB: 20,
      tpmNotEnableedCount: 1,
      isSecureBootNotEnableedCount: 5,
      isOsNotHardenedCount: 9,
      adminPasswordAgeGreaterThanThresholdCount: 5,
      multipleAdminCount: 4,
      getNotPatchedOSCount: 0,
    },
  ];
  const seriesOne = [
    {
      name: "Antivirus Not Installed",
      data: lineGraph?.map((item) => item.getAntivirusNotInstalledCount),
    },
    {
      name: "Antivirus Not Updated",
      data: lineGraph?.map((item) => item.AntivirusNotUpdatedCount),
    },
    {
      name: "Antivirus Malfunction",
      data: lineGraph?.map((item) => item.getAntivirusMalfunctionCount),
    },
    {
      name: "EDR Not Installed",
      data: lineGraph?.map((item) => item.getEDRNotInstalledCount),
    },
    {
      name: "Firewall Off",
      data: lineGraph?.map((item) => item.firewallOffPCCount),
    },
    {
      name: "Network Violation",
      data: lineGraph?.map((item) => item.getNetworkViolationPCCount),
    },
    {
      name: "Unwanted Ports",
      data: lineGraph?.map((item) => item.getPCsCountWithUnwantedPorts),
    },
    {
      name: "Unwhitelisted USB",
      data: lineGraph?.map((item) => item.getPCsCountWithUnwhitelistedUSB),
    },
    {
      name: "TPM Not Enabled",
      data: lineGraph?.map((item) => item.tpmNotEnableedCount),
    },
    {
      name: "Secure Boot Not Enabled",
      data: lineGraph?.map((item) => item.isSecureBootNotEnableedCount),
    },
    {
      name: "OS Not Hardened",
      data: lineGraph?.map((item) => item.isOsNotHardenedCount),
    },
    {
      name: "Admin Password Age Exceeded",
      data: lineGraph?.map(
        (item) => item.adminPasswordAgeGreaterThanThresholdCount
      ),
    },
    {
      name: "Multiple Admins",
      data: lineGraph?.map((item) => item.multipleAdminCount),
    },
    {
      name: "OS Not Patched",
      data: lineGraph?.map((item) => item.getNotPatchedOSCount),
    },
  ];

  const optionOne = {
    chart: {
      type: "area",
      height: 430,
    },
    title: {
      text: "Weekly Security Overview",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    xaxis: {
      categories: lineGraph?.map((item) => formatTimestampGraph(item.date)), // X-axis based on date
      title: {
        text: "Date",
      },
    },
    yaxis: {
      title: {
        // text: "Count",
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd MMM yyyy",
      },
    },
    colors: [
      "#1E90FF", // Dodger Blue
      "#FF4500", // Orange Red
      "#32CD32", // Lime Green
      "#FFD700", // Gold
      "#FF6347", // Tomato
      "#40E0D0", // Turquoise
      "#8A2BE2", // Blue Violet
      "#ff0000", // Deep Pink
      "#00BFFF", // Deep Sky Blue
      "#FF69B4", // Hot Pink
      "#98FB98", // Pale Green
      "#FF00FF", // Magenta
      "#8B4513", // Saddle Brown
      "#4682B4", // Steel Blue
      "#D2691E", // Chocolate
    ],
  };

  // // Mapping safe and unsafe counts
  // const safeData = deptSafeUnsafe?.map((item, index) => [
  //   index,
  //   item.safeCount,
  // ]);
  // const unsafeData = deptSafeUnsafe?.map((item, index) => [
  //   index,
  //   item.unsafeCount,
  // ]);

  // // Mapping department names dynamically
  // const departmentNames = deptSafeUnsafe?.map((item) => item.department); // Assuming each item has a `departmentName`

  // const seriesEight = [
  //   {
  //     name: "Safe", // Label for Safe counts
  //     type: "column",
  //     data: safeData, // Safe counts
  //   },
  //   {
  //     name: "Unsafe", // Label for Safe counts
  //     type: "column",
  //     data: unsafeData, // Unsafe counts
  //   },
  // ];

  // const optionEight = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     stacked: true,
  //     type: "bar", // Creating bar chart
  //     toolbar: { show: false },
  //   },
  //   grid: {
  //     borderColor: "rgba(72,94,144, 0.07)",
  //     padding: {
  //       top: -20,
  //       left: 5,
  //       bottom: -15,
  //     },
  //   },
  //   states: {
  //     hover: {
  //       filter: { type: "none" },
  //     },
  //     active: {
  //       filter: { type: "none" },
  //     },
  //   },
  //   colors: ["#506fd9", "#e5e9f2"], // Colors for Safe and Unsafe counts
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "40%",
  //       endingShape: "rounded", // Rounded ends for the bars
  //     },
  //   },
  //   stroke: {
  //     curve: "straight",
  //     lineCap: "square",
  //     width: 0,
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: 5, // Adjust based on your data
  //     tickAmount: 5,
  //   },
  //   xaxis: {
  //     categories: departmentNames, // Dynamically generated department names
  //     labels: {
  //       style: {
  //         colors: "#6e7985",
  //         fontSize: "10px",
  //         fontWeight: "500",
  //       },
  //     },
  //   },
  //   tooltip: { enabled: true }, // Tooltips enabled
  //   legend: { show: true }, // Show legend
  // };

  // const safeData =
  //   view === "Department"
  //     ? deptSafeUnsafe?.map((item, index) => [index, item?.safeCount])
  //     : sectionSafeUnsafe?.map((item, index) => [index, item?.safeCount]);

  // const unsafeData =
  //   view === "Department"
  //     ? deptSafeUnsafe?.map((item, index) => [index, item?.unsafeCount])
  //     : sectionSafeUnsafe?.map((item, index) => [index, item?.unsafeCount]);

  // const categoriesGraph =
  //   view === "Department"
  //     ? deptSafeUnsafe?.map((item) => item?.department)
  //     : sectionSafeUnsafe?.map((item) => item?.section);
  const categoriesGraph =
    view === "Department"
      ? departmentGraph?.map((item) =>
          item?.department === "Unknown" ||
          item?.department === undefined ||
          !item?.department
            ? "Empty"
            : item?.department
        )
      : sectionSafeUnsafe?.map((item) =>
          item?.section === "Unknown" || !item?.section
            ? "Empty"
            : item?.section
        );

  // If you want to see all entries including duplicates:

  console.log(categoriesGraph, "fffff");

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "bar",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: { filter: { type: "none" } },
      active: { filter: { type: "none" } },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "5%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 10,
      tickAmount: 5,
      title: {
        text: "Number of Vulnerabilities",
      },
    },
    // yaxis: {
    //   title: {
    //     text: "Count",
    //   },
    // },
    xaxis: {
      categories: categoriesGraph,
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: true },
    legend: { show: true },
  };

  const seriesEight = [
    {
      name: "Safe", // Label for Safe counts
      type: "column",
      data: safeData, // Safe counts
    },
    {
      name: "Unsafe", // Label for Safe counts
      type: "column",
      data: unsafeData, // Unsafe counts
    },
  ];
  const buttonStyle = {
    backgroundColor: view === "Department" ? "#007bff" : "#28a745",
    color: "white",
    border: "none",
    padding: "3px 6px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "9px",
  };
  // const series = monthGraph?.map((item) => ({
  //   name: formatTimestampGraph(item.date),
  //   data: [
  //     item.getAntivirusNotInstalledCount || 0,
  //     item.AntivirusNotUpdatedCount || 0,
  //     item.getAntivirusMalfunctionCount || 0,
  //     item.getEDRNotInstalledCount || 0,
  //     item.firewallOffPCCount || 0,
  //     item.getNetworkViolationPCCount || 0,
  //     item.getPCsCountWithUnwantedPorts || 0,
  //     item.getPCsCountWithUnwhitelistedUSB || 0,
  //     item.tpmNotEnableedCount || 0,
  //     item.isSecureBootNotEnableedCount || 0,
  //     item.isOsNotHardenedCount || 0,
  //     item.adminPasswordAgeGreaterThanThresholdCount || 0,
  //     item.multipleAdminCount || 0,
  //     item.getNotPatchedOSCount || 0,
  //   ],
  // }));
  const series = (monthGraph || []).map((item = {}) => ({
    name: formatTimestampGraph(item.date || ""),
    data: [
      item.getAntivirusNotInstalledCount || 0,
      item.AntivirusNotUpdatedCount || 0,
      item.getAntivirusMalfunctionCount || 0,
      item.getEDRNotInstalledCount || 0,
      item.firewallOffPCCount || 0,
      item.getNetworkViolationPCCount || 0,
      item.getPCsCountWithUnwantedPorts || 0,
      item.getPCsCountWithUnwhitelistedUSB || 0,
      item.tpmNotEnableedCount || 0,
      item.isSecureBootNotEnableedCount || 0,
      item.isOsNotHardenedCount || 0,
      item.adminPasswordAgeGreaterThanThresholdCount || 0,
      item.multipleAdminCount || 0,
      item.getNotPatchedOSCount || 0,
    ],
  }));
  console.log("Data ssslength:", series[0]);

  console.log("Data length:", (series[0]?.data || []).length);

  // const options = {
  //   chart: {
  //     type: "bar",
  //     height: 600,
  //     stacked: true,
  //   },
  
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "35%",
  //       // borderRadius: 4,
  //       horizontal: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     style: {
  //       colors: ["#000"],
  //     },
  //     offsetY: -10,
  //   },
  //   stroke: {
  //     show: true,
  //     width: 2,
  //     colors: ["transparent"],
  //   },
  //   xaxis: {
  //     categories: [
  //       "AV Not Installed",
  //       "AV Not Updated",
  //       "AV Malfunction",
  //       "EDR Not Installed",
  //       "Firewall Off",
  //       "Network Violations",
  //       "Unwanted Open Ports",
  //       "USB Violations",
  //       "TPM Not Enabled",
  //       "Secure Boot Not Enabled",
  //       "OS Not Hardened",
  //       "Old Admin Passwords",
  //       "Root Privilege Accounts",
  //       "OS Not Patched",
  //     ],
  //   },
  //   yaxis: {
  //     title: {
  //       text: "Count",
  //     },
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: (val) => val,
  //     },
  //   },
  //   title: {
  //     text: "Monthly Security Overview",
  //     align: "center",
  //     style: {
  //       fontSize: "20px",
  //       fontWeight: "bold",
  //       color: "#263238",
  //     },

  //   },
   
  //   colors: ["#008FFB", "#FF4560"],
  // };

  const options = {
    chart: {
      type: "bar",
      height: 600,
      stacked: true,
    },
    
    plotOptions: {
      bar: {
        columnWidth: "35%", // Adjust bar width if necessary
        horizontal: false,
      },
    },
  
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"], // Label color
        fontSize: "12px",  // Optional: Adjust font size
        fontFamily: "Helvetica, Arial, sans-serif", // Optional: Adjust font family
      },
      offsetY: 2,  // Increase this to move labels higher above the bars
      background: {
        enabled: false, // Ensure there's no background around labels
      },
      dropShadow: {
        enabled: false, // Disable any shadow to avoid overlap
      },
      align: 'center',  // Center the data label within the bars
    },
  
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"], // No borders around bars
    },
  
    xaxis: {
      categories: [
        "AV Not Installed",
        "AV Not Updated",
        "AV Malfunction",
        "EDR Not Installed",
        "Firewall Off",
        "Network Violations",
        "Unwanted Open Ports",
        "USB Violations",
        "TPM Not Enabled",
        "Secure Boot Not Enabled",
        "OS Not Hardened",
        "Old Admin Passwords",
        "Root Privilege Accounts",
        "OS Not Patched",
      ],
      labels: {
        rotate: -45, // Optional: Rotate X-axis labels if they overlap
        style: {
          fontSize: '12px', // Adjust font size of X-axis labels if needed
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
  
    yaxis: {
      title: {
        // text: "Count",
      },
      labels: {
        style: {
          fontSize: "12px", // Adjust font size of Y-axis labels if needed
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
  
    fill: {
      opacity: 1,
    },
  
    tooltip: {
      enabled: false,
      y: {
        formatter: (val) => val,
      },
    },
  
    title: {
      text: "Monthly Security Overview",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
  
    colors: ["#008FFB", "#FF4560"], // Adjust colors of bars if necessary
  };
  
  var TotalLinuxEndpoint = (linuxSummary?.totalClients / totalDashboard) * 100;

  var totalLinuxCount =
    linuxSummary?.totalSafeClients +
    linuxSummary?.totalUnsafeClients +
    linuxSummary?.totalUnresponsivelients;

  var linux_Safe_Per =
    (linuxSummary?.totalSafeClients / linuxSummary?.totalClients) * 100;
  var linux_UnSafe_Per =
    (linuxSummary?.totalUnsafeClients / linuxSummary?.totalClients) * 100;
  var linux_UnResponsive_Per =
    (linuxSummary?.totalUnresponsivelients / linuxSummary?.totalClients) * 100;

  linux_Safe_Per = Math.round(linux_Safe_Per);
  linux_UnSafe_Per = Math.round(linux_UnSafe_Per);
  linux_UnResponsive_Per = Math.round(linux_UnResponsive_Per);
  TotalLinuxEndpoint = Math.round(TotalLinuxEndpoint);

  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (char) => char.toUpperCase());
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const exportToPDF = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const userType = user?.data?.user?.roleName || "Admin Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(linuxSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(linuxAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );

    doc.setFontSize(15);
    doc.text(`Linux Summary Data`, 14, 22);

    const tableColumn = ["Sl.No", "Category", "Total Count"];

    const tableRows = data?.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
    });

    doc.save("linux_summary_data.pdf");
  };

  const exportToCSV = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(linuxSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(linuxAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const tableHeaders = ["Sl.No", "Category", "Total Count"];

    const tableRows = data.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    const csvContent = [
      `Organization: ${orgName}, Exported: ${exportDateTime}`,
      "",
      tableHeaders.join(","),
      ...tableRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "linux_summary_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sortedAlerts = [
    {
      alertIcon: avBlueIcon,
      alertTitle: "AV Not Installed",
      alertNumber: linuxAlertSummary?.avNotInstalled,
      apiUrl: ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: avRedIcon,
      alertTitle: "AV Malfunction",
      alertNumber: linuxAlertSummary?.avMalfunction,
      apiUrl: ANTI_VIRUS_MALFUNCTION_ENDPOINT,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Malfunction"
      )[0]?.cardDescription,
    },
    {
      alertIcon: avBlackIcon,
      alertTitle: "AV Not Updated",
      alertNumber: linuxAlertSummary?.avNotUpdated,
      apiUrl: ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "AV Not Updated"
      )[0]?.cardDescription,
    },
    {
      alertIcon: edr,
      alertTitle: "EDR Not Installed",
      alertNumber: linuxAlertSummary?.edrNotInstalled,
      apiUrl: EDR_NOT_INSTALLED_ENDPOINT,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "EDR Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: fireIcon,
      alertTitle: "Firewall Off",
      alertNumber: linuxAlertSummary?.firwallOff,
      apiUrl: LINUX_FIREWALL_OFF,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Firewall Off"
      )[0]?.cardDescription,
    },
    {
      alertIcon: usbIcon,
      alertTitle: "USB Violations",
      alertNumber: linuxAlertSummary?.usbViolation,
      apiUrl: TOTAL_USB_VOILATION,
      routeUrl: "/linux-usb-violation",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "USB Violations"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unrecog,
      alertTitle: "Unrecognized IP Address",
      alertNumber: linuxAlertSummary?.networkViolation,
      apiUrl: NETWORK_VIOLATION,
      routeUrl: "/linux-serverity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unrecognized IP Address"
      )[0]?.cardDescription,
    },
    {
      alertIcon: openport,
      alertTitle: "Unwanted Open Ports",
      alertNumber: linuxAlertSummary?.unwantedPorts,
      apiUrl: UNWANTED_PORT,
      routeUrl: "/linux-serverity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unwanted Open Ports"
      )[0]?.cardDescription,
    },
    {
      alertIcon: usbviolation,
      alertTitle: "Unknown Network Comm",
      alertNumber: linuxAlertSummary?.communicationViolation,
      apiUrl: NETWORK_COMMUNICATION_VIOLATION,
      routeUrl: "/linux-serverity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Unknown Network Communication"
      )[0]?.cardDescription,
    },
    {
      alertIcon: tpm_icon,
      alertTitle: "TPM Not Available",
      alertNumber: linuxAlertSummary?.tpmNotEnabled,
      apiUrl: TPM_DISABLED,
      routeUrl: "/linux-serverity-usb",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "TPM Not Available"
      )[0]?.cardDescription,
    },
    {
      alertIcon: secure,
      alertTitle: "Secure Boot Not Enabled",
      alertNumber: linuxAlertSummary?.fipsNotEnableed,
      apiUrl: IS_SECURE_BOOT_DISABLED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Secure Boot Not Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: oshardingset,
      alertTitle: "OS Not Hardened",
      alertNumber: linuxAlertSummary?.isOsNotHardenedCountSet,
      apiUrl: OS_NOT_HARDENED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "OS Not Hardened"
      )[0]?.cardDescription,
    },
    {
      alertIcon: os_not_patch,
      alertTitle: "OS Not Patched",
      alertNumber: linuxAlertSummary?.osNotPatched,
      apiUrl: LINUX_OS_NOT_PATCH,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "OS Not Patched"
      )[0]?.cardDescription,
    },
    {
      alertIcon: virus,
      alertTitle: "Virus Intrusion",
      alertNumber: 0,
      apiUrl: SCANNER_INSTALLED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Virus Intrusion"
      )[0]?.cardDescription,
    },
    {
      alertIcon: oldPassword,
      alertTitle: "Old Admin Passwords",
      alertNumber: linuxAlertSummary?.oldAdminPasswords,
      apiUrl: OLD_PASSWORD,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Old Admin Passwords"
      )[0]?.cardDescription,
    },
    {
      alertIcon: integrity,
      alertTitle: "File Integrity Check Failed",
      alertNumber: linuxAlertSummary?.fileIntegrityCheckFailedCount,
      apiUrl: FILE_INTERGRITY_FAILED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "File Integrity Check Failed"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts = sortedAlerts
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );

  const sortedAlerts2 = [
    {
      alertIcon: zen,
      alertTitle: "ZENworks Not Installed",
      alertNumber: linuxAlertSummary?.zenProgramNotInstallPCCount,
      apiUrl: ZEN_NOT_INSTALLED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "ZENworks Not Installed"
      )[0]?.cardDescription,
    },
    {
      alertIcon: ssl,
      alertTitle: "Certificate Not Available",
      alertNumber: linuxAlertSummary?.certificateClientProgramNotInstallPCCount,
      apiUrl: CERTIFICATE_CLIENT_NOT_INSTALLED,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Certificate Not Available"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unreccg,
      alertTitle: "Unrecognized Software",
      alertNumber: linuxAlertSummary?.blacklistedSoftware,
      apiUrl: BLACKLISTED_PROGRAM_ENDPOINTS,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Unrecognized Software"
      )[0]?.cardDescription,
    },
    {
      alertIcon: unknow,
      alertTitle: "Unknown Services",
      alertNumber: linuxAlertSummary?.unknownServices,
      apiUrl: UNKNOWN_SERVICE,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Unknown Services"
      )[0]?.cardDescription,
    },
    {
      alertIcon: wifi,
      alertTitle: "Wi-Fi Enabled",
      alertNumber: linuxAlertSummary?.wifiPcCount,
      apiUrl: WIFI_ENABLED,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Wi-Fi Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: bluetooth,
      alertTitle: "Bluetooth Enabled",
      alertNumber: linuxAlertSummary?.blueToothCount,
      apiUrl: BLUETOOTH_ENABLED,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Bluetooth Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: windows_rdp_gateway,
      alertTitle: "RDP Enabled",
      alertNumber: linuxAlertSummary?.rdpEnabled,
      apiUrl: RDP_ENABLED,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "RDP Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: fipsnot,
      alertTitle: "FIPS Not Enabled",
      alertNumber: linuxAlertSummary?.fipsNotEnableed,
      apiUrl: IS_FIPS_DISABLE,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "FIPS Not Enabled"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts2 = sortedAlerts2
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );

  const sortedAlerts3 = [
    {
      alertIcon: publicshare,
      alertTitle: "Shared Folders Detected",
      alertNumber: linuxAlertSummary?.sharedFolder,
      apiUrl: SHARED_FOLDER,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Shared Folders Detected"
      )[0]?.cardDescription,
    },
    {
      alertIcon: cddrive,
      alertTitle: "CD/DVD Drive Enabled",
      alertNumber: linuxAlertSummary?.cdDriveEnabled,
      apiUrl: CD_DRIVE_ENABLED,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "CD/DVD Drive Enabled"
      )[0]?.cardDescription,
    },
    {
      alertIcon: hardingSet,
      alertTitle: "Process Not Hardened",
      alertNumber: linuxAlertSummary?.additionalProcessHardeningInfoCount,
      apiUrl: IS_PROCESS_HARDENING_INFO,
      routeUrl: "/linux-serverity",
      controlApi: true,
      description: tooltipData.filter(
        (item) => item.cardName === "Process Not Hardened"
      )[0]?.cardDescription,
    },
    {
      alertIcon: battery,
      alertTitle: "BIOS Battery Unserviceable",
      alertNumber: linuxAlertSummary?.biosBatteryHealth,
      apiUrl: BIOS_BATTERY,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "BIOS Battery Unserviceable"
      )[0]?.cardDescription,
    },
    {
      alertIcon: ram,
      alertTitle: "High RAM Usage",
      alertNumber: linuxAlertSummary?.highRamUsage,
      apiUrl: IS_HIGH_RAM_USAGE,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "High RAM Usage"
      )[0]?.cardDescription,
    },
    {
      alertIcon: cpu,
      alertTitle: "High CPU Usage",
      alertNumber: linuxAlertSummary?.highCpuUsage,
      apiUrl: IS_HIGH_CPU_USAGE,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "High CPU Usage"
      )[0]?.cardDescription,
    },
    {
      alertIcon: multRoot,
      alertTitle: "Multiple Root Privilege Account",
      alertNumber: linuxAlertSummary?.mulitpleAdmins,
      apiUrl: MULTIPLE_PASSWORD,
      routeUrl: "/linux-serverity",
      description: tooltipData.filter(
        (item) => item.cardName === "Multiple Root Privilege Account"
      )[0]?.cardDescription,
    },
  ];

  // Sort the array by alertNumber (descending) and then by alertTitle (alphabetical)
  const sortedAndFilteredAlerts3 = sortedAlerts3
    .filter(
      (alert) => alert.alertNumber !== undefined && alert.alertNumber !== null
    )
    .sort(
      (a, b) =>
        b.alertNumber - a.alertNumber ||
        a.alertTitle.localeCompare(b.alertTitle)
    );
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        {/* <ol className="breadcrumb fs-sm mb-1">
      <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
             Dashboard
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/linux-dash" activeClassName="active">
              Linux Dashboard
            </NavLink>
          </li>
        
        </ol> */}

        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">Dashboard</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-dash" ? (
              <span className="active">Linux Dashboard</span>
            ) : (
              <NavLink exact to="/linux-dash">
                Linux Dashboard
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Linux Dashboard</p>
          </span>

          <div className="d-flex gap-2 mt-3 mt-md-0 align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={exportToPDF}
              >
                <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
              </button>

              <button
                type="button"
                className="btn btn-success text-white"
                onClick={exportToCSV}
              >
                <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
              </button>
            </div>
          </div>
        </div>
        {!loading ? (
          <>
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Linux Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={linuxSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={TOTAL_LINUX_CLIENT}
                  routeUrl={"/linux-serverity"}
                  cardIndex={0}
                  riskAt={TotalLinuxEndpoint}
                  footerTitle={"endpoints are Linux"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Compliant Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={linuxSummary?.totalSafeClients || 0}
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={TOTAL_SAFE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={1}
                  riskAt={linux_Safe_Per ? linux_Safe_Per : "0"}
                  footerTitle={"endpoints are secure"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Non-compliant Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={linuxSummary?.totalUnsafeClients || 0}
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={TOTAL_UNSAFE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={2}
                  riskAt={linux_UnSafe_Per ? linux_UnSafe_Per : "0"}
                  footerTitle={"endpoints at risk"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Linux Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={linuxSummary?.totalUnresponsivelients || 0}
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={TOTAL_UNRESPONSIVE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={3}
                  riskAt={linux_UnResponsive_Per ? linux_UnResponsive_Per : "0"}
                  footerTitle={"endpoints unresponsive"}
                />
              </Col>
            </Row>
            {/* <ReactApexChart
              series={series} // Data for the chart
              options={options} // Chart configurations
              type="bar"
              height={440}
            /> */}

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts2.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      {sortedAndFilteredAlerts3.map((alert, index) => (
                        <Col xl="3" key={index}>
                          <Alerts
                            alertIcon={alert.alertIcon}
                            alertTitle={alert.alertTitle}
                            alertNumber={alert.alertNumber}
                            apiUrl={alert.apiUrl}
                            routeUrl={alert.routeUrl}
                            controlApi={alert.controlApi}
                            description={alert.description}
                          />
                        </Col>
                      ))}
                    </Row>
                    {/* <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={publicshare}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={linuxAlertSummary?.sharedFolder}
                          apiUrl={SHARED_FOLDER}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) =>
                                item.cardName === "Shared Folders Detected"
                            )[0].cardDescription
                          }
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={cddrive}
                          alertTitle={"CD/DVD Drive Enabled"}
                          alertNumber={linuxAlertSummary?.cdDriveEnabled}
                          apiUrl={CD_DRIVE_ENABLED}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) => item.cardName === "CD/DVD Drive Enabled"
                            )[0].cardDescription
                          }
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={hardingSet}
                          alertTitle={"Process Not Harden"}
                          alertNumber={
                            linuxAlertSummary?.additionalProcessHardeningInfoCount
                          }
                          apiUrl={IS_PROCESS_HARDENING_INFO}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                          description={
                            tooltipData.filter(
                              (item) => item.cardName === "Process Not Hardened"
                            )[0].cardDescription
                          }
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={battery}
                          alertTitle={"BIOS Battery Unserviceable"}
                          alertNumber={linuxAlertSummary?.biosBatteryHealth}
                          apiUrl={BIOS_BATTERY}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) =>
                                item.cardName === "BIOS Battery Unserviceable"
                            )[0].cardDescription
                          }
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={ram}
                          alertTitle={"High RAM Usage"}
                          alertNumber={linuxAlertSummary?.highRamUsage}
                          apiUrl={IS_HIGH_RAM_USAGE}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) => item.cardName === "High RAM Usage"
                            )[0].cardDescription
                          }
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={cpu}
                          alertTitle={"High CPU Usage"}
                          alertNumber={linuxAlertSummary?.highCpuUsage}
                          apiUrl={IS_HIGH_CPU_USAGE}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) => item.cardName === "High CPU Usage"
                            )[0].cardDescription
                          }
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={multRoot}
                          alertTitle={"Multiple Root Privilege Account"}
                          alertNumber={linuxAlertSummary?.mulitpleAdmins}
                          apiUrl={MULTIPLE_PASSWORD}
                          routeUrl={"/linux-serverity"}
                          description={
                            tooltipData.filter(
                              (item) =>
                                item.cardName ===
                                "Multiple Root Privilege Account"
                            )[0].cardDescription
                          }
                        />
                      </Col>
                    </Row> */}
                  </Card.Body>
                </Card>
              </Col>

              <Row className=" card-one m-1 shadow p-3">
                <Col xl="12">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="area"
                    height={430}
                    className="apex-chart-two"
                  />
                </Col>
              </Row>
              {/* <Row className=" card-one m-1 shadow p-3">
                <div>
                  <h3>Safe vs Unsafe Counts by {view}</h3>
                  <button onClick={toggleView} style={buttonStyle}>
                    Toggle to {view === "Department" ? "Section" : "Department"}{" "}
                    View
                  </button>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                    height={350}
                  />
                </div>
              </Row> */}

              <Row className=" card-one m-1 shadow p-3">
                <Col xl="12">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={400}
                  />{" "}
                </Col>
              </Row>
            </Row>
            <Footer />
            {/* <CustomToaster /> */}
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
