export const UNTRUSTED_APP_SOURCE =
  "appinfo/get-unknown-source-app-detected-list";

export const OLD_PATCH = "osinfo/security-patch-older-than-threshold-list";

export const SE_LINUX_STATUS = "osinfo/device-se-linux-disabled-list";

export const ROOTED_DEVICE = "osinfo/device-rooted-status-enabled-list";

export const DEVELOPER_STATUS = "osinfo/developer-mode-status-enabled-list";

export const USB_DEBUGGING_STATUS = "osinfo/usb-debugging-status-enabled-list";

export const DEVICE_LOCK_STATUS = "osinfo/device-lock-status-disabled-list";

export const OPEN_NETWORK_CONNECTION =
  "networkinfo/get-open-network-connection-list";

export const BANNED_APPS = "appinfo/get-unknown-source-app-detected-list";

export const DANGEROUS_APPS = "threatsinfo/get-dangerous-app-detected-list";

export const BLUETOOTH_ENABLED = "cisinfo/bluetooth-settings-enabled-list";

export const SCREEN_TIME = "cisinfo/display-settings-enabled-list";

export const TIMEOUT_STATUS = "cisinfo/date-settings-enabled-list";

export const KEYBOARD_FOUND = "cisinfo/input-method-settings-enabled-list";

export const USB_VOLIATION = "usbinfo/total-usb-insertions";

export const EMULATOR_DETECTED = "othersinfo/get-device-emulator-enable-list";

export const USB_LIST_DATA = "usbinfo/total-usb-insertions-device-list";
