import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useFetchOsAndroid = (pcInfoId) => {
  const [osData, setOsData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState([]);
  const [antivirusInfoData, setAntivirusInfoData] = useState([]);
  const [hardwareInfoData, setHardwareInfoData] = useState([]);
  const [osPatchUpdateInfoData, setOsPatchUpdateInfoData] = useState(null);
  const [usbInfoData, setUsbInfoData] = useState([]);
  const [bluetoothInfoData, setBluetoothInfoData] = useState([]);
  const [installedProgrameData, setInstalledProgrameData] = useState([]);
  const [serviceProgrameData, setServiceProgrameData] = useState([]);
  const [startUpProgrameData, setStartUpProgrameData] = useState([]);
  const [recentEventData, setRecentEventData] = useState([]);
  const [threatsData, setThreatsData] = useState([]);
  const [locationInfo,setLocationInfo] = useState([])
  const [usbHistory,setUsbHistory] = useState([])
  const [fileHistory,setFileHistory] = useState([])
  const [cisData, setCisData] = useState([]);
  const [osloading, setOsLoading] = useState(true);
  const [securityControls,setSecurityControls] = useState([])
  const [othersData, setOthersData] = useState([]);
  const [auditScore, setAuditScore] = useState([0]);

  const { user } = useSelector((state) => state.authSlice);

  console.log(user?.data?.user?.userId,"user?.data?.user?.userId");

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        // const response = await axios.get(
        //   `${process.env.REACT_APP_ANDROID_URL}legacyinfo/dashboard/legacy-latest-by-androidId?androidId=f7ef16793e64f158`,
        //   { cancelToken: source.token }
        // );
        const response = await axios.get(
          `${process.env.REACT_APP_ANDROID_URL}/legacyinfo/dashboard/legacy-latest-by-androidId/${user?.data?.user?.userId}?androidId=${pcInfoId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          },
          { cancelToken: source.token }
        );
        const osEntriesData = response?.data?.latestLegacyLog;


        console.log(response?.data,'response?.data?.latestLegacyLog;')
       const  EventsTriggered = response?.data;

        console.log(
          Math.round(
            osEntriesData?.hardwareInfo?.internalHardDrivesInfo?.reduce(
              (s, v) => (s += +v?.Size),
              0
            )
          ),
          "HARDRIVE DATA"
        );

        setBasicInfoData([
          {
            "Android ID": osEntriesData?.androidId?.slice(0, 20) || "NA",
            // "Android Name": osEntriesData?.hardware[0]?.brandName || "NA",
            "Ip Address":
              osEntriesData?.network?.reduce((latest, current) =>
                new Date(latest.timestamp).getTime() >
                new Date(current.timestamp).getTime()
                  ? latest
                  : current
              ).deviceIp || "NA",
              "Trigger Type": osEntriesData?.eventTriggerType,
              "Audit Time" :response?.data?.formattedLoginTime,
              "Last Sync Time": response?.data?.formattedserverTimestamp,
              "Audit Agent Version" :osEntriesData?.currentAgentVersion,
              "Endpoint ID" : osEntriesData?.licenseKey

          },
        ]);

        

        setAuditScore(osEntriesData?.auditScore); // 0-1000
        setOsData([
          {
            "OS ": osEntriesData?.osType || "NA",
            "OS Version": osEntriesData?.os?.[0]?.androidVersion || "NA",
            "Encryption Status": osEntriesData?.os?.[0]?.encryptionStatus || "NA",
          },
        ]);
        setSecurityControls([

          {
            "Security Patch": osEntriesData?.os?.[0]?.securityPatch || "NA",
            "Root Status":
              osEntriesData?.os?.[0]?.deviceRootedStatus,
            "Device Lock":
              osEntriesData?.os?.[0]?.deviceLockStatus  || "NA",
            "Developer Mode Status":
            osEntriesData?.os?.[0]?.developerModeStatus.toString() || "NA",
            "USB Debugging Status": osEntriesData?.os?.[0]?.usbDebuggingStatus.toString()
            || "NA",
            "SELinux Status": osEntriesData?.os?.[0]?.seLinuxStatus || "NA",
          },

        ])

        console.log(osEntriesData?.os?.[0]?.usbDebuggingStatus,'usbDebuggingStatus')
        console.log(osEntriesData?.os?.[0]?.developerModeStatus,'developerModeStatus')


        let dnsServerArray = osEntriesData?.network[0]?.dns || "NA";
        // Check if dnsServerArray is a string that contains brackets
        if (typeof dnsServerArray === 'string' && dnsServerArray.startsWith('[') && dnsServerArray.endsWith(']')) {
            dnsServerArray = dnsServerArray.slice(1, -1); // Remove the brackets
        }
      
        
        


        setNetworkData([
          [
            {
              "Type": osEntriesData?.network[0]?.type || "NA",
              "Name": osEntriesData?.network[0]?.name || "NA",
              "IP Address": osEntriesData?.network[0]?.deviceIp || "NA",
              "MAC Address": osEntriesData?.network[0]?.bssid || "NA",
              "DNS Server": dnsServerArray,
              "Frequency": osEntriesData?.network[0]?.frequency || "NA",
              "Encryption": osEntriesData?.network[0]?.encryption || "NA",
              "Country Code": osEntriesData?.network[0]?.countryCode || "NA",

            },
          ],
          {
            totalOpenPorts: osEntriesData?.networkInfo?.openTcpPorts || [],
            totalNICCards: osEntriesData?.hardwareInfo?.nicInfo || [],
          },
        ]);
        setAntivirusInfoData([
          [
            {
              "Installed AV": "0",
              "Enterprise AV Installed": "0",
            },
          ],
          [],
        ]);

        console.log(osEntriesData, "OSSERIAL");

        var Set_hardware;
        if(osEntriesData?.hardware[0]?.sdcardSize  == 0){
          Set_hardware ="NA"
        }else{
          Set_hardware = osEntriesData?.hardware[0]?.sdcardSize  + " GB"
        }

        setHardwareInfoData([
          {
            
            "Manufacturer": osEntriesData?.hardware[0]?.manufacturer || "NA",
            "Model": osEntriesData?.hardware[0]?.model || "NA",
            "CPU Name": osEntriesData?.hardware[0]?.cpuName || "NA",
            "CPU Architecture":
              osEntriesData?.hardware[0]?.cpuArchitecture || "NA",

            "RAM": osEntriesData?.hardware[0]?.ramSize +' GB' || "NA",
            "ROM": osEntriesData?.hardware[0]?.romSize +' GB' || "NA",
            "SD Card": Set_hardware || "NA",

            // NA set at zero value 
            // "Android Version": osEntriesData?.os?.[0]?.androidVersion || "NA",
          },
        ]);
        setOsPatchUpdateInfoData(osEntriesData?.OsPatchUpdateInfo || []);

        setUsbInfoData([
          [
            {
              "Ports Found": 3,
              "Ports Enabled": 3,
            },
          ],
          osEntriesData?.UsbInformation?.usbStoredHistory || [],
        ]);

        setBluetoothInfoData([
          [
            {
              "Bluetooth Status": osEntriesData?.hardwareInfo?.bluetoothInfo
                ?.status
                ? osEntriesData?.hardwareInfo?.bluetoothInfo?.status
                : "NA",
              "Paried Device":
                osEntriesData?.hardwareInfo?.bluetoothInfo?.pairedDevice
                  .length || 0,
            },
          ],
          {
            blueToothPariedDevice:
              osEntriesData?.hardwareInfo?.bluetoothInfo?.pairedDevice || [],
          },
        ]);

        setInstalledProgrameData(osEntriesData?.apps || []);
        setLocationInfo(osEntriesData?.locations || []);
        
        setCisData(osEntriesData?.cis);
        setThreatsData(osEntriesData?.threats);

        setOthersData(osEntriesData?.others);

        setRecentEventData([EventsTriggered?.RecentEventsTriggered]);
        console.log(recentEventData,'recentEventData')
        setUsbHistory([EventsTriggered?.usbHistory]); // need to check
       
        setFileHistory([EventsTriggered?.FileHistory])

        setOsLoading(false);
      } catch (error) {
        setOsLoading(false);
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    // Cleanup function to cancel the request when the component unmounts
    return () => {
      source.cancel("Request canceled due to component unmount.");
    };
  }, [pcInfoId]);

  console.log(basicInfoData, "BASICINFODATA");

  return {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    osPatchUpdateInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    cisData,
    threatsData,
    othersData,
    locationInfo,
    usbHistory,
    securityControls,
    auditScore
  };
};

export default useFetchOsAndroid;
