const BasicClientData = [
  {
    clientId: "utCurse-HP5CG83791KG",
    hostname: "Win-Test1PC",
    userName: "utcurse",
    loginTime: "14-May-24 11:23:16 AM",
    lastBoot: "14-May-24 11:23:16 AM",
  },
  // Add more objects as needed
];

const hardwareInformation = [
  {
    User: "Manufacturer",
    Manufacturer: "Dell",
    Model: "HP EliteBook 830 G5",
    "CPU Info": "Intel(R) Core(TM) i5-14200U",
    RAM: "16 GB",
    "HDD/SSD": "512 GB",
    "OS Version": "23H135",
    "HDD/SSD Make": "Seagate Barracuda",
    "HDD/SSD Serial": "SEBAP12R",
    "BIOS Version": "4.74.1216",
    "Networks Cards": 2,
    "MAC Address": "0A:9A:F6:80:E9:7B",
    "Serial Number": "5CG83791KG",
    "BIT Type": "64bit",
    "Machine Type": "AMD64",
    // Add more key-value pairs as needed
  },
  // Add more objects as needed
];

const osInformation = [
  {
    Hostname: "Win-Test1PC",
    "OS Installed": "Windows 10",
    "OS Architecture": "64 Bit",
    "OS Version": "23H135",
    "OS Installed On": "14-Jan-24 11:23:16 AM",
    "Activation Status": true,
    Timezone: "+05:30 Asia/Kolkata (IST)",
    OSConfiguration: "Standalone-Workstation",
  },
  // You can add more objects as needed
];

export { BasicClientData, hardwareInformation, osInformation };
