import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import DashBoard from "../dashboard/DashBoard";
import Header from "./Header";
import React,{ useState } from "react";
export default function Main() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const [skin, setSkin] = useState(currentSkin);
  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add("sidebar-offset")
    : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
     <Header onSkin={setSkin} />
      <Sidebar />
      <Outlet />
    </React.Fragment>
  );
}
