// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";

// const useFetchAndroidSummary = () => {
//   const [loading, setLoading] = useState(false);
//   const [androidSummary, setAndroidSummary] = useState({
//     totalClients: 0,
//     totalSafeClients: 0,
//     totalUnsafeClients: 0,
//     totalUnresponsivelients: 0,
//   });
//   const [androidAlertSummary, setAndroidAlertSummary] = useState({
//     oldSecurityPatch: 0,
//     seLinuxStatus: 0,
//     deviceRootedJailbreaked: 0,
//     developerModeStatus: 0,
//     usbDebuggingStatus: 0,
//     deviceLockStatus: 0,
//     openNetworkConnection: 0,
//     untrustedAppSource: 0,
//     bannedApps: 0,
//     dangerousAppsRDP: 0,
//     bluetoothEnabled: 0,
//     screenTimeoutGreaterThan60Sec: 0,
//     thirdPartyKeyboardFound: 0,
//     timeZoneStatus: 0,
//     usbVoliation: 0,
//     emulatorDetected: 0,
//   });
//   const { user } = useSelector((state) => state.authSlice);
//   const [totalDashboard, setTotalDashboard] = useState(0)

//   useEffect(() => {
//     const source = axios.CancelToken.source();

//     const fetchWindowSummary = async () => {
//       try {
//         setLoading(true);

//         // const response = await axios.get(process.env.REACT_APP_SUMMARY_URL, {
//         //   cancelToken: source.token,
//         // });


//         const response = await axios.get(
//           `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );

//         console.log(response?.data?.android, "RESPONSELINUX");
//         setTotalDashboard(response?.data?.totalDevicesCount);

//         const {
//           androidDevicesCount,
//           getDeviceRootedStatusEnabledCount,
//           devicesWithOldSecurityPatch,
//           developerModeStatusEnabledCount,
//           usbDebuggingStatusEnabledCount,
//           deviceLockStatusDisabledCount,
//           getOpenNetworkConnection,
//           getDangerousAppDetectedCount,
//           countDevicesWithBluetoothSettingsActionCount,
//           countDevicesWithDateSettingsActionCount,
//           countDevicesWithDisplaySettingsActionCount,
//           countDevicesWithInputMethodSettingsActionCount,
//           getSeLinuxStatusDisabledCount,
//           totalUsbInsertionsCount,
//           getDevicesEmulatorEnableCount,
//           getUnknownSourceAppDetectedCount,
//           getBlacklistedAppDetectedCount,
//           getSafeDeviceCount,
//           getUnsafeDeviceCount,
//         } = response?.data?.android;

//         setAndroidSummary({
//           totalClients: androidDevicesCount,
//           totalSafeClients: getSafeDeviceCount,
//           totalUnsafeClients: getUnsafeDeviceCount,
//           totalUnresponsivelients: 0,
//         });

//         setAndroidAlertSummary({
//           oldSecurityPatch:
//             devicesWithOldSecurityPatch?.olderThanThresholdCount,
//           seLinuxStatus: getSeLinuxStatusDisabledCount,
//           deviceRootedJailbreaked: getDeviceRootedStatusEnabledCount,
//           developerModeStatus: developerModeStatusEnabledCount,
//           usbDebuggingStatus: usbDebuggingStatusEnabledCount,
//           deviceLockStatus: deviceLockStatusDisabledCount,
//           openNetworkConnection: getOpenNetworkConnection,
//           untrustedAppSource: getUnknownSourceAppDetectedCount,
//           bannedApps: getBlacklistedAppDetectedCount,
//           dangerousAppsRDP: getDangerousAppDetectedCount,
//           bluetoothEnabled: countDevicesWithBluetoothSettingsActionCount,
//           screenTimeoutGreaterThan60Sec:
//             countDevicesWithDisplaySettingsActionCount,

//           thirdPartyKeyboardFound:
//             countDevicesWithInputMethodSettingsActionCount,
//           timeZoneStatus: countDevicesWithDateSettingsActionCount,
//           usbVoliation: totalUsbInsertionsCount,
//           emulatorDetected: getDevicesEmulatorEnableCount,
//         });

//         setLoading(false);
//       } catch (error) {
//         if (!axios.isCancel(error)) {
//           console.error("Error fetching summary data:", error);
//           setLoading(false);
//         }
//       }
//     };

//     fetchWindowSummary();

//     return () => {
//       // Cancel the request if the component unmounts
//       source.cancel();
//     };
//   }, []);

//   console.log(androidAlertSummary,'androidAlertSummary   aaaaaaaaaaaaaaaaaa')

//   return { loading, androidSummary, androidAlertSummary ,totalDashboard};
// };

// export default useFetchAndroidSummary;

// polling for every 1 min code
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useFetchAndroidSummary = () => {
  const [loading, setLoading] = useState(false);
  const [androidSummary, setAndroidSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [androidAlertSummary, setAndroidAlertSummary] = useState({
    oldSecurityPatch: 0,
    seLinuxStatus: 0,
    deviceRootedJailbreaked: 0,
    developerModeStatus: 0,
    usbDebuggingStatus: 0,
    deviceLockStatus: 0,
    openNetworkConnection: 0,
    untrustedAppSource: 0,
    bannedApps: 0,
    dangerousAppsRDP: 0,
    bluetoothEnabled: 0,
    screenTimeoutGreaterThan60Sec: 0,
    thirdPartyKeyboardFound: 0,
    timeZoneStatus: 0,
    usbVoliation: 0,
    emulatorDetected: 0,
  });
  const { user } = useSelector((state) => state.authSlice);
  const [totalDashboard, setTotalDashboard] = useState(0);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let timeoutId;

    const fetchAndroidSummary = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        console.log(response?.data?.android, "RESPONSE_ANDROID");
        setTotalDashboard(response?.data?.totalDevicesCount);

        const {
          androidDevicesCount,
          getDeviceRootedStatusEnabledCount,
          devicesWithOldSecurityPatch,
          developerModeStatusEnabledCount,
          usbDebuggingStatusEnabledCount,
          deviceLockStatusDisabledCount,
          getOpenNetworkConnection,
          getDangerousAppDetectedCount,
          countDevicesWithBluetoothSettingsActionCount,
          countDevicesWithDateSettingsActionCount,
          countDevicesWithDisplaySettingsActionCount,
          countDevicesWithInputMethodSettingsActionCount,
          getSeLinuxStatusDisabledCount,
          totalUsbInsertionsCount,
          getDevicesEmulatorEnableCount,
          getUnknownSourceAppDetectedCount,
          getBlacklistedAppDetectedCount,
          getSafeDeviceCount,
          getUnsafeDeviceCount,
        } = response?.data?.android;

        setAndroidSummary({
          totalClients: androidDevicesCount,
          totalSafeClients: getSafeDeviceCount,
          totalUnsafeClients: getUnsafeDeviceCount,
          totalUnresponsivelients: 0,
        });

        setAndroidAlertSummary({
          oldSecurityPatch: devicesWithOldSecurityPatch?.olderThanThresholdCount,
          seLinuxStatus: getSeLinuxStatusDisabledCount,
          deviceRootedJailbreaked: getDeviceRootedStatusEnabledCount,
          developerModeStatus: developerModeStatusEnabledCount,
          usbDebuggingStatus: usbDebuggingStatusEnabledCount,
          deviceLockStatus: deviceLockStatusDisabledCount,
          openNetworkConnection: getOpenNetworkConnection,
          untrustedAppSource: getUnknownSourceAppDetectedCount,
          bannedApps: getBlacklistedAppDetectedCount,
          dangerousAppsRDP: getDangerousAppDetectedCount,
          bluetoothEnabled: countDevicesWithBluetoothSettingsActionCount,
          screenTimeoutGreaterThan60Sec: countDevicesWithDisplaySettingsActionCount,
          thirdPartyKeyboardFound: countDevicesWithInputMethodSettingsActionCount,
          timeZoneStatus: countDevicesWithDateSettingsActionCount,
          usbVoliation: totalUsbInsertionsCount,
          emulatorDetected: getDevicesEmulatorEnableCount,
        });

        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Error fetching Android summary data:", error);
          setLoading(false);
        }
      }
    };

    // Initial fetch
    fetchAndroidSummary();

    // Set polling interval (e.g., 60 seconds)
    timeoutId = setInterval(fetchAndroidSummary, 60000);

    return () => {
      // Cancel the request if the component unmounts
      source.cancel();
      // Clear the interval when component unmounts
      clearInterval(timeoutId);
    };
  }, [user?.data?.user?.userId, user?.data?.accessToken]);

  return { loading, androidSummary, androidAlertSummary, totalDashboard };
};

export default useFetchAndroidSummary;
