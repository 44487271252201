import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function CustomButton({ buttonTitle, handleClick, buttonType }) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  return (
    <div className="m-0 p-0 w-100">
      <Button
        type={buttonType}
        className="rounded-md fs-11 w-100 border-0   mx-auto text-center fw-medium text-light bg-dark"
        onClick={handleClick}
      >
        <p className="p-0 m-0 text-center">{buttonTitle}</p>
      </Button>
    </div>
  );
}

export default CustomButton;
