import React, { useState, useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import cyberLogo from "../assets/img/onlyLogo.png";
import axios from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import CustomToaster from "../components/common/CustomToaster";
import { hideToaster, showToaster } from "../feature/slices/toastSlice";
import { useDispatch } from "react-redux";
const VerifyAcc = () => {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpExpiresAt, setOtpExpiresAt] = useState(null);
  const [otpCountdown, setOtpCountdown] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const userId = params.get("userId");
  const dispatch = useDispatch();
  useEffect(() => {
    if (otpExpiresAt) {
      const countdownInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const expiresAtTime = new Date(otpExpiresAt).getTime();
        const timeDifference = expiresAtTime - currentTime;

        if (timeDifference > 0) {
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setOtpCountdown(seconds);
        } else {
          setOtpCountdown(null);
          clearInterval(countdownInterval);
        }
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [otpExpiresAt]);

  const handleGenerateOTP = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/request-otp`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.data.status) {
        setOtpSent(true);
        setOtpExpiresAt(response.data.expiresAt);
        showToasterMessage("success", response.data.message);

        dispatch(
          showToaster({
            title: "OTP",
            message: "Sent successfully",
            color: "success",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
        }, 2000);
        // Show toaster message
      } else {
        // Handle failure, show error message
        showToasterMessage("error", response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      // Handle error, show error message
      showToasterMessage("error", "Failed to generate OTP.");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setOtpVerificationLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/email-verify`,
        { email, otp: parseInt(otp) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setOtpVerificationLoading(false);
      if (response.data.status) {
        // Handle success, show toaster message or redirect
        showToasterMessage("success", "OTP verified successfully!");
        dispatch(
          showToaster({
            title: "OTP",
            message: "Verified successfully!",
            color: "success",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
        }, 2000);
        // Show toaster message
        setOtpVerified(true);
      } else {
        // Handle failure, show error message
        showToasterMessage("error", "Failed to verify OTP.");
      }
    } catch (error) {
      setOtpVerificationLoading(false);
      console.error("Error:", error);
      // Handle error, show error message
      showToasterMessage("error", "Failed to verify OTP.");
    }
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordPattern.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a special character."
      );
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-profile/${userId}`,
        { password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status) {
        // Handle success, show toaster message or redirect
        showToasterMessage("success", "Password updated successfully!");
        dispatch(
          showToaster({
            title: "Password",
            message: "updated successfully",
            color: "success",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
          window.top.location.href = "/";
        }, 2000);
      } else {
        // Handle failure, show error message
        showToasterMessage("error", "Failed to update password.");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error, show error message
      showToasterMessage("error", "Failed to update password.");
    }
  };

  const showToasterMessage = (type, message) => {
    // Implement your toaster logic here
    // You can dispatch actions to your Redux store or use state to show toaster

    console.log(type, message, "message");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link
            to="/"
            className="sidebar-logo d-flex align-items-center gap-2 justify-content-start"
          >
            <img
              src={cyberLogo}
              id="logo"
              alt="Cyber Auditor"
              className="cyBerLogo"
            />
            <p className="m-0 p-0 fw-semibold fs-13">CYBER AUDITOR</p>
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          <div className="card shadow-sm rounded-4" style={{ width: "38rem" }}>
            <div className="card-body p-5">
              <h3 className="card-title" style={{ textAlign: "center" }}>
                Create an Account
              </h3>
              <p className="card-text">Become a Cyber Auditor Register User</p>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email address
                </label>
                <span className="form-control">{email}</span>
              </div>
              {/* <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-dark btn-sm"
                  onClick={handleGenerateOTP}
                  disabled={loading || otpSent}
                >
                  {loading ? (
                    <span>
                      <i className="fas fa-spinner fa-spin"></i> Generating
                      OTP...
                    </span>
                  ) : (
                    <span>
                      Generate OTP <i className="fas fa-paper-plane"></i>
                    </span>
                  )}
                </button>
                
                {otpSent && (
                  <div className="mt-3">
                    {otpCountdown !== null ? (
                      <p className="card-text">
                        OTP will expire in {otpCountdown} seconds
                      </p>
                    ) : (
                      <p className="card-text text-danger">OTP has expired</p>
                    )}
                  </div>
                )}
               
              </div> */}
              {!otpVerified && (
                <>
              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-dark btn-sm"
                  onClick={handleGenerateOTP}
                  disabled={loading || otpSent}
                >
                  {loading ? (
                    <span>
                      <i className="fas fa-spinner fa-spin"></i> Generating
                      OTP...
                    </span>
                  ) : (
                    <span>
                      Generate OTP <i className="fas fa-paper-plane"></i>
                    </span>
                  )}
                </button>

                {otpSent && (
                

                  <div className="mt-3 d-flex align-items-center">
      {otpCountdown !== null ? (
        <p className="card-text mb-0">OTP will expire in {otpCountdown} seconds</p>
      ) : (
        <>
          <p className="card-text text-danger mb-0 mr-2">OTP has expired</p> &nbsp;
          <button
            type="button"
            className="btn btn-warning btn-sm"
            onClick={() => window.location.reload()}
          >
            <i className="fa-solid fa-rotate"></i>
          </button>
        </>
      )}
    </div>
                )}
                
              </div>

              {otpSent && otpCountdown !== null && (
                <div className="mb-3">
                  <label htmlFor="otpInput" className="form-label">
                    Verify OTP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="otpInput"
                    placeholder="6 Digit OTP"
                    value={otp}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 6) {
                        setOtp(value);
                      }
                    }}
                    maxLength={6}
                  />
                  <button
                    type="button"
                    className="btn btn-dark btn-sm mt-3"
                    onClick={handleVerifyOTP}
                    disabled={otpVerificationLoading}
                  >
                    {otpVerificationLoading ? (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i> Verifying
                        OTP...
                      </span>
                    ) : (
                      <span>
                        Verify OTP <i className="fas fa-check"></i>
                      </span>
                    )}
                  </button>
                </div>
              )}
</>
              )}
              {otpVerified && (
                <form onSubmit={handleUpdatePassword}>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleInputPassword1"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                    {passwordError && (
                      <div className="form-text text-danger">
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span
                        className="input-group-text"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                   
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </Container>
      </div>

      <CustomToaster />
    </div>
  );
};

export default VerifyAcc;
