import React, { useEffect, useState, useRef } from "react";
import HistoryCard from "../../components/common/HistoryCard";
// import Header from "../../layouts/Header";
import windowIcon from "../../assets/img/DashIcon/newWindowIcon.png";
import { Col, Row, Button } from "react-bootstrap";
import meterIcon from "../../assets/img/meter.png";
import ReactSpeedometer from "react-d3-speedometer/slim";

import CustomButton from "../../components/common/CustomButton";
import CustomModal from "../../components/CustomModal";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import CustomAccordian from "../../components/common/CustomAccordian";
import PageLoader from "../../components/common/Loader/PageLoader";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { showToaster } from "../../feature/slices/toastSlice";
import { Dropdown, Menu } from "antd";
import GenratePdf from "../../components/common/GenratePdf";
import useFetchOsInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchOsInfo";
import { useSkinMode } from "../../components/SkinModeContext";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
function ClientDetail() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const { skinMode } = useSkinMode(); // Access the skin mode state
  const [skin, setSkin] = useState(currentSkin);
  const [handleNICModal, setHandleNICModal] = useState(false);
  const [handlePortModal, setHandlePortModal] = useState(false);
  const [handleAntiVirusModal, setHandleAntiVirusModal] = useState(false);
  const [handleUsbHistoryModal, setHandleUsbHistoryModal] = useState(false);
  const navigate = useNavigate();

  const [handleBlueToothInfoModal, setHandleBlueToothInfoModal] =
    useState(false);
  const [
    handleBlueToothInfoConnectedModal,
    setHandleBlueToothInfoConnectedModal,
  ] = useState(false);

  const [handleInstalledPage, setHandleInstalledPage] = useState(1);
  const [handleInstalledRowPage, setHandleRowPage] = useState(10);

  const [handleServicePage, setHandleServicePage] = useState(1);
  const [handleServiceRowPage, setHandleServiceRowPage] = useState(10);

  const [handleStartUpPage, setHandleStartUpPage] = useState(1);
  const [handleStartUpRowPage, setHandleStartUpRowPage] = useState(10);

  const [establishedConnectionPage, setEstablishedConnectionPage] = useState(1);
  const [establishedConnectionRowPage, setEstablishedConnectionRowPage] =
    useState(10);

  const [highCpuUsagePage, setHighCpuUsagePage] = useState(1);
  const [highCpuUsageRowPage, setHighCpuUsageRowPage] = useState(10);

  const [ramUsagePage, setRamUsagePage] = useState(1);
  const [ramUsageRowPage, setRamUsageRowPage] = useState(10);

  const [sharedDirPage, setSharedDirPage] = useState(1);
  const [sharedDirRowPage, setSharedDirRowPage] = useState(10);

  const [internalHardDrivePage, setInternalHardDrivePage] = useState(1);
  const [internalHardDriveRowPage, setInternalHardDriveRowPage] = useState(10);

  const [usbHistoryPage, setUsbHistoryPage] = useState([]);
  const [usbHistoryRowPage, setUsbHistoryRowPage] = useState([]);

  const [handleEventsTriggeredPage, setHandleEventsTriggeredPage] =
    useState(10);
  const [handleEventsTriggeredRowPage, setHandleEventsTriggeredRowPage] =
    useState(10);

  const [handleUsbFilePage, setHandleUsbFilePage] = useState(10);
  const [handleUsbFileRowPage, setHandleUsbFileRowPage] = useState(10);

  const [handleAntivirusPage, setHandleAntivirusPage] = useState(10);
  const [handleAntivirusRowPage, setHandleAntivirusRowPage] = useState(10);

  const location = useLocation();
  const { pcInfoId, timeTrigger, title } = location?.state;
  const darkModeStyles = {
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };
  if (skinMode === "dark") {
    document.body.classList.add("dark-skin"); // Add a class to the body
  } else {
    document.body.classList.remove("dark-skin"); // Remove it when it's not dark mode
  }
  console.log(title, "titletitletitle");

  const dispatch = useDispatch();

  const {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    windowCisData,
    establishedConnection,
    highCpuUsage,
    ramUsage,
    securityControls,
    accountDetail,
    osPatchInformation,
    sharedDir,
    internalHardDrive,
    logError,
    installedAv,
    malwareInfos,
    usbFileData,
    auditScore,
    opticalDriveInfo,
  } = useFetchOsInfo(pcInfoId, timeTrigger);

  console.log(bluetoothInfoData, "bluetoothInfoData");
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const avNotInstalledRef = useRef(null);
  const osnotpatchedRef = useRef(null);
  const edrNotInstalledRef = useRef(null);
  const usbViolationRef = useRef(null);
  const unRecoogRef = useRef(null);
  const unNetworkRef = useRef(null);
  const unTpmRef = useRef(null);
  const processNotHardenRef = useRef(null);
  const oldAdminpsdRef = useRef(null);
  const zenNetwokNotInsRef = useRef(null);
  const unknowServiceRef = useRef(null);
  const bluetoothTableRef = useRef(null);
  const shareDirectorysRef = useRef(null);
  const hardwareInfoRef = useRef(null);
  const highCpuUsageRef = useRef(null);
  const highRamUsageRef = useRef(null);
  const fileIntegrityFailedRef = useRef(null);
  const osNotHardenedRef = useRef(null);
  const secureBootRef = useRef(null);
  const fipsNotEnabledRef = useRef(null);
  const opticalDriveRef = useRef(null);
  const usbHistoryRef = useRef(null);
  const unRecogSoftwareRef = useRef(null);

  let activeRef = null;

  const scrollToSection = (ref) => {
    if (ref.current) {
      if (activeRef && activeRef.current) {
        activeRef.current.classList.remove("red-text");
      }

      const targetPosition =
        ref.current.getBoundingClientRect().top + window.scrollY - 90;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 800;
      let startTime = null;

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easing = easeInOutCubic(progress);

        window.scrollTo(0, startPosition + distance * easing);

        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        } else {
          ref.current.classList.add("scroll-border", "red-text");
          setTimeout(() => {
            ref.current.classList.remove("scroll-border", "red-text");
            activeRef = null;
          }, 2000);
        }
      };

      const easeInOutCubic = (t) => {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };
      requestAnimationFrame(animation);
    }
  };

  const handleScroll = () => {
    if (title === "AV Not Installed") {
      scrollToSection(avNotInstalledRef);
    } else if (title === "OS Not Patched") {
      scrollToSection(osnotpatchedRef);
    } else if (title === "Firewall Off") {
      scrollToSection(edrNotInstalledRef);
    } else if (title === "AV Malfunction") {
      scrollToSection(avNotInstalledRef);
    } else if (title === "AV Not Updated") {
      scrollToSection(avNotInstalledRef);
    } else if (title === "EDR Not Installed") {
      scrollToSection(edrNotInstalledRef);
    } else if (title === "USB Violations") {
      scrollToSection(usbViolationRef);
    } else if (title === "Unrecognized IP Address") {
      scrollToSection(unRecoogRef);
    } else if (title === "Unknown Network Comm") {
      scrollToSection(unNetworkRef);
    } else if (title === "TPM Not Available") {
      scrollToSection(unTpmRef);
    } else if (title === "Secure Boot Not Enabled") {
      scrollToSection(secureBootRef);
    } else if (title === "Old Admin Passwords") {
      scrollToSection(oldAdminpsdRef);
    } else if (title === "Multiple Root Privilege Account") {
      scrollToSection(oldAdminpsdRef);
    } else if (title === "ZENworks Not Installed") {
      scrollToSection(zenNetwokNotInsRef);
    } else if (title === "Certificate Not Available") {
      scrollToSection(zenNetwokNotInsRef);
    } else if (title === "Unknown Services") {
      scrollToSection(unknowServiceRef);
    } else if (title === "Wi-Fi Enabled") {
      scrollToSection(unRecoogRef);
    } else if (title === "Bluetooth Enabled") {
      scrollToSection(bluetoothTableRef);
    } else if (title === "RDP Enabled") {
      scrollToSection(edrNotInstalledRef);
    } else if (title === "FIPS Not Enabled") {
      scrollToSection(fipsNotEnabledRef);
    } else if (title === "Shared Folders Detected") {
      scrollToSection(shareDirectorysRef);
    } else if (title === "Process Not Hardened") {
      scrollToSection(processNotHardenRef);
    } else if (title === "BIOS Battery Unserviceable") {
      scrollToSection(hardwareInfoRef);
    } else if (title === "High CPU Usage") {
      scrollToSection(highCpuUsageRef);
    } else if (title === "High RAM Usage") {
      scrollToSection(highRamUsageRef);
    } else if (title === "Unwanted Open Ports") {
      scrollToSection(unRecoogRef);
    } else if (title === "File Integrity Check Failed") {
      scrollToSection(fileIntegrityFailedRef);
    } else if (title === "OS Not Hardened") {
      scrollToSection(osNotHardenedRef);
    } else if (title === "CD/DVD Drive Enabled") {
      scrollToSection(opticalDriveRef);
    } else if (title === "Unrecognized Software") {
      scrollToSection(zenNetwokNotInsRef);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleScroll();
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  if (logError) {
    dispatch(
      showToaster({
        title: "Log",
        message: "Not log Found",
        color: "warning",
      })
    );
  }

  const toHumanReadable = (text) => {
    return text
      .replace(/cis/gi, "CIS ") // Replace "cis" with "CIS " (case-insensitive)
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };
  const ServiceTable = ({ services }) => (
    <table>
      <thead>
        <tr>
          {/* <th>S.No </th> */}
          <th>Service</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {services.map((service, index) => (
          <tr key={service._id}>
            {/* <td>{index + 1}</td> */}
            <td>{service.service} &nbsp;</td>
            <td>{service.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold", // Make header text bold
      },
    },
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  // var Usb_histroy = usbInfoData[1]?.usbHistory.slice().reverse();
  var Usb_histroy = usbInfoData[1]?.usbHistory;
  // console.log(usbInfoData[1]?.usbHistory, "without sort");
  // console.log(Usb_histroy, "sorted");

  const customGutter = {
    "--bs-gutter-x": "8px",
  };


  const ejfNUe = {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor:' #FFFFFF'
}
const noDataStyleFound = skinMode === "dark" ? ejfNUe : { };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4" style={mainStyle}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb fs-sm mb-1 d-flex">
            <li className="breadcrumb-item">
              {location.pathname === "/" ? (
                <span className="active">Dashboard</span>
              ) : (
                <NavLink exact to="/">
                  Dashboard
                </NavLink>
              )}
            </li>
            <li className="breadcrumb-item">
              {location.pathname === "/window-dash" ? (
                <span className="active">Windows Dashboard</span>
              ) : (
                <button
                  onClick={() => navigate("/window-dash")}
                  className="btn btn-link p-0 m-0"
                >
                  Windows Dashboard
                </button>
              )}
            </li>
            {title && (
              <li className="breadcrumb-item">
                {location.pathname === "/endpoints-list" ? (
                  <span className="active">{title}</span>
                ) : (
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-link p-0 m-0"
                  >
                    {title}
                  </button>
                )}
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {location.pathname === "/windows-client-Details" ? (
                <span className="active text-secondary">Endpoint Details</span>
              ) : (
                <button
                  onClick={() => window.location.reload()}
                  className="btn btn-link p-0 m-0 "
                >
                  Endpoint Details
                </button>
              )}
            </li>
          </ol>
        </nav>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center justify-content-center gap-4">
            <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
              <Link
                to="/window-dash"
                className="shadow"
                style={{ borderRadius: "25px" }}
              >
                <img
                  src={windowIcon}
                  alt="Window-logo"
                  width={55}
                  height={55}
                />
              </Link>
              <p className="mx-2 fs-18 m-0 p-0">|</p>
              <h4 class="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">
                Window Endpoint:
              </h4>
            </span>

            <p className="p-0 m-0 fs-16 fw-bold text-primary">
              {basicInfoData?.[0]?.Hostname || "Client Detail"}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-4 ">
            <GenratePdf
              osData={osData}
              osloading={osloading}
              networkData={networkData}
              basicInfoData={basicInfoData}
              antivirusInfoData={antivirusInfoData}
              hardwareInfoData={hardwareInfoData}
              usbInfoData={usbInfoData}
              bluetoothInfoData={bluetoothInfoData}
              installedProgrameData={installedProgrameData}
              serviceProgrameData={serviceProgrameData}
              startUpProgrameData={startUpProgrameData}
              recentEventData={recentEventData}
              windowCisData={windowCisData}
              establishedConnection={establishedConnection}
              highCpuUsage={highCpuUsage}
              ramUsage={ramUsage}
              securityControls={securityControls}
              accountDetail={accountDetail}
              osPatchInformation={osPatchInformation}
              sharedDir={sharedDir}
              internalHardDrive={internalHardDrive}
              logError={logError}
            />

            <div className="d-flex justify-content-center align-items-center">
              {/* <Button
                className="btn-white rounded-md d-flex align-items-center gap-2"
                onClick={() => navigate(-1)}
              >
                <i
                  className="ri-arrow-left-line fs-18 lh-1 mr-2"
                  style={{ verticalAlign: "middle", marginRight: "3px" }}
                ></i>
                <span>Back</span>
              </Button> */}

              <Button
                type="button"
                className="btn btn-sm btn-primary text-white"
                onClick={() => navigate(-1)}
              >
                <i
                  className="ri-arrow-left-line fs-18 lh-1 mr-2"
                  style={{ verticalAlign: "middle", marginRight: "3px" }}
                ></i>{" "}
                Back
              </Button>
            </div>
            {/* <ReactSpeedometer
              width={150}
              height={102}
              needleHeightRatio={0.6}
              value={auditScore}
              currentValueText="Client Score"
              customSegmentLabels={[
                {
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  position: "INSIDE",
                  color: "#555",
                  fontSize: "19px",
                },
                {
                  position: "INSIDE",
                  color: "#555",
                },
                {
                  position: "INSIDE",
                  color: "#555",
                },
              ]}
              ringWidth={4}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              needleColor={"#90f2ff"}
              textColor={"#d8dee9"}
            /> */}
          </div>
        </div>
        {osloading ? (
          <PageLoader />
        ) : (
          <>
            <div class="row" style={customGutter}>
              <div class="col">
                <HistoryCard headerName={"Basic Information"}>
                  {basicInfoData?.length > 0 ? (
                    basicInfoData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div
                            key={key}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6 text-break text-dark">
                              {key === "IP Address" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap ms-auto gap-2">
                                  {value?.map((v) => (
                                    <p className="m-0 p-0 text-dark">{v}</p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0 text-dark">Basic Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard headerName={"Hardware Information"}>
                  {hardwareInfoData?.length > 0 ? (
                    hardwareInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div
                            key={key}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            {key === "RAM" ? (
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu className="p-2 card-one shadow-md">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Total
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end text-dark">
                                        {hardwareInfoData[1]?.ramData
                                          ?.totalRamGb + " GB" || "NA"}
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between ">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end text-dark">
                                        Used
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end text-dark">
                                        {hardwareInfoData[1]?.ramData
                                          ?.usedRamGb + " GB" || "NA"}
                                      </p>
                                    </div>
                                    {/* 
                                        <div className="d-flex align-items-center justify-content-between">
                                          <p className="p-0 m-0 fw-medium fs-10 fw-semibold text-end">
                                            Available
                                          </p>
                                          <p className="p-0 m-0  fs-10 fw-semibold text-end">
                                            {Math.round(
                                              hardwareInfoData[1]?.ramData
                                                ?.totalRamGb -
                                                hardwareInfoData[1]?.ramData
                                                  ?.usedRamGb
                                            ) + " GB" || "NA"}
                                          </p>
                                        </div> */}
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Free
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.freeRamGb + " GB" || "NA"}
                                      </p>
                                    </div>
                                  </Menu>
                                }
                              >
                                <p
                                  className="p-0 m-0 fw-medium fs-11 text-primary  w-50 text-end "
                                  style={{ cursor: "pointer" }}
                                >
                                  {value + " GB"}
                                </p>
                              </Dropdown>
                            ) : (
                              <div className="p-0 m-0 fw-medium fs-11 text-end text-dark">
                                {value}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Hardware Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard headerName={"OS Information"}>
                  {osData?.length > 0 ? (
                    osData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                            <p key={key} className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <p
                              className={` m-0 fw-medium text-end fs-11 ${
                                value === true ? "text-success" : "text-dark"
                              }`}
                            >
                              {typeof value === "boolean"
                                ? value.toString()
                                : value}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Os Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard
                  headerName={"Network Information"}
                  paddingBottom={true}
                >
                  <p className="fs-9 p-0 my-2 text-primary fw-semibold">
                    Current Network
                  </p>
                  {networkData?.length > 0 ? (
                    networkData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                            key={key}
                          >
                            <p className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6 text-dark">
                              {key === "DNS Server" ? (
                                <Dropdown
                                  trigger={["click"]}
                                  overlay={
                                    <Menu className="p-2 card-one shadow-md">
                                      {value?.map((v, i) => (
                                        <Menu.Item key={i}>
                                          <span className="p-0 m-0 fw-medium fs-11 fw-semibold text-end text-dark">
                                            {v}
                                          </span>
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  }
                                >
                                  <p
                                    className="p-0 m-0 fw-medium fs-11 text-primary w-50 text-end ml-4rem-important"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {value[0]}
                                  </p>
                                </Dropdown>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                        <div className="d-flex align-items-center gap-2 justify-content-between my-3">
                          <CustomButton
                            buttonTitle={"View Open Ports"}
                            handleClick={() => setHandlePortModal(true)}
                          />
                          <CustomButton
                            buttonTitle={"View NIC Cards"}
                            handleClick={() => setHandleNICModal(true)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Network Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col" ref={edrNotInstalledRef}>
                <HistoryCard headerName={"Security Controls"}>
                  {securityControls?.length > 0 ? (
                    securityControls?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div
                            key={key}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6 text-dark">
                              {key === "IP Address" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap   ms-auto gap-2">
                                  {value?.map((v) => (
                                    <p className="m-0 p-0">{v}</p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Basic Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>

              <div className="col">
                <HistoryCard headerName={"Bluetooth Details"}>
                  {bluetoothInfoData[0]?.length > 0 ? (
                    bluetoothInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                            <p key={key} className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <p className="p-0 m-0 fw-medium fs-11 text-end text-dark">
                              {value}
                            </p>
                          </div>
                        ))}
                        <div className="d-flex w-100 gap-2 align-items-center justify-content-between mt-5">
                          <CustomButton
                            buttonTitle={"Paired Devices History"}
                            handleClick={() =>
                              setHandleBlueToothInfoModal(true)
                            }
                          />
                          <CustomButton
                            buttonTitle={"Connected Devices History"}
                            handleClick={() =>
                              setHandleBlueToothInfoConnectedModal(true)
                            }
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Bluetooth Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div className="col-6">
                <HistoryCard
                  headerName={"USB History"}
                  infoButton={() => console.log("usbHistory")}
                >
                  <div className="pb-3">
                    <DataTable
                      fixedHeader={true}
                      columns={[
                        {
                          name: "Date/Time",
                          selector: (v, index) => v?.time,
                          width: "160px",
                        },

                        {
                          name: "Device Name",
                          selector: (v) => (
                            <Tippy content={v?.usbName}>
                              <div>{v?.usbName}</div>
                            </Tippy>
                          ),
                        },

                        {
                          name: "Type",
                          selector: (v) => v?.interfaceType,
                          width: "60px",
                        },
                        {
                          name: "Manufacturer",
                          selector: (v) => v?.usbManufacturer,
                          width: "120px",
                        },
                        {
                          name: "Serial Number",
                          selector: (v) => v?.usbSerialNumber,
                          width: "200px",
                        },
                        {
                          name: "Vendor ID",
                          selector: (v) => v?.usbVendorId,
                        },
                        {
                          name: "Product ID",
                          selector: (v) => v?.usbProductId,
                        },
                      ]}
                      data={Usb_histroy}
                      customStyles={currentStyles}
                      pagination
                      paginationPerPage={4} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setUsbHistoryPage(page);
                      }}
                      onChangeRowsPerPage={(page) => setUsbHistoryRowPage(page)}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          USB History is not available
                        </p>
                      }
                    />
                  </div>
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6">
                <HistoryCard
                  headerName={"Os Patch Information"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v, i) => v?.description,
                      },
                      {
                        name: "Installed By",
                        selector: (v, i) => (
                          <Tippy content={v?.installedBy}>
                            <div>{v?.installedBy}</div>
                          </Tippy>
                        ),
                      },
                      { name: "Type", selector: (v, i) => "OS" },
                      {
                        name: "Installed On",
                        selector: (v, i) => (
                          <Tippy content={v?.installedOn}>
                            <div>{v?.installedOn}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Hot Fix ID",
                        selector: (v, i) => v?.hotFixID,
                      },
                    ]}
                    data={osPatchInformation}
                    customStyles={currentStyles}
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Os Patch is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6">
                <HistoryCard
                  headerName={"USB File Histroy"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleUsbFilePage - 1) * handleUsbFileRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Date",
                        selector: (v) => (
                          <Tippy content={v?.timeStamp}>
                            <div>{v?.timeStamp}</div>
                          </Tippy>
                        ),
                      },
                      { name: "EventType", selector: (v) => v?.eventType },

                      {
                        name: "Path",
                        selector: (v) => (
                          <Tippy content={v?.path ? v?.path : "NA"}>
                            <div>{v?.path ? v?.path : "NA"}</div>
                          </Tippy>
                        ),
                      },
                      // {
                      //   name: "Destination",
                      //   selector: (v) => (
                      //     <Tippy content={v?.destinationPath}>
                      //       <div>
                      //         {v?.destinationPath ? v?.destinationPath : "NA"}
                      //       </div>
                      //     </Tippy>
                      //   ),
                      // },
                      {
                        name: "File Type",
                        selector: (v) => (v.fileType ? v.fileType : "NA"),
                      },
                      {
                        name: "File Hash",
                        selector: (v) => (v.fileHash ? v.fileHash : "NA"),
                      },
                    ]}
                    data={usbFileData}
                    pagination
                    paginationPerPage={handleUsbFileRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      console.log(page);
                      setHandleUsbFilePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleUsbFileRowPage(page)
                    }
                    customStyles={currentStyles}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        USB File Histroy Triggered is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={unknowServiceRef}>
                <HistoryCard
                  headerName={"Installed Applications"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (v, index) =>
                          (handleInstalledPage - 1) * handleInstalledRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Applications",
                        selector: (v) => (
                          <Tippy content={v?.name}>
                            <div>{v?.name}</div>
                          </Tippy>
                        ),
                      },
                      { name: "Version", selector: (v) => v?.version },
                      { name: "Date", selector: (v) => v?.date },
                    ]}
                    data={installedProgrameData}
                    customStyles={currentStyles}
                    pagination
                    highlightOnHover
                    paginationPerPage={10} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      console.log(page);
                      setHandleInstalledPage(page);
                    }}
                    onChangeRowsPerPage={(page) => setHandleRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Installed Programme is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6">
                <HistoryCard
                  headerName={"Services List"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleServicePage - 1) * handleServiceRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v) => (
                          <Tippy content={v?.displayName}>
                            <div>{v?.displayName}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Start Type",
                        selector: (v) => v?.startType,
                      },
                      { name: "Status", selector: (v) => v?.status },
                    ]}
                    data={serviceProgrameData}
                    customStyles={currentStyles}                    pagination
                    paginationPerPage={handleServiceRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      console.log(page);
                      setHandleServicePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleServiceRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Service program info is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6">
                <HistoryCard
                  headerName={"Startup Programs List"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleStartUpPage - 1) * handleStartUpRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      { name: "Program Name", selector: (v, i) => v },
                    ]}
                    data={startUpProgrameData}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={handleStartUpRowPage}
                    onChangePage={(page) => setHandleStartUpPage(page)}
                    onChangeRowsPerPage={(page) =>
                      setHandleStartUpRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Startup program info is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6">
                <HistoryCard
                  headerName={"Established Connection"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (establishedConnectionPage - 1) *
                            establishedConnectionRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Local Address",
                        selector: (v, i) => (
                          <Tippy content={v?.localAddress}>
                            <div>{v?.localAddress}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Local Port",
                        selector: (v, i) => v?.localPort,
                      },
                      {
                        name: "Foreign Address",
                        selector: (v, i) => (
                          <Tippy content={v?.foreignAddress}>
                            <div>{v?.foreignAddress}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Foreign Port",
                        selector: (v, i) => v?.foreignPort,
                      },
                    ]}
                    data={establishedConnection}
                    pagination
                    paginationPerPage={establishedConnectionRowPage}
                    onChangePage={(page) => setEstablishedConnectionPage(page)}
                    onChangeRowsPerPage={(page) =>
                      setEstablishedConnectionRowPage(page)
                    }
                    customStyles={currentStyles}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Established Connection is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={highCpuUsageRef}>
                <HistoryCard
                  headerName={"High Cpu Usage"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (highCpuUsagePage - 1) * highCpuUsageRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Programme Name",
                        selector: (v, i) => v?.name,
                      },
                      { name: "PID", selector: (v, i) => v?.idProcess },
                      {
                        name: "Usage",
                        selector: (v, i) => v?.percentProcessorTime,
                      },
                    ]}
                    data={highCpuUsage}
                    pagination
                    customStyles={currentStyles}
                    paginationPerPage={highCpuUsageRowPage}
                    onChangePage={(page) => setHighCpuUsagePage(page)}
                    onChangeRowsPerPage={(page) => setHighCpuUsageRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        High CPU Usage data is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6" ref={oldAdminpsdRef}>
                <HistoryCard
                  headerName={"User Accounts"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      { name: "User Name", selector: (v) => v?.userName },
                      {
                        name: "Type",
                        selector: (v) =>
                          accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                            v?.userName
                          )
                            ? "Admin"
                            : "User",
                      },
                      {
                        name: "Password Age",
                        selector: (v) => v?.passwordAge || "NA",
                      },
                    ]}
                    data={accountDetail[0]?.usersPasswordAge}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={handleServiceRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      console.log(page);
                      setHandleServicePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleServiceRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        User Account Detail is not available
                      </p>
                    }
                  />

                  {/* {accountDetail.length > 0 ? (
                    <div className="table-responsive p-0 m-0  my-2">
                      <table className="table m-0 p-0" id="historyTable">
                        <thead>
                          <tr>
                            <th scope="col" className="fs-11">
                              S. No.
                            </th>
                            <th scope="col">User Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">password Age</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountDetail[0]?.usersPasswordAge?.map(
                            (v, index) => (
                              <tr key={index}>
                                <td className="fs-12">{index + 1}</td>
                                <td className="fs-12">{v?.userName}</td>
                                <td className="fs-12">
                                  {accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                                    v?.userName
                                  )
                                    ? "Admin"
                                    : "User"}
                                </td>
                                <td className="fs-12">{v?.passwordAge}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="p-0 my-2  me-auto">
                      User Account Detail is not available
                    </p>
                  )} */}
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6">
                <HistoryCard
                  headerName={"Recent Events Triggered"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Event Type",
                        selector: (v) => (
                          <Tippy content={v?.eventTriggerType}>
                            <div>{v?.eventTriggerType}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Timestamp",
                        selector: (v) => (
                          <Tippy content={v?.serverTimestamp}>
                            <div>{v?.serverTimestamp}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "SnapShot",
                        selector: (v) => "NA",
                      },
                    ]}
                    data={recentEventData[0]}
                    pagination
                    // paginationPerPage={handleEventsTriggeredRowPage} // Adjust the number of rows per page as needed
                    // onChangePage={(page) => {
                    //   console.log(page);
                    //   setHandleEventsTriggeredPage(page);
                    // }}
                    // onChangeRowsPerPage={(page) =>
                    //   setHandleEventsTriggeredRowPage(page)
                    // }
                    customStyles={currentStyles}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Recent Events Triggered is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>

              <div className="col-6" ref={shareDirectorysRef}>
                <HistoryCard
                  headerName={"Shared Directory Information"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (sharedDirPage - 1) * sharedDirRowPage + index + 1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v, i) => v?.name,
                        width: "300px",
                      },
                      { name: "Path", selector: (v, i) => v?.path },
                    ]}
                    data={sharedDir}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={sharedDirRowPage}
                    onChangePage={(page) => setSharedDirPage(page)}
                    onChangeRowsPerPage={(page) => setSharedDirRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Shared directory data is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={highRamUsageRef}>
                <HistoryCard
                  headerName={"RAM Usage"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (ramUsagePage - 1) * ramUsageRowPage + index + 1,
                        width: "70px",
                      },
                      {
                        name: "Programme Name",
                        selector: (v, i) => v?.name,
                        width: "350px",
                      },
                      { name: "PID", selector: (v, i) => v?.pId },
                      {
                        name: "Usage",
                        selector: (v, i) => v?.memoryPercent,
                      },
                    ]}
                    data={ramUsage}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={ramUsageRowPage}
                    onChangePage={(page) => setRamUsagePage(page)}
                    onChangeRowsPerPage={(page) => setRamUsageRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        RAM Usage data is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6">
                <HistoryCard
                  headerName={"Internal Hard Drive"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (internalHardDrivePage - 1) *
                            internalHardDriveRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Drive",
                        selector: (v, i) => v?.device,
                        width: "100px",
                      },
                      {
                        name: "Total Space",
                        selector: (v, i) => v?.totalSpaceGb,
                      },
                      {
                        name: "Free Space",
                        selector: (v, i) => v?.freeSpaceGb,
                      },
                      {
                        name: "Low Disk Space",
                        selector: (v, i) =>
                          v?.lowDiskSpace ? "True" : "False",
                      },
                    ]}
                    data={internalHardDrive}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={internalHardDrivePage}
                    onChangePage={(page) => setInternalHardDrivePage(page)}
                    onChangeRowsPerPage={(page) =>
                      setInternalHardDriveRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Internal Hard Drive is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>
            <div className="row mt-2" style={customGutter}>
              <div className="col-6">
                <HistoryCard headerName={"Detected Malware"}>
                  {malwareInfos?.length > 0 ? (
                    malwareInfos?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value]) => (
                          <div
                            key={key}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6 text-break">
                              {key === "IP Address" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap ms-auto gap-2">
                                  {value?.map((v) => (
                                    <p className="m-0 p-0">{v}</p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">
                        Detected Malware is not available
                      </p>
                    </div>
                  )}
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard
                  headerName={"Installed Antivirus"}
                  infoButton={() => console.log("myTable")}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v, i) => v?.name,
                        width: "300px",
                      },
                      {
                        name: "Services",
                        selector: (v, i) => (
                          <ServiceTable services={v.services} />
                        ),
                      },
                    ]}
                    data={installedAv}
                    customStyles={currentStyles}
                    pagination
                    // paginationPerPage={handleAntivirusRowPage}
                    // onChangePage={(page) => setHandleAntivirusPage(page)}
                    // onChangeRowsPerPage={(page) =>
                    //   setHandleAntivirusRowPage(page)
                    // }
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Shared directory data is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col">
                <HistoryCard
                  headerName={"CIS Recommendation"}
                  infoButton={() => console.log("myTable")}
                >
                  {!windowCisData || Object.keys(windowCisData).length === 0 ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="p-0 m-0 fs-11 fw-bold">No Data</p>
                    </div>
                  ) : (
                    <div className="row">
                      {Object.entries(windowCisData)
                        .reduce(
                          (acc, [category, data], index) => {
                            const colIndex = index % 3; // Determine the column (0, 1, or 2)
                            if (!acc[colIndex]) acc[colIndex] = []; // Initialize if not present
                            acc[colIndex].push(
                              <CustomAccordian
                                key={index}
                                accordianHeader={toHumanReadable(category)}
                                // unTpmRef={unTpmRef}
                              >
                                {category === "cisFilesystemIntegrityInfo" ? (
                                  Object.entries(data).map(
                                    ([subCategory, subData], subIndex) => (
                                      <CustomAccordian
                                        key={subIndex}
                                        accordianHeader={toHumanReadable(
                                          subCategory
                                        )}
                                      >
                                        {Object.entries(subData).map(
                                          ([key, value], innerIndex) => (
                                            <div
                                              key={innerIndex}
                                              className="d-flex align-items-center py-1 justify-content-between"
                                            >
                                              <p className="p-0 m-0 fs-11 fw-bold">
                                                {toHumanReadable(key)}
                                              </p>
                                              <p
                                                // className={`p-0 m-0 fs-11 fw-semibold ${
                                                //   value === "True"
                                                //     ? "text-success"
                                                //     : value === "False"
                                                //     ? "text-danger"
                                                //     : "text-secondary"
                                                // }`}
                                                className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                              >
                                                {value}
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </CustomAccordian>
                                    )
                                  )
                                ) : category ===
                                  "cisFilesystemConfigurationInfo" ? (
                                  <CustomAccordian
                                    key={index}
                                    accordianHeader={toHumanReadable(category)}
                                  >
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Mount Name</th>
                                          <th>Is Configured</th>
                                          <th>Is Disabled</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.map((partition, subIndex) => (
                                          <tr key={subIndex}>
                                            <td>{partition.mountName}</td>
                                            <td>{partition.isConfigured}</td>
                                            <td>{partition.isDisabled}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </CustomAccordian>
                                ) : category === "cisAuditPartitionsInfo" ? (
                                  <CustomAccordian
                                    key={index}
                                    accordianHeader={toHumanReadable(category)}
                                  >
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Mount Point</th>
                                          <th>Mounted</th>
                                          <th>Options</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.map((partition, subIndex) => (
                                          <tr key={subIndex}>
                                            <td>{partition.mountPoint}</td>
                                            <td
                                            // className={`${
                                            //   partition?.isMounted === "True"
                                            //     ? "text-success"
                                            //     : partition?.isMounted ===
                                            //       "False"
                                            //     ? "text-danger"
                                            //     : "text-secondary"
                                            // }`}
                                            >
                                              {partition.isMounted}
                                            </td>
                                            <td>
                                              <ul>
                                                {Object.entries(
                                                  partition.mountOptions
                                                ).map(([key, value], ind) => (
                                                  <li key={ind}>
                                                    {key}:
                                                    <p
                                                      // className={`p-0 m-0 fs-11 fw-semibold ${
                                                      //   value === "True"
                                                      //     ? "text-success"
                                                      //     : value === "False"
                                                      //     ? "text-danger"
                                                      //     : "text-secondary"
                                                      // }`}
                                                      className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                                    >
                                                      {value.toString()}{" "}
                                                    </p>
                                                  </li>
                                                ))}
                                              </ul>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </CustomAccordian>
                                ) : category ===
                                    "cisConfigureFirewallRulesInfo" ||
                                  category ===
                                    "cisConfigureIptablesSoftwaresInfo" ? (
                                  Object.entries(data).map(
                                    ([subCategory, subData], subIndex) => (
                                      <CustomAccordian
                                        key={subIndex}
                                        accordianHeader={toHumanReadable(
                                          subCategory
                                        )}
                                      >
                                        {typeof subData === "object" &&
                                        subData !== null ? (
                                          Object.keys(subData).length === 0 ? (
                                            <div className="d-flex align-items-center py-1 justify-content-between">
                                              <p className="p-0 m-0 fs-11 fw-bold">
                                                No Data
                                              </p>
                                            </div>
                                          ) : (
                                            Object.entries(subData).map(
                                              ([key, value], innerIndex) => (
                                                <div
                                                  key={innerIndex}
                                                  className="d-flex flex-column py-1"
                                                >
                                                  <p className="p-0 m-0 fs-11 fw-bold">
                                                    {toHumanReadable(key)}
                                                  </p>
                                                  {typeof value === "object" &&
                                                  value !== null ? (
                                                    // Handle nested object
                                                    Object.entries(value).map(
                                                      (
                                                        [
                                                          nestedKey,
                                                          nestedValue,
                                                        ],
                                                        nestedIndex
                                                      ) => (
                                                        <div
                                                          key={nestedIndex}
                                                          className="d-flex align-items-center py-1 justify-content-between"
                                                        >
                                                          <p className="p-0 m-0 fs-11 fw-bold">
                                                            {toHumanReadable(
                                                              nestedKey
                                                            )}
                                                          </p>
                                                          <p
                                                            // className={`p-0 m-0 fs-11 fw-semibold ${
                                                            //   nestedValue ===
                                                            //   "True"
                                                            //     ? "text-success"
                                                            //     : nestedValue ===
                                                            //       "False"
                                                            //     ? "text-danger"
                                                            //     : "text-secondary"
                                                            // }`}
                                                            className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                                          >
                                                            {nestedValue ||
                                                              "No Data"}
                                                          </p>
                                                        </div>
                                                      )
                                                    )
                                                  ) : (
                                                    <p
                                                      // className={`p-0 m-0 fs-11 fw-semibold ${
                                                      //   value === "True"
                                                      //     ? "text-success"
                                                      //     : value === "False"
                                                      //     ? "text-danger"
                                                      //     : "text-secondary"
                                                      // }`}
                                                      className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                                    >
                                                      {value || "No Data"}
                                                    </p>
                                                  )}
                                                </div>
                                              )
                                            )
                                          )
                                        ) : (
                                          <div className="d-flex align-items-center py-1 justify-content-between">
                                            <p className="p-0 m-0 fs-11 fw-bold">
                                              {toHumanReadable(subCategory)}
                                            </p>
                                            <p
                                              // className={`p-0 m-0 fs-11 fw-semibold ${
                                              //   subData === "True"
                                              //     ? "text-success"
                                              //     : subData === "False"
                                              //     ? "text-danger"
                                              //     : "text-secondary"
                                              // }`}
                                              className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                            >
                                              {subData || "No Data"}
                                            </p>
                                          </div>
                                        )}
                                      </CustomAccordian>
                                    )
                                  )
                                ) : (
                                  Object.entries(data).map(
                                    ([key, value], innerIndex) => (
                                      <div
                                        key={innerIndex}
                                        className="d-flex align-items-center py-1 justify-content-between"
                                      >
                                        <p className="p-0 m-0 fs-11 fw-bold">
                                          {toHumanReadable(key)}
                                        </p>
                                        <p
                                          // className={`p-0 m-0 fs-11 fw-semibold ${
                                          //   value === "True"
                                          //     ? "text-success"
                                          //     : value === "False"
                                          //     ? "text-danger"
                                          //     : "text-secondary"
                                          // }`}
                                          className="p-0 m-0 fs-11 fw-semibold text-secondary"
                                        >
                                          {typeof value === "object"
                                            ? Object.values(value).join(", ")
                                            : value}
                                        </p>
                                      </div>
                                    )
                                  )
                                )}
                              </CustomAccordian>
                            );
                            return acc;
                          },
                          [[], [], []]
                        )
                        .map((column, colIndex) => (
                          <div key={colIndex} className="col-md-4">
                            {column}
                          </div>
                        ))}
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>
          </>
        )}
      </div>

      {/* PORT MODAL */}
      <CustomModal
        modelTitle={"Open Ports"}
        show={handlePortModal}
        handleClose={() => setHandlePortModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Open Ports {networkData[1]?.totalOpenPorts?.length}
        </p>
        {networkData[1]?.totalOpenPorts?.map((v, index) => (
          <div
            className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
            key={index}
          >
            <p className="p-0 m-0">#{index + 1}</p>
            <p className="p-0 m-0">{v}</p>
          </div>
        ))}
      </CustomModal>

      {/* PORT MODAL END */}

      {/* NIC MODAL */}

      <CustomModal
        modelTitle={"NIC Details"}
        show={handleNICModal}
        handleClose={() => setHandleNICModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total NIC {networkData[1]?.totalNICCards?.length}
        </p>
        {networkData[1]?.totalNICCards?.map((v, index) => (
          <div
            className="shadow p-2 rounded-md my-2 d-flex flex-column gap-1"
            key={index}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Name</p>
              <p className="p-0 m-0 fs-10">{v?.interfaceDescription}</p>
            </div>
            {/* <div className="d-flex  align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Product</p>
              <p className="p-0 m-0 fs-10">{v?.product}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Vendor</p>
              <p className="p-0 m-0 fs-10">{v?.vendor}</p>
            </div> */}
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Mac Address</p>
              <p className="p-0 m-0 fs-10">{v?.macAddress}</p>
            </div>
          </div>
        ))}
      </CustomModal>

      {/* NIC MODAL END */}
      {/* ANTIVIRUS MODAL */}

      <CustomModal
        modelTitle={"NIC Details"}
        show={handleAntiVirusModal}
        handleClose={() => setHandleAntiVirusModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Av Service {antivirusInfoData[1]?.length}
        </p>
        {antivirusInfoData[1]?.map((v, index) => (
          <div className="my-3 shadow p-1 rounded-md" key={index}>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.service}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Status</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.status}
              </p>
            </div>
          </div>
        ))}
      </CustomModal>

      {/* ANTIVUIRS */}

      {/* USB HISORY */}

      {/* <CustomModal
        modelTitle={"USB Information"}
        show={handleUsbPortModal}
        handleClose={() => setHandleUsbPortModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Usb Connected {usbInfoData[1]?.usbConnection?.length}
        </p>
        {usbInfoData[1]?.usbConnection?.map((v, index) => (
          <div className="my-3 shadow p-1 rounded-md" key={index}>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.usbName}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
                Manufacturer
              </p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.vendor}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
                Serial Name
              </p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.serialName}
              </p>
            </div>
          </div>
        ))}
      </CustomModal> */}

      <CustomModal
        modelTitle={"USB History"}
        show={handleUsbHistoryModal}
        handleClose={() => setHandleUsbHistoryModal(false)}
        customWidth="lg"
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Result {usbInfoData[1]?.usbHistory?.length}
        </p>

        <DataTable
          fixedHeader={true}
          columns={[
            {
              name: "Date/Time",
              selector: (v, index) => v?.time,
              width: "150px",
            },
            {
              name: "Device Name",
              selector: (v, index) => v?.usbName,
              width: "150px",
            },
            { name: "Type", selector: (v) => v?.interfaceType },
            { name: "Manufacturer", selector: (v) => v?.usbManufacturer },
            {
              name: "S. No.",
              selector: (v) => v?.usbSerialNumber,
              width: "220px",
            },
            { name: "Vendor ID", selector: (v) => v?.usbVendorId },
            { name: "Product ID", selector: (v) => v?.usbProductId },
          ]}
          data={usbInfoData[1]?.usbHistory}
          pagination
          paginationPerPage={10} // Adjust the number of rows per page as needed
          onChangePage={(page) => {
            console.log(page);
            setUsbHistoryPage(page);
          }}
          onChangeRowsPerPage={(page) => setUsbHistoryRowPage(page)}
          noDataComponent={
            <p className="p-0 my-2  me-auto">USB History is not available</p>
          }
        />
      </CustomModal>

      {/* USB HISTORY END */}

      {/* BLUETOOTH HISTORY */}
      <CustomModal
        modelTitle={"Paired Devices"}
        show={handleBlueToothInfoModal}
        handleClose={() => setHandleBlueToothInfoModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Devices: {bluetoothInfoData[1]?.blueToothPariedDevice?.length}
        </p>
        {bluetoothInfoData[1]?.blueToothPariedDevice?.map((v, index) => (
          <div className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md">
            <p className="p-0 m-0 fs-11 fw-bold">{index + 1}</p>
            <p className="p-0 m-0 fs-11">{v}</p>
            {/* <p className="p-0 m-0 fs-11">{v?.macAddress}</p> */}
          </div>
        ))}
      </CustomModal>

      <CustomModal
        modelTitle={"Connected Devices"}
        show={handleBlueToothInfoConnectedModal}
        handleClose={() => setHandleBlueToothInfoConnectedModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Open Ports{" "}
          {bluetoothInfoData[1]?.blueToothConnectedDevice?.length}
        </p>
        {bluetoothInfoData[1]?.blueToothConnectedDevice?.map((v, index) => (
          <div className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md">
            <p className="p-0 m-0 fs-11 fw-bold">#{index}</p>
            <p className="p-0 m-0 fs-11">{v?.name}</p>
          </div>
        ))}
      </CustomModal>

      {/* <CustomToaster /> */}

      {/* BLUETOOTH HISTORYENd */}
    </React.Fragment>
  );
}

export default ClientDetail;
