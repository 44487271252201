// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";

// const useFetchOsInfo = (pcInfoId, timeTrigger) => {
//   const [osData, setOsData] = useState([]);
//   const [networkData, setNetworkData] = useState([]);
//   const [basicInfoData, setBasicInfoData] = useState([]);
//   const [antivirusInfoData, setAntivirusInfoData] = useState([]);
//   const [hardwareInfoData, setHardwareInfoData] = useState([]);
//   const [osPatchUpdateInfoData, setOsPatchUpdateInfoData] = useState(null);
//   const [usbInfoData, setUsbInfoData] = useState([]);
//   const [bluetoothInfoData, setBluetoothInfoData] = useState([]);
//   const [installedProgrameData, setInstalledProgrameData] = useState([]);
//   const [serviceProgrameData, setServiceProgrameData] = useState([]);
//   const [startUpProgrameData, setStartUpProgrameData] = useState([]);
//   const [recentEventData, setRecentEventData] = useState([]);
//   const [windowCisData, setWindowCisData] = useState([]);
//   const [establishedConnection, setEstablishedConnection] = useState([]);
//   const [highCpuUsage, setHighCpuUsage] = useState([]);
//   const [ramUsage, setRamUsage] = useState([]);
//   const [osloading, setOsLoading] = useState(true);
//   const [securityControls, setSecurityControls] = useState([]);
//   const [sharedDir, setSharedDir] = useState([]);
//   const [osPatchInformation, setOsPatchInformation] = useState([]);
//   const [accountDetail, setAccountDetail] = useState([]);
//   const [internalHardDrive, setInternalHardDrive] = useState([]);
//   const [logError, setLogError] = useState(false);
//   const [installedAv, setInstalledAv] = useState([]);
//   const [malwareInfos, setMalwareInfos] = useState([]);
//   const [usbFileData, setUsbFileData] = useState([]);
//   const [auditScore,setAuditScore] = useState([0])

// console.log(installedProgrameData,'installedProgrameData')
//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const source = axios.CancelToken.source();
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_WINDOW_URL}/legacyinfo/dashboard/legacy-latest-by-pcId/${user?.data?.user?.userId}?pcId=${pcInfoId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           },
//           { cancelToken: source.token }
//         );

//         console.log(response, "RESPONSETriggger");

//         const osInstalledTime =
//           response?.data?.data?.formattedosInstallationDate;
//         const loginTime = response?.data?.data?.formattedLoginTime;
//         const osEntriesData = response?.data?.data;
//         const usdHistoryData = response?.data?.data?.UsbInformation;
//         const recentEventTrigger = response?.data?.data?.RecentEventsTriggered;
//         const serverTimeeStamp = response?.data?.data?.formattedserverTimestamp;
//         const bootTime = response?.data?.data?.formattedSystemBootTime;
//         const  EventsTriggered = response?.data?.data;
//         const usbFileHistroy = response?.data.data.UsbInformation;
//         const usbFileHistroyNew = response?.data.data;
//         const triggerUser_type = response?.data?.data?.latestEventTriggerType;
//         const audit_score = osEntriesData?.pcIdentityInfo;
//         const InstalledApps = osEntriesData?.backendInfo?.installedPrograms;
//         console.log(InstalledApps,'InstalledApps')
//         console.log(osEntriesData?.osInfo?.servicesInfo,'service')
//         setInstalledProgrameData(InstalledApps)
//         setServiceProgrameData(osEntriesData?.osInfo?.servicesInfo);
//         setStartUpProgrameData(osEntriesData?.osInfo?.startupPrograms || []);
//         setOsPatchInformation(
//           osEntriesData?.backendInfo?.osPatchInfo || []
//         );
//         setHighCpuUsage(osEntriesData?.hardwareInfo?.highCpuUsage || []);
//         setRamUsage(osEntriesData?.hardwareInfo?.ramUsage || []);
//         setInternalHardDrive(
//           osEntriesData?.hardwareInfo?.internalHardDrivesInfo || []
//         );
//        //setSharedDir(osEntriesData?.osInfo?.sharedDirectories || []);
//         setAuditScore(audit_score?.auditScore)
//         setBasicInfoData([
//           {
//             "Serial Number":
//               osEntriesData?.pcIdentityInfo?.serialNumber || "NA",
//             "Endpoint ID": osEntriesData?.pcIdentityInfo?.licenseKey || "NA",
//             Hostname: osEntriesData?.pcIdentityInfo?.hostname || "NA",
//             "IP Address":
//               osEntriesData?.pcIdentityInfo?.ipAddress?.split(" ") || [],
//             "Logged-In User":
//               osEntriesData?.pcIdentityInfo?.currentUser || "NA",
//             "User Type":
//               osEntriesData?.accountInfo?.adminAccountsAreMultiple?.adminAccountsName?.includes(
//                 osEntriesData?.pcIdentityInfo?.currentUser
//               )
//                 ? "Admin"
//                 : "User" || "NA",

//             "Trigger Type": triggerUser_type || "NA",
//             "Audit Time": loginTime || "NA",
//             "Last Sync Time": serverTimeeStamp || "NA",
//             "Last Boot Time": bootTime || "NA",
//             "Audit Agent":
//               osEntriesData?.pcIdentityInfo?.currentAgentVersion || "NA",
//           },
//         ]);
//         setOsData([
//           {
//             OS: osEntriesData?.pcIdentityInfo?.osName || "NA",
//             "OS Version": osEntriesData?.osInfo?.osVersion || "NA",
//             "OS Architecture":
//               osEntriesData?.hardwareInfo?.machineBitType || "NA",

//             "OS Installed On": osInstalledTime || "NA",
//             Timezone: osEntriesData?.hardwareInfo?.timeZone || "NA",
//             "Audit Logging":
//               osEntriesData?.osInfo?.auditLogs === "active"
//                 ? "Active"
//                 : "Inactive" || "NA",

//             "Boot Device": osEntriesData?.osInfo?.bootDevice || "NA",
//           },
//         ]);



//         setNetworkData([
//           [
//             {
//               Name:
//                 osEntriesData?.NetworkInfo?.wifiInfo.length > 0 &&
//                 osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? osEntriesData?.NetworkInfo?.ethernetStatus
//                     ? "Ethernet"
//                     : "OFF"
//                   : osEntriesData?.NetworkInfo?.wifiInfo.length > 0
//                   ? osEntriesData?.NetworkInfo?.wifiInfo[0]?.ssid
//                   : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? "Ethernet"
//                   : "OFF",
//               "IP Address":
//                 osEntriesData?.pcIdentityInfo?.ipAddress.split(" ")[0] || "NA",
//               // Type:
//               //   osEntriesData?.NetworkInfo?.connectionStatus?.connectionType ||
//               //   "NA",
//               "MAC Address":
//                 osEntriesData?.NetworkInfo?.wifiInfo?.length > 0 &&
//                 osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? osEntriesData?.NetworkInfo?.ethernetStatus
//                     ? osEntriesData?.NetworkInfo?.ethernetStatus[0]?.macAddress
//                     : "NA"
//                   : osEntriesData?.NetworkInfo?.wifiInfo?.length > 0
//                   ? osEntriesData?.NetworkInfo?.wifiInfo[0]?.macAddress
//                   : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? osEntriesData?.NetworkInfo?.ethernetStatus[0]?.macAddress
//                   : "NA",
//               "Network Category":
//                 osEntriesData?.NetworkInfo?.wifiInfo?.length > 0 &&
//                 osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? osEntriesData?.NetworkInfo?.ethernetStatus
//                     ? "Wired"
//                     : "NA"
//                   : osEntriesData?.NetworkInfo?.wifiInfo?.length > 0
//                   ? "Wifi"
//                   : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
//                   ? "Wired"
//                   : "NA",
//               "DNS Server": osEntriesData?.NetworkInfo?.dnsServer || [],
//               "Connected To Domain":
//                 osEntriesData?.pcIdentityInfo?.connectedToDomainName || " NA",

//               "NTP Server":
//                 osEntriesData?.NetworkInfo?.ntpInfo?.ntpServer || "NA",
//               "NTP Status": osEntriesData?.NetworkInfo?.ntpInfo?.isNtpEnabled
//                 ? "Enabled"
//                 : "Disabled" || "NA",

//               "Total NIC Cards":
//                 osEntriesData?.hardwareInfo?.nicDetails?.nicCount || "NA",
//               "Total Open Ports":
//                 osEntriesData?.networkInfomation?.TotalOpenPorts + "" || "NA",
//             },
//           ],
//           {
//             totalOpenPorts: osEntriesData?.networkInfo?.TotalOpenPorts || [],
//             totalNICCards:
//               osEntriesData?.hardwareInfo?.nicDetails?.nicMacDetails || [],
//           },
//         ]);

//         setAntivirusInfoData([
//           [
//             {
//               "Installed AV": "0",
//               "Enterprise AV Installed": "0",
//             },
//           ],
//           [],
//         ]);
//         setInstalledAv(osEntriesData?.AntivirusInformation?.antivirusInstalled);
//         // setUsbFileData(usbFileHistroy?.FileHistory);
//         setUsbFileData(usbFileHistroyNew?.fileHistory);
//         setHardwareInfoData([
//           [
//             {
//               Manufacturer:
//                 osEntriesData?.pcIdentityInfo?.systemManufacturer || "NA",
//               Model: osEntriesData?.pcIdentityInfo?.systemModel || "NA",
//               "Machine Type": osEntriesData?.hardwareInfo?.machineType || "NA",
//               "Motherboard SN":
//                 osEntriesData?.pcIdentityInfo?.motherboardSerialNumber || "NA",
//               CPU: osEntriesData?.pcIdentityInfo?.processorInfo || "NA",
//               "Processor Architecture":
//                 osEntriesData?.hardwareInfo?.machineBitType || "NA",
//               RAM:
//                 osEntriesData?.hardwareInfo?.memoryInformation?.totalRamGb ||
//                 "NA",
//               "HDD/SSD":
//                 Math.round(
//                   osEntriesData?.hardwareInfo?.internalHardDrivesInfo?.reduce(
//                     (s, v) => (s += +v?.totalSpaceGb),
//                     0
//                   )
//                 ) + " GB" || "NA",
//               "Device UUID":
//                 osEntriesData?.hardwareInfo?.deviceUuidNumber || "NA",

//               "BIOS Version": osEntriesData?.hardwareInfo?.biosVersion || "NA",
//               "BIOS Battery State": osEntriesData?.hardwareInfo
//                 ?.biosBatteryServiceable
//                 ? "Serviceable"
//                 : "Unserviceable" || "NA",
//             },
//           ],
//           {
//             ramData: osEntriesData?.hardwareInfo?.memoryInformation || [],
//           },
//         ]);

//         // console.log(osEntriesData?.osInfo?.edrInstalled, "OSSTATUS");
  
//         setSecurityControls([
//           {
//             "RDP Status": osEntriesData?.osInfo?.rdpStatus?.rdpEnabled
//               ? "Enabled"
//               : "Disabled" || "NA",
//             "SSH Status":
//               osEntriesData?.osInfo?.rdpStatus?.sshActive === "false"
//                 ? "Disabled"
//                 : "Enabled" || "NA",
//             Firewall: "UFW.service",
//             "Firewall Status":
//               osEntriesData?.networkInfo?.firewallStatus?.domain === "ON" &&
//               osEntriesData?.networkInfo?.firewallStatus?.private === "ON" &&
//               osEntriesData?.networkInfo?.firewallStatus?.public === "ON"
//                 ? "On"
//                 : "Off" || "NA",
//             "Installed AV": "NA",
//             "Av Services Status": "NA",
//             "EDR Installed": osEntriesData?.osInfo?.edrInstalled
//               ? "True"
//               : "False" || "NA",
//             "EDR Service Status": "NA",
//             "NAC Installed": osEntriesData?.networkInfo?.nacInstalled
//               ? "True"
//               : "False" || "NA",
//           },
//         ]);

//         setOsPatchUpdateInfoData(osEntriesData?.OsPatchUpdateInfo || []);

//         setUsbInfoData([
//           [
//             {
//               "Usb History": usdHistoryData?.history?.length,
//             },
//           ],
//           {
//             usbHistory: usdHistoryData?.history || [],
//             usbConnection: osEntriesData?.usbInfo?.usbInfoDetails || [],
//           },
//         ]);

//         console.log(osEntriesData?.NetworkInfo.bluetoothInfo.bluetoothPairedDevices, "NetworkInfo");

//         // setBluetoothInfoData([osEntriesData?.NetworkInfo?.bluetoothInfo || []]); // bluetoothInfoData?.bluetoothInfo || []]);
//         setBluetoothInfoData([
//           [
//             {
//               "Bluetooth Status": osEntriesData?.NetworkInfo?.bluetoothInfo?.status
//                 ? osEntriesData?.NetworkInfo?.bluetoothInfo?.status
//                 : "NA",
//               // "Device Serial No":
//               //   osEntriesData?.NetworkInfo?.bluetoothInfo?.deviceSerial || "NA",
//               "Paried Device":
//               osEntriesData?.NetworkInfo?.bluetoothInfo?.bluetoothPairedDevices?.length || 0,
//             },
//           ],
//           {
//             blueToothPariedDevice:
//             osEntriesData?.NetworkInfo.bluetoothInfo.bluetoothPairedDevices || [],
//             // blueToothConnectedDevice:
//             //   osEntriesData?.NetworkInfo?.bluetoothInfo?.connectedDevice || [],
//           },
//         ]);
//         // setInstalledProgrameData(InstalledApps);

      
  
//         setRecentEventData([EventsTriggered?.RecentEventsTriggered]);

//         // setMalwareInfo(osEntriesData?.OSInformation?.malwareInfo || []);

//         setMalwareInfos([
//           {
//             "productName": osEntriesData?.OSInformation?.malwareInfo?.productName,
//             "detectionId": osEntriesData?.OSInformation?.malwareInfo?.detectionId,
//             "detectionTime": osEntriesData?.OSInformation?.malwareInfo?.detectionTime,
//             "threatName": osEntriesData?.OSInformation?.malwareInfo?.threatName,
//             "severityName": osEntriesData?.OSInformation?.malwareInfo?.severityName,
//             "categoryName": osEntriesData?.OSInformation?.malwareInfo?.categoryName,
//             "sourceName":osEntriesData?.OSInformation?.malwareInfo?.sourceName,
//             "processName": osEntriesData?.OSInformation?.malwareInfo?.processName,
//             "detectionUser":osEntriesData?.OSInformation?.malwareInfo?.detectionUser,
//             "path": osEntriesData?.OSInformation?.malwareInfo?.path,
//             "originName": osEntriesData?.OSInformation?.malwareInfo?.originName,
//           },
//         ]);
//         setWindowCisData(osEntriesData?.CisRecommendation || []);

//         setEstablishedConnection(
//           osEntriesData?.NetworkInfo?.establishedNetworkConnections || []
//         );

       
      

//         setAccountDetail([osEntriesData?.AccountInfo] || []);

//         console.log(osEntriesData?.AccountInfo, "inforAccount");

     

//         // console.log(osEntriesData, "OS INFORMAITON");

       

      

//         setOsLoading(false);
//       } catch (error) {
//         if (axios.isCancel(error)) {
//           console.log("Request canceled:", error.message);
//           setOsLoading(false);
//         } else if (error.response && error.response.status === 404) {
//           console.log(error.response, "Data not found");
//           setLogError(true);

//           setOsLoading(false);
//         } else {
//           console.error("Error fetching data:", error);
//           setOsLoading(false);
//         }
//       }
//     };

//     fetchData();

//     // Cleanup function to cancel the request when the component unmounts
//     return () => {
//       source.cancel("Request canceled due to component unmount.");
//     };

//     // eslint-disable-next-line
//   }, [pcInfoId, timeTrigger]);

//   return {
//     osData,
//     osloading,
//     networkData,
//     basicInfoData,
//     antivirusInfoData,
//     hardwareInfoData,
//     osPatchUpdateInfoData,
//     usbInfoData,
//     bluetoothInfoData,
//     installedProgrameData,
//     serviceProgrameData,
//     startUpProgrameData,
//     recentEventData,
//     windowCisData,
//     establishedConnection,
//     highCpuUsage,
//     ramUsage,
//     securityControls,
//     accountDetail,
//     osPatchInformation,
//     sharedDir,
//     internalHardDrive,
//     logError,
//     installedAv,
//     usbFileData,
//     malwareInfos,
//     auditScore
//   };
// };

// export default useFetchOsInfo;

import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useFetchOsInfo = (pcInfoId, timeTrigger) => {
  const [osData, setOsData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [basicInfoData, setBasicInfoData] = useState([]);
  const [antivirusInfoData, setAntivirusInfoData] = useState([]);
  const [hardwareInfoData, setHardwareInfoData] = useState([]);
  const [osPatchUpdateInfoData, setOsPatchUpdateInfoData] = useState(null);
  const [usbInfoData, setUsbInfoData] = useState([]);
  const [bluetoothInfoData, setBluetoothInfoData] = useState([]);
  const [installedProgrameData, setInstalledProgrameData] = useState([]);
  const [serviceProgrameData, setServiceProgrameData] = useState([]);
  const [startUpProgrameData, setStartUpProgrameData] = useState([]);
  const [recentEventData, setRecentEventData] = useState([]);
  const [windowCisData, setWindowCisData] = useState([]);
  const [establishedConnection, setEstablishedConnection] = useState([]);
  const [highCpuUsage, setHighCpuUsage] = useState([]);
  const [ramUsage, setRamUsage] = useState([]);
  const [osloading, setOsLoading] = useState(true);
  const [securityControls, setSecurityControls] = useState([]);
  const [sharedDir, setSharedDir] = useState([]);
  const [osPatchInformation, setOsPatchInformation] = useState([]);
  const [accountDetail, setAccountDetail] = useState([]);
  const [internalHardDrive, setInternalHardDrive] = useState([]);
  const [logError, setLogError] = useState(false);
  const [installedAv, setInstalledAv] = useState([]);
  const [malwareInfos, setMalwareInfos] = useState([]);
  const [usbFileData, setUsbFileData] = useState([]);
  const [auditScore, setAuditScore] = useState(0); // Initialize as number instead of array
  const [opticalDriveInfo, setOpticalDriveInfo] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WINDOW_URL}/legacyinfo/dashboard/legacy-latest-by-pcId/${user?.data?.user?.userId}?pcId=${pcInfoId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          },
          { cancelToken: source.token }
        );
        console.log(response?.data.data.latestEventTriggerType,'resssss')
        const osEntriesData = response?.data?.data;
        const usdHistoryData = response?.data?.data?.UsbInformation;
        const  EventsTriggered = response?.data?.data;


        const auditScore = osEntriesData?.pcIdentityInfo?.auditScore || 0;

        setAuditScore(auditScore);

        setInstalledProgrameData(osEntriesData?.backendInfo?.installedPrograms || []);
        setServiceProgrameData(osEntriesData?.osInfo?.servicesInfo || []);
        setStartUpProgrameData(osEntriesData?.osInfo?.startupPrograms || []);
        setOsPatchInformation(osEntriesData?.backendInfo?.osPatchInfo || []);
        setHighCpuUsage(osEntriesData?.hardwareInfo?.highCpuUsage || []);
        setRamUsage(osEntriesData?.hardwareInfo?.ramUsage || []);
        setInternalHardDrive(osEntriesData?.hardwareInfo?.internalHardDrivesInfo || []);

        setBasicInfoData([
          {
            "Serial Number": osEntriesData?.pcIdentityInfo?.serialNumber || "NA",
            "Endpoint ID": osEntriesData?.pcIdentityInfo?.licenseKey || "NA",
            Hostname: osEntriesData?.pcIdentityInfo?.hostname || "NA",
            "IP Address": osEntriesData?.pcIdentityInfo?.ipAddress?.split(" ") || [],
            "Logged-In User": osEntriesData?.pcIdentityInfo?.currentUser || "NA",
            "User Type": osEntriesData?.AccountInfo?.adminAccountsAreMultiple?.adminAccountsName.includes(
              osEntriesData?.pcIdentityInfo?.currentUser
            )
              ? "Admin"
              : "User" || "NA",
            "Trigger Type": osEntriesData?.latestEventTriggerType || "NA",
            "Audit Time": osEntriesData?.formattedLoginTime || "NA",
            "Last Sync Time": osEntriesData?.formattedserverTimestamp || "NA",
            "Last Boot Time": osEntriesData?.formattedSystemBootTime || "NA",
            "Audit Agent": osEntriesData?.pcIdentityInfo?.currentAgentVersion || "NA",
          },
        ]);
    console.log( osEntriesData?.hardwareInfo.processorInfo ,'rrrrrrr')
        setOsData([
          {
            OS: osEntriesData?.pcIdentityInfo?.osName || "NA",
            "OS Version": osEntriesData?.osInfo?.osVersion || "NA",
            "OS Architecture": osEntriesData?.hardwareInfo?.machineBitType || "NA",
            "OS Installed On": osEntriesData?.formattedosInstallationDate || "NA",
            Timezone: osEntriesData?.hardwareInfo?.timeZone || "NA",
            "Audit Logging": osEntriesData?.osInfo?.auditLogs === "active"
              ? "Active"
              : "Inactive" || "NA",
            "Boot Device": osEntriesData?.osInfo?.bootDevice || "NA",
          },
        ]);
        setHardwareInfoData([
          [
            {
              Manufacturer:
                osEntriesData?.pcIdentityInfo?.systemManufacturer || "NA",
              Model: osEntriesData?.pcIdentityInfo?.systemModel || "NA",
              "Machine Type": osEntriesData?.hardwareInfo?.machineType || "NA",
              "Motherboard SN":
                osEntriesData?.pcIdentityInfo?.motherboardSerialNumber || "NA",
              CPU: osEntriesData?.hardwareInfo.processorInfo || "NA",
              "Processor Architecture":
                osEntriesData?.hardwareInfo?.machineBitType || "NA",
              RAM:
                osEntriesData?.hardwareInfo?.memoryInformation?.totalRamGb ||
                "NA",
              "HDD/SSD":
                Math.round(
                  osEntriesData?.hardwareInfo?.internalHardDrivesInfo?.reduce(
                    (s, v) => (s += +v?.totalSpaceGb),
                    0
                  )
                ) + " GB" || "NA",
              "Device UUID":
                osEntriesData?.hardwareInfo?.deviceUuidNumber || "NA",

              "BIOS Version": osEntriesData?.hardwareInfo?.biosVersion || "NA",
              "BIOS Battery State": osEntriesData?.hardwareInfo
                ?.biosBatteryServiceable
                ? "Serviceable"
                : "Unserviceable" || "NA",
            },
          ],
          {
            ramData: osEntriesData?.hardwareInfo?.memoryInformation || [],
          },
        ]);
        const dnss= ['192.168.1.1','192.168.1.1','192.168.1.1','192.168.1.1']
        setNetworkData([
          [
            {
              Name:
                osEntriesData?.NetworkInfo?.wifiStatus.length > 0 &&
                osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.NetworkInfo?.ethernetStatus
                    ? "Ethernet"
                    : "OFF"
                  : osEntriesData?.NetworkInfo?.wifiStatus.length > 0
                  ? osEntriesData?.NetworkInfo?.wifiStatus[0]?.ssid
                  : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? "Ethernet"
                  : "OFF",
              "IP Address":
                osEntriesData?.pcIdentityInfo?.ipAddress.split(" ")[0] || "NA",
              // Type:
              //   osEntriesData?.NetworkInfo?.connectionStatus?.connectionType ||
              //   "NA",
              "MAC Address":
                osEntriesData?.NetworkInfo?.wifiStatus?.length > 0 &&
                osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.NetworkInfo?.ethernetStatus
                    ? osEntriesData?.NetworkInfo?.ethernetStatus[0]?.macAddress
                    : "NA"
                  : osEntriesData?.NetworkInfo?.wifiStatus?.length > 0
                  ? osEntriesData?.NetworkInfo?.wifiStatus[0]?.macAddress
                  : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.NetworkInfo?.ethernetStatus[0]?.macAddress
                  : "NA",
              "Network Category":
                osEntriesData?.NetworkInfo?.wifiStatus?.length > 0 &&
                osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? osEntriesData?.NetworkInfo?.ethernetStatus
                    ? "Wired"
                    : "NA"
                  : osEntriesData?.NetworkInfo?.wifiStatus?.length > 0
                  ? "Wifi"
                  : osEntriesData?.NetworkInfo?.ethernetStatus?.length > 0
                  ? "Wired"
                  : "NA",
              "DNS Server":osEntriesData?.networkInfo?.dnsServer  || [],
              "Connected To Domain":
                osEntriesData?.pcIdentityInfo?.connectedToDomainName || " NA",

              "NTP Server":
                osEntriesData?.NetworkInfo?.ntpDetails?.ntpServer || "NA",
              "NTP Status": osEntriesData?.NetworkInfo?.ntpDetails?.isNtpEnabled === "True"
                ? "Enabled"
                : "Disabled" || "NA",

              "Total NIC Cards":
                osEntriesData?.hardwareInfo?.nicDetails?.nicCount || "NA",
              "Total Open Ports":
                osEntriesData?.networkInfomation?.TotalOpenPorts + "" || "NA",
            },
          ],
          {
            totalOpenPorts: osEntriesData?.networkInfo?.TotalOpenPorts || [],
            totalNICCards:
              osEntriesData?.hardwareInfo?.nicDetails?.nicMacDetails || [],
          },
        ]);
     
        

        setOpticalDriveInfo(osEntriesData?.hardwareInfo?.opticalDriveInfo?.driveDetails || []);

        setSecurityControls([
          {
            "RDP Status": osEntriesData?.osInfo?.rdpStatus?.rdpEnabled === "False"
              ? "Disabled" 
              : "Enabled" || "NA",
            "SSH Status":
              osEntriesData?.osInfo?.rdpStatus?.sshActive === "False"
                ? "Disabled"
                : "Enabled" || "NA",
            Firewall: "UFW.service",
            "Firewall Status":
              osEntriesData?.networkInfo?.firewallStatus?.domain === "ON" &&
              osEntriesData?.networkInfo?.firewallStatus?.private === "ON" &&
              osEntriesData?.networkInfo?.firewallStatus?.public === "ON"
                ? "On"
                : "Off" || "NA",
            "Installed AV": "NA",
            "Av Services Status": "NA",
            "EDR Installed": osEntriesData?.osInfo?.edrInstalled
              ? "True"
              : "False" || "NA",
            "EDR Service Status": "NA",
            "NAC Installed": osEntriesData?.networkInfo?.nacInstalled
              || "NA",
          },
        ]);
        setEstablishedConnection(
          osEntriesData?.NetworkInfo?.establishedNetworkConnections || []
        );

        setUsbInfoData([
          [
            {
              "Usb History": usdHistoryData?.history?.length,
            },
          ],
          {
            usbHistory: usdHistoryData?.history || [],
            usbConnection: osEntriesData?.usbInfo?.usbInfoDetails || [],
          },
        ]);

        setBluetoothInfoData([
          [
            {
              "Bluetooth Status": osEntriesData?.NetworkInfo?.bluetoothInfo?.status
                ? osEntriesData?.NetworkInfo?.bluetoothInfo?.status
                : "NA",
              "End Point Serial No":
                osEntriesData?.NetworkInfo?.bluetoothInfo?.deviceSerial || "NA",
              "Paried Devices":
              osEntriesData?.NetworkInfo?.bluetoothInfo?.pairedDevice?.length || 0,
            },
          ],
          {
            blueToothPariedDevice:
            osEntriesData?.NetworkInfo.bluetoothInfo.pairedDevice || [],
            // blueToothConnectedDevice:
            //   osEntriesData?.NetworkInfo?.bluetoothInfo?.connectedDevice || [],
          },
        ]);
      
        setUsbFileData(osEntriesData?.fileHistory);
        setAccountDetail([osEntriesData?.AccountInfo] || []);
        setRecentEventData([EventsTriggered?.RecentEventsTriggered]);
        setSharedDir(osEntriesData?.osInfo?.sharedDirectories || []);
        setMalwareInfos([
          {
            "productName": osEntriesData?.OSInformation?.malwareInfo?.productName,
            "detectionId": osEntriesData?.OSInformation?.malwareInfo?.detectionId,
            "detectionTime": osEntriesData?.OSInformation?.malwareInfo?.detectionTime,
            "threatName": osEntriesData?.OSInformation?.malwareInfo?.threatName,
            "severityName": osEntriesData?.OSInformation?.malwareInfo?.severityName,
            "categoryName": osEntriesData?.OSInformation?.malwareInfo?.categoryName,
            "sourceName":osEntriesData?.OSInformation?.malwareInfo?.sourceName,
            "processName": osEntriesData?.OSInformation?.malwareInfo?.processName,
            "detectionUser":osEntriesData?.OSInformation?.malwareInfo?.detectionUser,
            "path": osEntriesData?.OSInformation?.malwareInfo?.path,
            "originName": osEntriesData?.OSInformation?.malwareInfo?.originName,
          },
        ]);
        setInstalledAv(osEntriesData?.AntivirusInformation?.antivirusInstalled);
        setWindowCisData(osEntriesData?.CisRecommendation || []);


        // Log state to confirm data updates
        console.log(osEntriesData?.pcIdentityInfo?.serialNumber, 'Serial Number after fetch');
        setOsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.response && error.response.status === 404) {
          console.log(error.response, "Data not found");
          setLogError(true);
        } else {
          console.error("Error fetching data:", error);
        }
        setOsLoading(false);
      }
    };

    fetchData();

    return () => {
      source.cancel("Request canceled due to component unmount.");
    };
  }, [pcInfoId, timeTrigger, user?.data?.accessToken]);

  // Log state updates to confirm data changes
  useEffect(() => {
    console.log(basicInfoData, 'Basic Info Data after state update');
  }, [basicInfoData]);

  return {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    osPatchUpdateInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    windowCisData,
    establishedConnection,
    highCpuUsage,
    ramUsage,
    securityControls,
    accountDetail,
    osPatchInformation,
    sharedDir,
    internalHardDrive,
    logError,
    installedAv,
    usbFileData,
    malwareInfos,
    auditScore,
  };
};

export default useFetchOsInfo;
