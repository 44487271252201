import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link,NavLink,useLocation } from "react-router-dom";
import Footer from "../../layouts/Footer";
// import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
// import DashCard from "../../components/common/DashBoard/DashCard";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
// import PageLoader from "../../components/common/Loader/PageLoader";
import jsPDF from "jspdf";

// import ServerityOkIcon from "../assets/img/ServerityIcon/allOk.png";
// import ServerityGreenIcon from "../assets/img/ServerityIcon/greenOk.png";
// import ServerityRedIcon from "../assets/img/ServerityIcon/redOk.png";
// import ServerityYellowIcon from "../assets/img/ServerityIcon/yellowOk.png";
// import windowOkIcon from "../assets/img/ServerityIcon/blueRec.png";
// import windowGreenIcon from "../assets/img/ServerityIcon/greenRec.png";
// import windowRedIcon from "../assets/img/ServerityIcon/redRec.png";
// import windowOYellowIcon from "../assets/img/ServerityIcon/yellowRec.png";
// import DetailCard from "../../components/common/DashBoard/detailCard";

import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import secure from "../../assets/img/Alerts/security_update.png";
import selinux from "../../assets/img/Alerts/selinux.png";
import android_root from "../../assets/img/Alerts/android_root.png";
import developer_mode from "../../assets/img/Alerts/developer_mode.png";
import usb_debbugin from "../../assets/img/Alerts/usb_debbugin.png";
import phone_lock from "../../assets/img/Alerts/phone_lock.png";
import open_nw from "../../assets/img/Alerts/open_nw.png";
import untrusted_app from "../../assets/img/Alerts/untrusted_app.webp";
import banned_app from "../../assets/img/Alerts/banned_app.png";
import dangers_app from "../../assets/img/Alerts/dangers_app.png";
import screen_timeout from "../../assets/img/Alerts/screen_timeout.png";
import time_zone from "../../assets/img/Alerts/time_zone.png";
import third_part from "../../assets/img/Alerts/third_part.png";
import emulater from "../../assets/img/Alerts/emulater.png";
import bluetooth from "../../assets/img/Alerts/bluetooth.png";
import usbIcon from "../../assets/img/Alerts/usbIcon.png";
import unknow from "../../assets/img/Alerts/unknow.png";
import oldPassword from "../../assets/img/Alerts/oldPassword.png";
import multRoot from "../../assets/img/Alerts/multRoot.png";
import publicshare from "../../assets/img/Alerts/publicshare.png";
import wifi from "../../assets/img/Alerts/wifi.png";
import windows_rdp_gateway from "../../assets/img/Alerts/windows_rdp_gateway.png";
import cddrive from "../../assets/img/Alerts/cddrive.ico";
import battery from "../../assets/img/Alerts/battery.png";
import scanner_icon from "../../assets/img/DashIcon/scanner_icon.png";
import ram from "../../assets/img/Alerts/ram.png";
import cpu from "../../assets/img/Alerts/cpu.png";
import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";
import os_formated from "../../assets/img/Alerts/os_formated.webp";
import harware from "../../assets/img/Alerts/harware.png";
import network_connectivity from "../../assets/img/Alerts/network_connectivity.webp";
import tpm_icon from "../../assets/img/Alerts/tpm_icon.png";
import openport from "../../assets/img/Alerts/openport.png";
import unrecog from "../../assets/img/Alerts/unrecog.png";
import usbviolation from "../../assets/img/Alerts/usbviolation.png";
import oshardingset from "../../assets/img/Alerts/oshardingset.png";
import os_not_patch from "../../assets/img/Alerts/os_not_patch.png";
import virus from "../../assets/img/Alerts/virus.png";
import domain from "../../assets/img/DashIcon/domain.png";


import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";


import fireIcon from "../../assets/img/Alerts/fireIcon.png";


import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";

import PageLoader from "../../components/common/Loader/PageLoader";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  FIRWALL_OFF,
  MALWARE_DETECTION_ENDPOINT,
  NAC_NAP,
  OS_PATCHED,
  USB_VOILATION,
  WINDOW_NOT_ACTIVATED,
} from "../../api/endPoints/windowAlertEndPoint";
import {
  LINUX_BLACKLIST,
  LINUX_FIREWALL_OFF,
  LINUX_NIC,
  LINUX_NOT_ACTIVATED,
  LINUX_OS_NOT_PATCH,
} from "../../api/endPoints/linuxAlertEndPoint";
import AndroidClients from "../../components/common/clients/androidClients/AndroidClients";
import useFetchChromeSummary from "../../hooks/ChromeHooks/useFetchChromeSummary";
import {
  BANNED_APPS,
  BLUETOOTH_ENABLED,
  DANGEROUS_APPS,
  DEVELOPER_STATUS,
  DEVICE_LOCK_STATUS,
  EMULATOR_DETECTED,
  KEYBOARD_FOUND,
  OLD_PATCH,
  OPEN_NETWORK_CONNECTION,
  ROOTED_DEVICE,
  SCREEN_TIME,
  SE_LINUX_STATUS,
  TIMEOUT_STATUS,
  UNTRUSTED_APP_SOURCE,
  USB_DEBUGGING_STATUS,
  USB_VOLIATION,
} from "../../api/endPoints/anrdroidEndPoints";
import ChromeClients from "../../components/common/clients/chromeClients/ChromeClients";
import { useDispatch, useSelector } from "react-redux";

export default function ChromeDetail() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };






  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const { loading, chromeSummary, chromeAlertSummary,totalDashboard} =
    useFetchChromeSummary();


  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (char) => char.toUpperCase());
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const exportToPDF = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(chromeSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(chromeAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );

    doc.setFontSize(15);
    doc.text(`Chrome Summary Data`, 14, 22);

    const tableColumn = ["Sl.No", "Category", "Total Count"];

    const tableRows = data?.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
    });

    doc.save("Chrome_summary_data.pdf");
  };

  const exportToCSV = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(chromeSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(chromeAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const tableHeaders = ["Sl.No", "Category", "Total Count"];

    const tableRows = data.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    const csvContent = [
      `Organization: ${orgName}, Exported: ${exportDateTime}`,
      "",
      tableHeaders.join(","),
      ...tableRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "linux_summary_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">

      <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">Dashboard</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
            
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/chrome-dash" ? (
              <span className="active">Chrome Dashboard</span>
            ) : (
              <NavLink exact to="/chrome-dash">
              Chrome Dashboard
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="#"  className="shadow" style={{ borderRadius: "25px" }}>
              <img src={chromeIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Chrome DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0 align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={exportToPDF}
              >
                <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
              </button>

              <button
                type="button"
                className="btn btn-success text-white"
                onClick={exportToCSV}
              >
                <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
              </button>
            </div>
          </div>
        </div>
        {!loading ? (
          <>
            <ChromeClients data={chromeSummary} totalDashboard={totalDashboard}  />

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start align-items-center">
                      <Col xl="3">
                        <Alerts
                          alertIcon={secure}
                          alertTitle={"Old Security Patch"}
                          alertNumber={chromeAlertSummary?.oldSecurityPatch}
                          // alertNumber={3}

                          apiUrl={OLD_PATCH}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={selinux}
                          alertTitle={"SELinux Status"}
                          alertNumber={chromeAlertSummary?.seLinuxStatus}
                          apiUrl={SE_LINUX_STATUS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={android_root}
                          alertTitle={"Rooted / Jailbreaked"}
                          alertNumber={
                            chromeAlertSummary?.deviceRootedJailbreaked
                          }
                          apiUrl={ROOTED_DEVICE}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={unrecog}
                          alertTitle={"Unrecognized IP Address"}
                          alertNumber={0}
                          // apiUrl={NETWORK_VIOLATION}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={openport}
                          alertTitle={"Unwanted Open Ports"}
                          alertNumber={0}
                          // apiUrl={UNWANTED_PORT}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={usbviolation}
                          alertTitle={"Unknown Network Comm"}
                          alertNumber={
                           0
                          }
                          // apiUrl={NETWORK_COMMUNICATION_VIOLATION}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={tpm_icon}
                          alertTitle={"TPM Not Available"}
                          alertNumber={0}
                          // apiUrl={TPM_DISABLED}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={secure}
                          alertTitle={"Secure Boot Not Enabled"}
                          alertNumber={0}
                          // apiUrl={IS_SECURE_BOOT_DISABLED}
                          // routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={oshardingset}
                          alertTitle={"OS Not Hardened"}
                          alertNumber={
0                          }
                          // apiUrl={OS_NOT_HARDENED}
                          // routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={os_not_patch}
                          alertTitle={"OS Not Patched"}
                          alertNumber={0}
                          // apiUrl={LINUX_OS_NOT_PATCH}
                          // routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={virus}
                          alertTitle={"Virus Intrusion"}
                          alertNumber={0}
                          // apiUrl={SCANNER_INSTALLED}
                          // routeUrl={"/linux-serverity"}
                        />
                      </Col>



                      <Col xl="3">
                        <Alerts
                          alertIcon={developer_mode}
                          alertTitle={"Developer Mode Status"}
                          alertNumber={chromeAlertSummary?.developerModeStatus}
                          apiUrl={DEVELOPER_STATUS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={usb_debbugin}
                          alertTitle={"USB Debugging Status"}
                          alertNumber={chromeAlertSummary?.usbDebuggingStatus}
                          apiUrl={USB_DEBUGGING_STATUS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={phone_lock}
                          alertTitle={"Device Lock Status"}
                          alertNumber={chromeAlertSummary?.deviceLockStatus}
                          apiUrl={DEVICE_LOCK_STATUS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={open_nw}
                          alertTitle={"Open Network Connection"}
                          alertNumber={
                            chromeAlertSummary?.openNetworkConnection
                          }
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={untrusted_app}
                          alertTitle={"Untrusted App Source"}
                          alertNumber={chromeAlertSummary?.untrustedAppSource}
                          apiUrl={UNTRUSTED_APP_SOURCE}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={banned_app}
                          alertTitle={"Banned Apps"}
                          alertNumber={chromeAlertSummary?.bannedApps}
                          apiUrl={BANNED_APPS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={dangers_app}
                          alertTitle={"Dangerous Apps (RDP)"}
                          alertNumber={chromeAlertSummary?.dangerousAppsRDP}
                          apiUrl={DANGEROUS_APPS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={bluetooth}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={chromeAlertSummary?.bluetoothEnabled}
                          apiUrl={BLUETOOTH_ENABLED}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={screen_timeout}
                          alertTitle={"Screen Timeout > 60 sec"}
                          alertNumber={
                            chromeAlertSummary?.screenTimeoutGreaterThan60Sec
                          }
                          apiUrl={SCREEN_TIME}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={time_zone}
                          alertTitle={"Timezone Status"}
                          alertNumber={chromeAlertSummary?.timeZoneStatus}
                          apiUrl={TIMEOUT_STATUS}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={third_part}
                          alertTitle={"3rd-party Keyboard Found"}
                          alertNumber={
                            chromeAlertSummary?.thirdPartyKeyboardFound
                          }
                          apiUrl={KEYBOARD_FOUND}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={usbIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={chromeAlertSummary?.usbVoliation}
                          // apiUrl={USB_VOLIATION}
                          apiUrl={'usbinfo/device-with-unwhitelisted-usb-insertion'}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={emulater}
                          alertTitle={"Emulator Detected"}
                          alertNumber={chromeAlertSummary?.emulatorDetected}
                          apiUrl={EMULATOR_DETECTED}
                          routeUrl={"/chrome-serverity"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="4">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[35, 20, 20, 15]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>1.8B</h1>
                        <p>86.24%</p>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={unknow}
                          alertTitle={"Unknown Services"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={oldPassword}
                          alertTitle={"Old Admin Passwords"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={multRoot}
                          alertTitle={"Multiple Root Privilege Account"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"UAC Disabled"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={publicshare}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={wifi}
                          alertTitle={"Wi-Fi Enabled"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={domain}
                          alertTitle={"Out of Domain"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windows_rdp_gateway}
                          alertTitle={"RDP Enabled"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts Found</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={cddrive}
                          alertTitle={"CD Drive Enabled"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={battery}
                          alertTitle={"BIOS Battery Unserviceable"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={scanner_icon}
                          alertTitle={"Scanner Installed"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={ram}
                          alertTitle={"High RAM Usages"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={cpu}
                          alertTitle={"High CPU Usages"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={os_formated}
                          alertTitle={"OS Formatted"}
                          alertNumber={0}
                          apiUrl={OS_PATCHED || ""}
                          routeUrl={"/linux-serverity"}
                          controlApi={false}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={harware}
                          alertTitle={"Hardware Changed"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Open Network Connection"}
                          alertNumber={0}
                          apiUrl={""}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Footer />
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
