import React, { useEffect, useState } from "react";

import riskIcon from "../../../assets/img/DashIcon/riskIcon.png";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

function DashCard({
  dashIcon,
  dashNumber,
  dashTitle,
  riskNumber,
  riskPerc,
  dashRoute,
  handleRoute,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [hoverMe, setHoverMe] = useState(false);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <div
      className={`outerCard ${hoverMe ? "hoverMe" : ""}`}
      onClick={handleRoute}
      onMouseLeave={() => setHoverMe(false)}
      onMouseEnter={() => setHoverMe(true)}
    >
      <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
        <span className="d-flex align-items-center justify-content-center">
          <img src={dashIcon} width={60} height={60} />
          <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
            {dashNumber || 0}
          </p>
        </span>
        <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 ">
          {dashTitle}
        </p>
        <span className="d-flex align-items-center justify-content-start">
          <img src={riskIcon} />
          <p className="m-0 p-0  mx-2">{riskNumber} clients at risk</p>
        </span>
        <ProgressBar
          className="w-100 mt-2"
          completed={riskPerc}
          bgColor="red"
          height="5px"
          baseBgColor="green"
          labelColor="#e80909"
          maxCompleted={100}
        />
        <div className="d-flex align-items-center justify-content-between w-100">
          <p className="m-0 o-0">{riskPerc}%</p>
          <p className="m-0 p-0">{100 - riskPerc}%</p>
        </div>
      </div>
    </div>
  );
}

export default DashCard;
