import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import cyberLogo from "../assets/img/onlyLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../api/axios";
import pageSvg from "../assets/svg/forgot_password.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideToaster, showToaster } from "../feature/slices/toastSlice";
import CustomToaster from "../components/common/CustomToaster";

export default function ForgotPassword() {
  const [otp, setOtp] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [otpExpireTime, setOtpExpireTime] = useState(0);
  const navigate = useNavigate();
  document.body.classList.remove("sidebar-show");
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = Math.floor((otpExpireTime - currentTime) / 1000);
      if (timeDifference > 0) {
        setTimer(timeDifference);
        setIsTimerActive(true);
      } else {
        setIsTimerActive(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [otpExpireTime]);

  async function handleOTP() {
    console.log(formik.values.email, "EMAIL");
    try {
      const res = await axios.post("/forget-password", {
        email: formik.values.email,
      });
      dispatch(
        showToaster({
          title: "Send Otp",
          message: res?.data?.message,
          color: "success",
        })
      );
      setTimeout(() => {
        dispatch(hideToaster());
      }, 2000);
      setOtp(true);
      setOtpExpireTime(new Date(res?.data?.expiresAt).getTime());
    } catch (error) {
      // Handle Reset error
      console.log(error, "ERROROTP");
      if (error.response) {
        dispatch(
          showToaster({
            title: "Error",
            message: error.response.data.message,
            color: "danger",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
        }, 2000);
      } else {
        dispatch(
          showToaster({
            title: "Error",
            message: "Something Went Wrong",
            color: "danger",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
        }, 2000);
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      otp: Yup.string()
        .required("OTP is required")
        .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
      newPassword: Yup.string()
        .min(6, "New Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await axios.post("/reset-password", values);
        dispatch(
          showToaster({
            title: "Reset",
            message: res?.data?.message,
            color: "success",
          })
        );
        setTimeout(() => {
          dispatch(hideToaster());
          navigate("/");
        }, 2000);
      } catch (error) {
        // Handle login error
        if (error.response) {
          dispatch(
            showToaster({
              title: "Error",
              message: error.response.data.message,
              color: "danger",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        } else {
          dispatch(
            showToaster({
              title: "Error",
              message: "Something Went Wrong",
              color: "danger",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        }
      }
      setSubmitting(false);
    },
  });

  const handleResend = () => {
    if (!formik.values.email) {
      dispatch(
        showToaster({
          title: "Error",
          message: "Email is required",
          color: "danger",
        })
      );
      return;
    }
    handleOTP();
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link
            to="/"
            className="sidebar-logo d-flex align-items-center gap-2 justify-content-start"
          >
            <img
              src={cyberLogo}
              id="logo"
              alt="Cyber Auditor"
              className="cyBerLogo"
            />
            <p className="m-0 p-0 fw-semibold fs-13 ">CYBER AUDITOR</p>
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"
                ></object>
              </div>
              <Card.Title>Reset your password</Card.Title>
              <Card.Text className="mb-5">
                Enter your username or email address and we will send you a link
                to reset your password.
              </Card.Text>

              <Row className="g-2">
                <Col sm="12">
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error-message">{formik.errors.email}</div>
                  )}
                </Col>
                <Col sm="12">
                  {otp && (
                    <>
                      <Form.Control
                        type="number"
                        name="otp"
                        placeholder="6 Digit"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.otp}
                        className={
                          formik.touched.otp && formik.errors.otp ? "error" : ""
                        }
                      />
                      {formik.touched.otp && formik.errors.otp && (
                        <div className="error-message">{formik.errors.otp}</div>
                      )}
                    </>
                  )}
                </Col>
                <Col sm="12">
                  {otp && (
                    <>
                      <Form.Control
                        type="text"
                        name="newPassword"
                        placeholder="New Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                        className={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                            ? "error"
                            : ""
                        }
                      />
                      {formik.touched.newPassword &&
                        formik.errors.newPassword && (
                          <div className="error-message">
                            {formik.errors.newPassword}
                          </div>
                        )}
                    </>
                  )}
                </Col>
                <Col sm="12">
                  {!otp && (
                    <Button variant="primary" onClick={handleOTP}>
                      Send Otp
                    </Button>
                  )}
                  {otp && (
                    <Button
                      variant="primary"
                      onClick={formik.handleSubmit}
                      disabled={
                        !formik.isValid || formik.isSubmitting || isTimerActive
                      }
                    >
                      {formik.isSubmitting ? (
                        <span className="m-0 p-0">
                          <Spinner size="sm" />
                        </span>
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  )}
                </Col>
                {isTimerActive && (
                  <Col sm="12" className="d-flex align-items-center">
                    <span className="mr-2">
                      Resend in {Math.floor(timer / 60)}:{timer % 60}
                    </span>
                    <Button
                      variant="outline-primary"
                      onClick={handleResend}
                      disabled={isTimerActive}
                    >
                      Resend
                    </Button>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <CustomToaster />
    </div>
  );
}
