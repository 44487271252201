import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Dropdown, Menu, Button } from "antd";

import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import CustomUsbViolation from "../../components/common/Table/CustomUsbViolation";
import DataTable from "react-data-table-component";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import Excellogo from "../../assets/img/Excellogo.png";
import { jsPDF } from "jspdf";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "jspdf-autotable"; // For adding tables easily
import { useSkinMode } from "../../components/SkinModeContext";

function LinuxUsbViolation() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();
  const location = useLocation();
  const { skinMode } = useSkinMode(); // Access the skin mode state

  console.log(state, "ststststinusb");
  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const { user } = useSelector((state) => state.authSlice);
  const [pcId, setPcId] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([""]);
  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });
  const [selectedPcId, setSelectedPcId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUsbHistoryId, setSelectedUsbHistoryId] = useState("");
  const [adminActionData, setAdminActionData] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [states, setStates] = useState(""); // For the state dropdown
  const [comment, setComment] = useState("");
  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async (callUrl, userId, pageNum, pageSz) => {
    let isMounted = true;
    let timeoutId;

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_LINUX_URL}/usbhistoryinfo/get-pc-with-unwhitelisted-usb/${userId}?page=${pageNum}&pageSize=${pageSz}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      if (isMounted) {
        console.log(response, "IN THE API RESPONSE");

        if (response?.data?.data.length > 0) {
          console.log(response?.data?.data, "LOGRESPONSE");
          const calculateData = response?.data?.data?.map((v) => {
            return {
              hostname: v?.hostname || "NA",
              serialNumber: v?.serialNumber || "NA",
              ipAddress: v?.ipAddress,
              openTcpPorts: v?.openTcpPorts || "NA",
              usbName: v?.usbName?.split(" ") || [],
              usbSerialNumber: v?.usbSerialNumber,
              macAddress: v?.macAddress,
              usbManufacturer: v?.usbManufacturer,
              usbProductId: v?.usbProductId,
              usbVendorId: v?.usbVendorId,
              localAddress: v?.localAddress,
              localPort: v?.localPort,
              foreignAddress: v?.foreignAddress,
              foreignPort: v?.foreignPort,
              time: v?.time,
              falsePositive: v?.falsePositive,
              usbHistoryId: v?.usbHistoryId,
              action: "yourActionValue",
              pc_Id: v?.pcId || "NA",
              adminActionCount: v?.adminActionCount,
              IsadminActionDone: v?.IsadminActionDone,
            };
          });
          setColumnData(calculateData);
        } else {
          setColumnData([]);
        }
      }
    } catch (error) {
      if (isMounted) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Unauthorized error occurred");
          } else if (error.response.status === 504) {
            console.log("Gateway Timeout error occurred");
          }
        } else {
          console.error("An error occurred:", error.message);
        }
        setError(error);
        console.log(error, "IN THE ERROR");
      }
    } finally {
      if (isMounted) {
        setLoading(false);
        // timeoutId = setTimeout(() => fetchData(callUrl, userId, pageNum, pageSz), 50000); // Adjusted interval to 50 seconds
      }
    }

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  };
  const UserType = user?.data?.user?.roleName;
  useEffect(() => {
    fetchData(state?.callUrl, user?.data?.user?.userId, pageNumber, pageSize);
  }, [user, , pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();

      // Add organization name and export date/time at the top of the CSV file
      const csvHeader = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
        "", // Empty line before column headers
      ];

      // Determine which columns should be included based on non-empty values
      const headers = [];
      const rows = [];

      if (columnData.some((item) => item.hostname)) {
        headers.push("Hostname");
        rows.push((item) => item.hostname);
      }
      if (columnData.some((item) => item.serialNumber)) {
        headers.push("Serial Number");
        rows.push((item) => item.serialNumber);
      }
      if (columnData.some((item) => item.usbName)) {
        headers.push("USB Name");
        rows.push((item) => item.usbName);
      }
      if (columnData.some((item) => item.usbSerialNumber)) {
        headers.push("USB Serial No");
        rows.push((item) => item.usbSerialNumber);
      }
      if (columnData.some((item) => item.macAddress)) {
        headers.push("MAC Address");
        rows.push((item) => item.macAddress);
      }
      if (columnData.some((item) => item.usbManufacturer)) {
        headers.push("USB Manufacturer");
        rows.push((item) => item.usbManufacturer);
      }
      if (columnData.some((item) => item.usbProductId)) {
        headers.push("USB ProductId");
        rows.push((item) => item.usbProductId);
      }
      if (columnData.some((item) => item.usbVendorId)) {
        headers.push("USB VendorId");
        rows.push((item) => item.usbVendorId);
      }
      if (columnData.some((item) => item.ipAddress)) {
        headers.push("IP Address");
        rows.push((item) => item.ipAddress);
      }
      if (columnData.some((item) => item.localAddress)) {
        headers.push("Local Address");
        rows.push((item) => item.localAddress);
      }
      if (columnData.some((item) => item.localPort)) {
        headers.push("Local Port");
        rows.push((item) => item.localPort);
      }
      if (columnData.some((item) => item.foreignAddress)) {
        headers.push("Foreign Address");
        rows.push((item) => item.foreignAddress);
      }
      if (columnData.some((item) => item.foreignPort)) {
        headers.push("Foreign Port");
        rows.push((item) => item.foreignPort);
      }
      if (columnData.some((item) => item.time)) {
        headers.push("Timestamp");
        rows.push((item) => item.time);
      }
      if (columnData.some((item) => item.falsePositive)) {
        headers.push("false Positive");
        rows.push((item) => item.falsePositive);
      }

      // Generate CSV data based on selected headers and rows
      const csvData = [
        csvHeader.join(","), // Add org name and export date/time
        headers.join(","), // Add column headers
        ...columnData.map((item) => rows.map((row) => row(item)).join(",")),
      ].join("\n");

      const blob = new Blob([csvData], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}_Report.csv`);
    }
  };

  // function exportToPDF() {
  //   if (columnData && columnData.length > 0) {
  //     const doc = new jsPDF({ orientation: "landscape" });

  //     // Organization name and export date/time
  //     const orgName = user?.data?.user?.organization || "Organization Name";
  //     const exportDateTime = new Date().toLocaleString();

  //     // Add header with org name and export date/time
  //     doc.setFontSize(12);
  //     doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
  //     doc.text(
  //       `Exported: ${exportDateTime}`,
  //       doc.internal.pageSize.getWidth() - 14,
  //       15,
  //       { align: "right" }
  //     ); // Right-aligned

  //     // Add title
  //     doc.setFontSize(15);
  //     doc.text(`${state?.titleState}`, 14, 22);

  //     // Define headers and rows
  //     const headers = [];
  //     const rows = [];

  //     const colWidths = {
  //       hostname: 10,
  //       serialNumber: 30,
  //       usbName: 40,
  //       usbSerialNumber: 30,
  //       macAddress: 30,
  //       usbManufacturer: 40,
  //       usbProductId: 20,
  //       usbVendorId: 20,
  //       ipAddress: 30,
  //       openTcpPorts: 30,
  //       localAddress: 50,
  //       localPort: 30,
  //       foreignAddress: 50,
  //       foreignPort: 30,
  //       time: 30,
  //       action: 30,
  //     };

  //     const checkAndPushHeader = (key, label) => {
  //       if (columnData.some((item) => item[key])) {
  //         headers.push(label);
  //         return (item) =>
  //           doc.splitTextToSize(item[key] || "NA", colWidths[key]);
  //       }
  //       return null;
  //     };

  //     const rowFuncs = [
  //       checkAndPushHeader("hostname", "Hostname"),
  //       checkAndPushHeader("serialNumber", "Serial Number"),
  //       checkAndPushHeader("usbName", "USB Name"),
  //       checkAndPushHeader("usbSerialNumber", "USB Serial No"),
  //       checkAndPushHeader("macAddress", "MAC Address"),
  //       checkAndPushHeader("usbManufacturer", "USB Manufacturer"),
  //       checkAndPushHeader("usbProductId", "USB ProductId"),
  //       checkAndPushHeader("usbVendorId", "USB VendorId"),
  //       checkAndPushHeader("ipAddress", "IP Address"),
  //       checkAndPushHeader("localAddress", "Local Address"),
  //       checkAndPushHeader("localPort", "Local Port"),
  //       checkAndPushHeader("foreignAddress", "Foreign Address"),
  //       checkAndPushHeader("foreignPort", "Foreign Port"),
  //       checkAndPushHeader("time", "Timestamp"),
  //       checkAndPushHeader("falsePositive", "False Positive"),
  //     ].filter(Boolean);

  //     // Prepare rows data
  //     columnData.forEach((item) => {
  //       const row = rowFuncs.map((func) => func(item)).join(" | ");
  //       rows.push(row.split(" | "));
  //     });

  //     // Use autoTable to create the table
  //     doc.autoTable({
  //       startY: 30,
  //       head: [headers],
  //       body: rows,
  //       styles: {
  //         cellPadding: 3,
  //         lineColor: [44, 62, 80],
  //         lineWidth: 0.1,
  //       },
  //       headStyles: {
  //         fillColor: [52, 73, 94],
  //         textColor: 255,
  //         halign: "center",
  //         lineWidth: 0.5,
  //         fontSize: 10, // Set a smaller font size for the header
  //       },
  //       bodyStyles: {
  //         lineColor: [44, 62, 80],
  //         lineWidth: 0.1,
  //       },
  //       alternateRowStyles: {
  //         fillColor: [240, 240, 240],
  //       },
  //       columnStyles: {
  //         // Set specific column widths here if necessary
  //         hostname: { cellWidth: colWidths.hostname },
  //         serialNumber: { cellWidth: colWidths.serialNumber },
  //         usbName: { cellWidth: colWidths.usbName },
  //         usbSerialNumber: { cellWidth: colWidths.usbSerialNumber },
  //         macAddress: { cellWidth: colWidths.macAddress },
  //         usbManufacturer: { cellWidth: colWidths.usbManufacturer },
  //         usbProductId: { cellWidth: colWidths.usbProductId },
  //         usbVendorId: { cellWidth: colWidths.usbVendorId },
  //         ipAddress: { cellWidth: colWidths.ipAddress },
  //         localAddress: { cellWidth: colWidths.localAddress },
  //         localPort: { cellWidth: colWidths.localPort },
  //         foreignAddress: { cellWidth: colWidths.foreignAddress },
  //         foreignPort: { cellWidth: colWidths.foreignPort },
  //         time: { cellWidth: colWidths.time },
  //         action: { cellWidth: colWidths.action },
  //       },
  //     });

  //     // Save the PDF
  //     doc.save(`${state?.titleState}_Report.pdf`);
  //   }
  // }
  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      const doc = new jsPDF({ orientation: "landscape" });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = new Date().toLocaleString();

      // Add header with org name and export date/time
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);

      // Define headers and rows
      const headers = [];
      const rows = [];

      const colWidths = {
        hostname: 10,
        serialNumber: 30,
        usbName: 40,
        usbSerialNumber: 30,
        macAddress: 30,
        usbManufacturer: 40,
        usbProductId: 20,
        usbVendorId: 20,
        ipAddress: 30,
        time: 30,
        falsePositive: 30,
      };

      // Helper to check and push the header
      const checkAndPushHeader = (key, label) => {
        if (columnData.some((item) => item[key])) {
          headers.push(label);
          return (item) => item[key] || "NA";
        }
        return null;
      };

      const rowFuncs = [
        checkAndPushHeader("hostname", "Hostname"),
        checkAndPushHeader("serialNumber", "Serial Number"),
        checkAndPushHeader("usbName", "USB Name"),
        checkAndPushHeader("usbSerialNumber", "USB Serial No"),
        checkAndPushHeader("macAddress", "MAC Address"),
        checkAndPushHeader("usbManufacturer", "USB Manufacturer"),
        checkAndPushHeader("usbProductId", "USB ProductId"),
        checkAndPushHeader("usbVendorId", "USB VendorId"),
        checkAndPushHeader("ipAddress", "IP Address"),
        checkAndPushHeader("time", "Timestamp"),
        checkAndPushHeader("falsePositive", "False Positive"),
      ].filter(Boolean); // Filter out nulls

      // Prepare rows data
      columnData.forEach((item) => {
        const row = rowFuncs.map((func) => func(item)); // Create an array of values without joining them
        rows.push(row);
      });

      // Use autoTable to create the table
      doc.autoTable({
        startY: 30,
        head: [headers],
        body: rows,
        styles: {
          cellPadding: 3,
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        headStyles: {
          fillColor: [52, 73, 94],
          textColor: 255,
          halign: "center",
          lineWidth: 0.5,
          fontSize: 10, // Set a smaller font size for the header
        },
        bodyStyles: {
          lineColor: [44, 62, 80],
          lineWidth: 0.1,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          // Set specific column widths here if necessary
          hostname: { cellWidth: colWidths.hostname },
          serialNumber: { cellWidth: colWidths.serialNumber },
          usbName: { cellWidth: colWidths.usbName },
          usbSerialNumber: { cellWidth: colWidths.usbSerialNumber },
          macAddress: { cellWidth: colWidths.macAddress },
          usbManufacturer: { cellWidth: colWidths.usbManufacturer },
          usbProductId: { cellWidth: colWidths.usbProductId },
          usbVendorId: { cellWidth: colWidths.usbVendorId },
          ipAddress: { cellWidth: colWidths.ipAddress },
          time: { cellWidth: colWidths.time },
          falsePositive: { cellWidth: colWidths.falsePositive },
        },
      });

      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const styleViewbutton = {
    marginLeft: "-6px !important",
  };

  const handleSerialNumberChange = (index, value) => {
    const newSerialNumbers = [...serialNumbers];
    newSerialNumbers[index] = value;
    setSerialNumbers(newSerialNumbers);
  };

  const addSerialNumberField = () => {
    setSerialNumbers([...serialNumbers, ""]);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    const serialNumbers = selectedRows.map((row) => row.serialNumber);
    setPcId(selectedRows);
    setSerialNumbers(serialNumbers);
  };

  const handleOpenModal = (pcId, usbHistoryId) => {
    setSelectedPcId(pcId); // Store pcId in state
    setSelectedUsbHistoryId(usbHistoryId); // Store usbHistoryId in state
    // setSelectedServiceNo(serviceNo); // Store serviceNo in state
    HandleAdminget(usbHistoryId);
  };

  const handleOpenModalCount = async (pcId, usbHistoryId) => {
    console.log("Opening modal with:", pcId, usbHistoryId);
    setSelectedPcId(pcId);
    setSelectedUsbHistoryId(usbHistoryId);
    await HandleAdminget(usbHistoryId);
    setShowModal(true);
  };

  const HandleAdminget = async (usbHistoryId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/get-admin-action/by/${usbHistoryId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setAdminActionData(response?.data?.data);
        // Optionally reset the form or fetch updated data
      } else {
        setAdminActionData();
        // toast.error("Failed to de-link USBs from devices");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the 404 error specifically
        console.warn("404 Not Found: ", error.message);
        setAdminActionData(); // Clear the state
      } else {
        console.error("Error de-linking USBs from devices:", error);
      }
    }
  };

  const handleSubmit = async (e, pcId, usbserialNumbers, usbHistoryId) => {
    e.preventDefault();
    try {
      const data = {
        organization_Id: user?.data?.user?.organizationId,
        // pcIds: pcId?.map((item) => item.pc_Id),
        // usbSerialNumbers: serialNumbers?.filter((sn) => sn.trim() !== ""), // Filter out any empty values
        pcIds: [pcId],
        usbSerialNumbers: [usbserialNumbers],
        serviceNo: user?.data?.user?.email,
        usbHistoryIds: [usbHistoryId],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/add/usbs/to-devices/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        fetchData(
          state?.callUrl,
          user?.data?.user?.userId,
          pageNumber,
          pageSize
        );
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitControl = async (e) => {
    e.preventDefault();
    try {
      const data = {
        action: states,
        comment: comment,
        usbHistoryId: selectedUsbHistoryId,
        serviceNo: user?.data?.user?.email,
        pcId: selectedPcId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/create-admin-action/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.data?.message?.length > 0) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form here

        // document.getElementById("closeModal").click();
        setComment("");
        setStates("");
        // onChange();();

        // setSerialNumbers([]);
        HandleAdminget(selectedUsbHistoryId);

        // window.location.reload();
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusChange = async (userId, pcId, usbserviceno) => {
    console.log("ddddd:", userId);

    try {
      const data = {
        falsePositive: true,
        serviceNo: user?.data?.user?.email,
        pcId: pcId,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_LINUX_URL}/usbhistoryinfo/update-false-positive/${userId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log(response.data, "rrrrrrrrrr");

      if (response?.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        fetchData(
          state?.callUrl,
          user?.data?.user?.userId,
          pageNumber,
          pageSize
        );
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      // toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };

  const hasFalsePositive = rowData?.some(
    (row) => row.falsePositive !== undefined
  );

  const getBadgeClass = (action) => {
    switch (action) {
      case "actionInitiated":
        return "text-bg-danger";
      case "updateViolation":
        return "text-bg-warning";
      case "closeViolation":
        return "text-bg-success";
      default:
        return "text-bg-secondary"; // fallback class
    }
  };

  // Inside your component render
  const action =
    Array.isArray(adminActionData) && adminActionData?.length > 0
      ? adminActionData[0]?.action
      : null;

  const badgeClass = getBadgeClass(action);

  const columnsAdmin = [
    {
      name: "S.No",
      selector: (row, index) => index + 1, // Automatically generate serial numbers
      sortable: true,
      width: "90px",
    },
    {
      name: "Date",
      selector: (row) => {
        if (!row.createdAt) return null; // Don't show anything if createdAt is empty
        return (
          <Tippy content={formatTimestamp(row.createdAt)}>
            <div>{formatTimestamp(row.createdAt)}</div>
          </Tippy>
        );
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "State",
      selector: (row) => row.action,
      sortable: true,
    },

    {
      name: "Service No",
      selector: (row) => (
        <Tippy content={row.serviceNo} placement="top">
          <span>{row.serviceNo}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Comment",
      selector: (row) => (
        <Tippy content={row.comment} placement="top">
          <span>{row.comment}</span>
        </Tippy>
      ),
      sortable: true,
    },
  ];

  const shouldHideActions = rowData?.adminActionCount === 1;

  const handleSerialClick = (row) => {
    // Set the active row when serial number is clicked
    setActiveRow(row);
    console.log(row, "usbSerialNumber");
  };

  const menuadminset = (row) => (
    <Menu>
      {row.usbName && (
        <Menu.Item key="usbName">
          <strong>USB Name:</strong> {row?.usbName}
        </Menu.Item>
      )}
      {row.usbProductId && (
        <Menu.Item key="productId">
          <strong>Product ID:</strong> {row?.usbProductId}
        </Menu.Item>
      )}
      {row.usbVendorId && (
        <Menu.Item key="vendorId">
          <strong>Vendor ID:</strong> {row?.usbVendorId}
        </Menu.Item>
      )}
    </Menu>
  );
  // old code here
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       name: "S. No.",
  //       selector: (_, index) => (pageNumber - 1) * pageSize + index + 1,
  //       width: "90px",
  //     },
  //     {
  //       name: "Hostname",
  //       selector: (row) => row.hostname,
  //       sortable: true,
  //     },
  //     {
  //       name: "Serial No.",
  //       selector: (row) => (
  //         <Tippy content={row.serialNumber}>
  //           <div>{row.serialNumber}</div>
  //         </Tippy>
  //       ),
  //       sortable: true,
  //     },

  //     // Conditionally add the "IP Address" column if any row has a valid IP Address

  //     {
  //       name: "IP Address",
  //       selector: (row) => (
  //         <Tippy content={row.ipAddress}>
  //           <div>{row.ipAddress}</div>
  //         </Tippy>
  //       ),
  //       sortable: true,
  //       width: "150px",
  //     },

  //     {
  //       name: "USB Serial No.",
  //       selector: (row) => (
  //         <Tippy content={row.usbSerialNumber}>
  //           {/* Directly trigger dropdown on hover */}
  //           <Dropdown
  //             overlay={menuadminset(row)} // Dropdown menu content
  //             trigger={["hover"]} // Built-in hover trigger
  //           >
  //             <div>
  //               <Button type="link">
  //                 {row.usbSerialNumber}{" "}
  //                 {/* <i className="fa-solid fa-angle-down"></i> */}
  //               </Button>
  //             </div>
  //           </Dropdown>
  //         </Tippy>
  //       ),
  //       sortable: true,
  //       width: "290px",
  //     },

  //     {
  //       name: "Manufacturer",
  //       selector: (row) => (
  //         <Tippy content={row.usbManufacturer}>
  //           <div>{row.usbManufacturer}</div>
  //         </Tippy>
  //       ),
  //       sortable: true,
  //       width: "180px",
  //     },
  //     {
  //       name: "Timestamp",
  //       selector: (row) => row.time,
  //       sortable: true,
  //       width: "180px",
  //     },
  //     {
  //       cell: (row) => (
  //         <>
  //           {UserType === "Super Admin" && (
  //             <>
  //               {row.falsePositive === undefined ? (
  //                 <span className="badge text-white rounded-pill text-bg-secondary">
  //                   N/A
  //                 </span>
  //               ) : row.falsePositive === false ? (
  //                 <button
  //                   type="button"
  //                   // className="btn btn-sm btn-outline-dark"
  //                   className={`btn btn-sm ${
  //                     row?.IsadminActionDone === "inProgress"
  //                       ? "btn-outline-dark"
  //                       : "btn-dark"
  //                   }`}
  //                   onClick={() =>
  //                     handleStatusChange(
  //                       row.usbHistoryId,
  //                       row?.pc_Id,
  //                       row.usbSerialNumber
  //                     )
  //                   }
  //                   // disabled={row?.adminActionCount > 0} // Disable button if usbCount > 0
  //                   disabled={row?.IsadminActionDone === "inProgress"}
  //                 >
  //                   False Positive
  //                 </button>
  //               ) : (
  //                 <span className="badge text-success rounded-pill">True</span>
  //               )}
  //             </>
  //           )}
  //         </>
  //       ),
  //       sortable: true,
  //       width: ` ${UserType === "Super Admin" ? "145px" : "1px"}`,
  //     },

  //     {
  //       cell: (row) => (
  //         <>
  //           {UserType === "Super Admin" && (
  //             <>
  //               <button
  //                 type="button"
  //                 // className="btn btn-sm btn-outline-info"
  //                 className={`btn btn-sm ${
  //                   row?.IsadminActionDone === "inProgress"
  //                     ? "btn-outline-info"
  //                     : "btn-info"
  //                 }`}
  //                 onClick={(e) =>
  //                   handleSubmit(
  //                     e,
  //                     row?.pc_Id,
  //                     row?.usbSerialNumber,
  //                     row?.usbHistoryId
  //                   )
  //                 }
  //                 // disabled={row?.adminActionCount > 0} // Disable button if usbCount > 0
  //                 disabled={row?.IsadminActionDone == "inProgress"}
  //               >
  //                 whitelist USB
  //               </button>
  //             </>
  //           )}
  //         </>
  //       ),
  //       width: ` ${UserType === "Super Admin" ? "145px" : "1px"}`,
  //     },

  //     {
  //       name: "Action",
  //       cell: (row) => (
  //         <>
  //           {UserType === "Super Admin" && (
  //             <div style={{ position: "relative", marginRight: "30px" }}>
  //               <button
  //                 type="button"
  //                 className="btn btn-sm btn-secondary"
  //                 style={{ width: "7rem", textAlign: "center" }}
  //                 id="openModalButton"
  //                 data-bs-toggle="modal"
  //                 data-bs-target="#deviceModal"
  //                 onClick={() => {
  //                   console.log("qwertt", row);
  //                   handleOpenModal(row?.pc_Id, row?.usbHistoryId);
  //                 }}
  //               >
  //                 Admin Action &nbsp;{" "}
  //               </button>

  //               <button
  //                 type="button"
  //                 // className="btn btn-sm btn-danger position-absolute"
  //                 className={`btn btn-sm position-absolute ${
  //                   row?.IsadminActionDone === "pending"
  //                     ? "btn-danger"
  //                     : row?.IsadminActionDone === "inProgress"
  //                     ? "btn-warning"
  //                     : row?.IsadminActionDone === "completed"
  //                     ? "btn-success"
  //                     : "btn-dark"
  //                 }`}
  //                 id="openModalButton"
  //                 data-bs-toggle="modal"
  //                 data-bs-target="#deviceModalCount"
  //                 disabled={row?.adminActionCount === 0}
  //                 style={{
  //                   top: "-10px",
  //                   right: "-11px",
  //                   height: "22px", // Height and width should be equal for a circle
  //                   width: "22px", // Set width to match height
  //                   borderRadius: "50%", // Make it circular
  //                   display: "flex", // Use flexbox to center text
  //                   alignItems: "center", // Center vertically
  //                   justifyContent: "center", // Center horizontally
  //                   padding: 0, // Remove default padding
  //                 }}
  //                 onClick={() =>
  //                   handleOpenModalCount(row?.pcId, row?.usbHistoryId)
  //                 }
  //               >
  //                 <span style={{ fontSize: "13px" }}>
  //                   {row?.adminActionCount}
  //                 </span>
  //               </button>
  //             </div>
  //           )}
  //           <button
  //             type="button"
  //             className="btn btn-sm btn-dark"
  //             style={{ width: "7rem", textAlign: "center" }}
  //             onClick={() =>
  //               navigate("/linux-client-Details", {
  //                 state: {
  //                   basicInfo: row,
  //                   pcInfoId: row?.pc_Id,
  //                   title: "USB Violations",
  //                 },
  //               })
  //             }
  //           >
  //             <i className="fas fa-long-arrow-alt-right"></i>
  //           </button>
  //         </>
  //       ),
  //       ignoreRowClick: true,
  //       allowOverflow: true,
  //       // width: "330px",
  //       // style: { minWidth: 'auto', maxWidth: '90rem' }, // Adjust the width range
  //     },
  //   ],
  //   [pageNumber, pageSize, navigate, rowData, hasFalsePositive]
  // );
  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (pageNumber - 1) * pageSize + index + 1,
        width: "90px",
      },
      {
        name: "Hostname",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.hostname}>
            <div>{row.hostname}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "IP Address",
        selector: (row) => (
          <Tippy maxWidth="none" content={row?.ipAddress?.replace(" ", ", ")}>
            <div>{row?.ipAddress?.replace(" ", ", ")}</div>
          </Tippy>
        ),
        sortable: true,
        width: "150px",
      },

      {
        name: "USB Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.usbSerialNumber}>
            <Dropdown overlay={menuadminset(row)} trigger={["hover"]}>
              <div>
                <Button type="link" className="p-0">
                  {row.usbSerialNumber}{" "}
                </Button>
              </div>
            </Dropdown>
          </Tippy>
        ),
        sortable: true,
        width: "290px",
      },

      {
        name: "Manufacturer",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.usbManufacturer}>
            <div>{row.usbManufacturer}</div>
          </Tippy>
        ),
        sortable: true,
        width: "180px",
      },

      {
        name: "Timestamp",
        selector: (row) => row.time,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.time}>
            <div>{row.time}</div>
          </Tippy>
        ),
        sortable: true,
        width: "180px",
      },

      {
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <>
                {row.falsePositive === undefined ? (
                  <span className="badge text-white rounded-pill text-bg-secondary">
                    N/A
                  </span>
                ) : row.falsePositive === false ? (
                  <button
                    type="button"
                    className={`btn btn-sm ${
                      row?.IsadminActionDone === "inProgress"
                        ? "btn-outline"
                        : "btn-dark"
                    }`}
                    onClick={() =>
                      handleStatusChange(
                        row.usbHistoryId,
                        row?.pc_Id,
                        row.usbSerialNumber
                      )
                    }
                    disabled={row?.IsadminActionDone === "inProgress"}
                  >
                    False Positive
                  </button>
                ) : (
                  <span className="badge text-success rounded-pill">True</span>
                )}
              </>
            )}
          </>
        ),
        sortable: true,
        width: ` ${UserType === "Super Admin" ? "145px" : "1px"}`,
      },

      {
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    row?.IsadminActionDone === "inProgress"
                      ? "btn-outline-info"
                      : "btn-info"
                  }`}
                  onClick={(e) =>
                    handleSubmit(
                      e,
                      row?.pc_Id,
                      row?.usbSerialNumber,
                      row?.usbHistoryId
                    )
                  }
                  disabled={row?.IsadminActionDone === "inProgress"}
                >
                  whitelist USB
                </button>
              </>
            )}
          </>
        ),
        width: ` ${UserType === "Super Admin" ? "145px" : "1px"}`,
      },

      {
        name: "Action",
        cell: (row) => (
          <>
            {UserType === "Super Admin" && (
              <div style={{ position: "relative", marginRight: "30px" }}>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  style={{ width: "7rem", textAlign: "center" }}
                  id="openModalButton"
                  data-bs-toggle="modal"
                  data-bs-target="#deviceModal"
                  onClick={() => {
                    handleOpenModal(row?.pc_Id, row?.usbHistoryId);
                  }}
                >
                  Admin Action &nbsp;{" "}
                </button>

                <button
                  type="button"
                  className={`btn btn-sm position-absolute ${
                    row?.IsadminActionDone === "pending"
                      ? "btn-danger"
                      : row?.IsadminActionDone === "inProgress"
                      ? "btn-warning"
                      : row?.IsadminActionDone === "completed"
                      ? "btn-success"
                      : "btn-dark"
                  }`}
                  id="openModalButton2"
                  data-bs-toggle="modal"
                  data-bs-target="#deviceModal2"
                  disabled={row?.adminActionCount === 0}
                  style={{
                    top: "-10px",
                    right: "-11px",
                    height: "22px",
                    width: "22px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 0,
                  }}
                  onClick={() =>
                    handleOpenModalCount(row?.pcId, row?.usbHistoryId)
                  }
                >
                  <span style={{ fontSize: "13px" }}>
                    {row?.adminActionCount}
                  </span>
                </button>
              </div>
            )}
            <Button
              className="btn rounded-md border-0 d-flex fs10 gap-3 mx-auto px-2 text-light bg-dark text-center justify-center align-items-center"
              onClick={() =>
                navigate("/linux-client-Details", {
                  state: {
                    basicInfo: row,
                    pcInfoId: row?.pc_Id,
                    title: state?.titleState,
                  },
                })
              }
            >
              <p className="m-0 p-0 fs-12">Details</p>
              <i className="fas fa-long-arrow-alt-right"></i>
            </Button>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        width: "330px",
      },
    ],
    [pageNumber, pageSize, navigate, rowData, hasFalsePositive]
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  useEffect(() => {
    const modalElement = document.getElementById("deviceModal");

    // Add event listener for when the modal is fully hidden
    const handleModalClose = () => {
      fetchData(state?.callUrl, user?.data?.user?.userId, pageNumber, pageSize);
    };

    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    // Cleanup listener on component unmount
    return () => {
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4" style={mainStyle}>
        {/* <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Dashboard
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/linux-dash" activeClassName="active">
              Linux Dashboard
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/linux-usb-violation" activeClassName="active">
              {state?.titleState}
            </NavLink>
          </li>
        </ol> */}

        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">{`Dashboard`}</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-dash" ? (
              <span className="active">{`Linux Dashboard`}</span>
            ) : (
              <NavLink exact to="/linux-dash">
                Linux Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/linux-usb-violation" ? (
              <span className="active">
                {state?.titleState || "USB Violation"}
              </span>
            ) : (
              <NavLink exact to="/linux-usb-violation">
                {state?.titleState || "USB Violation"}
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Linux Dashboard</p>
          </span>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn btn-sm btn-primary text-white"
              onClick={() => navigate(-1)}
            >
              <i
                className="ri-arrow-left-line fs-18 lh-1 mr-2"
                style={{ verticalAlign: "middle", marginRight: "3px" }}
              ></i>{" "}
              Back
            </button>
          </div>
          {/* <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-2"
              onClick={exportToCSV}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>
              <span className="d-none d-sm-inline">Generate Report</span>
            </Button>
          </div> */}
        </div>
        {/* <Row>
          <Col xl={6}>
            <p className="fs-20 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p className="text-danger p-0 m-0 fw-semibold">
              {totalDocuments || 0} Clients
            </p>
          </Col>
        </Row> */}

        <div class="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div class="col-xl">
            <p class="fs-20 text-dark fw-bolder p-0 m-0">USB Violations</p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0}{" "}
              {state?.titleState.includes("Violations") === true
                ? "Violations"
                : "Endpoints"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; CSV
            </button>
          </div>
        </div>

        {/* <div xl={12} className=" card allCardEffect shadow mt-2"> */}
        {/* {!loading ? (
          <CustomUsbViolation
            rowData={columnData}
            columnData={columnData}
            routeUrl={"/linux-client-Details"}
            currentPage={pageNumber}
            pageSize={pageSize}
            totalDocuments={totalDocuments}
            onChange={fetchData}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <PageLoader />
        )} */}

        <div xl={12} className=" card allCardEffect shadow mt-2">
          {!loading ? (
            <DataTable
              columns={columns}
              data={columnData || []}
              customStyles={currentStyles}
              pagination
              paginationServer
              // selectableRows
              // clearSelectedRows={toggleClearRows}
              // onSelectedRowsChange={handleSelectedRowsChange}
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onChangePage={(page) => {
                console.log("Page", page);
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                console.log("Page Size", newPageSize);
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={
                <p className="p-0 my-2 me-auto text-center"> No Data Found</p>
              }
            />
          ) : (
            <PageLoader />
          )}
        </div>
        <div
          className="modal fade"
          id="deviceModal"
          tabIndex="-1"
          aria-labelledby="deviceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deviceModalLabel">
                  Control <i className="fa-brands fa-usb"></i>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                ></button>
              </div>
              <div className="m-3">
                <div className="card allCardEffect shadow mt-2">
                  <form
                    onSubmit={(e) => handleSubmitControl(e, state, comment)}
                  >
                    <div className="modal-body">
                      {/* Dropdown for state selection */}
                      <div className="mb-3">
                        <label htmlFor="stateSelect" className="form-label">
                          State
                        </label>
                        <select
                          id="stateSelect"
                          className="form-select"
                          value={states}
                          onChange={(e) => setStates(e.target.value)}
                          required
                        >
                          <option value="">Select State</option>
                          <option value="actionInitiated">
                            Action Initiated
                          </option>
                          <option value="updateViolation">
                            Violation Updated
                          </option>
                          <option value="closeViolation">
                            Violation Closed
                          </option>
                        </select>
                      </div>

                      {/* Comment textarea */}
                      <div className="mb-3">
                        <label htmlFor="comment" className="form-label">
                          Comment
                        </label>
                        <textarea
                          id="comment"
                          className="form-control"
                          rows="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Add your comment here..."
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-sm btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                <div className="card allCardEffect shadow mt-2 mb-2">
                  <DataTable
                    columns={columnsAdmin}
                    data={adminActionData}
                    customStyles={currentStyles}
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2  text-center">No Data Found</p>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deviceModal2"
          tabIndex="-1"
          aria-labelledby="deviceModalLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="deviceModalLabel2">
                  Admin Actions History
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="card allCardEffect shadow mt-2 mb-2">
                  <DataTable
                    columns={columnsAdmin}
                    data={adminActionData}
                    customStyles={currentStyles}
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2 text-center">No Data Found</p>
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
}

export default LinuxUsbViolation;
