import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/img1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
import axios from "axios";
import CustomToaster from "../components/common/CustomToaster";
import { showToaster } from "../feature/slices/toastSlice";
import WatchLogNotification from "./WatchLogNotification";
import NotificationListMain from "./NotificationListMain";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPopper } from "@popperjs/core";
import {useSkinMode}  from "../components/SkinModeContext"
function Header({ onSkin }) {
  const [notificationData, setNotificationData] = useState([]);
  const [totalNotificationCount, setTotalNotificationCount] = useState(-1);
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const popperInstance = useRef(null);
  const [unreadCount, setUnreadCount] = useState(0);


   console.log(show,'showshowshowshow')
  const [notifications, setNotifications] = useState([
    {
      _id: "6685244474525c342376824f",
      pcId: "5CG83791KG-PGSLU00WBBB76I-f49d0c8d-8649-499d-81e9-24cd92820593",
      hostname: "Vinay",
      eventTriggerType: "WINDOWS_INSTALLATION_TRIGGER",
      isRead: false,
      organizationId: "667cf41d48fdb8da775dc233",
      organizationName: "mobisec technologies pvt. ltd",
      notificationType: "TRIGGER",
      timeStamp: "03-07-2024, 15:43:24",
      createdAt: "2024-07-03T10:13:24.386Z",
      updatedAt: "2024-07-03T10:13:24.386Z",
      __v: 0,
    },
    // Add more notifications as needed
  ]);

  const { user } = useSelector((state) => state.authSlice);
  const UsertypeDept = user?.data?.user?.departmentName;
  const UsertypeSection = user?.data?.user?.sectionName;
  const UserTypeSubSection = user?.data?.user?.subSectionName;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const countUnread = () => {
      const count = notificationData?.filter(
        (notification) => !notification.isRead
      )?.length;
      setUnreadCount(count);
    };

    countUnread();
  }, [notificationData]);

  console.log(unreadCount, "unreadCount");

  useEffect(() => {
    if (show && dropdownRef.current && menuRef.current) {
      popperInstance.current = createPopper(
        dropdownRef.current,
        menuRef.current,
        {
          placement: "bottom-end",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10], // Adjust offset as needed
              },
            },
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ],
        }
      );
    }

    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy();
        popperInstance.current = null;
      }
    };
  }, [show]);
  const handleLogOut = async () => {
    await dispatch(logout());
    navigate("/");
  };
  // useEffect(() => {
  //   let mounted = true;
  //   const fetchData = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_NOTIFY_URL}/get-all-notifications/by/${user?.data?.user?.userId}`,
  //         //  `${process.env.REACT_APP_NOTIFY_URL}/get-all-alert/by/${user?.data?.user?.userId}`
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user?.data?.accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         let trigger_TYPE =
  //           response?.data?.notifications?.[0]?.eventTriggerType;
  //         let host_name = response?.data?.notifications?.[0]?.hostname;

  //         let Alert_TYPE = response?.data?.notifications?.[0].alertType;

  //         if (mounted) {
  //           console.log(
  //             "Notification count changed from",
  //             totalNotificationCount,
  //             "to",
  //             response?.data?.notifications?.length
  //           );

  //           if (
  //             totalNotificationCount !== -1 &&
  //             totalNotificationCount !== response?.data?.notifications?.length
  //           ) {
  //             let title = "";
  //             let message = "";
  //             let color = "";

  //             if (trigger_TYPE === "LINUX_USB_TRIGGER") {
  //               title = "USB Trigger";
  //               message = `Unauthorized USB used in ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "LINUX_BLUETOOTH_TRIGGER") {
  //               title = "Bluetooth Trigger";
  //               message = `Bluetooth device used in ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "MAC_BLUETOOTH_TRIGGER") {
  //               title = "Bluetooth Trigger";
  //               message = `Bluetooth device used in ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "LINUX_INSTALLATION_TRIGGER") {
  //               title = "New device connected";
  //               message = `Cyberauditor  installed on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_INSTALLATION_TRIGGER") {
  //               title = "New device connected";
  //               message = `Cyberauditor  installed on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "WINDOWS_MANUAL_TRIGGER") {
  //               title = "Manual trigger";
  //               message = `Manual trigger installed on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "INSTALLATION_TRRIGGER") {
  //               title = "Installed trigger";
  //               message = `Installed trigger  on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "USER_LOGON_TRIGGER") {
  //               title = "Logon trigger";
  //               message = `Logon trigger  on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "LINUX_SPECIFIC_TIME_TRIGGER") {
  //               title = "Linux specific time";
  //               message = `Linux specific time trigger  on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "LINUX_USB_TRIGGER") {
  //               title = "Linux trigger";
  //               message = `Linux trigger  on ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "MAC_SPECIFIC_INTERVAL_TRIGGER") {
  //               title = "Mac specific interval trigger";
  //               message = `interval trigger on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_USB_TRIGGER") {
  //               title = "USB trigger";
  //               message = `USB trigger  on ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "LINUX_DAILY_TRIGGER") {
  //               title = "Daily trigger";
  //               message = `Daily trigger   on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_DAILY_TRIGGER") {
  //               title = "Daily trigger";
  //               message = `Daily trigger   on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "LINUX_INTERVAL_TRIGGER") {
  //               title = "Interval trigger";
  //               message = `Interval trigger  on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "LINUX_LOGON_TRIGGER") {
  //               title = "Logon trigger";
  //               message = `Logon trigger on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "LINUX_NETWORK_TRIGGER") {
  //               title = "Linux Network Trigger";
  //               message = `Network trigger on ${host_name}`;
  //               color = "danger";
  //             }
  //             if (trigger_TYPE === "WINDOWS_USB_TRIGGER") {
  //               title = "USB Trigger";
  //               message = `Unauthorized USB  used in ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_BLUETOOTH_TRIGGER") {
  //               title = "Bluetooth Trigger";
  //               message = `Bluetooth device  used in ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_INSTALLATION_TRIGGER") {
  //               title = "New device connected";
  //               message = `Cyberauditor installed on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_MANUAL_TRIGGER") {
  //               title = "Manual trigger";
  //               message = `Manual trigger installed on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "WINDOWS_DEFENDER_SCAN_TRIGGER") {
  //               title = "Defender scan";
  //               message = `Defender scanned at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_DEFENDER_MALWARE_TRIGGER") {
  //               title = "Defender MALWARE";
  //               message = `Defender malware at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_FIREWALL_TRIGGER") {
  //               title = "WINDOWS FIREWALL";
  //               message = `Windows firewall at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_SPECIFIC_INTERVAL_TRIGGER") {
  //               title = "WINDOWS FIREWALL";
  //               message = `Windows firewall at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MissedLog") {
  //               title = "Missed log";
  //               message = `Missed log at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_NETWORK_TRIGGER") {
  //               title = "MAC NETWORK TRIGGER";
  //               message = `Mac Network at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "MAC_LOGON_TRIGGER") {
  //               title = " LOGON";
  //               message = ` logon at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "WINDOWS_LOGON_TRIGGER") {
  //               title = " LOGON";
  //               message = ` logon at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "WINDOWS_DAILY_TRIGGER") {
  //               title = "Daily ";
  //               message = `Daily trigger at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "WINDOWS_10_MINUTES_TRIGGER") {
  //               title = "10 Min Trigger";
  //               message = ` 10 Min Trigger at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "USB_TRIGGER") {
  //               title = "USB trigger";
  //               message = ` USB trigger at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "WINDOWS_NETWORK_CHANGE_TRIGGER") {
  //               title = "Network trigger";
  //               message = ` Network trigger at ${host_name}`;
  //               color = "danger";
  //             }
  //             if (trigger_TYPE === "APP_INSTALL") {
  //               title = "APP Installed";
  //               // message = `Unauthorized USB {NAME} used in ${host_name}`;
  //               message = `New app installed  ${host_name}`;
  //               color = "warning";
  //             } else if (trigger_TYPE === "APP_UNINSTALL") {
  //               title = "APP UNINSTALL";
  //               message = `App uninstalled in ${host_name}`;
  //               color = "warning";
  //             } else if (trigger_TYPE === "NETWORK_STATE_CHANGE") {
  //               title = "Network state changed";
  //               message = `Network state changed on ${host_name}`;
  //               color = "warning";
  //             } else if (trigger_TYPE === "ROUTINE") {
  //               title = "ROUTINE";
  //               message = `Routing on this ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "SERVICE_STARTUP") {
  //               title = "Service start-up";
  //               message = `Service start-up on ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "INSTALLATION") {
  //               title = "Android Installation";
  //               message = `Installation at ${host_name}`;
  //               color = "success";
  //             } else if (trigger_TYPE === "DEVELOPER_SETTING_CHANGED") {
  //               title = "Developer setting changed";
  //               message = `Developer setting changed at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "USB_CONNECTED") {
  //               title = "USB connected";
  //               message = `USB connected at ${host_name}`;
  //               color = "danger";
  //             } else if (trigger_TYPE === "BLACKLIST_APP_INSTALL") {
  //               title = "BLACKLIST app installed";
  //               message = `BLACKLIST app installed at ${host_name}`;
  //               color = "danger";
  //             } else if (Alert_TYPE === "WATCHDOG_VERSION_ALERT") {
  //               title = "WATCHDOG VERSION ALERT";
  //               message = `Watch log version alert at${host_name}`;
  //               color = "danger";
  //             } else if (Alert_TYPE === "WATCHDOG_TRIGGER") {
  //               title = "Watchdog trigger";
  //               message = `Watchdog trigger on ${host_name}`;
  //               color = "danger";
  //             } else if (Alert_TYPE === "WATCHDOG_UPDATE_ALERT") {
  //               title = "WATCHDOG UPDATE ALERT";
  //               message = `Watchdog Update alert on ${host_name}`;
  //               color = "danger";
  //             } else if (Alert_TYPE === "WATCHDOG_SERVICE_ALERT") {
  //               title = "WATCHDOG UPDATE ALERT";
  //               message = `Watchdog Update alert on ${host_name}`;
  //               color = "danger";
  //             }

  //             dispatch(
  //               showToaster({
  //                 title: title || "NA",
  //                 message: message || "NA",
  //                 color: color || "success",
  //               })
  //             );
  //           }
  //           setTotalNotificationCount(response?.data?.notifications?.length);
  //           setNotificationData(response.data.notifications);
  //         }
  //       })
  //       .catch((error) => {
  //         setNotificationData([]);
  //         if (error.response) {
  //           const statusCode = error.response.status;
  //           switch (statusCode) {
  //             case 404:
  //               console.log("Resource not found (404)");
  //               break;
  //             case 500:
  //               console.log("Internal server error (500)");
  //               break;
  //             case 504:
  //               console.log("Gateway timeout (504)");
  //               break;
  //             default:
  //               console.log(`Error: ${statusCode}`);
  //           }
  //         } else if (error.request) {
  //           console.log("No response received");
  //         } else {
  //           console.log("Error", error.message);
  //         }
  //       })
  //       .finally(() => {
  //         if (mounted) {
  //           setTimeout(fetchData, 15000); // Retry polling after 15 seconds
  //         }
  //       });
  //   };

  //   fetchData();

  //   // Cleanup function to set mounted to false when component unmounts
  //   return () => {
  //     mounted = false;
  //   };
  // }, [
  //   user?.data?.accessToken,
  //   user?.data?.user?.userId,
  //   totalNotificationCount,
  //   dispatch,
  //   setTotalNotificationCount,
  // ]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/get-all-notifications/by/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const notifications = response.data.notifications;
      const trigger_TYPE = notifications?.[0]?.eventTriggerType;
      const host_name = notifications?.[0]?.hostname;
      const Alert_TYPE = notifications?.[0]?.alertType;

      if (
        totalNotificationCount !== -1 &&
        totalNotificationCount !== notifications.length
      ) {
        // Handle notifications
        const { title, message, color } = getNotificationDetails(
          trigger_TYPE,
          host_name,
          Alert_TYPE
        );
        dispatch(showToaster({ title, message, color }));
      }

      setTotalNotificationCount(notifications.length);
      setNotificationData(notifications);
    } catch (error) {
      handleFetchError(error);
    }
  };

  const getNotificationDetails = (trigger_TYPE, host_name, Alert_TYPE) => {
    let title = "NA",
      message = "NA",
      color = "success";


    // Your logic to determine title, message, and color based on trigger_TYPE and Alert_TYPE
    if (trigger_TYPE === "LINUX_USB_TRIGGER") {
      title = "USB Trigger";
      message = `Unauthorized USB used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "MAC_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Cyberauditor  installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Cyberauditor  installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_MANUAL_TRIGGER") {
      title = "Manual trigger";
      message = `Manual trigger installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "INSTALLATION_TRRIGGER") {
      title = "Installed trigger";
      message = `Installed trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "USER_LOGON_TRIGGER") {
      title = "Logon trigger";
      message = `Logon trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_SPECIFIC_TIME_TRIGGER") {
      title = "Linux specific time";
      message = `Linux specific time trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_USB_TRIGGER") {
      title = "Linux trigger";
      message = `Linux trigger  on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "MAC_SPECIFIC_INTERVAL_TRIGGER") {
      title = "Mac specific interval trigger";
      message = `interval trigger on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_USB_TRIGGER") {
      title = "USB trigger";
      message = `USB trigger  on ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_DAILY_TRIGGER") {
      title = "Daily trigger";
      message = `Daily trigger   on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_DAILY_TRIGGER") {
      title = "Daily trigger";
      message = `Daily trigger   on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_INTERVAL_TRIGGER") {
      title = "Interval trigger";
      message = `Interval trigger  on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_LOGON_TRIGGER") {
      title = "Logon trigger";
      message = `Logon trigger on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "LINUX_NETWORK_TRIGGER") {
      title = "Linux Network Trigger";
      message = `Network trigger on ${host_name}`;
      color = "danger";
    }
    if (trigger_TYPE === "WINDOWS_USB_TRIGGER") {
      title = "USB Trigger";
      message = `Unauthorized USB  used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_BLUETOOTH_TRIGGER") {
      title = "Bluetooth Trigger";
      message = `Bluetooth device  used in ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_INSTALLATION_TRIGGER") {
      title = "New device connected";
      message = `Cyberauditor installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_MANUAL_TRIGGER") {
      title = "Manual trigger";
      message = `Manual trigger installed on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_DEFENDER_SCAN_TRIGGER") {
      title = "Defender scan";
      message = `Defender scanned at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_DEFENDER_MALWARE_TRIGGER") {
      title = "Defender MALWARE";
      message = `Defender malware at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_FIREWALL_TRIGGER") {
      title = "WINDOWS FIREWALL";
      message = `Windows firewall at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_SPECIFIC_INTERVAL_TRIGGER") {
      title = "WINDOWS FIREWALL";
      message = `Windows firewall at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MissedLog") {
      title = "Missed log";
      message = `Missed log at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_NETWORK_TRIGGER") {
      title = "MAC NETWORK TRIGGER";
      message = `Mac Network at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "MAC_LOGON_TRIGGER") {
      title = " LOGON";
      message = ` logon at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_LOGON_TRIGGER") {
      title = " LOGON";
      message = ` logon at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_DAILY_TRIGGER") {
      title = "Daily ";
      message = `Daily trigger at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "WINDOWS_10_MINUTES_TRIGGER") {
      title = "10 Min Trigger";
      message = ` 10 Min Trigger at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "USB_TRIGGER") {
      title = "USB trigger";
      message = ` USB trigger at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "WINDOWS_NETWORK_CHANGE_TRIGGER") {
      title = "Network trigger";
      message = ` Network trigger at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "LINUX_FIREWALL_TRIGGER") {
      title = "LINUX FIREWALL";
      message = `Linux firewall at ${host_name}`;
      color = "danger";
    }
    
    if (trigger_TYPE === "APP_INSTALL") {
      title = "APP Installed";
      // message = `Unauthorized USB {NAME} used in ${host_name}`;
      message = `New app installed  ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "APP_UNINSTALL") {
      title = "APP UNINSTALL";
      message = `App uninstalled in ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "NETWORK_STATE_CHANGE") {
      title = "Network state changed";
      message = `Network state changed on ${host_name}`;
      color = "warning";
    } else if (trigger_TYPE === "ROUTINE") {
      title = "ROUTINE";
      message = `Routing on this ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "SERVICE_STARTUP") {
      title = "Service start-up";
      message = `Service start-up on ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "INSTALLATION") {
      title = "Android Installation";
      message = `Installation at ${host_name}`;
      color = "success";
    } else if (trigger_TYPE === "DEVELOPER_SETTING_CHANGED") {
      title = "Developer setting changed";
      message = `Developer setting changed at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "USB_CONNECTED") {
      title = "USB connected";
      message = `USB connected at ${host_name}`;
      color = "danger";
    } else if (trigger_TYPE === "BLACKLIST_APP_INSTALL") {
      title = "BLACKLIST app installed";
      message = `BLACKLIST app installed at ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_VERSION_ALERT") {
      title = "WATCHDOG VERSION ALERT";
      message = `Watch log version alert at${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_TRIGGER") {
      title = "Watchdog trigger";
      message = `Watchdog trigger on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_UPDATE_ALERT") {
      title = "WATCHDOG UPDATE ALERT";
      message = `Watchdog Update alert on ${host_name}`;
      color = "danger";
    } else if (Alert_TYPE === "WATCHDOG_SERVICE_ALERT") {
      title = "WATCHDOG UPDATE ALERT";
      message = `Watchdog Update alert on ${host_name}`;
      color = "danger";
    }
    // Continue with the other conditions...

    return { title, message, color };
  };

  const handleFetchError = (error) => {
    setNotificationData([]);
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 404:
          console.log("Resource not found (404)");
          break;
        case 500:
          console.log("Internal server error (500)");
          break;
        case 504:
          console.log("Gateway timeout (504)");
          break;
        default:
          console.log(`Error: ${statusCode}`);
      }
    } else if (error.request) {
      console.log("No response received");
    } else {
      console.log("Error", error.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    const intervalId = setInterval(() => {
      if (mounted) fetchData();
    }, 15000); // Retry polling after 15 seconds

    fetchData(); // Initial fetch

    return () => {
      mounted = false;
      clearInterval(intervalId);
    };
  }, [
    user?.data?.accessToken,
    user?.data?.user?.userId,
    totalNotificationCount,
    dispatch,
  ]);

  function updateNotification(notificationID) {
    axios
      .post(
        `${process.env.REACT_APP_NOTIFY_URL}/update-notification/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "UPDATE SUCCESSFULLY");
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const statusCode = error.response.status;
          switch (statusCode) {
            case 404:
              console.log("Resource not found (404)");
              break;
            case 500:
              console.log("Internal server error (500)");
              break;
            case 504:
              console.log("Gateway timeout (504)");
              break;
            default:
              console.log(`Error: ${statusCode}`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  }

  const handleRouteClient = (
    notifcationID,
    pcData,
    timeStamp,
    eventTriggerType
  ) => {
    if (eventTriggerType.includes("WINDOWS")) {
      navigate("/windows-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }

    if (eventTriggerType?.includes("LINUX")) {
      navigate("/linux-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
    if (eventTriggerType?.includes("MAC")) {
      navigate("/macOs-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
    if (eventTriggerType?.includes("CHROME")) {
      navigate("/chrome-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }

    if (eventTriggerType?.includes("ANDROID")) {
      navigate("/android-client-Details", {
        state: {
          pcInfoId: pcData,
          timeTrigger: timeStamp,
        },
      });
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  const NotificationList = () => {
    const notiList = notificationData?.map(
      ({ eventTriggerType, notificationType, timeStamp, pcId, _id }, index) => {
        return (
          <li
            className="list-group-item"
            key={index}
            onClick={() =>
              handleRouteClient(_id, pcId, timeStamp, eventTriggerType)
            }
          >
            <>
              <div
                className={
                  "avatar d-flex align-items-center justify-content-center fs2em  successText fw-semibold"
                }
              >
                <i className="ri-install-line"></i>
              </div>
              <div className="list-group-body" key={index}>
                <p>{eventTriggerType}</p>
                <span>{timeStamp}</span>
              </div>
            </>
          </li>
        );
      }
    );

    return <ul className="list-group h-10 notifcationHeight">{notiList}</ul>;
  };

  // const skinMode = (e) => {
  //   e.preventDefault();
  //   e.target.classList.add("active");

  //   let node = e.target.parentNode.firstChild;
  //   while (node) {
  //     if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
  //       node.classList.remove("active");
  //     node = node.nextElementSibling || node.nextSibling;
  //   }

  //   let skin = e.target.textContent.toLowerCase();
  //   let HTMLTag = document.querySelector("html");

  //   if (skin === "dark") {
  //     HTMLTag.setAttribute("data-skin", skin);
  //     localStorage.setItem("skin-mode", skin);
  //     onSkin(skin);
  //   } else {
  //     HTMLTag.removeAttribute("data-skin");
  //     localStorage.removeItem("skin-mode");
  //     onSkin("");
  //   }
  // };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  const handleNotificationClick = async (notificationId) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_NOTIFY_URL}/update-notification/${notificationId}`,
      { headers: { Authorization: `Bearer ${user?.data?.accessToken}` } }
    );
    console.log(response.data.message);
  };

  const handleClearAll = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/mark-all-notification-as-read/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {}, // Empty object for the request body
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log(response.data.message, "mark as all");
      toast.info(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setNotificationData([]);
      setTotalNotificationCount(0);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(
        "Error:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const { skinMode, toggleSkinMode } = useSkinMode(); // Access the skin mode state and toggle function

  const handleSkinModeToggle = (e) => {
    e.preventDefault();
    toggleSkinMode(); // Toggle the skin mode between 'dark' and 'light'
  };
  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>
      <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i>
      </div>
      &nbsp;&nbsp;
      {/* <nav className="nav nav-skin">
        <Link
          onClick={skinMode}
          className={
            localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"
          }
        >
          Light
        </Link>
        <Link
          onClick={skinMode}
          defaultValue={"Dark"}
          className={
            localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"
          }
        >
          Dark
        </Link>
      </nav> */}
      <nav className="nav nav-skin">
       
        <Link
          onClick={handleSkinModeToggle}
          className={skinMode === "light" ? "nav-link active" : "nav-link"}
        >
          Light
        </Link>
        <Link
          onClick={handleSkinModeToggle}
          className={skinMode === "dark" ? "nav-link active" : "nav-link"}
        >
          Dark
        </Link>
      </nav>
      <Dropdown
        className="dropdown-notification ms-3 ms-xl-4"
        align="end"
        show={show}
        onToggle={() => setShow(!show)}
      >
        <Dropdown.Toggle as="div" ref={dropdownRef}>
          <i className="ri-notification-3-line"></i>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {unreadCount}
            <span class="visually-hidden">unread messages</span>
          </span>
        </Dropdown.Toggle>
        {show && (
          <Dropdown.Menu
            ref={menuRef}
            className="mt-10-f me--10-f show"
            style={{ position: "absolute" }}
          >
            <NotificationListMain
              onChange={fetchData}
              notifications={notificationData}
              notificationCountAll={notificationData?.length}
              onNotificationClick={handleNotificationClick}
              onClearAll={handleClearAll}
              updateNotification={updateNotification} // Pass the function here
              closeDropdown={() => setShow(false)} // Pass close function here
            />
          </Dropdown.Menu>
        )}
      </Dropdown>
      <Dropdown className="dropdown-profile  ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <small
          className="text-muted fst-italic"
          style={{ fontSize: "8px", marginLeft: "-4px" }}
        >
          {user?.data?.user?.roleName}
        </small>
        <Dropdown.Menu className="mt-10-f">
          {/* <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">
              {user?.data?.user?.firstName || "Dummy"}
            </h5>
            <p className="fs-sm text-secondary mb-0">
              {UsertypeDept + "/" ? UsertypeDept + "/" : ""}
              {UsertypeSection + "/" ? UsertypeSection + "/" : ""}
              {UserTypeSubSection ? UserTypeSubSection : ""}
            </p>
            <p className="fs-sm text-secondary">
              {user?.data?.user?.organization || "NA"}
            </p>

            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="">
                <i className="ri-profile-line"></i> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="">
                <i className="ri-question-line"></i> Help Center
              </Link>
              <Link to="">
                <i className="ri-lock-line"></i> Privacy Settings
              </Link>
              <Link to="">
                <i className="ri-user-settings-line"></i> Account Settings
              </Link>
              <Link onClick={handleLogOut}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div> */}
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">
              {user?.data?.user?.firstName || "Dummy"}
            </h5>
            <p className="fs-sm text-secondary mb-0">
              {user?.data?.user?.roleName === "Super Admin" || user?.data?.user?.roleName === "Organization Admin"
                ? user.data.user.roleName
                : `${UsertypeDept ? UsertypeDept + '/' : ''}${UsertypeSection ? UsertypeSection + '/' : ''}${UserTypeSubSection || ''}`}
            </p>
            <p className="fs-sm text-secondary">
              {user?.data?.user?.organization || "NA"}
            </p>

            <nav className="nav">

              <Link to="/update-profile">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              {/* <Link to="">
                <i className="ri-profile-line"></i> View Profile
              </Link> */}
            </nav>
            <hr />
            <nav className="nav">
              {/* <Link to="">
                <i className="ri-question-line"></i> Help Center
              </Link>
              <Link to="">
                <i className="ri-lock-line"></i> Privacy Settings
              </Link>
              <Link to="">
                <i className="ri-user-settings-line"></i> Account Settings
              </Link> */}
              <Link onClick={handleLogOut}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {/* <WatchLogNotification /> */}
      <CustomToaster />
    </div>
  );
}

export default Header;
