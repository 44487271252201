export const MAC_OS_NOT_PATCH = "osinfo/os-not-patched";
export const MAC_NIC = "hardwareInfo/is-nic-disabled";

export const EDR_MALFUNCTION_ENDPOINT = "osinfo/edr-malfunction";
export const ANTIVIRUS_NOT_INSTALLED_ENDPOINT =
  "osinfo/antivirus-not-installed";
export const ANTI_VIRUS_MALFUNCTION_ENDPOINT = "osinfo/anti-virus-malfunction";
export const EDR_NOT_INSTALLED_ENDPOINT = "osinfo/edr-not-Installed";
export const MALWARE_DETECTION_ENDPOINT = "osinfo/malware-detection";
export const ANTI_VIRUS_NOT_UPDATED_ENDPOINT = "osinfo/anti-virus-not-updated";

export const MAC_NOT_ACTIVATED = "pcinfo/all-not-activated-mac";

export const MAC_FIREWALL_OFF = "networkinfo/firewall-off";

export const MAC_BLACKLIST = "backendinfo/all-blacklisted-programs";

export const TOTAL_MAC_LIST = "pcinfo/mac-pc-list";

export const SAFE_MAC_CLIENTS = "backendinfo/safe-mac-clients";

export const UNSAFE_MAC_CLIENTS = "backendinfo/unsafe-mac-clients";

export const UNRESPONSIVE_MAC_CLIENTS = "pcinfo/all-unresponsive-mac";

export const OS_NOT_PATCHED = "backendinfo/os-not-patched";

export const ALL_UNKNOWN_SERVICES = "backendinfo/all-unknown-services";

export const IS_NAC_DISABLED = "networkinfo/is-nac-disabled";

export const IS_WIFI_ENABLED = "networkinfo/is-wifi-enabled";

export const NTPC_SERVER = "networkinfo/is-ntp-server-enabled";

export const OPEN_NETWORK_CONNECTION = "networkinfo/is-open-network-connection";

export const IS_BLUETOOTH_ENABLED = "networkinfo/is-bluetooth-enabled";

export const MULITPLE_ADMIN = "accountinfo/is-multiple-admin";

export const OLDER_PASSWORD = "accountinfo/is-admin-pwd-greater-than-threshold";

export const RDP_ENABLED = "osinfo/is-rdp-enabled";

export const SHARED_FOLDER = "osinfo/is-shared-directories";

export const CD_DRIVE = "hardwareInfo/is-cd-drive-enabled";

export const IS_SCANNER_ENABLED = "hardwareInfo/is-scanner-Installed";

export const OUT_OF_DOMAIN = "pcinfo/get-out-of-domain-mac";

export const HIGH_CPU_USAGE = "hardwareInfo/is-high-cpu-usage";

export const HIGH_RAM_USAGE = "hardwareInfo/is-high-ram-usage";

export const BIOS_HEALTH = "hardwareInfo/is-efi-present";

export const USB_VOILATION = "usbinfo/get-pc-with-unwhitelisted-usb";
