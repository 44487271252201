// import React, { useState } from "react";
// import Header from "../../../layouts/Header";
// import Footer from "../../../layouts/Footer";
// import PageLoader from "../../../components/common/Loader/PageLoader";
// import download_agents from "../../../assets/img/download_agents.png";

// import windowIcon from "../../../assets/img/DashIcon/newWindowIcon.png";
// import androidIcon from "../../../assets/img/DashIcon/newAndroidIcon.png";
// import linuxIcon from "../../../assets/img/DashIcon/newLinuxIcon.png";
// import macIcon from "../../../assets/img/DashIcon/newMacIcon.png";
// import chromeIcon from "../../../assets/img/DashIcon/newChromeIcon.png";
// import iosIcon from "../../../assets/img/DashIcon/NewIOSIcon.svg";
// import download_small from "../../../assets/img/download_small.png";
// import { Link, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// export default function DownloadAgent() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const { user } = useSelector((state) => state.authSlice);

// const linux_url = `${process.env.REACT_APP_LINUX_URL}/download/${user?.data?.user?.userId}`
// const window_url = `${process.env.REACT_APP_WINDOW_URL}/download/msi/${user?.data?.user?.userId}`
// const mac_url = `${process.env.REACT_APP_MAC_URL}/download/${user?.data?.user?.userId}`
// const chrome_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`
// const android_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//             {/* <Link to="/window-dash"> */}
//             <img
//               src={download_agents}
//               alt="Window-logo"
//               width={30}
//               height={30}
//             />
//             {/* </Link> */}
//             <p className="mx-2 fs-18 m-0 p-0">|</p>
//             <p className="fs-18 fw-bolder p-0 m-0 ">Download Agents</p>
//           </span>
//         </div>

//         <>
//           <div className="row">
//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={windowIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       WINDOWS
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     <a href={window_url} target="_blank" rel="noopener noreferrer">
//                       {" "}
//                       <button type="button" class="btn btn-info">
//                         Window 8.1 & above{" "}
//                         <img src={download_small} width={20} height={15} />{" "}
//                       </button>
//                     </a>
//                     &nbsp;
//                     <a href="https://test.cyberauditor.in/api/v1/windows/download/windows7" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       Window 7
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>

//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={linuxIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       LINUX
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     {/* <p className="m-0 p-0  mx-2">clients at risk</p> */}
//                     <a href="https://test.cyberauditor.in/api/v1/linux/download/ubuntu" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       Ubuntu <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>
//                     &nbsp;
//                     <a href="https://test.cyberauditor.in/api/v1/linux/download/rhel" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       RHEL
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>

//                     &nbsp;
//                     <a href="https://test.cyberauditor.in/api/v1/linux/download/centos" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       CentOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>

//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={macIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       MAC
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     {/* <p className="m-0 p-0  mx-2">clients at risk</p> */}
//                     <a href="https://test.cyberauditor.in/api/v1/mac/download" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       MacOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>
//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row mt-3">
//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={chromeIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       CHROME
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     {/* <p className="m-0 p-0  mx-2">clients at risk</p> */}
//                     <a href="https://play.google.com/store/apps/details?id=com.mobisec.cyberauditorapp&hl=en-IN" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       ChromeOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>

//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={androidIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       ANDROID
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     {/* <p className="m-0 p-0  mx-2">clients at risk</p> */}
//                     <a href="https://play.google.com/store/apps/details?id=com.mobisec.cyberauditorapp&hl=en-IN" target="_blank" rel="noopener noreferrer">
//                     <button type="button" class="btn btn-info">
//                       Android
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>

//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div class="col">
//               <div class="card">
//                 <div className="p-3 card-one  pe-auto  border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={iosIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       IOS
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2 "></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     {/* <img src={riskIcon} /> */}
//                     {/* <p className="m-0 p-0  mx-2">clients at risk</p> */}
//                     <a href="" target="_blank" rel="noopener noreferrer">

//                     <button type="button" class="btn btn-info">
//                       iOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     </a>
//                   </span>

//                   <div className="d-flex align-items-center justify-content-between w-100">
//                     {/* <p className="m-0 o-0">{}%</p>
//           <p className="m-0 p-0">{100 - riskPerc}%</p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <Footer />
//         </>
//       </div>
//     </React.Fragment>
//   );
// }

// import React, { useState } from "react";
// import Header from "../../../layouts/Header";
// import Footer from "../../../layouts/Footer";
// import download_agents from "../../../assets/img/download_agents.png";
// import windowIcon from "../../../assets/img/DashIcon/newWindowIcon.png";
// import androidIcon from "../../../assets/img/DashIcon/newAndroidIcon.png";
// import linuxIcon from "../../../assets/img/DashIcon/newLinuxIcon.png";
// import macIcon from "../../../assets/img/DashIcon/newMacIcon.png";
// import chromeIcon from "../../../assets/img/DashIcon/newChromeIcon.png";
// import iosIcon from "../../../assets/img/DashIcon/NewIOSIcon.svg";
// import download_small from "../../../assets/img/download_small.png";
// import { useSelector } from "react-redux";

// export default function DownloadAgent() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const { user } = useSelector((state) => state.authSlice);

//   const linux_urlubuntu = `${process.env.REACT_APP_LINUX_URL}/download/ubuntu/${user?.data?.user?.userId}`;
//   const linux_urlcentos = `${process.env.REACT_APP_LINUX_URL}/download/centos/${user?.data?.user?.userId}`;
//   const linux_urlrhel = `${process.env.REACT_APP_LINUX_URL}/download/rhel/${user?.data?.user?.userId}`;
//   const window_url = `${process.env.REACT_APP_WINDOW_URL}/download/msi/${user?.data?.user?.userId}`;
//   const mac_url = `${process.env.REACT_APP_MAC_URL}/download/${user?.data?.user?.userId}`;
//   const chrome_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`;
//   const android_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`;

//   const handleDownload = async (url) => {
//     try {
//       const response = await fetch(url, {
//         headers: {
//           Authorization: `Bearer ${user?.data?.accessToken}`,
//         },
//       });

//       // const blob = await response.blob();
//       // const downloadUrl = window.URL.createObjectURL(blob);
//       // const link = document.createElement("a");

//       // // Replace "agent_download" with your desired filename with the .deb extension
//       // link.href = downloadUrl;
//       // link.setAttribute("download", "agent_download.deb"); // Specify .deb extension here
//       // document.body.appendChild(link);
//       // link.click();
//       // link.parentNode.removeChild(link);

//       const blob = await response.blob();
//       const downloadUrl = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");

//       let filename = "agent_download";

//       if (navigator.platform.includes("Win")) {
//         filename = "CyberauditorSetup.exe";
//       } else if (navigator.platform.includes("Linux")) {
//         // Assuming you can differentiate between Ubuntu and other Linux distros
//         const userAgent = navigator.userAgent.toLowerCase();
//         if (userAgent.includes("ubuntu")) {
//           filename = "cyberauditor-linux-agent.deb";
//         } else {
//           filename = "cyberauditor-linux-agent.rpm";
//         }
//       }if (navigator.platform.includes("Mac")) {
//         filename = "cyberauditor-mac-agent.pkg";
//       }

//       link.href = downloadUrl;
//       link.setAttribute("download", filename);
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//     } catch (error) {
//       console.error("Download failed:", error);
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//             <img
//               src={download_agents}
//               alt="Window-logo"
//               width={30}
//               height={30}
//             />
//             <p className="mx-2 fs-18 m-0 p-0">|</p>
//             <p className="fs-18 fw-bolder p-0 m-0 ">Download Agents</p>
//           </span>
//         </div>

//         <>
//           <div className="row">
//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={windowIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       WINDOWS
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(window_url)}
//                     >
//                       Window 7 & above{" "}
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     &nbsp;
//                     {/* <a
//                       href="https://test.cyberauditor.in/api/v1/windows/download/windows7"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       <button type="button" className="btn btn-info">
//                         Window 7
//                         <img src={download_small} width={20} height={15} />{" "}
//                       </button>
//                     </a> */}
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={linuxIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       LINUX
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(linux_urlubuntu)}
//                     >
//                       Ubuntu <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     &nbsp;
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(linux_urlrhel)}
//                     >
//                       RHEL
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                     &nbsp;
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(linux_urlcentos)}
//                     >
//                       CentOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={macIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       MAC
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(mac_url)}
//                     >
//                       MacOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row mt-3">
//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={chromeIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       CHROME
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(chrome_url)}
//                     >
//                       ChromeOS
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={androidIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       ANDROID
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <button
//                       type="button"
//                       className="btn btn-info"
//                       onClick={() => handleDownload(android_url)}
//                     >
//                       Android
//                       <img src={download_small} width={20} height={15} />{" "}
//                     </button>
//                   </span>
//                 </div>
//               </div>
//             </div>

//             <div className="col">
//               <div className="card">
//                 <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
//                   <span className="d-flex align-items-center justify-content-center">
//                     <img src={iosIcon} width={60} height={60} />
//                     <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
//                       IOS
//                     </p>
//                   </span>
//                   <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
//                   <span className="d-flex align-items-center justify-content-start">
//                     <a href="" target="_blank" rel="noopener noreferrer">
//                       <button type="button" className="btn btn-info">
//                         iOS
//                         <img src={download_small} width={20} height={15} />{" "}
//                       </button>
//                     </a>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <Footer />
//         </>
//       </div>
//     </React.Fragment>
//   );
// }
import React, { useState } from "react";
// import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import download_agents from "../../../assets/img/download_agents.png";
import windowIcon from "../../../assets/img/DashIcon/newWindowIcon.png";
import androidIcon from "../../../assets/img/DashIcon/newAndroidIcon.png";
import linuxIcon from "../../../assets/img/DashIcon/newLinuxIcon.png";
import macIcon from "../../../assets/img/DashIcon/newMacIcon.png";
import chromeIcon from "../../../assets/img/DashIcon/newChromeIcon.png";
import iosIcon from "../../../assets/img/DashIcon/ios.png";
import download_small from "../../../assets/img/download_small.png";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function DownloadAgent() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { user } = useSelector((state) => state.authSlice);

  const linux_urlubuntu = `${process.env.REACT_APP_LINUX_URL}/download/ubuntu/${user?.data?.user?.userId}`;
  const linux_urlcentos = `${process.env.REACT_APP_LINUX_URL}/download/centos/${user?.data?.user?.userId}`;
  const linux_urlrhel = `${process.env.REACT_APP_LINUX_URL}/download/rhel/${user?.data?.user?.userId}`;
  const window_url = `${process.env.REACT_APP_WINDOW_URL}/download/msi/${user?.data?.user?.userId}`;
  const mac_url = `${process.env.REACT_APP_MAC_URL}/download/${user?.data?.user?.userId}`;
  const chrome_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`;
  const android_url = `${process.env.REACT_APP_ANDROID_URL}/download/${user?.data?.user?.userId}`;

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      let filename = "agent_download";

      if (url.includes("windows")) {
        filename = "CyberauditorSetup.exe";
      } else if (url.includes("ubuntu")) {
        filename = "cyberauditor-linux-agent.deb";
      } else if (url.includes("rhel") || url.includes("centos")) {
        filename = "cyberauditor-linux-agent.rpm";
      } else if (url.includes("mac")) {
        filename = "cyberauditor-mac-agent.pkg";
      } else if (url.includes("chrome")) {
        filename = "cyberauditor-chrome-agent.zip";
      } else if (url.includes("android")) {
        filename = "cyberauditor-android-agent.apk";
      }

      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      toast.success('Download: '+filename, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Flip,
        });
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <img
              src={download_agents}
              alt="Window-logo"
              width={30}
              height={30}
            />
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Download Agents</p>
          </span>
        </div>

        <>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={windowIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      WINDOWS
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(window_url)}
                    >
                      Window 7 & above{" "}
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={linuxIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      LINUX
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(linux_urlubuntu)}
                    >
                      Ubuntu <img src={download_small} width={20} height={15} />{" "}
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(linux_urlrhel)}
                    >
                      RHEL
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(linux_urlcentos)}
                    >
                      CentOS
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={macIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      MAC
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(mac_url)}
                    >
                      MacOS
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={chromeIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      CHROME
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(chrome_url)}
                    >
                      ChromeOS
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={androidIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      ANDROID
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => handleDownload(android_url)}
                    >
                      Android
                      <img src={download_small} width={20} height={15} />{" "}
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card">
                <div className="p-3 card-one pe-auto border d-flex flex-column align-items-start justify-content-center gap-2 shadow p-3">
                  <span className="d-flex align-items-center justify-content-center">
                    <img src={iosIcon} width={60} height={60} />
                    <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals text-secondary">
                      IOS
                    </p>
                  </span>
                  <p className="m-0 p-0 fs-18 fw-bolder lh-3 ff-secondary py-2"></p>
                  <span className="d-flex align-items-center justify-content-start">
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <button type="button" className="btn btn-info">
                        iOS
                        <img src={download_small} style={{color:"#ffff"}} width={20} height={15} />{" "}
                      </button>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </>
      </div>
    </React.Fragment>
  );
}
