import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import Footer from "../layouts/Footer";
// import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { PolarArea } from "react-chartjs-2";
import DashCard from "../components/common/DashBoard/DashCard";
import Select from "react-select";
import { useSkinMode } from "../components/SkinModeContext";

import windowIcon from "../assets/img/DashIcon/newWindowIcon.png";
import androidIcon from "../assets/img/DashIcon/newAndroidIcon.png";
import linuxIcon from "../assets/img/DashIcon/newLinuxIcon.png";
import macIcon from "../assets/img/DashIcon/newMacIcon.png";
import chromeIcon from "../assets/img/DashIcon/newChromeIcon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../components/common/Loader/PageLoader";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import axios from "axios";
import { jsPDF } from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
export default function DashBoard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state?.selectedDepartment, "selectedDepartment");

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  // const optionOne = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     type: "area",
  //     toolbar: { show: false },
  //     stacked: true,
  //   },
  //   colors: ["#4f6fd9", "#506fd9"],
  //   grid: {
  //     borderColor: "rgba(72,94,144, 0.08)",
  //     padding: { top: -20 },
  //     yaxis: {
  //       lines: { show: false },
  //     },
  //   },
  //   stroke: {
  //     curve: "straight",
  //     width: [2, 0],
  //   },
  //   xaxis: {
  //     type: "numeric",
  //     tickAmount: 13,
  //     axisBorder: { show: false },
  //     labels: {
  //       style: {
  //         colors: "#6e7985",
  //         fontSize: "11px",
  //       },
  //     },
  //   },
  //   yaxis: {
  //     min: 0,
  //     max: 100,
  //     show: false,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       opacityFrom: 0.5,
  //       opacityTo: 0,
  //     },
  //   },
  //   dataLabels: { enabled: false },
  //   legend: { show: false },
  //   tooltip: { enabled: false },
  // };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: true },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },

    legend: { show: false },
  };

  const data = {
    d1: {
      sections: {
        s1: { subSections: ["sb1", "sb2", "sb3"] },
        s2: { subSections: ["sb12", "sb22", "sb32"] },
        s3: { subSections: ["sb13", "sb23", "sb33"] },
      },
    },
    d2: { sections: { s1: {}, s2: {}, s3: {} } },
    d3: { sections: { s1: {}, s2: {}, s3: {} } },
  };

  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [windowCount, setWindowCount] = useState(0);
  const [linuxCount, setLinuxCount] = useState(0);
  const [androidCount, setAndroidCount] = useState(0);
  const [macCount, setMacCount] = useState(0);
  const [chromeOsCount, setChromeOsCount] = useState(0);

  const [windowCountRisk, setwindowCountRisk] = useState(0);
  const [windowCountUnRisk, setwindowCountUnRisk] = useState(0);

  const [linuxCountRisk, setLinuxCountRisk] = useState(0);
  const [linuxCountUnRisk, setLinuxCountUnRisk] = useState(0);

  const [androidCountRisk, setAndroidCountRisk] = useState(0);
  const [androidCountUnRisk, setAndroidUnCountRisk] = useState(0);

  const [chromeCountRisk, setChromeCountRisk] = useState(0);
  const [chromeCountUnRisk, setChromeCountUnRisk] = useState(0);

  const [macCountRisk, setMacCountRisk] = useState(0);
  const [macCountUnRisk, setMacCountUnRisk] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [department, setDepartment] = useState(null);
  const [section, setSection] = useState(null);
  const [subSection, setSubSection] = useState(null);

  const [departments, setDepartments] = useState([]);
  console.log(departments, "departments");
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    state?.selectedDepartment || null
  );
  const [selectedSection, setSelectedSection] = useState(
    state?.selectedSection || null
  );
  const [selectedSubsection, setSelectedSubsection] = useState(
    state?.selectedSubsection || null
  );
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);
  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);
  const [TotalDashboard, setTotalDashboard] = useState(0);
  // const [lineGraph, setLineGraph] = useState();
  const [barGraph, setBarGraph] = useState();
  // const data = {
  //   d1: {
  //     sections: { s1: { subSections: ["sb1", "sb2", "sb3"] }, s2: {}, s3: {} },
  //   },
  //   d2: { sections: { s1: {}, s2: {}, s3: {} } },
  //   d3: { sections: { s1: {}, s2: {}, s3: {} } },
  // };

  // const [currentData, setCurrentData] = useState(
  //   Object.keys(data).map((department) => ({ name: department, value: 1 }))
  // );
  // const [currentSections, setCurrentSections] = useState(null);
  // const [currentSubSections, setCurrentSubSections] = useState(null);

  // const handleDepartmentClick = (department) => {
  //   setCurrentSections(
  //     Object.keys(data[department].sections).map((section) => ({
  //       name: section,
  //       value: 1,
  //     }))
  //   );
  //   setCurrentData(null);
  // };

  // const handleSectionClick = (section) => {
  //   const subSections =
  //     data[currentSections[0].name].sections[section].subSections;
  //   setCurrentSubSections(subSections.map((sub) => ({ name: sub, value: 1 })));
  //   setCurrentSections(null);
  // };
  if (skinMode === "dark") {
    document.body.classList.add("dark-skin"); // Add a class to the body
  } else {
    document.body.classList.remove("dark-skin"); // Remove it when it's not dark mode
  }
  const [currentData, setCurrentData] = useState(
    Object.keys(data).map((department) => ({ name: department, value: 1 }))
  );
  const [currentSections, setCurrentSections] = useState(null);
  const [currentSubSections, setCurrentSubSections] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState(null);

  const handleDepartmentClick = (department) => {
    setCurrentSections(
      Object.keys(data[department].sections).map((section) => ({
        name: section,
        value: 1,
      }))
    );
    setCurrentData(null);
    setCurrentDepartment(department);
  };

  const handleSectionClick = (section) => {
    const subSections = data[currentDepartment].sections[section].subSections;
    setCurrentSubSections(subSections.map((sub) => ({ name: sub, value: 1 })));
    setCurrentSections(null);
  };

  const handleBack = () => {
    if (currentSubSections) {
      setCurrentSections(
        Object.keys(data[currentDepartment].sections).map((section) => ({
          name: section,
          value: 1,
        }))
      );
      setCurrentSubSections(null);
    } else {
      setCurrentData(
        Object.keys(data).map((department) => ({ name: department, value: 1 }))
      );
      setCurrentSections(null);
      setCurrentDepartment(null);
    }
  };

  console.log(selectedDepartment, "selectedDepartment");

  const [summoryCount, setSummoryCount] = useState({});
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  var windows_unsafe_per =
    (windowCountRisk / (windowCountRisk + windowCountUnRisk)) * 100;
  var linux_unsafe_per =
    (linuxCountRisk / (linuxCountRisk + linuxCountUnRisk)) * 100;
  var android_unsafe_per =
    (androidCountRisk / (androidCountRisk + androidCountUnRisk)) * 100;
  var chrome_unsafe_per =
    (chromeCountRisk / (chromeCountRisk + chromeCountUnRisk)) * 100;
  var macc_unsafe_per = (macCountRisk / (macCountRisk + macCountUnRisk)) * 100;

  const linux_count = Math.round(linux_unsafe_per);
  console.log(macc_unsafe_per, "linux_unsafe_per");
  useEffect(() => {
    if (!isAuthenticated) {
      // User not authenticated, redirect to login page
      navigate("/login");
    } else {
      // Check token expiration time

      const tokenExpirationTimer = setTimeout(() => {
        // Token has expired, log the user out
        dispatch(logout()); // Dispatch logout action to clear user data and token
        navigate("/signin");
      }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // useEffect(() => {
  //   let isMounted = true; // Variable to track mount status

  //   const fetchData = () => {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user?.data?.accessToken}`,
  //           },
  //         }
  //       )

  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data,'rrrrrrrrrrrrrrrrrrrrrrrrr')
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount)
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients)
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount)
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients)
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients)

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount)
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients)
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount)
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients)
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients)

  //           setTotalDashboard(res?.data?.totalDevicesCount)

  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount)
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           const statusCode = error.response.status;
  //           switch (statusCode) {
  //             case 404:
  //               console.log("Resource not found (404)");
  //               break;
  //             case 500:
  //               console.log("Internal server error (500)");
  //               break;
  //             case 504:
  //               console.log("Gateway timeout (504)");
  //               break;
  //             default:
  //               console.log(`Error: ${statusCode}`);
  //           }
  //         } else if (error.request) {
  //           console.log("No response received");
  //         } else {
  //           console.log("Error", error.message);
  //         }
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           // Schedule the next fetchData call after 50 seconds
  //           setTimeout(fetchData, 50000);
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetchData call

  //   // Cleanup function to set isMounted to false when component unmounts
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [user]);

  // this code need to set id dpt ,section, sub section
  // useEffect(() => {
  //   let isMounted = true; // Track mount status

  //   const fetchData = () => {
  //     let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

  //     // Add filters to the URL if they are selected
  //     if (department) {
  //       url += `?department=${'id'}`;
  //     }
  //     if (section) {
  //       url += `${department ? '&' : '?'}section=${'id'}`;
  //     }
  //     if (subSection) {
  //       url += `${department || section ? '&' : '?'}subSection=${'id'}`;
  //     }

  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${user?.data?.accessToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data);
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount);
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients);
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount);
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients);
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

  //           setTotalDashboard(res?.data?.totalDevicesCount);

  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           setTimeout(fetchData, 50000); // Schedule the next fetch after 50s
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetch

  //   return () => {
  //     isMounted = false; // Cleanup when unmounting
  //   };
  // }, [user, department, section, subSection]);

  // useEffect(() => {
  //   let isMounted = true; // Track mount status

  //   const fetchData = () => {
  //     // Base URL
  //     let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

  //     // Add filters to the URL if they are selected
  //     if (department) {
  //       url += `?department=${department.value}`; // Use the department ID value
  //     }
  //     if (section) {
  //       url += `${department ? '&' : '?'}section=${section.value}`; // Use the section ID value
  //     }
  //     if (subSection) {
  //       url += `${department || section ? '&' : '?'}subSection=${subSection.value}`; // Use the sub-section ID value
  //     }

  //     axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${user?.data?.accessToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (isMounted) {
  //           console.log(res?.data);

  //           // Set the risk and safe count data
  //           setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount);
  //           setMacCountRisk(res?.data?.mac?.UnsafemacClients);
  //           setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
  //           setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
  //           setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

  //           setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount);
  //           setMacCountUnRisk(res?.data?.mac?.SafemacClients);
  //           setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
  //           setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
  //           setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

  //           // Set total devices and individual OS device counts
  //           setTotalDashboard(res?.data?.totalDevicesCount);
  //           setWindowCount(res?.data?.windows?.getTotalPCCount);
  //           setLinuxCount(res?.data?.linux?.getTotalPCCount);
  //           setAndroidCount(res?.data?.android?.androidDevicesCount);
  //           setMacCount(res?.data?.mac?.macPcCount);
  //           setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount);
  //         }
  //       })
  //       .catch((error) => {
  //         handleError(error);
  //       })
  //       .finally(() => {
  //         if (isMounted) {
  //           setLoading(false);
  //           setTimeout(fetchData, 50000); // Schedule the next fetch after 50 seconds
  //         }
  //       });
  //   };

  //   fetchData(); // Initial fetch

  //   return () => {
  //     isMounted = false; // Cleanup when unmounting
  //   };
  // }, [user, department, section, subSection]); // Include department, section, and sub-section as dependencies

  // Department API Call
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
    fetchData();
  }, [user?.data?.user?.organizationId]);

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SUMMARY_URL}/graph-data/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        // Handle the response data here
        // setLineGraph(response.data.response.sevenDayData);
        setDeptSafeUnsafe(
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts
        );
        setSectionSafeUnsafe(
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts
        );
        console.log(
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts,
          "HHHHHHH"
        );
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  // Fetch Summary Data
  useEffect(() => {
    let isMounted = true; // Track mount status

    const fetchData = () => {
      let url = `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`;

      // Check if department, section, or sub-section is selected and append to URL
      if (selectedDepartment) {
        url += `?department=${selectedDepartment.value}`;
      }
      if (selectedSection) {
        url += `${selectedDepartment ? "&" : "?"}section=${
          selectedSection.value
        }`;
      }
      if (selectedSubsection) {
        url += `${
          selectedDepartment || selectedSection ? "&" : "?"
        }subSection=${selectedSubsection.value}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        })
        .then((res) => {
          if (isMounted) {
            // Update your state with the fetched data
            console.log(res?.data);
            setChromeCountRisk(
              res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount
            );
            setMacCountRisk(res?.data?.mac?.UnsafemacClients);
            setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
            setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
            setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

            setChromeCountUnRisk(
              res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount
            );
            setMacCountUnRisk(res?.data?.mac?.SafemacClients);
            setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
            setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
            setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

            setTotalDashboard(res?.data?.totalDevicesCount);

            setWindowCount(res?.data?.windows?.getTotalPCCount);
            setLinuxCount(res?.data?.linux?.getTotalPCCount);
            setAndroidCount(res?.data?.android?.androidDevicesCount);
            setMacCount(res?.data?.mac?.macPcCount);
            setChromeOsCount(
              res?.data?.chromeOsSummaryInfo?.chromeDevicesCount
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching summary data:", error);
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            setTimeout(fetchData, 50000); // Schedule next fetch after 50 seconds
          }
        });
    };

    fetchData(); // Initial fetch

    return () => {
      isMounted = false; // Cleanup when unmounting
    };
  }, [user, selectedDepartment, selectedSection, selectedSubsection]); // Add selectedDepartment, selectedSection, and selectedSubsection to dependencies

  // Function to handle API errors
  const handleError = (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 404:
          console.log("Resource not found (404)");
          break;
        case 500:
          console.log("Internal server error (500)");
          break;
        case 504:
          console.log("Gateway timeout (504)");
          break;
        default:
          console.log(`Error: ${statusCode}`);
      }
    } else if (error.request) {
      console.log("No response received");
    } else {
      console.log("Error", error.message);
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };
  // const handleSelectedRowsChange = ({ selectedRows }) => {
  //   setSelectedRows(selectedRows);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const deviceIds = selectedRows?.map((row) => "row.pcId");

    // Construct the data object based on which fields are selected
    const data = { deviceIds };

    if (selectedDepartment) {
      data.departmentId = selectedDepartment.value;
    }
    if (selectedSection) {
      data.sectionId = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSectionId = selectedSubsection.value;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      // toast.success(response.data.message, {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: false,
      //   theme: "light",
      // });

      // Close Offcanvas and update DataTable data
      // setShowOffcanvas(false);
      // fetchData();
      // setSelectedRows([]);
      // setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error(error);

      console.error("Error submitting form:", error);
      // Handle error response
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const orgName = user?.data?.user?.organization || "Organization Name";
  const userType = user?.data?.user?.roleName || "Admin Name";
  const exportDateTime = formatTimestamp(new Date());

  const printToPdf = () => {
    const doc = new jsPDF();

    // Add organization and user info
    doc.setFontSize(12);
    doc.text("System Information Summary", 10, 10);
    doc.text(`Organization: ${orgName}`, 10, 20);
    doc.text(`Role: ${userType}`, 10, 30);
    doc.text(`Export Date: ${exportDateTime}`, 10, 40);

    // Filter Information
    doc.text("Filter Information:", 10, 60);
    doc.text(`Department: ${selectedDepartment?.label || "N/A"}`, 20, 70);
    doc.text(`Section: ${selectedSection?.label || "N/A"}`, 20, 80);
    doc.text(`Subsection: ${selectedSubsection?.label || "N/A"}`, 20, 90);

    // Total Devices Count
    // doc.text('Total Devices Count:', 10, 110);
    doc.text(`Total Devices Count: ${TotalDashboard}`, 10, 110);

    // Unsafe Devices Count
    doc.text("Unsafe Devices Count:", 10, 130);
    doc.text(`Chrome OS: ${chromeCountRisk}`, 20, 140);
    doc.text(`Mac OS: ${macCountRisk}`, 20, 150);
    doc.text(`Android: ${androidCountRisk}`, 20, 160);
    doc.text(`Linux: ${linuxCountRisk}`, 20, 170);
    doc.text(`Windows: ${windowCountRisk}`, 20, 180);

    // Safe Devices Count
    doc.text("Safe Devices Count:", 10, 200);
    doc.text(`Chrome OS: ${chromeCountUnRisk}`, 20, 210);
    doc.text(`Mac OS: ${macCountUnRisk}`, 20, 220);
    doc.text(`Android: ${androidCountRisk}`, 20, 230);
    doc.text(`Linux: ${linuxCountUnRisk}`, 20, 240);
    doc.text(`Windows: ${windowCountUnRisk}`, 20, 250);

    // Total Device Count Per OS
    doc.text("Total Device Count Per OS:", 10, 270);
    doc.text(`Chrome OS: ${chromeOsCount}`, 20, 280);
    doc.text(`Mac OS: ${macCount}`, 20, 290);
    doc.text(`Android: ${androidCount}`, 20, 300);
    doc.text(`Linux: ${linuxCount}`, 20, 310);
    doc.text(`Windows: ${windowCount}`, 20, 320);

    // Save the PDF
    doc.save("system_summary_filtered.pdf");
  };
  const printToCsv = () => {
    // CSV content
    let csvContent = "System Information Summary\n";
    csvContent += `Organization:,${orgName}\n`;
    csvContent += `Role:,${userType}\n`;
    csvContent += `Export Date:,${exportDateTime}\n\n`;

    // Filter Information
    csvContent += "Filter Information\n";
    csvContent += `Department:,${selectedDepartment?.label || "N/A"}\n`;
    csvContent += `Section:,${selectedSection?.label || "N/A"}\n`;
    csvContent += `Subsection:,${selectedSubsection?.label || "N/A"}\n\n`;

    // Total Devices Count
    csvContent += `Total Devices Count:,${TotalDashboard}\n\n`;

    // Unsafe Devices Count
    csvContent += "Unsafe Devices Count\n";
    csvContent += `Chrome OS:,${chromeCountRisk}\n`;
    csvContent += `Mac OS:,${macCountRisk}\n`;
    csvContent += `Android:,${androidCountRisk}\n`;
    csvContent += `Linux:,${linuxCountRisk}\n`;
    csvContent += `Windows:,${windowCountRisk}\n\n`;

    // Safe Devices Count
    csvContent += "Safe Devices Count\n";
    csvContent += `Chrome OS:,${chromeCountUnRisk}\n`;
    csvContent += `Mac OS:,${macCountUnRisk}\n`;
    csvContent += `Android:,${androidCountUnRisk}\n`; // Corrected the duplicate
    csvContent += `Linux:,${linuxCountUnRisk}\n`;
    csvContent += `Windows:,${windowCountUnRisk}\n\n`;

    // Total Device Count Per OS
    csvContent += "Total Device Count Per OS\n";
    csvContent += `Chrome OS:,${chromeOsCount}\n`;
    csvContent += `Mac OS:,${macCount}\n`;
    csvContent += `Android:,${androidCount}\n`;
    csvContent += `Linux:,${linuxCount}\n`;
    csvContent += `Windows:,${windowCount}\n`;

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "system_summary_filtered.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log(deptSafeUnsafe, "deptSafeUnsafe");

  // Mapping safe and unsafe counts
  const safeData = deptSafeUnsafe?.map((item, index) => [
    index,
    item.safeCount,
  ]);
  const unsafeData = deptSafeUnsafe?.map((item, index) => [
    index,
    item.unsafeCount,
  ]);

  // Mapping department names dynamically
  const departmentNames = deptSafeUnsafe?.map((item) => item.department); // Assuming each item has a `departmentName`

  const seriesEight = [
    {
      name: "Safe", // Label for Safe counts
      type: "column",
      data: safeData, // Safe counts
    },
    {
      name: "Unsafe", // Label for Safe counts
      type: "column",
      data: unsafeData, // Unsafe counts
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "bar", // Creating bar chart
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"], // Colors for Safe and Unsafe counts
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded", // Rounded ends for the bars
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 5, // Adjust based on your data
      tickAmount: 5,
    },
    xaxis: {
      categories: departmentNames, // Dynamically generated department names
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: true }, // Tooltips enabled
    legend: { show: true }, // Show legend
  };

  const lineGraph = [
    {
      date: "2024-09-07",
      getAntivirusNotInstalledCount: 3,
      AntivirusNotUpdatedCount: 2,
      getAntivirusMalfunctionCount: 2,
      getEDRNotInstalledCount: 2,
      firewallOffPCCount: 2,
      getNetworkViolationPCCount: 2,
      getPCsCountWithUnwantedPorts: 2,
      getPCsCountWithUnwhitelistedUSB: 2,
      tpmNotEnableedCount: 2,
      isSecureBootNotEnableedCount: 2,
      isOsNotHardenedCount: 2,
      adminPasswordAgeGreaterThanThresholdCount: 2,
      multipleAdminCount: 2,
      getNotPatchedOSCount: 2,
    },
    {
      date: "2024-09-08",
      getAntivirusNotInstalledCount: 1,
      AntivirusNotUpdatedCount: 1,
      getAntivirusMalfunctionCount: 1,
      getEDRNotInstalledCount: 1,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 0,
      getPCsCountWithUnwantedPorts: 0,
      getPCsCountWithUnwhitelistedUSB: 0,
      tpmNotEnableedCount: 0,
      isSecureBootNotEnableedCount: 0,
      isOsNotHardenedCount: 0,
      adminPasswordAgeGreaterThanThresholdCount: 0,
      multipleAdminCount: 0,
      getNotPatchedOSCount: 0,
    },
    {
      date: "2024-09-09",
      getAntivirusNotInstalledCount: 8,
      AntivirusNotUpdatedCount: 4,
      getAntivirusMalfunctionCount: 4,
      getEDRNotInstalledCount: 1,
      firewallOffPCCount: 1,
      getNetworkViolationPCCount: 0,
      getPCsCountWithUnwantedPorts: 0,
      getPCsCountWithUnwhitelistedUSB: 0,
      tpmNotEnableedCount: 0,
      isSecureBootNotEnableedCount: 0,
      isOsNotHardenedCount: 0,
      adminPasswordAgeGreaterThanThresholdCount: 0,
      multipleAdminCount: 0,
      getNotPatchedOSCount: 0,
    },
  ];

  // Map data for each series
  const dp1 = lineGraph.map((item) => item.getAntivirusNotInstalledCount);
  const dp2 = lineGraph.map((item) => item.getEDRNotInstalledCount);
  const dp3 = lineGraph.map((item) => item.getNotPatchedOSCount);

  // Map dates for the x-axis
  const dates = lineGraph.map((item) => item.date);

  const seriesOne = [
    {
      name: "Antivirus Not Installed",
      data: dp1, // getAntivirusNotInstalledCount over time
    },
    {
      name: "EDR Not Installed",
      data: dp2, // getEDRNotInstalledCount over time
    },
    {
      name: "OS Not Patched",
      data: dp3, // getNotPatchedOSCount over time
    },
  ];

  const optionOne = {
    chart: {
      type: "area",
      height: 430,
    },
    title: {
      text: "Security Overview",
      align: "center",
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    xaxis: {
      categories: dates, // Use the dates from your lineGraph data
      title: {
        text: "Date",
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      title: {
        text: "Count",
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        format: "dd MMM yyyy", // Format the date as per your needs
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
    },
  };

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <NavLink exact to="/" activeClassName="active">
                Dashboard
              </NavLink>
            </li>
            {/* <li className="breadcrumb-item">
            <NavLink exact to="/window-dash" activeClassName="active">
            Windows 
            </NavLink>
          </li>

          <li className="breadcrumb-item">
            <NavLink exact to="/linux-dash" activeClassName="active">
            Linux 
            </NavLink>
          </li>

          <li className="breadcrumb-item">
            <NavLink exact to="/macOs-dash" activeClassName="active">
            Mac 
            </NavLink>
          </li>

          <li className="breadcrumb-item">
            <NavLink exact to="/chrome-dash" activeClassName="active">
            Chrome 
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/android-dash" activeClassName="active">
            Android 
            </NavLink>
          </li> */}
          </ol>

          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0 fs-20 fw-bolder p-0 m-0 ">
                Welcome to Dashboard
              </h4>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-4 card card-one shadow p-2 flex-row border">
            <div className="row w-60">
              <div className="col-12 col-md-3">
                <div className="mb-3">
                  <label htmlFor="departments" className="form-label text-dark">
                    Departments
                  </label>
                  <Select
                    options={departments.map((dept) => ({
                      label: dept.departmentName,
                      value: dept._id,
                    }))}
                    onChange={handleDepartmentChange}
                    value={selectedDepartment} // bind selected value
                  />
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="mb-3">
                  <label htmlFor="sections" className="form-label text-dark">
                    Sections
                  </label>
                  <Select
                    options={sections.map((section) => ({
                      label: section.sectionName,
                      value: section._id,
                    }))}
                    onChange={handleSectionChange}
                    isDisabled={!selectedDepartment}
                    value={selectedSection} // bind selected value
                  />
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="mb-3">
                  <label htmlFor="subsections" className="form-label text-dark">
                    Subsections
                  </label>
                  <Select
                    options={subsections.map((subSection) => ({
                      label: subSection.subSectionName,
                      value: subSection._id,
                    }))}
                    onChange={(selectedOption) =>
                      setSelectedSubsection(selectedOption)
                    }
                    isDisabled={!selectedSection}
                    value={selectedSubsection} // bind selected value
                  />
                </div>
              </div>

              <div className="col-12 col-md-auto d-flex align-items-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginBottom: "17px" }}
                  onClick={() => {
                    setSelectedDepartment(null);
                    setSelectedSection(null);
                    setSelectedSubsection(null);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>

            <div
              className="d-flex gap-2 mt-3 mt-md-0 align-items-center"
              style={{ width: "171px" }}
            >
              <div className="d-flex align-items-center justify-content-between gap-2">
                <button
                  type="button"
                  className="btn btn-primary text-white"
                  onClick={printToPdf}
                >
                  <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
                </button>

                <button
                  type="button"
                  className="btn btn-success text-white"
                  onClick={printToCsv}
                >
                  <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
                </button>
              </div>
            </div>
          </div>
          <Row className="g-3">
            <Col xl="4">
              <DashCard
                dashIcon={windowIcon}
                dashNumber={windowCount}
                handleRoute={() => navigate("/window-dash")}
                dashTitle={"Windows Endpoints"}
                riskNumber={windowCountRisk}
                riskPerc={windows_unsafe_per ? windows_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={linuxIcon}
                dashNumber={linuxCount}
                handleRoute={() => navigate("/linux-dash")}
                dashTitle={"Linux Endpoints"}
                riskNumber={linuxCountRisk}
                riskPerc={linux_count ? linux_count : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={macIcon}
                dashNumber={macCount}
                dashTitle={"Mac Endpoints"}
                handleRoute={() => navigate("/macOs-dash")}
                riskNumber={macCountRisk}
                riskPerc={macc_unsafe_per ? macc_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={chromeIcon}
                dashNumber={chromeOsCount}
                dashTitle={"ChromeOS Endpoints"}
                handleRoute={() => navigate("/chrome-dash")}
                riskNumber={chromeCountRisk}
                riskPerc={chrome_unsafe_per ? chrome_unsafe_per : "0"}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={androidIcon}
                dashNumber={androidCount}
                handleRoute={() => navigate("/android-dash")}
                dashTitle={"Android Endpoints"}
                riskNumber={androidCountRisk}
                riskPerc={android_unsafe_per ? android_unsafe_per : "0"}
              />
            </Col>
            {/* <Col xl="4">
              <DashCard
                dashIcon={iosIcon}
                dashNumber={summoryCount?.androidCount}
                handleRoute={() => navigate("/")}
                dashTitle={"iOS Endpoints"}
                riskNumber={236}
                riskPerc={77}
              />
            </Col> */}

            {/* <Col xl="12">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Cyber Violation Analysis</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="overflow-hidden px-0 pb-3">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="area"
                    height={430}
                    className="apex-chart-two"
                  />
                </Card.Body>
              </Card>
            </Col> */}
            {/*  <Col md="4" xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Violations Categories</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <div className="ht-250">
                    <PolarArea data={dataPolar} options={optionPolar} />
                  </div>
                </Card.Body>
              </Card>
             </Col> */}
            {/* <Col xl="8">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Department Cyber Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="g-4">
                    <Col md="12">
                      <ReactApexChart
                        series={seriesEight}
                        options={optionEight}
                        height={210}
                        className="apex-chart-three"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
            {/* <Col xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Total Devices</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="position-relative d-flex justify-content-center">
                  <ReactApexChart
                    series={[
                      windowCount,
                      linuxCount,
                      macCount,
                      androidCount,
                      chromeOsCount,
                    ]}
                    options={optionDonut}
                    height="auto"
                    type="donut"
                    className="apex-donut-two"
                  />

                  <div className="finance-donut-value">
                    <h1>{TotalDashboard}</h1>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            {/* <Col xl="4">
              <div>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={currentData || currentSections || currentSubSections}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="value"
                      fill="#8884d8"
                      onClick={(data) => {
                        if (currentData) handleDepartmentClick(data.name);
                        else if (currentSections) handleSectionClick(data.name);
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
                <button onClick={handleBack}>Back</button>
              </div>
            </Col> */}
          </Row>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
