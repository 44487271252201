import React from "react";
import Forbidden from "../pages/Forbidden";
import ForgotPassword from "../pages/ForgotPassword";
import InternalServerError from "../pages/InternalServerError";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import CaSignin from "../pages/CaSignin";
import Signup from "../pages/Signup";
import VerifyAcc from "../pages/VerifyAcc";
import Test from "../pages/Test";

const publicRoutes = [
  { path: "/", element: <Signin /> },
  { path: "/signup", element: <Signup /> },
  { path: "/ca", element: <CaSignin /> },
  { path: "/verify", element: <VerifyAcc /> },
  // { path: "/test", element: <Test /> },
  { path: "/forgot", element: <ForgotPassword /> },
  // { path: "pages/lock", element: <LockScreen /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> },
  { path: "pages/error-503", element: <ServiceUnavailable /> },
  { path: "pages/error-505", element: <Forbidden /> },
];

export default publicRoutes;
