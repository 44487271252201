import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation,NavLink } from "react-router-dom";
import CustomTableLinux from "../../components/common/Table/CustomTableLinux";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import Excellogo from "../../assets/img/Excellogo.png";
import DataTable from "react-data-table-component";
import { Dropdown, Menu } from "antd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSkinMode } from "../../components/SkinModeContext";

function LinuxServerityDataUnrecognised() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [endpoints, setEndpoints] = React.useState({});
  const { user } = useSelector((state) => state.authSlice);

  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_LINUX_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        setTotalDocuments(response?.data?.pagination?.totalDocuments);
        if (isMounted) {
          console.log(response, "IN THE API RESPONSE");

          if (response?.data?.data.length > 0) {
            console.log(response?.data?.data, "LOGRESPONSE");
            const calculateData = response?.data?.data?.map((v) => {
              return {
                softwarename: v?.softwarename || "NA",
                softwareversion: v?.softwareversion || "NA",
                totalPCs: v?.totalPCs,
              };
            });
            setColumnData(calculateData);
          } else {
            setColumnData([]);
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Unauthorized error occurred");
            } else if (error.response.status === 504) {
              console.log("Gateway Timeout error occurred");
            }
          } else {
            console.error("An error occurred:", error.message);
          }
          setError(error);
          console.log(error, "IN THE ERROR");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, state, pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = async (e, record) => {
    console.log("Dropdown clicked for record:", record); // Log the record data
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LINUX_URL}/backendinfo/blacklisted-program-endpoints/${user?.data?.user?.userId}/`,
        {
          params: {
            softwarename: record.softwarename,
            softwareversion: record.softwareversion,
          },
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API Response:", response.data);

      // Update the endpoints in the state
      setEndpoints((prevEndpoints) => ({
        ...prevEndpoints,
        [record.softwarename + record.softwareversion]: response.data.endpoints, // Save endpoints by unique key
      }));
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const menu = (record) => {
    // Fetch the endpoints from state
    const recordEndpoints =
      endpoints[record.softwarename + record.softwareversion] || [];

    return (
      <Menu>
        {recordEndpoints.length > 0 ? (
          recordEndpoints.map((endpoint, index) => (
            <Menu.Item key={index}>
              <div>
                <strong>Hostname:</strong> {endpoint.hostname} <br />
                <strong>Serial No:</strong> {endpoint.serialNumber}
              </div>
            </Menu.Item>
          ))
        ) : (
          <Menu.Item key="empty">No endpoints available</Menu.Item>
        )}
      </Menu>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (pageNumber - 1) * pageSize + index + 1,
        width: "90px",
      },
      {
        name: "Software Name",
        selector: (row) => row.softwarename,
        sortable: true,
      },
      {
        name: "Software Version",
        selector: (row) => (
          <Tippy content={row.softwareversion}>
            <div>{row.softwareversion}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Total Endpoints Affected",
        selector: (row) => row.totalPCs,
        cell: (row) => (
          <Dropdown
            overlay={menu(row)}
            trigger={["click"]}
            onClick={(e) => handleMenuClick(e, row)}
          >
            <Button className="bg-transparent border-0 p-0 m-0">
              {row.totalPCs} <i className="fa-solid fa-angle-down"></i>
            </Button>
          </Dropdown>
        ),
        sortable: true,
      },
      // {
      //   name: "Action",
      //   selector: (row) => row.totalPCs,
      //   cell: (row) => (
      //     <>
      //       <button
      //         type="button"
      //         className="btn btn-primary btn-sm text-white"
      //         // onClick={() => exportToPDF(columnData)}
      //       >
      //         <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
      //       </button>
      //       &nbsp; &nbsp;
      //       <button
      //         type="button"
      //         className="btn btn-success btn-sm text-white"
      //         onClick={exportToCSV}
      //       >
      //         <img src={Excellogo} height={20} width={15} />
      //         &nbsp; CSV
      //       </button>
      //     </>
      //   ),
      //   sortable: true,
      // },
    ],
    [pageNumber, pageSize, endpoints]
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Add headers
      const csvData = [
        [
          "Software Name",
          "Software Version",
          "Total PCs",
          // "Hostnames",
          // "Serial Numbers",
        ],
        ...columnData.map((item) => {
          // Check if endpoints exist and format hostnames and serial numbers
          const hostnames =
            item.endpoints?.length > 0
              ? item.endpoints.map((endpoint) => endpoint.hostname).join(", ")
              : "N/A";

          const serialNumbers =
            item.endpoints?.length > 0
              ? item.endpoints.map((endpoint) => endpoint.s_no).join(", ")
              : "N/A";

          return [
            item.softwarename || "N/A",
            item.softwareversion || "N/A",
            item.totalPCs || "N/A",
            // hostnames, // Hostnames as a single string
            // serialNumbers, // Serial numbers as a single string
          ];
        }),
      ];

      // Convert array to CSV string
      const csvString = csvData.map((row) => row.join(",")).join("\n");

      // Create a Blob from the CSV string
      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      // Save the CSV file
      saveAs(blob, `${state?.titleState}.csv`);
    }
  };

  const exportToPDF = (data) => {
    const doc = new jsPDF();
    doc.text("Unrecognized Software", 14, 16);

    const tableColumn = [
      "Software Name",
      "Software Version",
      "Total PCs",
      // "Hostnames",
      // "Serial Numbers",
    ];
    const tableRows = data.map((item) => [
      item.softwarename || "N/A",
      item.softwareversion || "N/A",
      item.totalPCs || "N/A",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("Unrecognized_Software.pdf");
  };
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}

      <div
        className="main main-app p-3 p-lg-4"
        style={mainStyle}
      >
        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Dashboard
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/linux-dash" activeClassName="active">
              Linux Dashboard
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/linux-serverity" activeClassName="active">
              {state?.titleState}
            </NavLink>
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-20 fw-bolder p-0 m-0 ">Linux DashBoard</p>
          </span>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div class="col-xl">
            <p class="fs-18 text-dark fw-bolder p-0 m-0">
              {state?.titleState.includes("Comm") === true
                ? "Unknown Network Communication"
                : state?.titleState || "Alert Title"}
            </p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0}{" "}
              {state?.titleState.includes("Violations") === true
                ? "Violations"
                : "Endpoints"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF(columnData)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; CSV
            </button>
          </div>
        </div>

        <div xl={12} className=" card allCardEffect shadow mt-2">
          {!loading ? (
            <DataTable
              columns={columns}
              data={columnData || []}
              customStyles={customStyles}
              pagination
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onChangePage={(page) => {
                console.log("Page", page);
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                console.log("Page Size", newPageSize);
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={
                <p className="p-0 my-2 me-auto">No Data Found</p>
              }
            />
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LinuxServerityDataUnrecognised;
