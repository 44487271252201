import React, { useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import cyberLogo from "../assets/img/onlyLogo.png";
import axios from "../api/axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomToaster from "../components/common/CustomToaster";
import { useDispatch } from "react-redux";
import { hideToaster, showToaster } from "../feature/slices/toastSlice";
import { setUser } from "../feature/slices/AuthSlice";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";

export default function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const formData = new FormData();
      // Append fields to the FormData object
      formData.append("email", formik.values.email);
      formData.append("password", formik.values.password);
      try {
        const res = await axios.post("/login", formData);
        if (
          res?.data?.isEmailVerified === false &&
          res?.data?.message === "Please verify your email to log in" &&
          res?.data?.status === false
        ) {
          resetForm();
          dispatch(
            showToaster({
              title: "Login",
              message: "OTP not verified",
              color: "warning",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
            navigate(`/verify`, {
              state: {
                email: values.email,
              },
            });
          }, 1000);
        } else {
          dispatch(
            showToaster({
              title: "Login",
              message: "Login Successfully",
              color: "success",
            })
          );
          console.log(res?.data, "RESPONSEDATA");
          setTimeout(() => {
            dispatch(hideToaster());
            dispatch(setUser(res.data));
            navigate(`/`);
          }, 1000);
        }
      } catch (error) {
        // Handle login error
        if (error.response) {
          dispatch(
            showToaster({
              title: "Error",
              message: error.response.data.message,
              color: "error",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        } else {
          dispatch(
            showToaster({
              title: "Error",
              message: "Something Went Wrong",
              color: "error",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        }
      }
      setSubmitting(false);
    },
  });

  const styleCard = {
    // 1st basic
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgb(228 228 228 / 65%)'
    // 2nd mid
    // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
    // 3rd high +
    // boxShadow: 'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px'

  }

  const styleTitle = {
    marginLeft: '-1px !important',
    marginTop:' 27px !important'
  }
  const styleLink= {
    // textDecorationLine: 'underline',
    color: '#33339c'
  }
  return (
    <div className="page-sign">
      <Card className="card-sign" style={styleCard}>
        <Card.Header>
          <Link className="sidebar-logo d-flex align-items-center gap-2 justify-content-start">
            <img
              src={cyberLogo}
              id="logo"
              alt="Cyber Auditor"
              className="cyBerLogo"
            />
            <p className="m-0 p-0 fw-semibold fs-13  fs-5">CYBER AUDITOR</p>
          </Link>
        <Card.Text className="my-2  mt-3" style={styleTitle}>
            Login to Cyber Auditor
          </Card.Text>
        </Card.Header>
        <Card.Body
       style={{paddingTop:'12px'}}
         >
          <Form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                name="email"
                // placeholder="jatin@gmail.com"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={
                  formik.touched.email && formik.errors.email ? "error" : ""
                }
              />
              {formik.touched.email && formik.errors.email && (
                <div className="error-message">{formik.errors.email}</div>
              )}
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="/forgot">Forgot password?</Link>
              </Form.Label>
              <Input.Password
                name="password"
                // placeholder="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={
                  formik.touched.password && formik.errors.password
                    ? "error py-2"
                    : "form-control d-flex"
                }
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined onClick={() => setShowPassword(false)} />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={() => setShowPassword(true)}
                    />
                  )
                }
                visible={showPassword}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="error-message">{formik.errors.password}</div>
              )}
            </div>
            <Button variant="dark" className="btn-sign" type="submit">
              {formik.isSubmitting ? (
                <span className="m-0 p-0">
                  <Spinner size="sm" />
                </span>
              ) : (
                "Sign in"
              )}
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer>
          Don't have an account? &nbsp; <Link to="/signup" style={styleLink}>Create an Account</Link>
        </Card.Footer>
      </Card>
      <CustomToaster />
    </div>
  );
}
