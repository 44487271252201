import React from "react";

import ServerityOkIcon from "../../../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../DashBoard/detailCard";
import { Col, Row } from "react-bootstrap";
import {
  TOTAL_LINUX_CLIENT,
  TOTAL_SAFE_LINUX,
  TOTAL_UNRESPONSIVE_LINUX,
  TOTAL_UNSAFE_LINUX,
} from "../../../../api/endPoints/linuxAlertEndPoint";

function AndroidClients(androidSummary,totalDashboard) {




var TotalLinuxEndpoint = (androidSummary?.data?.totalClients / androidSummary?.totalDashboard) * 100;
// Total number of values
var totalAndroidCount = androidSummary?.data?.totalSafeClients + androidSummary?.data?.totalUnsafeClients + androidSummary?.data?.totalUnresponsivelients;

// Percentage calculations
var android_Safe_Per = (androidSummary?.data?.totalSafeClients / androidSummary?.data?.totalClients) * 100;
var android_UnSafe_Per = (androidSummary?.data?.totalUnsafeClients / androidSummary?.data?.totalClients) * 100;
var android_UnResponsive_Per = (androidSummary?.data?.totalUnresponsivelients / androidSummary?.data?.totalClients) * 100;

android_Safe_Per = Math.round(android_Safe_Per);
android_UnSafe_Per = Math.round(android_UnSafe_Per);
android_UnResponsive_Per = Math.round(android_UnResponsive_Per);
TotalLinuxEndpoint= Math.round(TotalLinuxEndpoint);

  console.log(androidSummary, "Android");
  return (
    <Row className="g-3">
      <Col xl="3">
        <DetailCard
          severityName={"Total Android Clients"}
          severityIcon={ServerityOkIcon}
          severityNumber={androidSummary?.data?.totalClients || 0}
          cardIcon={windowOkIcon}
          severityColor={"primary"}
          apiUrl={'androididinfo/android-device-list'}
          routeUrl={"/android-serverity"}
          riskAt={TotalLinuxEndpoint}
          footerTitle={'endpoints are Android'}
          


        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Safe Android Clients"}
          severityIcon={ServerityGreenIcon}
          severityNumber={androidSummary?.data?.totalSafeClients || 0}
          cardIcon={windowGreenIcon}
          severityColor={"success"}
          apiUrl={'androididinfo/safe-device-list'}
          routeUrl={"/android-serverity"}
          riskAt={android_Safe_Per ? android_Safe_Per :'0'}
          footerTitle={'endpoints secure'}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unsafe Android Clients"}
          severityIcon={ServerityRedIcon}
          severityNumber={androidSummary?.data?.totalUnsafeClients || 0}
          cardIcon={windowRedIcon}
          severityColor={"danger"}
          apiUrl={'androididinfo/unsafe-device-list'}
          routeUrl={"/android-serverity"}
          riskAt={android_UnSafe_Per ? android_UnSafe_Per : '0'}
          footerTitle={'endpoints at risk'}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unresponsive Android Clients"}
          severityIcon={ServerityYellowIcon}
          severityNumber={androidSummary?.data?.totalUnresponsivelients || 0}
          cardIcon={windowOYellowIcon}
          severityColor={"warning"}
          apiUrl={'androididinfo/unresponsive-device-list'}
          routeUrl={"/android-serverity"}
          riskAt={android_UnResponsive_Per ? android_UnResponsive_Per : '0'}
          footerTitle={'endpoints  unresponsive'}
        />
      </Col>
    </Row>
  );
}

export default AndroidClients;
