import React from "react";
import { Container, Spinner } from "react-bootstrap";

function PageLoader() {
  return (
    <Container className="d-flex h-100 align-items-center justify-content-center my-4">
      <Spinner size="xl" animation="border" />
    </Container>
  );
}

export default PageLoader;
