import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2024, Mobisec Technologies, All Rights Reserved.</span>
    </div>
  );
}
