import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";

function CustomAccordian({ accordianHeader, children, autoOpen = false ,  }) {
  console.log(accordianHeader,'accordianHeaderaccordianHeaderaccordianHeader')
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // const handleHeaderClick = () => {
  //   if (accordianHeader === "CIS Process Hardening Info" && unTpmRef.current) {
  //     unTpmRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  return (
    <Accordion defaultActiveKey="1" className="mt-3">
      <Accordion.Item eventKey={autoOpen ? "1" : "0"}>
        <Accordion.Header>
          <span className="d-flex align-items-center gap-2 justify-content-center">
            <p className="p-0 m-0 fs-12 fw-bold text-secondary">
              {accordianHeader}
            </p>
          </span>
        </Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CustomAccordian;
