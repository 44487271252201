import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import Footer from "../../layouts/Footer";
// import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import macIcon from "../../assets/img/DashIcon/newMacIcon.png";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";
import jsPDF from "jspdf";
import edrIcon from "../../assets/img/Alerts//edrIcon.png";
import multRoot from "../../assets/img/Alerts/multRoot.png";
import oldPassword from "../../assets/img/Alerts/oldPassword.png";
import unknow from "../../assets/img/Alerts/unknow.png";
import wifi from "../../assets/img/Alerts/wifi.png";
import bluetooth from "../../assets/img/Alerts/bluetooth.png";
import napIcon from "../../assets/img/Alerts/NapIcon.png";
import publicshare from "../../assets/img/Alerts/publicshare.png";
import windows_rdp_gateway from "../../assets/img/Alerts/windows_rdp_gateway.png";
import battery from "../../assets/img/Alerts/battery.png";
import ram from "../../assets/img/Alerts/ram.png";
import cpu from "../../assets/img/Alerts/cpu.png";
import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";
import openport from "../../assets/img/Alerts/openport.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";

import usbIcon from "../../assets/img/Alerts/usbIcon.png";
import usbviolation from "../../assets/img/Alerts/usbviolation.png";
import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";
import unrecog from "../../assets/img/Alerts/unrecog.png";
import domain from "../../assets/img/DashIcon/domain.png";
import scanner_icon from "../../assets/img/DashIcon/scanner_icon.png";
import social_transfer_icon from "../../assets/img/DashIcon/social_transfer_icon.png";
import tpm_icon from "../../assets/img/Alerts/tpm_icon.png";
import oshardingset from "../../assets/img/Alerts/oshardingset.png";
import secure from "../../assets/img/Alerts/secure.png";
import open_nw from "../../assets/img/Alerts/open_nw.png";

import PageLoader from "../../components/common/Loader/PageLoader";
import useFetchMacSummary from "../../hooks/macHooks/useFetchMacOsSummary";
import { Bar } from 'react-chartjs-2';

import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  MALWARE_DETECTION_ENDPOINT,
  // NAC_NAP,
  // OS_PATCHED,
  BIOS_HEALTH,
  IS_BLUETOOTH_ENABLED,
  CD_DRIVE,
  HIGH_CPU_USAGE,
  HIGH_RAM_USAGE,
  MAC_BLACKLIST,
  MAC_FIREWALL_OFF,
  MAC_OS_NOT_PATCH,
  MULITPLE_ADMIN,
  IS_NAC_DISABLED,
  OLDER_PASSWORD,
  OPEN_NETWORK_CONNECTION,
  OUT_OF_DOMAIN,
  RDP_ENABLED,
  IS_SCANNER_ENABLED,
  SHARED_FOLDER,
  USB_VOILATION,
  ALL_UNKNOWN_SERVICES,
  IS_WIFI_ENABLED,
  TOTAL_MAC_LIST,
  SAFE_MAC_CLIENTS,
  UNRESPONSIVE_MAC_CLIENTS,
  UNSAFE_MAC_CLIENTS,
} from "../../api/endPoints/macAlertEndPoint";
import CustomToaster from "../../components/common/CustomToaster";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showToaster } from "../../feature/slices/toastSlice";

export default function MacosDetail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = {
    Marketing: {
        sections: {
            Campaigns: {
                subSections: [
                    "Email Campaigns",
                    "Social Media Ads",
                    "Influencer Collaborations",
                ],
            },
            Analytics: {
                subSections: ["Traffic Analysis", "Conversion Rates"],
            },
            Branding: {
                subSections: ["Logo Design", "Brand Guidelines", "Merchandise"],
            },
        },
    },
    Sales: {
        sections: {
            LeadGeneration: {
                subSections: ["Cold Calling", "Email Outreach", "Networking Events"],
            },
            SalesStrategy: {
                subSections: ["Market Research", "Sales Forecasting"],
            },
            CustomerRelationship: {
                subSections: ["Client Meetings", "Feedback Surveys"],
            },
        },
    },
    Development: {
        sections: {
            Frontend: {
                subSections: ["React Development", "UI/UX Design"],
            },
            Backend: {
                subSections: ["API Development", "Database Management"],
            },
            DevOps: {
                subSections: ["CI/CD Pipelines", "Cloud Services"],
            },
        },
    },
};
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  const {
    loading,
    macSummary,
    macAlertSummary,
    handleReq = "",
    totalDashboard,
  } = useFetchMacSummary();

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (handleReq === "MAC_USB_TRIGGER") {
    dispatch(
      showToaster({
        title: "USB",
        message: "USB VIOLATION",
        color: "warning",
      })
    );
  }

  if (handleReq === "MAC_INSTALLATION_TRIGGER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "New Device Register",
        color: "success",
      })
    );
  }

  if (handleReq === "NO_DEVICE_REGISTER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "No Device Register",
        color: "danger",
      })
    );
  }

  console.log(handleReq, "REQUESTHANDLEREQ");

  var TotalLinuxEndpoint = (macSummary?.totalClients / totalDashboard) * 100;

  // Total number of values
  var totalMaccCount =
    macSummary?.totalSafeClients +
    macSummary?.totalUnsafeClients +
    macSummary?.totalUnresponsivelients;

  // Percentage calculations
  var mac_Safe_Per =
    (macSummary?.totalSafeClients / macSummary?.totalClients) * 100;
  var mac_UnSafe_Per =
    (macSummary?.totalUnsafeClients / macSummary?.totalClients) * 100;
  var mac_UnResponsive_Per =
    (macSummary?.totalUnresponsivelients / macSummary?.totalClients) * 100;

  TotalLinuxEndpoint = Math.round(TotalLinuxEndpoint);
  mac_Safe_Per = Math.round(mac_Safe_Per);
  mac_UnSafe_Per = Math.round(mac_UnSafe_Per);
  mac_UnResponsive_Per = Math.round(mac_UnResponsive_Per);

  const formatCamelCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (char) => char.toUpperCase());
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const exportToPDF = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(macSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(macAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Organization: ${orgName}`, 14, 15);
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    );

    doc.setFontSize(15);
    doc.text(`Mac Summary Data`, 14, 22);

    const tableColumn = ["Sl.No", "Category", "Total Count"];

    const tableRows = data?.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
    });

    doc.save("mac_summary_data.pdf");
  };

  const exportToCSV = () => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    const data = [
      ...Object.entries(macSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
      ...Object.entries(macAlertSummary)?.map(([key, value]) => ({
        category: formatCamelCase(key),
        count: value,
      })),
    ];

    const tableHeaders = ["Sl.No", "Category", "Total Count"];

    const tableRows = data.map((item, index) => [
      index + 1,
      item.category,
      item.count,
    ]);

    const csvContent = [
      `Organization: ${orgName}, Exported: ${exportDateTime}`,
      "",
      tableHeaders.join(","),
      ...tableRows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "mac _summary_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



      const calculateCounts = (data) => {
        const sectionCount = {};
        const subSectionCount = {};

        for (const department in data) {
            const sections = data[department].sections;
            sectionCount[department] = Object.keys(sections).length;
            subSectionCount[department] = {};

            for (const section in sections) {
                const subSections = sections[section].subSections;
                subSectionCount[department][section] = subSections.length;
            }
        }

        return {
            sectionCount,
            subSectionCount,
        };
    };

    const counts = calculateCounts(data);

    // Prepare data for Chart.js
    const departments = Object.keys(counts.sectionCount);
    const sectionCounts = Object.values(counts.sectionCount);
    const subSectionCounts = departments.map(dep => {
        return Object.values(counts.subSectionCount[dep]).reduce((acc, count) => acc + count, 0);
    });

    const chartData = {
        labels: departments,
        datasets: [
            {
                label: 'Sections',
                data: sectionCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                barThickness: 20, // Adjust the width of the bars here

            },
            {
                label: 'Sub-Sections',
                data: subSectionCounts,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                barThickness: 20, // Adjust the width of the bars here

            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Count',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Departments',
                },
            },
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">Dashboard</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/macOs-dash" ? (
              <span className="active">Mac Dashboard</span>
            ) : (
              <NavLink exact to="/macOs-dash">
                Mac Dashboard
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/macOs-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={macIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Mac DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0 align-items-center">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={exportToPDF}
              >
                <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
              </button>

              <button
                type="button"
                className="btn btn-success text-white"
                onClick={exportToCSV}
              >
                <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
              </button>
            </div>
          </div>
        </div>
        {!loading ? (
          <>
         
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Mac Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={macSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={TOTAL_MAC_LIST}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={0}
                  riskAt={TotalLinuxEndpoint}
                  footerTitle={"endpoints are Mac"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Safe Mac Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={macSummary?.totalSafeClients || 0}
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={SAFE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={1}
                  riskAt={mac_Safe_Per ? mac_Safe_Per : "0"}
                  footerTitle={"endpoints are secure"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unsafe Mac Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={macSummary?.totalUnsafeClients || 0}
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={UNSAFE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={2}
                  riskAt={mac_UnSafe_Per ? mac_UnSafe_Per : "0"}
                  footerTitle={"endpoints at risk"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Mac Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={macSummary?.totalUnresponsivelients || 0}
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={UNRESPONSIVE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={3}
                  riskAt={mac_UnResponsive_Per ? mac_UnResponsive_Per : "0"}
                  footerTitle={"endpoints Unresponsive"}
                />
              </Col>
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlueIcon}
                          alertTitle={"AV Not Installed"}
                          alertNumber={macAlertSummary?.avNotInstalled}
                          apiUrl={ANTIVIRUS_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avRedIcon}
                          alertTitle={"AV Malfunction"}
                          alertNumber={macAlertSummary?.avMalfunction}
                          apiUrl={ANTI_VIRUS_MALFUNCTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlackIcon}
                          alertTitle={"AV Not Updated"}
                          alertNumber={macAlertSummary?.avNotUpdated}
                          apiUrl={ANTI_VIRUS_NOT_UPDATED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Not Installed"}
                          alertNumber={macAlertSummary?.edrNotInstalled}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={fireIcon}
                          alertTitle={"Firewall OFF"}
                          alertNumber={macAlertSummary?.firwallOff}
                          apiUrl={MAC_FIREWALL_OFF}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={usbIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={macAlertSummary?.usbViolation}
                          apiUrl={USB_VOILATION}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={unrecog}
                          alertTitle={"Unrecognized IP Address"}
                          alertNumber={0}
                          // apiUrl={NETWORK_VIOLATION}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={openport}
                          alertTitle={"Unwanted Open Ports"}
                          alertNumber={0}
                          // apiUrl={UNWANTED_PORT}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={usbviolation}
                          alertTitle={"Unknown Network Comm"}
                          alertNumber={0}
                          // apiUrl={NETWORK_COMMUNICATION_VIOLATION}
                          routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={tpm_icon}
                          alertTitle={"TPM Not Available"}
                          alertNumber={0}
                          // apiUrl={TPM_DISABLED}
                          // routeUrl={"/linux-serverity-usb"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={secure}
                          alertTitle={"Secure Boot Not Enabled"}
                          alertNumber={0}
                          // apiUrl={IS_SECURE_BOOT_DISABLED}
                          // routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={oshardingset}
                          alertTitle={"OS Not Hardened"}
                          alertNumber={0}
                          // apiUrl={OS_NOT_HARDENED}
                          // routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Malfunction"}
                          alertNumber={macAlertSummary?.edrMalfunction}
                          apiUrl={EDR_MALFUNCTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"OS Not Patched"}
                          alertNumber={macAlertSummary?.osNotPatched}
                          apiUrl={MAC_OS_NOT_PATCH}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={napIcon}
                          alertTitle={"NAP/NAC Disabled"}
                          alertNumber={macAlertSummary?.napnacDisabled}
                          apiUrl={IS_NAC_DISABLED}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={malwareIcon}
                          alertTitle={"Malware detected"}
                          alertNumber={macAlertSummary?.malawareDetected}
                          apiUrl={MALWARE_DETECTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={closeAlertIcon}
                          alertTitle={"Blacklisted Software"}
                          alertNumber={macAlertSummary?.blacklistedSoftware}
                          apiUrl={MAC_BLACKLIST}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="3">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[35, 20, 20, 15]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>1.8B</h1>
                        <p>86.24%</p>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={unknow}
                          alertTitle={"Unknown Services"}
                          alertNumber={macAlertSummary?.unknownServices}
                          apiUrl={ALL_UNKNOWN_SERVICES}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={oldPassword}
                          alertTitle={"Old Admin Passwords"}
                          alertNumber={macAlertSummary?.oldAdminPasswords}
                          apiUrl={OLDER_PASSWORD}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={multRoot}
                          alertTitle={"Multiple Root Privilege Account"}
                          alertNumber={macAlertSummary?.mulitpleAdmins}
                          apiUrl={MULITPLE_ADMIN}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"UAC Disabled"}
                          alertNumber={0}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={publicshare}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={macAlertSummary?.sharedFolder}
                          apiUrl={SHARED_FOLDER}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={bluetooth}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={macAlertSummary?.blueToothCount}
                          apiUrl={IS_BLUETOOTH_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={wifi}
                          alertTitle={"Wi-Fi Enabled"}
                          alertNumber={macAlertSummary?.wifiPcCount}
                          apiUrl={IS_WIFI_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Out of Domain"}
                          alertNumber={macAlertSummary?.outOfDomain}
                          apiUrl={OUT_OF_DOMAIN}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windows_rdp_gateway}
                          alertTitle={"RDP Enabled"}
                          alertNumber={macAlertSummary?.rdpEnabled}
                          apiUrl={RDP_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"CD/DVD Drive Enabled"}
                          alertNumber={macAlertSummary?.cdDriveEnabled}
                          apiUrl={CD_DRIVE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={battery}
                          alertTitle={"BIOS Battery Unserviceable"}
                          alertNumber={macAlertSummary?.biosBatteryHealth}
                          apiUrl={BIOS_HEALTH}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={scanner_icon}
                          alertTitle={"Scanner Installed"}
                          alertNumber={macAlertSummary?.scannerInstalled}
                          apiUrl={IS_SCANNER_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={ram}
                          alertTitle={"High RAM Usage"}
                          alertNumber={macAlertSummary?.highRamUsage}
                          apiUrl={HIGH_RAM_USAGE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={cpu}
                          alertTitle={"High CPU Usage"}
                          alertNumber={macAlertSummary?.highCpuUsage}
                          apiUrl={HIGH_CPU_USAGE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      {/* <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"OS Formatted"}
                          alertNumber={0}
                          apiUrl={OS_PATCHED}
                          routeUrl={"/macOs-serverity"}
                          controlApi={false}
                        />
                      </Col> */}

                      {/* <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Hardware Changed"}
                          alertNumber={macAlertSummary?.napnacDisabled}
                          apiUrl={NAC_NAP}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col> */}
                      <Col xl="3">
                        <Alerts
                          alertIcon={open_nw}
                          alertTitle={"Open Network Connection"}
                          alertNumber={macAlertSummary?.openNetworkConnection}
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={12}>
                <Card class="card-one shadow p-3">
                <Bar data={chartData} options={options} />
                </Card>
              </Col>
            </Row>
            <Footer />
            {/* <CustomToaster /> */}
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
