// // import React, { useEffect, useState } from "react";
// // import { Button } from "react-bootstrap";
// // import { useNavigate } from "react-router-dom";
// // import DataTable from "react-data-table-component";
// // import { DownOutlined } from "@ant-design/icons";
// // import { Dropdown, Menu } from "antd";
// // import Tippy from "@tippyjs/react";
// // import "tippy.js/dist/tippy.css"; // optional
// // function CustomTable({
// //   rowData,
// //   routeUrl,
// //   currentPage,
// //   pageSize,
// //   handlePageChange,
// //   handlePageSizeChange,
// //   totalDocuments,
// // }) {
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const navigate = useNavigate();
// //   const [skin, setSkin] = useState(currentSkin);
// // console.log(rowData,'===============')
// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName(
// //         "btn-outline-primary"
// //       );

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   switchSkin(skin);
// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const handleMenuClick = (e, record) => {
// //     console.log("Click on menu item:", e, "for record:", record);
// //   };

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };
// //   const menu = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.ipAddress?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const menuadmin = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.adminAccountsName?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   }
// //   const columns = React.useMemo(
// //     () => [
// //       {
// //         name: "S. No.",
// //         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
// //         width: "70px",
// //       },
// //       {
// //         name: "Hostname",
// //         selector: (row) => row.hostname,
// //         sortable: true,
// //       },

// //       {
// //         name: "Serial Name",
// //         selector: (row) =>
// //         <Tippy content={row.serialNumber}>
// //         <div>{row.serialNumber}</div>
// //       </Tippy>
// //         ,
// //         sortable: true,
// //       },
// //       {
// //         name: "IP Address",
// //         selector: (row) => row.ipAddress, // This targets the IP address
// //         cell: (row) => (
// //           <Dropdown overlay={menu(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.ipAddress || "NA"} <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Admin Accounts Name",
// //         selector: (row) => row.adminAccountsName, // This targets the admin accounts name array
// //         cell: (row) => (
// //           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.adminAccountsName?.length > 0
// //                 ? row.adminAccountsName.join(", ")
// //                 : "NA"}
// //               <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       },

// //       {
// //         name: "Client Score",
// //         selector: (row) =>

// //         <Tippy content={row.clientScore}>
// //         <div>{row.clientScore}</div>
// //       </Tippy>,
// //         sortable: true,
// //       },
// //       {
// //         name: "Sync time",
// //         selector: (row) =>

// //         <Tippy content={formatTimestamp(row.updatedAt)}>
// //         <div>{formatTimestamp(row.updatedAt)}</div>
// //       </Tippy>,
// //         sortable: true,
// //       },

// //       {
// //         name: "Action",
// //         cell: (row) => (
// //           <Button
// //             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
// //             onClick={() =>
// //               navigate(routeUrl, {
// //                 state: {
// //                   basicInfo: row,
// //                   pcInfoId: row?.pc_Id,
// //                 },
// //               })
// //             }
// //           >
// //             <p className="m-0 p-0 fs-12">View Details</p>
// //             <i className="fas fa-long-arrow-alt-right ps-4"></i>
// //           </Button>
// //         ),
// //         ignoreRowClick: true,
// //         allowoverflow: false,
// //       },
// //     ],
// //     [currentPage, pageSize, navigate, routeUrl]
// //   );

// //   return (
// //     <DataTable
// //       columns={columns}
// //       data={rowData || []}
// //       pagination
// //       paginationServer
// //       paginationTotalRows={totalDocuments}
// //       paginationDefaultPage={currentPage}
// //       paginationPerPage={pageSize}
// //       onChangePage={(page) => {
// //         console.log("Page", page);
// //         handlePageChange(page);
// //       }}
// //       onChangeRowsPerPage={(newPageSize) => {
// //         console.log("Page Size", newPageSize);
// //         handlePageSizeChange(newPageSize);
// //       }}
// //       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
// //     />
// //   );
// // }

// // export default CustomTable;
// // import React, { useEffect, useState } from "react";
// // import { Button } from "react-bootstrap";
// // import { useNavigate } from "react-router-dom";
// // import DataTable from "react-data-table-component";
// // import { DownOutlined } from "@ant-design/icons";
// // import { Dropdown, Menu } from "antd";
// // import Tippy from "@tippyjs/react";
// // import "tippy.js/dist/tippy.css"; // optional

// // function CustomTable({
// //   rowData,
// //   routeUrl,
// //   currentPage,
// //   pageSize,
// //   handlePageChange,
// //   handlePageSizeChange,
// //   totalDocuments,
// // }) {
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const navigate = useNavigate();
// //   const [skin, setSkin] = useState(currentSkin);

// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName(
// //         "btn-outline-primary"
// //       );

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   switchSkin(skin);
// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const handleMenuClick = (e, record) => {
// //     console.log("Click on menu item:", e, "for record:", record);
// //   };

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const menu = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.ipAddress?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const menuadmin = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.adminAccountsName?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const columns = React.useMemo(() => {
// //     const baseColumns = [
// //       {
// //         name: "S. No.",
// //         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
// //         width: "90px",
// //       },
// //       {
// //         name: "Hostname",
// //         selector: (row) => row.hostname,
// //         sortable: true,
// //       },
// //       {
// //         name: "Serial Name",
// //         selector: (row) => (
// //           <Tippy content={row.serialNumber}>
// //             <div>{row.serialNumber}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "IP Address",
// //         selector: (row) => row.ipAddress, // This targets the IP address
// //         cell: (row) => (
// //           <Dropdown overlay={menu(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.ipAddress || "NA"} <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Client Score",
// //         selector: (row) => (
// //           <Tippy content={row.clientScore}>
// //             <div>{row.clientScore}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Sync time",
// //         selector: (row) => (
// //           <Tippy content={formatTimestamp(row.updatedAt)}>
// //             <div>{formatTimestamp(row.updatedAt)}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Action",
// //         cell: (row) => (
// //           <Button
// //             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
// //             onClick={() =>
// //               navigate(routeUrl, {
// //                 state: {
// //                   basicInfo: row,
// //                   pcInfoId: row?.pc_Id,
// //                 },
// //               })
// //             }
// //           >
// //             <p className="m-0 p-0 fs-12">View Details</p>
// //             <i className="fas fa-long-arrow-alt-right ps-4"></i>
// //           </Button>
// //         ),
// //         ignoreRowClick: true,
// //         allowoverflow: false,
// //       },
// //     ];

// //     // Conditionally add the "Admin Accounts Name" column if it's present
// //     if (rowData?.some((row) => row.adminAccountsName)) {
// //       baseColumns.splice(4, 0, {
// //         name: "Admin Accounts Name",
// //         selector: (row) => row.adminAccountsName,
// //         cell: (row) => (
// //           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.adminAccountsName?.length > 0
// //                 ? row.adminAccountsName.join(", ")
// //                 : "NA"}
// //               <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       });
// //     }

// //     return baseColumns;
// //   }, [currentPage, pageSize, navigate, routeUrl, rowData]);

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };
// //   return (
// //     <DataTable
// //       columns={columns}
// //       data={rowData || []}
// //       pagination
// //       customStyles={customStyles}
// //       paginationServer
// //       paginationTotalRows={totalDocuments}
// //       paginationDefaultPage={currentPage}
// //       paginationPerPage={pageSize}
// //       onChangePage={(page) => {
// //         console.log("Page", page);
// //         handlePageChange(page);
// //       }}
// //       onChangeRowsPerPage={(newPageSize) => {
// //         console.log("Page Size", newPageSize);
// //         handlePageSizeChange(newPageSize);
// //       }}
// //       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
// //     />
// //   );
// // }

// // export default CustomTable;

// // import React, { useEffect, useState } from "react";
// // import { Button } from "react-bootstrap";
// // import { useNavigate } from "react-router-dom";
// // import DataTable from "react-data-table-component";
// // import { DownOutlined } from "@ant-design/icons";
// // import { Dropdown, Menu } from "antd";
// // import Tippy from "@tippyjs/react";
// // import "tippy.js/dist/tippy.css"; // optional

// // function CustomTable({
// //   rowData,
// //   routeUrl,
// //   currentPage,
// //   pageSize,
// //   handlePageChange,
// //   handlePageSizeChange,
// //   totalDocuments,
// // }) {
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const navigate = useNavigate();
// //   const [skin, setSkin] = useState(currentSkin);

// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName(
// //         "btn-outline-primary"
// //       );

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   switchSkin(skin);
// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const handleMenuClick = (e, record) => {
// //     console.log("Click on menu item:", e, "for record:", record);
// //   };

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const menu = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.ipAddress?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const menuadmin = (record) => {
// //     return (
// //       <Menu onClick={(e) => handleMenuClick(e, record)}>
// //         {record?.adminAccountsName?.map((v, index) => (
// //           <Menu.Item key={index}>{v}</Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const sharedDirectoriesMenu = (directories) => {
// //     return (
// //       <Menu>
// //         {directories?.map((dir, index) => (
// //           <Menu.Item key={index}>
// //             <Tippy content={`Path: ${dir.path}`}>
// //               <div>
// //                 {dir.name}
// //                 <br />
// //                 <small>
// //                   Owner: {dir.permission.owner || "N/A"}, Group:{" "}
// //                   {dir.permission.group || "N/A"}, Other:{" "}
// //                   {dir.permission.other || "N/A"}
// //                 </small>
// //               </div>
// //             </Tippy>
// //           </Menu.Item>
// //         ))}
// //       </Menu>
// //     );
// //   };

// //   const columns = React.useMemo(() => {
// //     const baseColumns = [
// //       {
// //         name: "S. No.",
// //         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
// //         width: "90px",
// //       },
// //       {
// //         name: "Hostname",
// //         selector: (row) => row.hostname,
// //         sortable: true,
// //       },
// //       {
// //         name: "Serial Name",
// //         selector: (row) => (
// //           <Tippy content={row.serialNumber}>
// //             <div>{row.serialNumber}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "IP Address",
// //         selector: (row) => row.ipAddress, // This targets the IP address
// //         cell: (row) => (
// //           <Dropdown overlay={menu(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.ipAddress || "NA"} <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Client Score",
// //         selector: (row) => (
// //           <Tippy content={row.clientScore}>
// //             <div>{row.clientScore}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Sync time",
// //         selector: (row) => (
// //           <Tippy content={formatTimestamp(row.updatedAt)}>
// //             <div>{formatTimestamp(row.updatedAt)}</div>
// //           </Tippy>
// //         ),
// //         sortable: true,
// //       },
// //       {
// //         name: "Action",
// //         cell: (row) => (
// //           <Button
// //             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
// //             onClick={() =>
// //               navigate(routeUrl, {
// //                 state: {
// //                   basicInfo: row,
// //                   pcInfoId: row?.pc_Id,
// //                 },
// //               })
// //             }
// //           >
// //             <p className="m-0 p-0 fs-12">View Details</p>
// //             <i className="fas fa-long-arrow-alt-right ps-4"></i>
// //           </Button>
// //         ),
// //         ignoreRowClick: true,
// //         allowoverflow: false,
// //       },
// //     ];

// //     // Conditionally add the "Admin Accounts Name" column if it's present
// //     if (rowData?.some((row) => row.adminAccountsName)) {
// //       baseColumns.splice(4, 0, {
// //         name: "Admin Accounts Name",
// //         selector: (row) => row.adminAccountsName,
// //         cell: (row) => (
// //           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.adminAccountsName?.length > 0
// //                 ? row.adminAccountsName.join(", ")
// //                 : "NA"}
// //               <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       });
// //     }

// //     // Conditionally add the "Shared Directories" column if it's present
// //     if (rowData?.some((row) => row.sharedDirectories)) {
// //       baseColumns.push({
// //         name: "Shared Directories",
// //         selector: (row) => row.sharedDirectories,
// //         cell: (row) => (
// //           <Dropdown
// //             overlay={sharedDirectoriesMenu(row.sharedDirectories)}
// //             trigger={["click"]}
// //           >
// //             <Button className="bg-transparent border-0 p-0 m-0">
// //               {row.sharedDirectories?.length > 0
// //                 ? row.sharedDirectories.map((dir) => dir.name).join(", ")
// //                 : "N/A"}{" "}
// //               <DownOutlined />
// //             </Button>
// //           </Dropdown>
// //         ),
// //         sortable: true,
// //       });
// //     }

// //     return baseColumns;
// //   }, [currentPage, pageSize, navigate, routeUrl, rowData]);

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };
// //   return (
// //     <DataTable
// //       columns={columns}
// //       data={rowData || []}
// //       pagination
// //       customStyles={customStyles}
// //       paginationServer
// //       paginationTotalRows={totalDocuments}
// //       paginationDefaultPage={currentPage}
// //       paginationPerPage={pageSize}
// //       onChangePage={(page) => {
// //         console.log("Page", page);
// //         handlePageChange(page);
// //       }}
// //       onChangeRowsPerPage={(newPageSize) => {
// //         console.log("Page Size", newPageSize);
// //         handlePageSizeChange(newPageSize);
// //       }}
// //       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
// //     />
// //   );
// // }

// // export default CustomTable;

// import React, { useEffect, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Menu } from "antd";
// import { useSelector } from "react-redux";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// import { saveAs } from "file-saver";
// import { jsPDF } from "jspdf";
// import "jspdf-autotable";
// import * as XLSX from "xlsx";
// import Excellogo from "../../../assets/img/Excellogo.png";
// import { height } from "@mui/system";

// function CustomTable({
//   rowData,
//   routeUrl,
//   currentPage,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   totalDocuments,
//   title,
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const navigate = useNavigate();
//   const [skin, setSkin] = useState(currentSkin);
//   const [modalData, setModalData] = useState(null);
//   // const [isModalOpen, setModalOpen] = useState(false);
//   const { user } = useSelector((state) => state.authSlice);

//   const [modalOpen, setModalOpen] = useState(false); // Modal visibility state

//   console.log(modalData, "modalData");
//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const handleMenuClick = (e, record) => {
//     console.log("Click on menu item:", e, "for record:", record);
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const menu = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ipAddress?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuService = (record) => {
//     return (
//       <Menu>
//         {record?.service?.map((detail, index) => {
//           const [key, value] = Object.entries(detail)[0];
//           return (
//             <Menu.Item key={index}>
//               {key}: {value}
//             </Menu.Item>
//           );
//         })}
//       </Menu>
//     );
//   };
//   const menuadmin = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.notInstalledAvs?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuadminssid = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ssid?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const menuadminauthenticationType = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.authenticationType?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const sharedDirectoriesMenu = (directories) => {
//     return (
//       <Menu>
//         {directories?.map((dir, index) => (
//           <Menu.Item key={index}>
//             <Tippy content={`Path: ${dir.path}`}>
//               <div>
//                 {dir.name}
//                 <br />
//                 <small>
//                   Owner: {dir.permission.owner || "N/A"}, Group:{" "}
//                   {dir.permission.group || "N/A"}, Other:{" "}
//                   {dir.permission.other || "N/A"}
//                 </small>
//               </div>
//             </Tippy>
//           </Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const avDetails = (directories) => {
//     return (
//       <Menu>
//         {directories?.map((dir, index) => (
//           <Menu.Item key={index}>
//             <Tippy
//               content={`Current Version: ${dir.currentAVVersion}, Expected Version: ${dir.expectedAVVersion}`}
//             >
//               <div>
//                 {dir.avName}
//                 <br />
//                 <small>
//                   Current Version: {dir.currentAVVersion || "N/A"}, Expected
//                   Version: {dir.expectedAVVersion || "N/A"}
//                 </small>
//               </div>
//             </Tippy>
//           </Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const osDetails = (directories) => {
//     return (
//       <Menu>
//         {directories?.map((dir, index) => (
//           <Menu.Item key={index}>
//             <Tippy
//               content={`Current Version: ${dir.currentOSVersion}, Expected Version: ${dir.expectedOSVersion}`}
//             >
//               <div>
//                 {dir.osName}
//                 <br />
//                 <small>
//                   Current Version: {dir.currentOSVersion || "N/A"}, Expected
//                   Version: {dir.expectedOSVersion || "N/A"}
//                 </small>
//               </div>
//             </Tippy>
//           </Menu.Item>
//         ))}
//       </Menu>
//     );
//   };
//   const userAdminpassword = (directories) => {
//     return (
//       <Menu>
//         {directories?.map((dir, index) => (
//           <Menu.Item key={index}>
//             <div>
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item active mb-0" aria-current="page">
//                   {dir.userType}
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   {dir.userName}
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   {dir.passwordAge}
//                 </li>
//               </ol>
//             </div>
//           </Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   // Initialize variables
//   let isAslrEnabled = true;
//   let isPrelinkDisabled = true;
//   const columns = React.useMemo(() => {
//     const baseColumns = [
//       {
//         name: "S. No.",
//         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
//         width: "90px",
//       },
//       {
//         name: "Hostname",
//         selector: (row) => (
//           <Tippy content={row.hostname}>
//             <div>{row.hostname}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Serial No.",
//         selector: (row) => (
//           <Tippy content={row.serialNumber}>
//             <div>{row.serialNumber}</div>
//           </Tippy>
//         ),
//         sortable: true,
//         width: "120px",
//       },
//       {
//         name: "IP Address",
//         selector: (row) => row.ipAddress, // This targets the IP address
//         cell: (row) => {
//           const ipArray = row.ipAddress || []; // Ensure it's an array, even if null/undefined
//           if (ipArray.length === 1) {
//             return <span>{ipArray[0]}</span>; // Render the single IP without dropdown
//           } else if (ipArray.length > 1) {
//             return (
//               <Dropdown overlay={menu(row)} trigger={["click"]}>
//                 <Button className="bg-transparent border-0 p-0 m-0">
//                   {ipArray[0]} <i className="fa-solid fa-angle-down"></i>
//                 </Button>
//               </Dropdown>
//             );
//           } else {
//             return <span>NA</span>; // Render "NA" if no IPs are present
//           }
//         },
//         sortable: true,
//         width: "150px",
//       },
//       // {
//       //   name: "Timestamp",
//       //   selector: (row) => (
//       //     <Tippy content={formatTimestamp(row.updatedAt)}>
//       //       <div>{formatTimestamp(row.updatedAt)}</div>
//       //     </Tippy>
//       //   ),
//       //   sortable: true,
//       //   width: "160px",
//       // },
//     ];

//     if (rowData?.some((row) => row.updatedAt)) {
//       baseColumns.splice(4, 0, {
//         name: "Timestamp",
//         selector: (row) => formatTimestamp(row.updatedAt),
//         sortable: true,
//       });
//     }
//     // Conditionally add the "Admin Accounts Name" column if it's present
//     if (rowData?.some((row) => row.adminAccountsName)) {
//       baseColumns.splice(4, 0, {
//         name: "Admin Accounts",
//         selector: (row) =>
//           row.adminAccountsName?.length > 0
//             ? row.adminAccountsName.join(", ")
//             : "NA",
//         sortable: true,
//       });
//     }

//     if (rowData?.some((row) => row.highCpuUsageCount)) {
//       baseColumns.splice(4, 0, {
//         name: "Count",
//         selector: (row) => row.highCpuUsageCount,
//         sortable: true,
//         width: "120px",
//         cell: (row) => (
//           <span
//             onClick={() => handleCountClick(row)}
//             style={{
//               color: "blue",
//               cursor: "pointer",
//               // textDecoration: "underline", // Optional: makes it look like a link
//             }}
//           >
//             {row.highCpuUsageCount}
//           </span>
//         ),
//       });
//     }

//     const handleCountClick = (row) => {
//       // console.log("Row clicked:", row); // Log the row object
//       if (row.highCpuUsage) {
//         console.log("High CPU Usage Data:", row.highCpuUsage); // Log the highCpuUsage data
//         setModalData(row.highCpuUsage);
//         setModalOpen(true); // Open the modal
//       } else {
//         console.log("No highCpuUsage data found for this row.");
//       }
//     };

//     const handleCountClickRam = (row) => {
//       console.log("Row clicked:", row); // Log the row object
//       if (row?.ramUsage) {
//         console.log("High RAM Usage Data:", row?.ramUsage); // Log the highCpuUsage data
//         setModalData(row?.ramUsage);
//         setModalOpen(true);
//       } else {
//         console.log("No highCpuUsage data found for this row.");
//       }
//     };

//     if (rowData?.some((row) => row.highRamUsageCount)) {
//       baseColumns.splice(4, 0, {
//         name: "Count",
//         selector: (row) => (
//           <span
//             onClick={() => handleCountClickRam(row)}
//             style={{
//               color: "blue",
//               cursor: "pointer",
//               // textDecoration: "underline", // Optional: makes it look like a link
//             }}
//           >
//             {row.highRamUsageCount}
//           </span>
//         ),
//         sortable: true,
//         width: "120px",
//       });
//     }

//     if (rowData?.some((row) => row.currentOSVersion)) {
//       baseColumns.splice(4, 0, {
//         name: "Current Version",
//         selector: (row) => row.currentOSVersion,
//         sortable: true,
//         width: "220px",
//       });
//     }
//     if (rowData?.some((row) => row.expectedOSVersion)) {
//       baseColumns.splice(4, 0, {
//         name: "Expected Version",
//         selector: (row) => row.expectedOSVersion,
//         sortable: true,
//         width: "220px",
//       });
//     }
//     if (rowData?.some((row) => row.osName)) {
//       baseColumns.splice(4, 0, {
//         name: "OS Name",
//         selector: (row) => row.osName,
//         sortable: true,
//       });
//     }
//     if (rowData?.some((row) => row.currentAVVersion)) {
//       baseColumns.splice(4, 0, {
//         name: "Current Version",
//         selector: (row) => row.currentAVVersion,
//         sortable: true,
//         width: "220px",
//       });
//     }
//     if (rowData?.some((row) => row.expectedAVVersion)) {
//       baseColumns.splice(4, 0, {
//         name: "Expected Version",
//         selector: (row) => row.expectedAVVersion,
//         sortable: true,
//         width: "220px",
//       });
//     }
//     if (rowData?.some((row) => row.avName)) {
//       baseColumns.splice(4, 0, {
//         name: "AV Name",
//         selector: (row) => row.avName,
//         sortable: true,
//       });
//     }

//     if (rowData?.some((row) => row.serverTimestamp)) {
//       baseColumns.splice(4, 0, {
//         name: "Timestamp",
//         selector: (row) => formatTimestamp(row.serverTimestamp),
//         sortable: true,
//       });
//     }

//     if (rowData?.some((row) => row.service)) {
//       baseColumns.splice(4, 0, {
//         name: "Services",
//         selector: (row) => row.service,
//         cell: (row) => {
//           const serviceArray = row.service || []; // Ensure it's an array, even if null/undefined
//           if (serviceArray.length === 1) {
//             // Extract key and value from the single service entry
//             const [key, value] = Object.entries(serviceArray[0])[0];
//             return (
//               <span>
//                 {key}: {value}
//               </span>
//             );
//           } else if (serviceArray.length > 1) {
//             return (
//               <Dropdown overlay={menuService(row)} trigger={["click"]}>
//                 <Button className="bg-transparent border-0 p-0 m-0">
//                   {Object.entries(serviceArray[0])[0].join(": ")}{" "}
//                   <i className="fa-solid fa-angle-down"></i>
//                 </Button>
//               </Dropdown>
//             );
//           } else {
//             return <span>NA</span>; // Render "NA" if no services are present
//           }
//         },
//         sortable: true,
//         width: "220px",
//       });
//     }

//     if (rowData?.some((row) => row.antivirusVersion)) {
//       baseColumns.splice(4, 0, {
//         name: "Current Version",
//         selector: (row) => row.antivirusVersion,
//         sortable: true,
//         width: "220px",
//       });
//     }

//     if (rowData?.some((row) => row.antivirusName)) {
//       baseColumns.splice(4, 0, {
//         name: "AV Name",
//         selector: (row) => row.antivirusName,
//         sortable: true,
//       });
//     }

//     // if (
//     //   rowData?.some(
//     //     (row) => row.hardeningIssues && typeof row.hardeningIssues === "object"
//     //   )
//     // ) {
//     //   baseColumns.splice(4, 0, {
//     //     // Adjust the index as needed
//     //     name: "Hardening Issues",
//     //     selector: (row) => {
//     //       const hardeningIssues = row.hardeningIssues;
//     //       if (hardeningIssues) {
//     //         return Object.entries(hardeningIssues)
//     //           .map(([key, value]) => `${key}: ${value}`)
//     //           .join(", ");
//     //       }
//     //       return "No issues"; // Default message if no hardening issues
//     //     },
//     //     sortable: false, // Change to true if you want sorting capability
//     //     minWidth: "220px", // Set a minimum width
//     //     width: "35rem", // Set the desired width
//     //     wrap: true, // Allow text wrapping if supported by your table component
//     //   });
//     // }

//     if (
//       rowData?.some(
//         (row) => row.hardeningIssues && typeof row.hardeningIssues === "object"
//       )
//     ) {
//       baseColumns.splice(4, 0, {
//         name: "Hardening Issues",
//         selector: (row) => {
//           const hardeningIssues = row.hardeningIssues;
//           if (hardeningIssues) {
//             return Object.entries(hardeningIssues)
//               .map(([key, value]) => {
//                 // Transform key and value for better readability
//                 const formattedKey = key
//                   .replace(/is(.*)Disabled/, '$1') // Remove 'is' and 'Disabled'
//                   .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
//                   .trim(); // Trim any extra spaces
//                 const formattedValue = value ? "Enabled" : "Disabled"; // Map boolean to user-friendly string
//                 return `${formattedKey}: ${formattedValue}`;
//               })
//               .join(", ");
//           }
//           return "No issues"; // Default message if no hardening issues
//         },
//         sortable: false, // Change to true if you want sorting capability
//         minWidth: "220px", // Set a minimum width
//         width: "30rem", // Set the desired width
//         wrap: true, // Allow text wrapping if supported by your table component
//       });
//     }
    

//     // Conditionally add the "Shared Directories" column if it's present
//     if (rowData?.some((row) => row.sharedDirectories)) {
//       baseColumns.push({
//         name: "Shared Directories",
//         selector: (row) => row.sharedDirectories,
//         cell: (row) => (
//           <Dropdown
//             overlay={sharedDirectoriesMenu(row.sharedDirectories)}
//             trigger={["click"]}
//           >
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.sharedDirectories?.length > 0
//                 ? row.sharedDirectories.map((dir) => dir.name).join(", ")
//                 : "N/A"}{" "}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       });
//     }

//     if (rowData?.some((row) => row.avDetails)) {
//       baseColumns.push({
//         name: "Av Details",
//         selector: (row) => row.avDetails,
//         cell: (row) => (
//           <div overlay={avDetails(row.avDetails)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {/* {row.avDetails?.length > 0
//                 ? row.avDetails.map((dir) => dir.avName).join(", ")
//                 : "N/A"}{" "} */}
//               {row.avDetails?.length > 0
//                 ? row.avDetails
//                     .map(
//                       (dir) =>
//                         `${dir.avName}: Current Version: ${
//                           dir.currentAVVersion || "N/A"
//                         }, Expected Version: ${dir.expectedAVVersion || "N/A"}`
//                     )
//                     .join(", ")
//                 : "N/A"}

//               <DownOutlined />
//             </Button>
//           </div>
//         ),
//         sortable: true,
//         width: "450px",
//       });
//     }

//     if (rowData?.some((row) => row.osDetails)) {
//       baseColumns.push({
//         name: "Os Details",
//         selector: (row) => row.osDetails,
//         cell: (row) => (
//           <Dropdown overlay={osDetails(row.osDetails)} trigger={["click"]}>
//             <Button className="bg-transparent border-0 p-0 m-0">
//               {row.osDetails?.length > 0
//                 ? row.osDetails.map((dir) => dir.osName).join(", ")
//                 : "N/A"}{" "}
//               <DownOutlined />
//             </Button>
//           </Dropdown>
//         ),
//         sortable: true,
//       });
//     }

//     // if (rowData?.some((row) => row.usersPasswordAge)) {
//     //   baseColumns.push({
//     //     name: "Users Password Age",
//     //     selector: (row) => row.usersPasswordAge,
//     //     cell: (row) => (
//     //       <Dropdown
//     //         overlay={userAdminpassword(row.usersPasswordAge)}
//     //         trigger={["click"]}
//     //       >
//     //         <Button className="bg-transparent border-0 p-0 m-0">
//     //           {row.usersPasswordAge?.length > 0
//     //             ? row.usersPasswordAge.map((dir) => dir.userName).join(", ")
//     //             : "N/A"}{" "}
//     //           <DownOutlined />
//     //         </Button>
//     //       </Dropdown>
//     //     ),
//     //     sortable: true,
//     //   });
//     // }
//     // if (rowData?.some((row) => row.usersPasswordAge)) {
//     //   baseColumns.push({
//     //     name: "Users Password Age",
//     //     selector: (row) => row.usersPasswordAge,
//     //     cell: (row) => (
//     //       <span>
//     //         {row.usersPasswordAge?.length > 0
//     //           ? row.usersPasswordAge.map((dir) => (
//     //               <span key={dir.userName} style={{ marginRight: '5px' }}>
//     //                 {`${dir.userType} (${dir.userName}, ${dir.passwordAge})`}
//     //               </span>
//     //             ))
//     //           : "N/A"}
//     //       </span>
//     //     ),
//     //     sortable: true,
//     //   });
//     // }
//     if (rowData?.some((row) => row.usersPasswordAge)) {
//       baseColumns.push({
//         name: "Users Password Age",
//         selector: (row) => row.usersPasswordAge,
//         cell: (row) => (
//           <span>
//             {row.usersPasswordAge?.length > 0
//               ? row.usersPasswordAge.map((dir, index) => (
//                   <span key={dir.userName}>
//                     {`${dir.userName}/${dir.userType}/${dir.passwordAge}`}
//                     {index < row.usersPasswordAge.length - 1 && ", "}
//                   </span>
//                 ))
//               : "N/A"}
//           </span>
//         ),
//         sortable: true,
//       });
//     }

//     // if (rowData?.some((row) => row.notInstalledAvs)) {
//     //   baseColumns.push({
//     //     name: "Not Installed",
//     //     selector: (row) => row.notInstalledAvs,
//     //     cell: (row) => {
//     //       let avArray = row.notInstalledAvs?.[0] || []; // Ensure it's an array, even if null/undefined

//     //       // Convert to array if it's a single value
//     //       if (!Array.isArray(avArray)) {
//     //         avArray = [avArray];
//     //       }

//     //       if (avArray.length === 1) {
//     //         return <span>{avArray[0]}</span>; // Render the single item directly
//     //       } else if (avArray.length > 1) {
//     //         return (
//     //           <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
//     //             <Button className="bg-transparent border-0 p-0 m-0">
//     //               {avArray.join(", ")} <DownOutlined />
//     //             </Button>
//     //           </Dropdown>
//     //         );
//     //       } else {
//     //         return <span>NA</span>; // Render "NA" if no items are present
//     //       }
//     //     },
//     //     sortable: true,
//     //     width: "150px",
//     //   });
//     // }
//     if (rowData?.some((row) => row.notInstalledAvs)) {
//       baseColumns.push({
//         name: "Not Installed",
//         selector: (row) => row.notInstalledAvs,
//         cell: (row) => {
//           const avArray = Array.isArray(row.notInstalledAvs)
//             ? row.notInstalledAvs
//             : [];

//           if (avArray.length === 1) {
//             // If there's only one item in the array, render it directly without a dropdown
//             return <span>{avArray[0]}</span>;
//           } else if (avArray.length > 1) {
//             // If there are multiple items, show the dropdown
//             return (
//               <Dropdown overlay={menuadmin(row)} trigger={["click"]}>
//                 <Button className="bg-transparent border-0 p-0 m-0">
//                   {avArray.join(", ")} <DownOutlined />
//                 </Button>
//               </Dropdown>
//             );
//           } else {
//             // Render "NA" if the array is empty
//             return <span>NA</span>;
//           }
//         },
//         sortable: true,
//         width: "210px",
//       });
//     }

//     if (rowData?.some((row) => row.ssid)) {
//       baseColumns.push({
//         name: "SSID",
//         selector: (row) => row.ssid,
//         cell: (row) => {
//           const avArray = row.ssid || []; // Ensure it's an array, even if null/undefined

//           if (avArray.length === 1) {
//             return <span>{avArray[0]}</span>; // Render the single item directly
//           } else if (avArray.length > 1) {
//             return (
//               <Dropdown overlay={menuadminssid(row)} trigger={["click"]}>
//                 <Button className="bg-transparent border-0 p-0 m-0">
//                   {avArray.join(", ")} <i class="fa-solid fa-angle-down"></i>
//                 </Button>
//               </Dropdown>
//             );
//           } else {
//             return <span>NA</span>; // Render "NA" if no items are present
//           }
//         },
//         sortable: true,
//         width: "150px",
//       });
//     }

//     if (rowData?.some((row) => row.authenticationType)) {
//       baseColumns.push({
//         name: "Auth Type",
//         selector: (row) => row.authenticationType,
//         cell: (row) => {
//           const avArray = row.authenticationType || []; // Ensure it's an array, even if null/undefined

//           if (avArray.length === 1) {
//             return <span>{avArray[0]}</span>; // Render the single item directly
//           } else if (avArray.length > 1) {
//             return (
//               <Dropdown
//                 overlay={menuadminauthenticationType(row)}
//                 trigger={["click"]}
//               >
//                 <Button className="bg-transparent border-0 p-0 m-0">
//                   {avArray.join(", ")} <DownOutlined />
//                 </Button>
//               </Dropdown>
//             );
//           } else {
//             return <span>NA</span>; // Render "NA" if no items are present
//           }
//         },
//         sortable: true,
//         width: "150px",
//       });
//     }

//     // Add the "Action" column at the last position
//     baseColumns.push({
//       name: "Action",
//       cell: (row) => (
//         <div className="flex justify-center">
//           <Button
//             className="btn rounded-md  border-0 d-flex  fs10  gap-2 mx-auto px-2 text-light bg-dark"
//             onClick={() =>
//               navigate(routeUrl, {
//                 state: {
//                   basicInfo: row,
//                   pcInfoId: row?.pc_Id,
//                   title: title,
//                 },
//               })
//             }
//           >
//             <p className="m-0 p-0 fs-12">Details</p>
//             <i className="fas fa-long-arrow-alt-right ps-4"></i>
//           </Button>
//         </div>
//       ),
//       ignoreRowClick: true,
//       allowoverflow: false,
//       width: "160px",
//     });

//     return baseColumns;
//   }, [currentPage, pageSize, navigate, routeUrl, rowData]);

//   const columnsList = [
//     {
//       name: "Process Name",
//       selector: (row) => row?.name,
//       sortable: true,
//     },
//     {
//       name: "PID",
//       selector: (row) => row?.pid,
//       sortable: true,
//     },
//     {
//       name: "CPU Usage (%)",
//       selector: (row) => row?.cpuPercent || row?.memoryPercent,
//       sortable: true,
//     },
//   ];
//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#D7E3E8",
//       },
//     },
//   };

//   const modalStyle = {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "white",
//     width: "80%",
//     maxWidth: "600px",
//     maxHeight: "80vh", // Set a maximum height for the modal
//     padding: "20px",
//     borderRadius: "8px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     overflowY: "auto", // Enable vertical scrolling
//     zIndex: "1000",
//   };

//   const backdropStyle = {
//     position: "fixed",
//     top: "0",
//     left: "0",
//     right: "0",
//     bottom: "0",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     zIndex: "999", // One less than modal
//   };

//   const exportToPDF = () => {
//     const doc = new jsPDF({ orientation: "landscape" });

//     // Add header and title
//     const orgName = user?.data?.user?.organization || "Organization Name";
//     const exportDateTime = new Date().toLocaleString();

//     doc.setFontSize(12);
//     doc.text(`Organization: ${orgName}`, 14, 15);
//     doc.text(
//       `Exported: ${exportDateTime}`,
//       doc.internal.pageSize.getWidth() - 14,
//       15,
//       { align: "right" }
//     );
//     doc.setFontSize(15);
//     doc.text(`High Usage List`, 14, 22);

//     // Get all keys from the first item in modalData, excluding _id
//     const tableColumn = Object.keys(modalData[0] || {}).filter(
//       (key) => key !== "_id"
//     );
//     const tableRows = modalData.map((item) => {
//       return tableColumn.map((col) => item[col]); // Use the keys to get values
//     });

//     doc.autoTable({
//       startY: 30,
//       head: [tableColumn],
//       body: tableRows,
//     });

//     doc.save(`Usage_List${exportDateTime}.pdf`);
//   };

//   const exportToExcel = () => {
//     const filteredData = modalData.map(({ _id, ...rest }) => rest); // Exclude _id
//     const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Violations");

//     // Create a buffer and save
//     const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
//     const blob = new Blob([buffer], { type: "application/octet-stream" });

//     saveAs(blob, `Usage_List.xlsx`);
//   };

//   return (
//     <>
//       {" "}
//       <DataTable
//         columns={columns}
//         data={rowData || []}
//         pagination
//         customStyles={customStyles}
//         paginationServer
//         paginationTotalRows={totalDocuments}
//         paginationDefaultPage={currentPage}
//         paginationPerPage={pageSize}
//         onChangePage={(page) => {
//           console.log("Page", page);
//           handlePageChange(page);
//         }}
//         onChangeRowsPerPage={(newPageSize) => {
//           console.log("Page Size", newPageSize);
//           handlePageSizeChange(newPageSize);
//         }}
//         noDataComponent={<p className="p-0 my-2 me-auto text-center">No Data Found</p>}
//       />
//       <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Usage List</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {/* DataTable to display API response */}
//           <DataTable
//             columns={columnsList}
//             data={modalData}
//             pagination
//             customStyles={customStyles}
//           />
//         </Modal.Body>
//         <Modal.Footer>
//           <button
//             type="button"
//             className="btn btn-sm btn-primary text-white"
//             onClick={exportToPDF}
//           >
//             <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
//           </button>
//           <button
//             type="button"
//             className="btn btn-sm btn-success text-white"
//             onClick={exportToExcel}
//           >
//             <img src={Excellogo} height={15} width={15} />
//             &nbsp; CSV
//           </button>
//           <Button
//             variant="btn btn-secondary btn-sm"
//             onClick={() => setModalOpen(false)}
//           >
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default CustomTable;
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { toHumanReadable } from "../../../components/common/toHumanReadable";
import { linuxApi } from "../../../api/axiosSet";
import {useSkinMode } from "../../../components/SkinModeContext"

function CustomTable({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
  title,
}) {
  // const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const { skinMode } = useSkinMode(); // Access the skin mode state

  const navigate = useNavigate();
  // const [skin, setSkin] = useState(currentSkin);
  const [modalData, setModalData] = useState(null);
  // const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useSelector((state) => state.authSlice);

  const [modalOpen, setModalOpen] = useState(false); // Modal visibility state
  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  // const switchSkin = (skin) => {
  //   if (skin === "dark") {
  //     const btnWhite = document.getElementsByClassName("btn-white");

  //     for (const btn of btnWhite) {
  //       btn.classList.add("btn-outline-primary");
  //       btn.classList.remove("btn-white");
  //     }
  //   } else {
  //     const btnOutlinePrimary = document.getElementsByClassName(
  //       "btn-outline-primary"
  //     );

  //     for (const btn of btnOutlinePrimary) {
  //       btn.classList.remove("btn-outline-primary");
  //       btn.classList.add("btn-white");
  //     }
  //   }
  // };

  // switchSkin(skin);
  // useEffect(() => {
  //   switchSkin(skin);
  // }, [skin]);

  const handleMenuClick = (e, record) => {

  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuService = (record) => {
    return (
      <Menu>
        {record?.service?.map((detail, index) => {
          const [key, value] = Object.entries(detail)[0];
          return (
            <Menu.Item key={index}>
              {key}: {value}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  const menuadmin = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.notInstalledAvs?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuadminssid = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ssid?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const menuadminauthenticationType = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.authenticationType?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const sharedDirectoriesMenu = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy maxWidth="none" content={`Path: ${dir.path}`}>
              <div>
                {dir.name}
                <br />
                <small>
                  Owner: {dir.permission.owner || "N/A"}, Group:{" "}
                  {dir.permission.group || "N/A"}, Other:{" "}
                  {dir.permission.other || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const avDetails = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy
              content={`Current Version: ${dir.currentAVVersion}, Expected Version: ${dir.expectedAVVersion}`}
            >
              <div>
                {dir.avName}
                <br />
                <small>
                  Current Version: {dir.currentAVVersion || "N/A"}, Expected
                  Version: {dir.expectedAVVersion || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const osDetails = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <Tippy
              content={`Current Version: ${dir.currentOSVersion}, Expected Version: ${dir.expectedOSVersion}`}
            >
              <div>
                {dir.osName}
                <br />
                <small>
                  Current Version: {dir.currentOSVersion || "N/A"}, Expected
                  Version: {dir.expectedOSVersion || "N/A"}
                </small>
              </div>
            </Tippy>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const userAdminpassword = (directories) => {
    return (
      <Menu>
        {directories?.map((dir, index) => (
          <Menu.Item key={index}>
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active mb-0" aria-current="page">
                  {dir.userType}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {dir.userName}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {dir.passwordAge}
                </li>
              </ol>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const [currentPageModal, setCurrentPageModal] = useState(1);
  const [pageSizeModal, setPageSizeModal] = useState(10);
  const [totalDocumentsModal, setTotalDocumentsModal] = useState(0);

  const [currentSelectedPcId, setCurrentSelectedPcId] = useState(null);

  const fetchSoftwareData = async () => {
    try {
      const response = await linuxApi.get(
        `/backendinfo/blacklisted-programs/${currentSelectedPcId}?page=${currentPageModal}&pageSize=${pageSizeModal}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      setModalData(response?.data?.blacklistedPrograms);
      setTotalDocumentsModal(response?.data?.pagination?.totalDocuments);
    }
    catch {
    }
  }

  const fetchServicesData = async () => {
    try {
      const response = await linuxApi.get(
        `/backendinfo/non-whitelisted-services/${currentSelectedPcId}?page=${currentPageModal}&pageSize=${pageSizeModal}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      setModalData(response?.data?.nonWhitelistedServices);
      setTotalDocumentsModal(response?.data?.pagination?.totalDocuments);
    }
    catch {
    }
  }

  const fetchIntegrityData = async () => {
    try {
      const response = await linuxApi.get(
        `/backendinfo/file-integrity-check-failed/${currentSelectedPcId}?page=${currentPageModal}&pageSize=${pageSizeModal}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      setModalData(response?.data?.mismatchedFiles);
      setTotalDocumentsModal(response?.data?.mismatchedFiles.length());
    }
    catch {
    }
  }

  const fetchOSHardenData = async () => {
    try {
      const response = await linuxApi.get(
        `/cisinfo/os-not-hardened/properties-count/${currentSelectedPcId}?page=${currentPageModal}&pageSize=${pageSizeModal}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      setModalData(response?.data?.mismatchedFields);
      setTotalDocumentsModal(response?.data?.mismatchedFields.length());
    }
    catch {
    }
  }

  const handleCountClickedSoftwares = (row) => {
    setCurrentSelectedPcId(row?.pc_Id);
    setModalOpen(true);
  }

  const handleCountClickedServices = (row) => {
    setCurrentSelectedPcId(row?.pc_Id);
    setModalOpen(true);
  }

  const handleCountClickedIntegrity = (row) => {
    setCurrentSelectedPcId(row?.pc_Id);
    setModalOpen(true);
  }
  const handleCountClickedOSHarden = (row) => {
    setCurrentSelectedPcId(row?.pc_Id);
    setModalOpen(true);
  }

  const handleCountClick = (row) => {
    if (row.highCpuUsage) {
      setModalData(row.highCpuUsage);
      setModalOpen(true);
    }
  };

  const handleCountClickRam = (row) => {
    if (row?.ramUsage) {
      setModalData(row?.ramUsage);
      setModalOpen(true);
    }
  };

  const fetchDataByTitle = {
    "Unrecognized Software": fetchSoftwareData,
    "Unknown Services": fetchServicesData,
    "File Integrity Check Failed": fetchIntegrityData,
    "OS Not Hardened": fetchOSHardenData,
  };

  useEffect(() => {
    if (currentSelectedPcId && fetchDataByTitle[title]) {
      fetchDataByTitle[title]();
    }
  }, [pageSizeModal, currentPageModal, currentSelectedPcId, title]);


  const columns = React.useMemo(() => {
    const baseColumns = [
      {
        name: "S. No.",
        selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        width: "90px",
        sortable: true,
      },
      {
        name: "Hostname",
        selector: (row) => row?.hostname,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.hostname}>
            <div>{row.hostname}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "IP Address",
        selector: (row) => row.ipAddress,
        cell: (row) => {
          const ipArray = row.ipAddress || [];
          if (ipArray?.length === 1) {
            return <span>{ipArray[0]}</span>;
          } else if (ipArray?.length > 1) {
            return (
              <Dropdown overlay={menu(row)} trigger={["click"]}>
                <div role="button">
                  <span>{ipArray[0]}</span>
                  &nbsp;
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </Dropdown>
            );
          } else {
            return <span className="ip-span">NA</span>;
          }
        },
      }

    ];

    // Conditionally add the "Admin Accounts Name" column if it's present
    if (rowData?.some((row) => row.adminAccountsName)) {
      baseColumns.splice(4, 0, {
        name: "Admin Accounts",
        selector: (row) =>
          row.adminAccountsName?.length > 0
            ? row.adminAccountsName.join(", ")
            : "NA",
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.highCpuUsageCount)) {
      baseColumns.splice(4, 0, {
        name: "Count",
        selector: (row) => row.highCpuUsageCount,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => handleCountClick(row)}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {row.highCpuUsageCount}
          </span>
        ),
      });
    }

    if (rowData?.some((row) => row.highRamUsageCount)) {
      baseColumns.splice(4, 0, {
        name: "Count",
        selector: (row) => (
          <span
            onClick={() => handleCountClickRam(row)}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {row.highRamUsageCount}
          </span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.currentOSVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.currentOSVersion}>
            <div>{row.currentOSVersion}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.expectedOSVersion)) {
      baseColumns.splice(4, 0, {
        name: "Expected Version",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.expectedOSVersion}>
            <div>{row.expectedOSVersion}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.osName)) {
      baseColumns.splice(4, 0, {
        name: "OS Name",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.osName}>
            <div>{row.osName}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.currentAVVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.currentAVVersion}>
            <div>{row.currentAVVersion}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.expectedAVVersion)) {
      baseColumns.splice(4, 0, {
        name: "Expected Version",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.expectedAVVersion}>
            <div>{row.expectedAVVersion}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }
    if (rowData?.some((row) => row.avName)) {
      baseColumns.splice(4, 0, {
        name: "AV Name",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.avName}>
            <div>{row.avName}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.service)) {
      baseColumns.splice(4, 0, {
        name: "Services",
        selector: (row) => row.service,
        cell: (row) => {
          const serviceArray = row.service || [];
          if (serviceArray?.length === 1) {
            const [key, value] = Object.entries(serviceArray[0])[0];
            return (
              <span>
                {key}: {value}
              </span>
            );
          } else if (serviceArray?.length > 1) {
            return (
              <Dropdown overlay={menuService(row)} trigger={["click"]}>
                <div role="button">
                  {Object.entries(serviceArray[0])[0].join(": ")}&nbsp;
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no services are present
          }
        },
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.antivirusVersion)) {
      baseColumns.splice(4, 0, {
        name: "Current Version",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.antivirusVersion}>
            <div>{row.antivirusVersion}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.antivirusName)) {
      baseColumns.splice(4, 0, {
        name: "AV Name",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.antivirusName}>
            <div>{row.antivirusName}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (
      rowData?.some(
        (row) => row.hardeningIssues && typeof row.hardeningIssues === "object"
      )
    ) {
      baseColumns.splice(4, 0, {
        name: "Hardening Issues",
        selector: (row) => {
          const hardeningIssues = row.hardeningIssues;
          if (hardeningIssues) {
            return Object.entries(hardeningIssues)
              .map(([key, value]) => {
                return `${toHumanReadable(key).replace(/Is /g, "")}: ${value}`;
              })
              .join(", ");
          }
          return "No issues"; // Default message if no hardening issues
        },
        sortable: false, // Change to true if you want sorting capability
        wrap: true, // Allow text wrapping if supported by your table component
      });
    }


    // Conditionally add the "Shared Directories" column if it's present
    if (rowData?.some((row) => row.sharedDirectories)) {
      baseColumns.push({
        name: "Shared Directories",
        selector: (row) => row.sharedDirectories,
        cell: (row) => (
          <Dropdown
            overlay={sharedDirectoriesMenu(row.sharedDirectories)}
            trigger={["click"]}
          >
            <Button
              className="bg-transparent border-0 p-0 m-0 d-flex align-items-center"
              style={{ width: "100%", textAlign: "left" }}
            >
              <span>
                {row.sharedDirectories?.length > 0
                  ? row.sharedDirectories.map((dir) => dir.name).join(", ")
                  : "N/A"}
                &nbsp;
                <DownOutlined />
              </span>
            </Button>
          </Dropdown>
        ),
        sortable: true,
      });
    }


    if (rowData?.some((row) => row.avDetails)) {
      baseColumns.push({
        name: "Av Details",
        selector: (row) => row.avDetails,
        cell: (row) => (
          <div overlay={avDetails(row.avDetails)} trigger={["click"]}>
            <Button className="bg-transparent border-0 p-0 m-0">
              {/* {row.avDetails?.length > 0
                ? row.avDetails.map((dir) => dir.avName).join(", ")
                : "N/A"}{" "} */}
              {row.avDetails?.length > 0
                ? row.avDetails
                  .map(
                    (dir) =>
                      `${dir.avName}: Current Version: ${dir.currentAVVersion || "N/A"
                      }, Expected Version: ${dir.expectedAVVersion || "N/A"}`
                  )
                  .join(", ")
                : "N/A"}

              <DownOutlined />
            </Button>
          </div>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.osDetails)) {
      baseColumns.push({
        name: "Os Details",
        selector: (row) => row.osDetails,
        cell: (row) => (
          <Dropdown overlay={osDetails(row.osDetails)} trigger={["click"]}>
            <Button className="bg-transparent border-0 p-0 m-0">
              {row.osDetails?.length > 0
                ? row.osDetails.map((dir) => dir.osName).join(", ")
                : "N/A"}{" "}
              <DownOutlined />
            </Button>
          </Dropdown>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.usersPasswordAge)) {
      baseColumns.push({
        name: "Users Password Age",
        selector: (row) => row.usersPasswordAge,
        cell: (row) => (
          <span>
            {row.usersPasswordAge?.length > 0
              ? row.usersPasswordAge.map((dir, index) => (
                <span key={dir.userName}>
                  {`${dir.userName}/${dir.userType}/${dir.passwordAge}`}
                  {index < row.usersPasswordAge?.length - 1 && ", "}
                </span>
              ))
              : "N/A"}
          </span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.notInstalledAvs)) {
      baseColumns.push({
        name: "Not Installed",
        selector: (row) => row.notInstalledAvs?.join(", ") || "NA",
        cell: (row) => {
          const avArray = Array.isArray(row.notInstalledAvs)
            ? row.notInstalledAvs
            : [];

          if (avArray?.length === 1) {
            return (
              <Tippy maxWidth="none" content={avArray[0]}>
                <span>{avArray[0]}</span>
              </Tippy>
            );
          } else if (avArray?.length > 1) {
            const content = avArray.join(", ");
            return (
              <Tippy maxWidth="none" content={content}>
                <span>{content}</span>
              </Tippy>
            );
          } else {
            return <span>NA</span>;
          }
        },
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.ssid)) {
      baseColumns.push({
        name: "SSID",
        selector: (row) => row.ssid,
        cell: (row) => {
          const avArray = row.ssid || []; // Ensure it's an array, even if null/undefined

          if (avArray?.length === 1) {
            return <span>{avArray[0]}</span>; // Render the single item directly
          } else if (avArray?.length > 1) {
            return (
              <Dropdown overlay={menuadminssid(row)} trigger={["click"]}>
                <Button className="bg-transparent border-0 p-0 m-0">
                  {avArray.join(", ")} <i className="fa-solid fa-angle-down"></i>
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no items are present
          }
        },
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.authenticationType)) {
      baseColumns.push({
        name: "Auth Type",
        selector: (row) => row.authenticationType,
        cell: (row) => {
          const avArray = row.authenticationType || []; // Ensure it's an array, even if null/undefined

          if (avArray?.length === 1) {
            return <span>{avArray[0]}</span>; // Render the single item directly
          } else if (avArray?.length > 1) {
            return (
              <Dropdown
                overlay={menuadminauthenticationType(row)}
                trigger={["click"]}
              >
                <Button className="bg-transparent border-0 p-0 m-0">
                  {avArray.join(", ")} <DownOutlined />
                </Button>
              </Dropdown>
            );
          } else {
            return <span>NA</span>; // Render "NA" if no items are present
          }
        },
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.nonWhitelistedCount)) {
      baseColumns.push({
        name: "Count",
        selector: (row) => row.nonWhitelistedCount,
        cell: (row) => (
          <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleCountClickedSoftwares(row)}>
            {row.nonWhitelistedCount}
          </span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.nonWhitelistedServiceCount)) {
      baseColumns.push({
        name: "Count",
        selector: (row) => row.nonWhitelistedServiceCount,
        cell: (row) => (
          <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleCountClickedServices(row)}>
            {row.nonWhitelistedServiceCount}
          </span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.mismatchCount)) {
      baseColumns.push({
        name: "Count",
        selector: (row) => row.mismatchCount,
        cell: (row) => (
          row?.mismatchCount !== 0 ?
            <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => handleCountClickedIntegrity(row)}>
              {row.mismatchCount}
            </span> :
            <span>{row.mismatchCount}</span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.OsNotHardenedmismatchCount)) {
      baseColumns.push({
        name: "Count",
        selector: (row) => row.OsNotHardenedmismatchCount,
        cell: (row) => (
          row?.OsNotHardenedmismatchCount !== 0 ?
            <span style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
              onClick={() => handleCountClickedOSHarden(row)}>
              {row.OsNotHardenedmismatchCount}
            </span> :
            <span>{row?.OsNotHardenedmismatchCount || 0}</span>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row?.edrName)) {
      baseColumns.push({
        name: "EDR Name",
        selector: (row) => row?.edrName,
        cell: (row) => (
          <Tippy maxWidth="none" content={row?.edrName || "N/A"}>
            <span>{row?.edrName || "N/A"}</span>
          </Tippy>
        ),
      });
    }

    if (rowData?.some((row) => row?.zenWorksName)) {
      baseColumns.push({
        name: "ZenWork Name",
        selector: (row) => row?.zenWorksName,
        cell: (row) => (
          <Tippy maxWidth="none" content={row?.zenWorksName || "N/A"}>
            <span>{row?.zenWorksName || "N/A"}</span>
          </Tippy>
        ),
      });
    }

    if (rowData?.some((row) => row?.certificateName)) {
      baseColumns.push({
        name: "Certificate Name",
        selector: (row) => row?.certificateName,
        cell: (row) => (
          <Tippy maxWidth="none" content={row?.certificateName || "N/A"}>
            <span>{row?.certificateName || "N/A"}</span>
          </Tippy>
        ),
      });
    }

    if (rowData?.some((row) => row?.firewallService)) {
      baseColumns.push({
        name: "Service Name",
        selector: (row) => row?.firewallService.join(", ") || "N/A",
        cell: (row) => (
          <Tippy maxWidth="none" content={row?.firewallService.join(", ") || "N/A"}>
            <span>{row?.firewallService.join(", ") || "N/A"}</span>
          </Tippy>
        ),
      });
    }

    if (rowData?.some((row) => row.fipsCrypto)) {
      baseColumns.push({
        name: "FIPS Enabled",
        selector: (row) => row.fipsCrypto,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.fipsCrypto}>
            <span>{row.fipsCrypto}</span>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.rdpStatus)) {
      baseColumns.push({
        name: "RDP Status",
        selector: (row) => row.rdpStatus,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.rdpStatus}>
            <span>{row.rdpStatus}</span>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.cdDvdStatus)) {
      baseColumns.push({
        name: "CD/DVD Status",
        selector: (row) => row.cdDvdStatus,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.cdDvdStatus}>
            <span>{row.cdDvdStatus}</span>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.biosBatteryStatus)) {
      baseColumns.push({
        name: "Bios Battery Status",
        selector: (row) => row.biosBatteryStatus,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.biosBatteryStatus || "N/A"}>
            <span>{row.biosBatteryStatus || "N/A"}</span>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.bluetoothStatus)) {
      baseColumns.push({
        name: "Bluetooth Status",
        selector: (row) => row.bluetoothStatus,
        cell: (row) => (
          <Tippy maxWidth="none" content={row.bluetoothStatus || "N/A"}>
            <span>{row.bluetoothStatus || "N/A"}</span>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.updatedAt)) {
      baseColumns.splice(7, 0, {
        name: "Timestamp",
        selector: (row) => row?.updatedAt,
        cell: (row) => (
          <Tippy maxWidth="none" content={formatTimestamp(row.updatedAt)}>
            <div>{formatTimestamp(row.updatedAt)}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }

    if (rowData?.some((row) => row.serverTimestamp)) {
      baseColumns.splice(7, 0, {
        name: "Timestamp",
        selector: (row) => (
          <Tippy maxWidth="none" content={formatTimestamp(row.serverTimestamp)}>
            <div>{formatTimestamp(row.serverTimestamp) || "NA"}</div>
          </Tippy>
        ),
        sortable: true,
      });
    }

    // Add the "Action" column at the last position
    baseColumns.push({
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center items-center">
          <Button
            className="btn rounded-md border-0 d-flex fs10 gap-3 mx-auto px-2 text-light bg-dark text-center justify-center align-items-center"
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.pc_Id,
                  title: title,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">Details</p>
            <i className="fas fa-long-arrow-alt-right"></i>
          </Button>
        </div>
      ),
      ignoreRowClick: true,
      allowoverflow: false,
    });

    return baseColumns;
  }, [currentPage, pageSize, navigate, routeUrl, rowData]);

  function convertDateFormat(dateStr) {
    const regex = new RegExp(
      `^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)\\s(\\d{1,2})\\s` +
      `(January|February|March|April|May|June|July|August|September|October|November|December)\\s` +
      `(\\d{4})\\s(0?[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])\\s(AM|PM)\\s` +
      `(IST|GMT|UTC|[A-Z]{3})$`
    );

    const monthMap = {
      "January": "01", "February": "02", "March": "03", "April": "04", "May": "05", "June": "06",
      "July": "07", "August": "08", "September": "09", "October": "10", "November": "11", "December": "12"
    };

    const match = dateStr.match(regex);
    if (!match) return dateStr;

    const [, , day, month, year, hour, minute, second, period] = match;

    // Convert hour to 24-hour format
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) hour24 += 12;
    if (period === "AM" && hour24 === 12) hour24 = 0;

    const pad = (num) => num.toString().padStart(2, '0');

    return `${pad(day)}-${monthMap[month]}-${year} ${pad(hour24)}:${pad(minute)}:${pad(second)}`;
  }

  const columnListSoftware = [
    {
      name: "Software Name",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.name}>
          <span>{row?.name}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Version",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.version}>
          <span>{row?.version}</span>
        </Tippy>
      ),
    },
    {
      name: "Install Date",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.date}>
          <span>{convertDateFormat(row?.date) || row?.date}</span>
        </Tippy>
      ),
      sortable: true,
    },
  ]

  const columnListServices = [
    {
      name: "Service Name",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.displayName}>
          <span>{row?.displayName}</span>
        </Tippy>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.status}>
          <span>{row?.status}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.description}>
          <span>{row?.description}</span>
        </Tippy>
      ),
    },
  ]

  const columnListOSHarden = [
    {
      name: "Key Name",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.field}>
          <span>{row?.field}</span>
        </Tippy>
      ),
      width: "400px",
    },
    {
      name: "Actual Value",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.actualValue || "NA"}>
          <span>{row?.actualValue || "NA"}</span>
        </Tippy>
      ),
    },
    {
      name: "Expected Value",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.expectedValue || "NA"}>
          <span>{row?.expectedValue || "NA"}</span>
        </Tippy>
      ),
    },
  ]

  const columnsList = [
    {
      name: "Process Name",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.name}>
          <span>{row?.name}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "PID",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.pid}>
          <span>{row?.pid}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: title === "High CPU Usage" ? "CPU Usage (%)" : "RAM Usage (%)",
      selector: (row) => row?.cpuPercent || row?.memoryPercent,
      sortable: true,
    },
  ];

  const columnListIntegrity = [
    {
      name: "File Path",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.filePath}>
          <span>{row?.filePath}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Actual Hash",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.actualHash}>
          <span>{row?.actualHash}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Expected Hash",
      selector: (row) => (
        <Tippy maxWidth="none" content={row?.expectedHash}>
          <span>{row?.expectedHash}</span>
        </Tippy>
      ),
      sortable: true,
    }
  ]

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "80vh", // Set a maximum height for the modal
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflowY: "auto", // Enable vertical scrolling
    zIndex: "1000",
  };

  const backdropStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "999", // One less than modal
  };

  const exportToCSV = () => {
    if (modalData && modalData?.length > 0) {
      // Determine which columns have data
      const hasData = (key) => modalData?.some((item) => item[key]);
      const headers = [
        { label: title === "Unrecognized Software" ? "Software Name" : "Process Name", key: "name" },
        { label: "Service Name", key: "displayName" },
        { label: "Process ID", key: "pid" },
        { label: "Ram Usage (%)", key: "memoryPercent" },
        { label: "CPU Usage (%)", key: "cpuPercent" },
        { label: "Version", key: "version" },
        { label: "Install Date", key: "date" },
        { label: "Status", key: "status" },
        { label: "Description", key: "description" },
        { label: "File Path", key: "filePath" },
        { label: "Actual Hash", key: "actualHash" },
        { label: "Expected Hash", key: "expectedHash" },
        { label: "Key Name", key: "field" },
        { label: "Actual Value", key: "actualValue" },
        { label: "Expected Value", key: "expectedValue" },
      ].filter((header) => hasData(header.key));

      const csvData = [
        headers.map((header) => header.label),
        ...modalData.map((item) =>
          headers.map((header) => item[header.key])
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });
      let dataname = title === "Unknown Services" ? "UnrecognizedServicesList" :
        title === "Unrecognized Software" ? "UnrecognizedSoftwaresList" :
          title === "High CPU Usage" ? "CPUUsageList" : title === "OS Not Hardened" ? "OSHardeningList" : "RAMUsageList";
      saveAs(blob, `${dataname}.csv`);
    }
  };

  function exportToPDF() {
    if (modalData && modalData?.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      let dataname = title === "Unknown Services" ? "UnrecognizedServicesList" :
        title === "Unrecognized Software" ? "UnrecognizedSoftwaresList" :
          title === "High CPU Usage" ? "CPUUsageList" : title === "OS Not Hardened" ? "OSHardeningList" : "RAMUsageList";
      doc.text(`${dataname}`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => modalData?.some((item) => item[key]);
      const headers = [
        { label: title === "Unrecognized Software" ? "Software Name" : "Process Name", key: "name" },
        { label: "Service Name", key: "displayName" },
        { label: "Process ID", key: "pid" },
        { label: "Ram Usage (%)", key: "memoryPercent" },
        { label: "CPU Usage (%)", key: "cpuPercent" },
        { label: "Version", key: "version" },
        { label: "Install Date", key: "date" },
        { label: "Status", key: "status" },
        { label: "Description", key: "description" },
        { label: "File Path", key: "filePath" },
        { label: "Actual Hash", key: "actualHash" },
        { label: "Expected Hash", key: "expectedHash" },
        { label: "Key Name", key: "field" },
        { label: "Actual Value", key: "actualValue" },
        { label: "Expected Value", key: "expectedValue" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      modalData.forEach((item) => {
        const rowData = headers.map((header) => item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for all cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
        // Conditional column width using columnStyles
        columnStyles: {
          [tableColumn.indexOf('Hardening Issues')]: {
            cellWidth: 100,
            halign: "left",
          },
        },
      });
      doc.save(`${dataname}.pdf`);
    }
  }

  const colseModal = () => {
    setModalOpen(false);
    setPageSizeModal(10);
    setCurrentPageModal(1);
    setTotalDocumentsModal(0);
  }

  const setColumnList = () => {
    if (title === "Unrecognized Software") {
      return columnListSoftware;
    } else if (title === "Unknown Services") {
      return columnListServices;
    } else if (title === "File Integrity Check Failed") {
      return columnListIntegrity;
    } else if (title === "OS Not Hardened") {
      return columnListOSHarden;
    } else {
      return columnsList;
    }
  }

  const columnsName = React.useMemo(() => setColumnList(), [title]);

  return (
    <>
      <DataTable
        columns={columns}
        data={rowData || []}
        pagination
        customStyles={currentStyles}
        paginationServer
        paginationTotalRows={totalDocuments}
        paginationDefaultPage={currentPage}
        paginationPerPage={pageSize}
        onChangePage={(page) => {
          handlePageChange(page);
        }}
        onChangeRowsPerPage={(newPageSize) => {
          handlePageSizeChange(newPageSize);
        }}
        noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
      />
      <Modal show={modalOpen} onHide={colseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title === "Unrecognized Software" ? "Softwares List" : title === "Unknown Services" ? "Services List" :
            title === "OS Not Hardened" ? "Hardening List" : "Usage List"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* DataTable to display API response */}
          <DataTable
            columns={columnsName}
            data={modalData || []}
            pagination
            customStyles={customStyles}
            paginationServer={!(title === "File Integrity Check Failed" || title === "OS Not Hardened")}
            paginationTotalRows={totalDocumentsModal}
            paginationDefaultPage={currentPageModal}
            paginationPerPage={pageSizeModal}
            onChangePage={(page) => {
              if (title === "File Integrity Check Failed" || title === "OS Not Hardened") {
                return;
              }
              if (page !== currentPageModal) setCurrentPageModal(page);
            }}
            onChangeRowsPerPage={(newPageSize) => {
              if (title === "File Integrity Check Failed" || title === "OS Not Hardened") {
                return;
              }
              if (newPageSize !== pageSizeModal) setPageSizeModal(newPageSize);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-primary text-white"
            onClick={exportToPDF}
          >
            <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
          </button>
          <button
            type="button"
            className="btn btn-sm btn-success text-white"
            onClick={exportToCSV}
          >
            <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
          </button>
          <Button
            variant="btn btn-secondary btn-sm"
            onClick={colseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomTable;
