import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import DataTable from "react-data-table-component";
import Header from "../../../layouts/Header";
import Tippy from "@tippyjs/react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import CustomToaster from "../../../components/common/CustomToaster";
import { hideToaster, showToaster } from "../../../feature/slices/toastSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ListOrg() {
  const navigate = useNavigate();
  const [filterTypeEnterprise, setFilterTypeEnterprise] = useState(false);
  const [filterTypePremium, setFilterTypePremium] = useState(false);
  const [filterTypeStandard, setFilterTypeStandard] = useState(false);
  const [filterStatusActive, setFilterStatusActive] = useState(false);
  const [filterStatusInactive, setFilterStatusInactive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [orgName, setOrgName] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [columnData, setColumnData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
console.log(currentPage,'currentPage')
  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  useEffect(() => {
    fetchData(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const fetchData = async (page, limit) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/get/all-organizations`
      );
      if (response.data.success) {
        setTotalCount(response.data.totalOrganizations);
        const formattedData = response.data.organizations.map((org, index) => ({
          id: org._id,
          name: org.organizationName,
          regDate: org.createdAt,
          licenses: org.deviceCount,
          type: org.type,
          status: org.status,
        }));
        setColumnData(formattedData);
      } else {
        setFeedback("Failed to fetch organizations.");
      }
    } catch (error) {
      console.error("Error:", error);
      setFeedback("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      name: "S. No.",
      selector: (_, index) => index + 1,
      width: "110px",
    },
    {
      name: "Name",
      selector: (row) => (
        <Tippy content={row.name}>
          <div>{row.name}</div>
        </Tippy>
      ),
      width: "250px",
      sortable: true,
    },
    {
      name: "Registered On",
      selector: (row) => formatTimestamp(row.regDate),
      sortable: true,
    },
    {
      name: "Licenses",
      selector: (row) => row.licenses,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span style={getStatusStyle(row.status)}>{row.status}</span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/org-detail/${row.id}`)}
        >
          <i className="fa fa-arrow-right"></i>
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const filteredData = columnData.filter((row) => {
    let matchType = true;
    let matchStatus = true;
    let matchSearch = true;

    // Filtering by type
    if (filterTypeEnterprise && row.type !== "Enterprise") {
      matchType = false;
    }
    if (filterTypePremium && row.type !== "Premium") {
      matchType = false;
    }
    if (filterTypeStandard && row.type !== "Standard") {
      matchType = false;
    }

    // Filtering by status
    if (filterStatusActive && row.status !== "Active") {
      matchStatus = false;
    }
    if (filterStatusInactive && row.status !== "Inactive") {
      matchStatus = false;
    }

    // Searching by name, registration date, or licenses
    if (
      !(
        row.name.toLowerCase().includes(searchText.toLowerCase()) ||
        row.regDate.toLowerCase().includes(searchText.toLowerCase()) ||
        row.licenses.toString().includes(searchText.toLowerCase())
      )
    ) {
      matchSearch = false;
    }

    return matchType && matchStatus && matchSearch;
  });

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const data = {
      organizationName: orgName,
      superAdminName: adminName,
      superAdminEmail: adminEmail,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/register-organization`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setOrgName('')
      setAdminName("")
 setAdminEmail("")
      if (response.status === 201) {
        // setFeedback("Organization added successfully!");
        // dispatch(
        //   showToaster({
        //     title: "Organization",
        //     message: "added successfully!",
        //     color: "success",
        //   })
        // );
        // setTimeout(() => {
        //   dispatch(hideToaster());
        // }, 2000);
      
        fetchData(currentPage, rowsPerPage); // Refresh data after adding
        document.getElementById("closeModalButton").click(); // Close the modal
      } else {
        // setFeedback("Failed to add organization.");

    
      }
    } catch (error) {
      // dispatch(
      //   showToaster({
      //     title: "Error",
      //     message: error?.response?.data?.message,
      //     color: "danger",
      //   })
      // );
      // setTimeout(() => {
      //   dispatch(hideToaster());
      // }, 2000);
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // setFeedback(error?.response?.data?.message);
    }
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc", // Adjust this value as needed
      },
    },
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {/* Filter controls */}
        {/* <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/">Admin</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to="/">Organizations</Link>
                </li>
              </ol> */}

              <ol className="breadcrumb fs-sm mb-1">
  <li className="breadcrumb-item">
    <NavLink exact to="/" activeClassName="active">Admin</NavLink>
  </li>
  <li className="breadcrumb-item">
    <NavLink exact to="/organizations" activeClassName="active">Organizations</NavLink>
  </li>
</ol>
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row">
        
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column ">
            <p className="fs-18 fw-bolder p-0 m-0 ">All Organizations</p>
            <p className="fs-14  p-0 m-0 ">{totalCount ? totalCount : ""}</p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="fa-solid fa-plus me-2" />
              Add Organization
            </button>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content  p-1">
                <div className="modal-header">
                  <h1
                    className="modal-title fs-5 mx-auto SubTitleCustomStyle"
                    id="exampleModalLabel"
                  >
                    Add Organization
                  </h1>
                </div>
                <div className="modal-body">
                  {feedback && <div className="mt-3">{feedback}</div>}

                  <div
                    className="toast align-items-center text-bg-primary border-0"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div className="d-flex">
                      <div className="toast-body">
                        Hello, world! This is a toast message.
                      </div>
                      <button
                        type="button"
                        className="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>

                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="orgName"
                        className="form-label"
                        style={{ color: "black" }}
                      >
                        Organization name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter organization name"
                        id="orgName"
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="adminName"
                        className="form-label"
                        style={{ color: "black" }}
                      >
                        Super admin name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter super admin name"
                        id="adminName"
                        value={adminName}
                        onChange={(e) => setAdminName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="adminEmail"
                        className="form-label"
                        style={{ color: "black" }}
                      >
                        Super admin email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter super admin email"
                        id="adminEmail"
                        value={adminEmail}
                        onChange={(e) => setAdminEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        id="closeModalButton"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomToaster />
        {/* Data table */}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            // paginationServer
            // paginationTotalRows={totalCount}
            // paginationPerPage={rowsPerPage}
            // onChangePage={(page) => {
            //   setCurrentPage(page);
            // }}
            // onChangeRowsPerPage={(rowsPerPage) => {
            //   setRowsPerPage(rowsPerPage);
            //   // setCurrentPage(4); // Reset to first page on rows per page change
            // }}
            customStyles={customStyles}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default ListOrg;
