// import React, { useEffect, useState } from "react";

// import { Button, Card, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";

// import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
// import { Link, useLocation } from "react-router-dom";
// import CustomTable from "../../components/common/Table/CustomTable";
// import { androidRowData } from "../../components/common/TableData/ServerityData";

// import axios from "axios";
// import PageLoader from "../../components/common/Loader/PageLoader";

// import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
// import ChromeClients from "../../components/common/clients/chromeClients/ChromeClients";
// import { useSelector } from "react-redux";

// function ChromeServerityData() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const { state } = useLocation();

//   console.log(state, "LOCATION DATA");

//   const { androidSummary } = useFetchAndroidSummary();
//   const [skin, setSkin] = useState(currentSkin);
//   const [apiResponseData, setApiResponseData] = useState(null);
//   const [columnData, setColumnData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   const [formData, setFormData] = useState({
//     hostname: "",
//     serialNumber: "",
//     ipAddress: "",
//     clientScore: "",
//   });
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   console.log(user, "USERMAC");
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_ANDROID_URL}/${state?.callUrl}/${user?.data?.user?.userId}&osType=CHROME_OS`,
//           {
//             params: formData, // Use formData as filter criteria
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );
//         setApiResponseData();
//         console.log(response?.data, "RESPONSEANROID");
//         if (response?.data?.length > 0) {
//           const calculateData = response.data?.map((v) => {
//             console.log(v, "IN THE ANDOIRD DATA");
//             return {
//               hostname: v.androidInfo?.hardware[0]?.brandName || "NA",
//               serialNumber: v.androidInfo?.hardware[0]?.model || "NA",
//               ipAddress: v?.latestDeviceIp || "NA",
//               clientScore: "",
//               action: "yourActionValue",
//               pc_Id: v?.androidId || "NA",
//             };
//           });
//           setColumnData(calculateData);
//         } else {
//           setColumnData([]);
//         }
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [state?.callUrl]);

//   console.log(columnData, "COLUMNDATAA");
//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//             <Link to="#">
//               <img src={chromeIcon} alt="Window-logo" width={55} height={55} />
//             </Link>
//             <p className="mx-2 fs-18 m-0 p-0">|</p>
//             <p className="fs-18 fw-bolder p-0 m-0 ">Chrome DashBoard</p>
//           </span>
//           <div className="d-flex gap-2 mt-3 mt-md-0">
//             <Button
//               variant=""
//               className="btn-white d-flex align-items-center gap-2"
//             >
//               <i className="ri-share-line fs-18 lh-1"></i>Share
//             </Button>
//             <Button
//               variant=""
//               className="btn-white d-flex align-items-center gap-2"
//             >
//               <i className="ri-printer-line fs-18 lh-1"></i>Print
//             </Button>
//             <Button
//               variant="primary"
//               className="d-flex align-items-center gap-2"
//             >
//               <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
//               <span className="d-none d-sm-inline"> Report</span>
//             </Button>
//           </div>
//         </div>

//         <ChromeClients data={androidSummary} />
//         <div className=" card-one my-4 p-4">
//           <Row>
//             <Col xl={12} className="my-4">
//               <p className="fs-20 text-dark fw-bolder p-0 m-0">
//                 {state?.titleState || "Alert Title"}
//               </p>
//               <p className="text-danger p-0 m-0 fw-semibold">
//                 {state?.alertNumber || 0} Clients
//               </p>
//             </Col>
//             <Col xl={12}>
//               {!loading ? (
//                 <CustomTable
//                   rowData={androidRowData}
//                   columnData={columnData}
//                   routeUrl={"/chrome-client-Details"}
//                 />
//               ) : (
//                 <PageLoader />
//               )}
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default ChromeServerityData;

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import CustomTableAndroid from "../../components/common/Table/CustomTableAndroid";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { useSkinMode } from "../../components/SkinModeContext";

import DataTable from "react-data-table-component";
import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
function ChromeServerityData() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();
  const location = useLocation();
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };
  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_ANDROID_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}&osType=CHROME_OS`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        console.log(response?.data, "chromeOS ............");
        setTotalDocuments(response?.data?.pagination?.totalDocuments);
        if (isMounted) {
          console.log(response, "IN THE API RESPONSE");

          if (response?.data?.data.length > 0) {
            console.log(response?.data?.data, "LOGRESPONSE");
            const calculateData = response?.data?.data?.map((v) => {
              return {
                id: v?._id || "NA",
                androidId: v?.androidId || "NA",
                androidVersion: v?.androidVersion || "NA",
                brandName: v?.brandName || "NA",
                chromeOSVersion: v?.chromeOSVersion || "NA",
                deviceIp: v?.deviceIp || "NA",
              };
            });
            setColumnData(calculateData);
          } else {
            setColumnData([]);
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Unauthorized error occurred");
            } else if (error.response.status === 504) {
              console.log("Gateway Timeout error occurred");
            }
          } else {
            console.error("An error occurred:", error.message);
          }
          setError(error);
          console.log(error, "IN THE ERROR");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, state, pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);

      const headers = [
        { label: "Android Version", key: "androidVersion" },
        { label: "Brand Name", key: "brandName" },
        { label: "IP Address", key: "deviceIp" },
        { label: "ChromeOS Version", key: "chromeOSVersion" },
        // { label: "Score", key: "clientScore" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" }, // New field
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SS Id", key: "ssid" },
        { label: "Authentication Type", key: "authenticationType" },
        { label: "Authentication Status", key: "authenticationStatus" },
        { label: "OS Name", key: "osName" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Antivirus", key: "antivirusName" },
        { label: "Antivirus Version", key: "antivirusVersion" },
        { label: "service", key: "service" },
        { label: "Timestamp", key: "serverTimestamp" },
        { label: "High Cpu Usage Count", key: "highCpuUsageCount" },
        { label: "High Ram Usage Count", key: "highRamUsageCount" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            // Extracting the key for dynamic property access
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "updatedAt"
              ? formatTimestamp(item[header.key])
              : header.key === "usersPasswordAge"
              ? item[header.key]
                  .map(
                    (user) =>
                      `${user.userType} :${user.userName}: ${user.passwordAge}`
                  )
                  .join("; ")
              : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "avDetails"
              ? formatAvDetails(item[header.key])
              : header.key === "service"
              ? formatOsDetails(item[header.key])
              : item[header.key]
          )
        ),
      ];

      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`${state?.titleState}`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => columnData.some((item) => item[key]);
      const headers = [
        { label: "Android Version", key: "androidVersion" },
        { label: "Brand Name", key: "brandName" },
        { label: "IP Address", key: "deviceIp" },
        { label: "ChromeOS Version", key: "chromeOSVersion" },
        // { label: "Score", key: "clientScore" },
        { label: "Timestamp", key: "updatedAt" },
        { label: "Shared Directories", key: "sharedDirectories" },
        { label: "Users PasswordAge", key: "usersPasswordAge" },
        { label: "Not Installed", key: "notInstalledAvs" },
        { label: "Av Details", key: "avDetails" },
        { label: "Os Details", key: "osDetails" },
        { label: "SS Id", key: "ssid" },
        { label: "Authentication Type", key: "authenticationType" },
        { label: "OS Name", key: "osName" },
        { label: "Current Version", key: "currentOSVersion" },
        { label: "Expected Version", key: "expectedOSVersion" },
        { label: "AV Name", key: "avName" },
        { label: "Current Version", key: "currentAVVersion" },
        { label: "Expected Version", key: "expectedAVVersion" },
        { label: "Antivirus", key: "antivirusName" },
        { label: "Antivirus Version", key: "antivirusVersion" },
        { label: "service", key: "service" },
        { label: "Timestamp", key: "serverTimestamp" },
        { label: "High Cpu Usage Count", key: "highCpuUsageCount" },
        { label: "High Ram Usage Count", key: "highRamUsageCount" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
            ? formatSharedDirectories(item[header.key])
            : header.key === "usersPasswordAge"
            ? item[header.key]
                .map(
                  (user) =>
                    `${user.userType} :${user.userName}: ${user.passwordAge}`
                )
                .join("; ")
            : header.key === "avDetails"
            ? formatAvDetails(item[header.key])
            : header.key === "service"
            ? formatOsDetails(item[header.key])
            : Array.isArray(item[header.key])
            ? item[header.key].join(", ")
            : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`${state?.titleState}_Report.pdf`);
    }
  }

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div
        className="main main-app p-3 p-lg-4"
        style={mainStyle}
      >
        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            {location.pathname === "/" ? (
              <span className="active">{`Dashboard`}</span>
            ) : (
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/chrome-dash" ? (
              <span className="active">{`Chrome Dashboard`}</span>
            ) : (
              <NavLink exact to="/chrome-dash">
                Chrome Dashboard
              </NavLink>
            )}
          </li>
          <li className="breadcrumb-item">
            {location.pathname === "/chrome-serverity" ? (
              <span className="active">{state?.titleState}</span>
            ) : (
              <NavLink exact to="/chrome-serverity">
                {state?.titleState}
              </NavLink>
            )}
          </li>
        </ol>
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link
              to="/linux-dash"
              className="shadow"
              style={{ borderRadius: "25px" }}
            >
              <img src={chromeIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Chrome DashBoard</p>
          </span>
          <div className="d-flex justify-content-center align-items-center">
          <button
              type="button"
              className="btn btn-sm btn-primary text-white"
              onClick={() => navigate(-1)}
            
            >
              <i
                className="ri-arrow-left-line fs-18 lh-1 mr-2"
                style={{ verticalAlign: "middle", marginRight: "3px" }}
              ></i> Back
            </button>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row allCardEffect shadow">
          <div class="col-xl">
            <p class="fs-18 text-dark fw-bolder p-0 m-0">
              {state?.titleState || "Alert Title"}
            </p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {" "}
              {totalDocuments || 0} Endpoints{" "}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={exportToPDF}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={exportToCSV}
            >
              {/* <img src={Excellogo} height={20} width={15} /> */}
              <i className="fa-solid fa-file-excel"></i>
              &nbsp; CSV
            </button>
          </div>
        </div>

        {/* <Col xl={4}>
              <p className="fs-20 text-dark fw-bolder p-0 m-0">
                {state?.titleState || "Alert Title"}
              </p>
              <p className="text-danger p-0 m-0 fw-semibold">
                {totalDocuments || 0} Clients
              </p>
            </Col> */}

        <div className="card allCardEffect shadow">
          {!loading ? (
            <CustomTableAndroid
              rowData={columnData}
              columnData={columnData}
              titleState={state?.titleState}
              routeUrl={"/chrome-client-Details"}
              currentPage={pageNumber}
              pageSize={pageSize}
              totalDocuments={totalDocuments}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChromeServerityData;
