// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";

// const useFetchChromeSummary = () => {
//   const [loading, setLoading] = useState(false);
//   const [chromeSummary, setChromeSummary] = useState({
//     totalClients: 0,
//     totalSafeClients: 0,
//     totalUnsafeClients: 0,
//     totalUnresponsivelients: 0,
//   });
//   const [chromeAlertSummary, setChromeAlertSummary] = useState({
//     oldSecurityPatch: 0,
//     seLinuxStatus: 0,
//     deviceRootedJailbreaked: 0,
//     developerModeStatus: 0,
//     usbDebuggingStatus: 0,
//     deviceLockStatus: 0,
//     openNetworkConnection: 0,
//     untrustedAppSource: 0,
//     bannedApps: 0,
//     dangerousAppsRDP: 0,
//     bluetoothEnabled: 0,
//     screenTimeoutGreaterThan60Sec: 0,
//     thirdPartyKeyboardFound: 0,
//     timeZoneStatus: 0,
//     usbVoliation: 0,
//     emulatorDetected: 0,
//   });
//   const { user } = useSelector((state) => state.authSlice);
//   const [totalDashboard, setTotalDashboard] = useState(0)

//   useEffect(() => {
//     const source = axios.CancelToken.source();

//     const fetchWindowSummary = async () => {
//       try {
//         setLoading(true);

//         // const response = await axios.get(process.env.REACT_APP_SUMMARY_URL, {
//         //   cancelToken: source.token,
//         // });
//         const response = await axios.get(
//           `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         );

//         console.log(response?.data.chromeOsSummaryInfo, "useFetchChromeSummary");
//         setTotalDashboard(response?.data?.totalDevicesCount);

//         const {
//           chromeDevicesCount,
//           getDeviceRootedStatusEnabledCount,
//           devicesWithOldSecurityPatch,
//           developerModeStatusEnabledCount,
//           usbDebuggingStatusEnabledCount,
//           deviceLockStatusDisabledCount,
//           getOpenNetworkConnection,
//           getDangerousAppDetectedCount,
//           countDevicesWithBluetoothSettingsActionCount,
//           countDevicesWithDateSettingsActionCount,
//           countDevicesWithDisplaySettingsActionCount,
//           countDevicesWithInputMethodSettingsActionCount,
//           getSeLinuxStatusDisabledCount,
//           totalUsbInsertionsCount,
//           getDevicesEmulatorEnableCount,
//           getUnknownSourceAppDetectedCount,
//           getBlacklistedAppDetectedCount,
//           getSafeDeviceCount,
//           getUnsafeDeviceCount,
//           getUnresponsiveDeviceCount
//         } = response?.data?.chromeOsSummaryInfo;

//         setChromeSummary({
//           totalClients: chromeDevicesCount,
//           totalSafeClients: getSafeDeviceCount,
//           totalUnsafeClients: getUnsafeDeviceCount,
//           totalUnresponsivelients: getUnresponsiveDeviceCount,
//         });

//         setChromeAlertSummary({
//           oldSecurityPatch:
//             devicesWithOldSecurityPatch?.olderThanThresholdCount,
//           seLinuxStatus: getSeLinuxStatusDisabledCount,
//           deviceRootedJailbreaked: getDeviceRootedStatusEnabledCount,
//           developerModeStatus: developerModeStatusEnabledCount,
//           usbDebuggingStatus: usbDebuggingStatusEnabledCount,
//           deviceLockStatus: deviceLockStatusDisabledCount,
//           openNetworkConnection: getOpenNetworkConnection,
//           untrustedAppSource: getUnknownSourceAppDetectedCount,
//           bannedApps: getBlacklistedAppDetectedCount,
//           dangerousAppsRDP: getDangerousAppDetectedCount,
//           bluetoothEnabled: countDevicesWithBluetoothSettingsActionCount,
//           screenTimeoutGreaterThan60Sec:
//             countDevicesWithDisplaySettingsActionCount,

//           thirdPartyKeyboardFound:
//             countDevicesWithInputMethodSettingsActionCount,
//           timeZoneStatus: countDevicesWithDateSettingsActionCount,
//           usbVoliation: totalUsbInsertionsCount,
//           emulatorDetected: getDevicesEmulatorEnableCount,
//         });

//         setLoading(false);
//       } catch (error) {
//         if (!axios.isCancel(error)) {
//           console.error("Error fetching summary data:", error);
//           setLoading(false);
//         }
//       }
//     };

//     fetchWindowSummary();

//     return () => {
//       // Cancel the request if the component unmounts
//       source.cancel();
//     };
//   }, []);

//   return { loading, chromeSummary, chromeAlertSummary, totalDashboard};
// };

// export default useFetchChromeSummary;

// polling every 1 min 
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useFetchChromeSummary = () => {
  const [loading, setLoading] = useState(false);
  const [chromeSummary, setChromeSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [chromeAlertSummary, setChromeAlertSummary] = useState({
    oldSecurityPatch: 0,
    seLinuxStatus: 0,
    deviceRootedJailbreaked: 0,
    developerModeStatus: 0,
    usbDebuggingStatus: 0,
    deviceLockStatus: 0,
    openNetworkConnection: 0,
    untrustedAppSource: 0,
    bannedApps: 0,
    dangerousAppsRDP: 0,
    bluetoothEnabled: 0,
    screenTimeoutGreaterThan60Sec: 0,
    thirdPartyKeyboardFound: 0,
    timeZoneStatus: 0,
    usbVoliation: 0,
    emulatorDetected: 0,
  });
  const { user } = useSelector((state) => state.authSlice);
  const [totalDashboard, setTotalDashboard] = useState(0);

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status
    let timerId;

    const source = axios.CancelToken.source();

    const fetchWindowSummary = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
            cancelToken: source.token,
          }
        );

        console.log(response?.data.chromeOsSummaryInfo, "useFetchChromeSummary");
        setTotalDashboard(response?.data?.totalDevicesCount);

        const {
          chromeDevicesCount,
          getDeviceRootedStatusEnabledCount,
          devicesWithOldSecurityPatch,
          developerModeStatusEnabledCount,
          usbDebuggingStatusEnabledCount,
          deviceLockStatusDisabledCount,
          getOpenNetworkConnection,
          getDangerousAppDetectedCount,
          countDevicesWithBluetoothSettingsActionCount,
          countDevicesWithDateSettingsActionCount,
          countDevicesWithDisplaySettingsActionCount,
          countDevicesWithInputMethodSettingsActionCount,
          getSeLinuxStatusDisabledCount,
          totalUsbInsertionsCount,
          getDevicesEmulatorEnableCount,
          getUnknownSourceAppDetectedCount,
          getBlacklistedAppDetectedCount,
          getSafeDeviceCount,
          getUnsafeDeviceCount,
          getUnresponsiveDeviceCount
        } = response?.data?.chromeOsSummaryInfo;

        if (!isMounted) return; // Prevent state updates if unmounted

        setChromeSummary({
          totalClients: chromeDevicesCount,
          totalSafeClients: getSafeDeviceCount,
          totalUnsafeClients: getUnsafeDeviceCount,
          totalUnresponsivelients: getUnresponsiveDeviceCount,
        });

        setChromeAlertSummary({
          oldSecurityPatch: devicesWithOldSecurityPatch?.olderThanThresholdCount,
          seLinuxStatus: getSeLinuxStatusDisabledCount,
          deviceRootedJailbreaked: getDeviceRootedStatusEnabledCount,
          developerModeStatus: developerModeStatusEnabledCount,
          usbDebuggingStatus: usbDebuggingStatusEnabledCount,
          deviceLockStatus: deviceLockStatusDisabledCount,
          openNetworkConnection: getOpenNetworkConnection,
          untrustedAppSource: getUnknownSourceAppDetectedCount,
          bannedApps: getBlacklistedAppDetectedCount,
          dangerousAppsRDP: getDangerousAppDetectedCount,
          bluetoothEnabled: countDevicesWithBluetoothSettingsActionCount,
          screenTimeoutGreaterThan60Sec: countDevicesWithDisplaySettingsActionCount,
          thirdPartyKeyboardFound: countDevicesWithInputMethodSettingsActionCount,
          timeZoneStatus: countDevicesWithDateSettingsActionCount,
          usbVoliation: totalUsbInsertionsCount,
          emulatorDetected: getDevicesEmulatorEnableCount,
        });

        setLoading(false);

        // Schedule the next fetch only if the component is still mounted
        if (isMounted) {
          timerId = setTimeout(fetchWindowSummary, 60000); // Polling every 60 seconds
        }
      } catch (error) {
        if (isMounted && !axios.isCancel(error)) {
          console.error("Error fetching summary data:", error);
          setLoading(false);

          // Retry fetch after 1 second in case of error
          timerId = setTimeout(fetchWindowSummary, 1000); // Retry interval set to 1 second
        }
      }
    };

    fetchWindowSummary(); // Initial fetch

    return () => {
      isMounted = false; // Cleanup on unmount
      clearTimeout(timerId); // Cleanup the timeout
      source.cancel(); // Cancel the request if the component unmounts
    };
  }, [user?.data?.user?.userId, user?.data?.accessToken]);

  return { loading, chromeSummary, chromeAlertSummary, totalDashboard };
};

export default useFetchChromeSummary;
