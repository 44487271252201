import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./detail.css";
import {useSkinMode} from '../../SkinModeContext'
function DetailCard({
  severityIcon,
  severityName,
  severityNumber,
  cardIcon,
  severityColor,
  apiUrl,
  routeUrl,
  riskAt,
  footerTitle,
  

}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const navigate = useNavigate();
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const handleRouteDetailClick = () => {
    navigate(routeUrl, {
      state: {
        titleState: severityName,
        alertNumber: severityNumber,
        callUrl: apiUrl,
      },
    });
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  if (skinMode === "dark") {
    document.body.classList.add('dark-skin'); // Add a class to the body
  } else {
    document.body.classList.remove('dark-skin'); // Remove it when it's not dark mode
  }
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  let onClickHandler = null;
  if (severityNumber > 0) {
    onClickHandler = handleRouteDetailClick;
  }
const styleSubTitle = {
 
}
  return (
      <div
        className={`card-one  border d-flex flex-column align-items-start justify-content-center detailHoverCard shadow p-1`}
      onClick={onClickHandler}
      >
        <div className="p-3 d-flex flex-column  align-items-start justify-content-center ">
          <p className="m-0 p-0 fs-15  fw-bolder lh-3 ff-secondary py-2 ">
            {severityName}
          </p>
          <span className="d-flex align-items-center justify-content-center">
            <img src={severityIcon} />
            <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
              {severityNumber}
            </p>
          </span>
        </div>
        <div className="d-flex w-100  align-items-center justify-content-between">
          {/* <img src={riskIcon} /> */}
          <p className="m-0 p-0 ps-3 " >
            <span  className={ "text-" + severityColor}>{riskAt}%</span> {footerTitle}
          </p>
          <img src={cardIcon} width={100} />
        </div>
      </div>
  );
}

export default DetailCard;
