import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Select } from "antd";
import { Link } from "react-router-dom";
import cyberLogo from "../assets/img/onlyLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../api/axios";
import FormData from "form-data";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomToaster from "../components/common/CustomToaster";
import { useDispatch } from "react-redux";
import { hideToaster, showToaster } from "../feature/slices/toastSlice";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";

const { Option } = Select;

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      phone: "",
      email: "",
      password: "",
      role: "",
      department: "",
      organisation: "",
      section: "",
      image: "",
      countryCode: "+91",
    },
    validationSchema: Yup.object({
      fname: Yup.string().required("Full name is required"),
      lname: Yup.string().required("Last name is required"),
      phone: Yup.string()
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Invalid phone"
        )
        .required("Phone is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      role: Yup.string().required("Role is required"),
      department: Yup.string(),
      organisation: Yup.string().required("Organisation is required"),
      section: Yup.string(),
      image: Yup.mixed(),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("fname", formik.values.fname);
      formData.append("lname", formik.values.lname);
      formData.append("phone", formik.values.phone);
      formData.append("email", formik.values.email);
      formData.append("password", formik.values.password);
      formData.append("role", formik.values.role);
      formData.append("department", "");
      formData.append(
        "organisation",
        formik.values.organisation
      );
      formData.append("section", "");
      formData.append("image", formik.values.image);
      try {
        const res = await axios.post("/signup", formData);

        console.log(res, "RESPONSEEMAIl");

        if (
          res?.data?.message === "User created successfully" &&
          res?.data?.status
        ) {
          resetForm();
          dispatch(
            showToaster({
              title: "Sign up",
              message: "User created successfully",
              color: "success",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
          setTimeout(() => {
            navigate(`/`, {
              // state: {
              //   email: values.email,
              //   otpExpire: res?.data?.expiresAt,
              // },
            });
          }, 1000);
        }
      } catch (error) {
        // Handle login error
        console.log(error, "error");
        if (error.response) {
          dispatch(
            showToaster({
              title: "Error",
              message: error.response.data.message,
              color: "danger",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        } else {
          dispatch(
            showToaster({
              title: "Error",
              message: "Something Went Wrong",
              color: "danger",
            })
          );
          setTimeout(() => {
            dispatch(hideToaster());
          }, 2000);
        }
      }

      setSubmitting(false);
    },
  });
  const styleCard = {
    // 1st basic
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgb(228 228 228 / 65%)',
    // 2nd mid
    // boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
    // 3rd high +
    // boxShadow: 'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px'
    width: "50rem"
  }


  return (
    <div className="page-sign">
      <Card className="card-sign"   style={styleCard} >
        <Card.Header>
          <Link className="sidebar-logo d-flex align-items-center gap-2 justify-content-start">
            <img
              src={cyberLogo}
              id="logo"
              alt="Cyber Auditor"
              className="cyBerLogo"
            />
            <p className="m-0 p-0 fw-semibold fs-13 ">CYBER AUDITOR</p>
          </Link>
          <Card.Title className="my-2">Sign Up</Card.Title>
          <Card.Text>It's free to signup and only takes a minute.</Card.Text>
        </Card.Header>
        <Card.Body>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>First name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="fname"
                    placeholder="First name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fname}
                    className={
                      formik.touched.fname && formik.errors.fname ? "error" : ""
                    }
                  />
                  {formik.touched.fname && formik.errors.fname && (
                    <div className="error-message">{formik.errors.fname}</div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Last name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="lname"
                    placeholder="Last name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lname}
                    className={
                      formik.touched.lname && formik.errors.lname ? "error" : ""
                    }
                  />
                  {formik.touched.lname && formik.errors.lname && (
                    <div className="error-message">{formik.errors.lname}</div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Phone*</Form.Label>
                  <Input.Group compact>
                    <Select
                      style={{ width: "30%" }}
                      defaultValue="+91"
                      onChange={(value) =>
                        formik.setFieldValue("countryCode", value)
                      }
                    >
                      <Option value="+91">+91</Option>
                      <Option value="+44">+44</Option>
                    </Select>
                    <Input
                      style={{ width: "70%" }}
                      type="text"
                      name="phone"
                      placeholder="Enter your phone number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}

                      maxLength={10}
                      className={
                        formik.touched.phone && formik.errors.phone
                          ? "error"
                          : ""
                      }
                    />
                  </Input.Group>
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="error-message">{formik.errors.phone}</div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="jatin@gmail.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error-message">{formik.errors.email}</div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label>Password*</Form.Label>
                  <Input.Password
                    name="password"
                    placeholder="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={
                      formik.touched.password && formik.errors.password
                        ? "error py-2"
                        : "form-control d-flex"
                    }
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined onClick={() => setShowPassword(false)} />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(true)}
                        />
                      )
                    }
                    visible={showPassword}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="error-message">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Role*</Form.Label>
                  <Select
                    aria-label="Default select example"
                    type="role"
                    name="role"
                    placeholder="role"
                    onChange={(value) => formik.setFieldValue("role", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    className={
                      formik.touched.role && formik.errors.role ? "error " : ""
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select Role</Option>
                    <Option value="Super Admin">Super Admin</Option>
                    <Option value="Organisation Admin">
                      Organisation Admin
                    </Option>
                    <Option value="Department Admin">Department Admin</Option>
                    <Option value="Section Admin">Section Admin</Option>
                  </Select>
                  {formik.touched.role && formik.errors.role && (
                    <div className="error-message">{formik.errors.role}</div>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Department</Form.Label>
                  <Select
                    aria-label="Default select example"
                    type="department"
                    name="department"
                    placeholder="department"
                    disabled={true}
                    onChange={(value) =>
                      formik.setFieldValue("department", value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.department}
                    className={
                      formik.touched.department && formik.errors.department
                        ? "error"
                        : ""
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select Department</Option>
                    <Option value="Department of Internal Security">
                      Department of Internal Security
                    </Option>
                  </Select>
                  {formik.touched.department && formik.errors.department && (
                    <div className="error-message">
                      {formik.errors.department}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Organisation*</Form.Label>
                  <Select
                    aria-label="Default select example"
                    type="organisation"
                    name="organisation"
                    placeholder="organisation"
                    onChange={(value) =>
                      formik.setFieldValue("organisation", value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.organisation}
                    className={
                      formik.touched.organisation && formik.errors.organisation
                        ? "error"
                        : ""
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select organisation</Option>
                    <Option value="mobisec technologies pvt. ltd">
                      mobisec technologies pvt. ltd
                    </Option>
                  </Select>
                  {formik.touched.organisation &&
                    formik.errors.organisation && (
                      <div className="error-message">
                        {formik.errors.organisation}
                      </div>
                    )}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Form.Label>section</Form.Label>
                  <Select
                    aria-label="Default select example"
                    type="section"
                    name="section"
                    placeholder="section"
                    disabled={true}
                    onChange={(value) => formik.setFieldValue("section", value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.section}
                    className={
                      formik.touched.section && formik.errors.section
                        ? "error"
                        : ""
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select section</Option>
                    <Option value="MHA Control Room">MHA Control Room</Option>
                  </Select>
                  {formik.touched.section && formik.errors.section && (
                    <div className="error-message">{formik.errors.section}</div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <Form>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Select an image to upload</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      value={formik.values.image}
                      accept=".jpeg, .jpg, .png"
                      onChange={(event) => {
                        formik.handleChange(event);
                        // Additional logic if needed
                      }}
                      onBlur={formik.handleBlur}
                      // value={formik.values.image}
                      className={
                        formik.touched.image && formik.errors.image
                          ? "error"
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                    {formik.touched.image && formik.errors.image && (
                      <div className="error-message">{formik.errors.image}</div>
                    )}
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <div className="mb-4">
              <small>
                By clicking <strong>Create Account</strong> below, you agree to
                our terms of service and privacy statement.
              </small>
            </div>
            <Button
              variant="primary"
              className="btn-sign"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <span className="m-0 p-0">
                  <Spinner size="sm" />
                </span>
              ) : (
                "Create Account"
              )}
            </Button>
          </form>

          <div className="divider">
            <span>or sign up using</span>
          </div>
        </Card.Body>
        <Card.Footer>
          Already have an account? <Link to="/signin">Sign In</Link>
        </Card.Footer>
      </Card>
      <CustomToaster />
    </div>
  );
}
