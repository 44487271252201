// import React, { useEffect } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Main from "./layouts/Main";
// import NotFound from "./pages/NotFound";
// import publicRoutes from "./routes/PublicRoutes";
// import protectedRoutes from "./routes/ProtectedRoutes";
// import { logout } from "./feature/slices/AuthSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { persistor } from "./feature/store";

// // Import CSS
// import "./assets/css/remixicon.css";

// // Import SCSS
// import "./scss/style.scss";

// import "./app.css";
// import AdminRoutes from "./routes/AdminRoutes";
// import UserAdminRoutes from "./routes/UserAdminRoutes"

// export default function App() {
//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);


 
//   const expireTime = user?.data?.accessTokenExpiry * 1000; // Convert seconds to milliseconds
//   const UserType =  user?.data?.user?.roleName
//   const dispatch = useDispatch();

//   console.log(UserType, 'UserType')
//   useEffect(() => {
//     const currentTime = new Date().getTime();
//     const expireInSeconds = Math.floor((expireTime - currentTime) / 1000);

//     const timeout = setTimeout(() => {
//       dispatch(logout());
//     }, expireInSeconds * 1000);

//     return () => clearTimeout(timeout);
//   }, [dispatch, expireTime]);

//   return (
//     <React.Fragment>
//       {/* Loader and Overlay */}
//       <BrowserRouter>
//         <PersistGate loading={null} persistor={persistor}>
//           <Routes>
//             {isAuthenticated ? (
//               <Route path="/" element={<Main />}>
//                 {protectedRoutes.map((route, index) => (
//                   <Route
//                     path={route.path}
//                     element={route.element}
//                     key={index}
//                   />
//                 ))}
//                 {AdminRoutes.map((route, index) => {
//                   return (
//                     <Route
//                       path={route.path}
//                       element={route.element}
//                       key={index}
//                     />
//                   );
//                 })}
//                 {UserAdminRoutes.map((route, index) => {
//                   return (
//                     <Route
//                       path={route.path}
//                       element={route.element}
//                       key={index}
//                     />
//                   );
//                 })}
//               </Route>
//             ) : (
//               publicRoutes.map((route, index) => (
//                 <Route path={route.path} element={route.element} key={index} />
//               ))
//             )}
//             <Route path="*" element={<NotFound />} />
//           </Routes>
//         </PersistGate>
//       </BrowserRouter>
//     </React.Fragment>
//   );
// }

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { logout } from "./feature/slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./feature/store";

// Import CSS
import "./assets/css/remixicon.css";

// Import SCSS
import "./scss/style.scss";

import "./app.css";
import AdminRoutes from "./routes/AdminRoutes";
import UserAdminRoutes from "./routes/UserAdminRoutes";
import OrganizationAdmin from "./routes/OrganizationAdmin"
import {SkinModeProvider} from "./components/SkinModeContext"
export default function App() {
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  const expireTime = user?.data?.accessTokenExpiry * 1000; // Convert seconds to milliseconds
  const UserType = user?.data?.user?.roleName;
  const dispatch = useDispatch();

  console.log(UserType, 'UserType');
  useEffect(() => {
    const currentTime = new Date().getTime();
    const expireInSeconds = Math.floor((expireTime - currentTime) / 1000);

    const timeout = setTimeout(() => {
      dispatch(logout());
    }, expireInSeconds * 1000);

    return () => clearTimeout(timeout);
  }, [dispatch, expireTime]);

  return (
    <React.Fragment>
      {/* Loader and Overlay */}
      <BrowserRouter>
      <SkinModeProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            {isAuthenticated ? (
              <Route path="/" element={<Main />}>
                { 
                  UserType === "Super Admin" &&
                  protectedRoutes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
                { 
                  UserType ===  "Guest Admin"  && 
                  protectedRoutes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
                { 
                  UserType ===  "Department Admin"  && 
                  protectedRoutes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
                { 
                  UserType ===  "Section Admin"  && 
                  protectedRoutes.map((route, index) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                ))}
                {UserType === "subSection Admin" &&
                protectedRoutes.map((route, index) => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  ))}
                {/* "subSection Admin"   || "Department Admin" || "Section Admin" || */}
                {UserType === "Organization Admin" &&
                OrganizationAdmin.map((route, index) => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  ))}
                {UserType === "Ca Admin" &&
                  AdminRoutes.map((route, index) => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  ))}
                {UserType === "Super Admin" &&
                  UserAdminRoutes.map((route, index) => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  ))}
              </Route>
            ) : (
              publicRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PersistGate>
        </SkinModeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}
