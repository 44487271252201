import { combineReducers } from "@reduxjs/toolkit";
import toastSlice from "./slices/toastSlice";
import { persistedAuthReducer } from "./persistenConfig";

const rootReducer = combineReducers({
  toastSlice: toastSlice,
  authSlice: persistedAuthReducer,
  // Add other reducers if needed
});

export default rootReducer;
