// apiEndpoints.js

// Alert API End Point

export const EDR_MALFUNCTION_ENDPOINT = "osinfo/edr-malfunction";
export const ANTIVIRUS_NOT_INSTALLED_COUNT_ENDPOINT =
  "osinfo/antivirus-not-installed-count";
export const ANTIVIRUS_NOT_INSTALLED_ENDPOINT =
  "osinfo/antivirus-not-installed";
export const ANTI_VIRUS_MALFUNCTION_ENDPOINT = "osinfo/anti-virus-malfunction";
export const EDR_NOT_INSTALLED_ENDPOINT = "osinfo/edr-not-Installed";
export const MALWARE_DETECTION_ENDPOINT = "osinfo/malware-detection";
export const ANTI_VIRUS_NOT_UPDATED_ENDPOINT = "osinfo/anti-virus-not-updated";

export const OS_PATCHED = "backendinfo/os-not-patched";
export const NAC_NAP = "networkinfo/is-nac-disabled";

export const FIRWALL_OFF = "networkinfo/firewall-off";

export const WINDOW_NOT_ACTIVATED = "pcinfo/all-not-activated-windows";

export const BLACKLIST_PROGRAME = "backendinfo/all-blacklisted-programs";

export const WINDOW_ALL_CLIINT = "pcinfo/windows-pc-list";

export const USB_VOILATION = "usbhistoryinfo/get-pc-with-unwhitelisted-usb";

export const UNRESPONSIVE_WINDOWS = "pcinfo/all-unresponsive-windows";

export const SAFE_WINDOW_CLIENT = "backendinfo/safe-windows-clients";

export const UNSAFE_WINDOW_CLIENTS = "backendinfo/unsafe-windows-clients";

// Alert Api End Point End

// WindowClientDetail End Point
export const OS_INFO_DATA = "collection/os-info-data";
export const NETWORK_INFO_DATA = "windows/networkinfo";

// End

export const SHARED_FOLDER = "osinfo/is-shared-directories";

export const RDP_ENABLED = "osinfo/is-rdp-enabled";

export const WIFI_ENABLED = "networkinfo/is-wifi-enabled";

export const BLUETOOTH_ENABLED = "networkinfo/is-bluetooth-enabled";

export const OPEN_NETWORK_CONNECTION = "networkinfo/is-open-network-connection";

export const UNKNOWN_SERVICES = "backendinfo/all-unknown-services";

export const MULTIPLE_PASSWORD = "accountinfo/is-multiple-admin";

export const OLD_PASSWORD = "accountinfo/is-admin-pwd-greater-than-threshold";

export const NAC_DISABLED = "networkinfo/is-nac-disabled";

export const OUT_OF_DOMAIN = "pcinfo/get-out-of-domain-windows";

export const CD_DRIVE_ENABLED = "hardwareInfo/is-cd-drive-enabled";

export const SCANNER_INSTALLED = "hardwareInfo/is-scanner-Installed";

export const IS_HIGH_CPU_USAGE = "hardwareInfo/is-high-cpu-usage";

export const IS_HIGH_RAM_USAGE = "hardwareInfo/is-high-ram-usage";

export const BIOS_BATTERY = "hardwareInfo/bios-battery-serviceable";
