import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Menu } from "antd";
import Tippy from "@tippyjs/react";
import { linuxApi } from "../../../api/axiosSet";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "tippy.js/dist/tippy.css";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {useSkinMode } from "../../../components/SkinModeContext"

function CustomTableLinuxUsb({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
  titleState
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);
  const [modalOpen, setModalOpen] = useState(false);
  const [networkData, setNetworkData] = useState([]);
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const { user } = useSelector((state) => state.authSlice);
  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const styleViewbutton = {
    marginLeft: "-6px !important",
  };

  const handleCountClick = (pcId) => {

    linuxApi
      .get(
        `/networkinfo/network-communication-violation-list/${user?.data?.user?.userId}?pcId=${pcId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((response) => {
        setNetworkData(response.data.establishedNetworkConnections);
        setModalOpen(true); // Open the modal
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const columnsList = [
    {
      name: "Local Address",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.localAddress}>
          <div>{row.localAddress}</div>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Local Port", selector: (row) => (
        <Tippy maxWidth="none" content={row.localPort}>
          <div>{row.localPort}</div>
        </Tippy>
      ),
      sortable: true
    },
    {
      name: "Foreign Address",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.foreignAddress}>
          <div>{row.foreignAddress}</div>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Foreign Port",
      selector: (row) => (
        <Tippy maxWidth="none" content={row.foreignPort}>
          <div>{row.foreignPort}</div>
        </Tippy>
      ),
      sortable: true,
    },

  ];
  const formatMultipleIpAddresses = (ipAddresses) => {
    if (typeof ipAddresses === "string") {
      return ipAddresses?.split(" ").join(", ");
    }
    return ipAddresses
      .map((ipAddress) => ipAddress)
      .join(" ")
      .replace(/ /g, ", ");
  };

  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        width: "90px",
        sortable: true,
      },
      {
        name: "Hostname",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.hostname}>
            <div>{row.hostname}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Serial No.",
        selector: (row) => (
          <Tippy maxWidth="none" content={row.serialNumber}>
            <div>{row.serialNumber}</div>
          </Tippy>
        ),
        sortable: true,
      },

      // Conditionally add the "IP Address" column if any row has a valid IP Address
      ...(rowData?.some((row) => row.ipAddress)
        ? [
          {
            name: "IP Address",
            selector: (row) => (
              <Tippy maxWidth="none" content={formatMultipleIpAddresses(row.ipAddress)}>
                <div>{formatMultipleIpAddresses(row.ipAddress)}</div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),

      ...(rowData?.some((row) => row.foreignAddressCount)
        ? [
          {
            name: "Count",
            selector: (row) => row.foreignAddressCount,
            sortable: true,
            cell: (row) => (
              <span
                onClick={() => handleCountClick(row.pcId)}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  // textDecoration: "underline", // Optional: makes it look like a link
                }}
              >
                {row.foreignAddressCount}
              </span>
            ),
          },
        ]
        : []),

      ...(rowData?.some((row) => row.time)
        ? [
          {
            name: "Timestamp",
            selector: (row) => row.time,
            sortable: true,
          },
        ]
        : []),
      ...(rowData?.some((row) => row.macAddress)
        ? [
          {
            name: "Mac Address",
            selector: (row) => (
              <Tippy maxWidth="none" content={row.macAddress}>
                <div>{row.macAddress}</div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),
      // Conditionally add the "Local Address" column if present
      ...(rowData?.some((row) => row.localAddress)
        ? [
          {
            name: "Local IP",
            selector: (row) => row.localAddress || "N/A",
            sortable: true,
          },
        ]
        : []),
      // Conditionally add the "Local Port" column if present
      ...(rowData?.some((row) => row.localPort)
        ? [
          {
            name: "Local Port",
            selector: (row) => row.localPort || "N/A",
            sortable: true,
          },
        ]
        : []),
      // Conditionally add the "Foreign Address" column if present
      ...(rowData?.some((row) => row.foreignAddress)
        ? [
          {
            name: "Remote IP",
            selector: (row) => row.foreignAddress || "N/A",
            sortable: true,
          },
        ]
        : []),
      // Conditionally add the "Foreign Port" column if present
      ...(rowData?.some((row) => row.foreignPort)
        ? [
          {
            name: "Remote Port",
            selector: (row) => row.foreignPort || "N/A",
            sortable: true,
          },
        ]
        : []),
      ...(rowData?.some((row) => row.usbName && row.usbName.length > 0)
        ? [
          {
            name: "Name",
            selector: (row) => row.usbName.join(", "),
            sortable: true,
          },
        ]
        : []),

      ...(rowData?.some(
        (row) => row.usbSerialNumber && row.usbSerialNumber.length > 0
      )
        ? [
          {
            name: "USB Serial No.",
            selector: (row) => (
              <Tippy maxWidth="none" content={row.usbSerialNumber}>
                <div>{row.usbSerialNumber}</div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),

      ...(rowData?.some((row) => row.usbManufacturer)
        ? [
          {
            name: "Manufacturer",
            selector: (row) => (
              <Tippy maxWidth="none" content={row.usbManufacturer}>
                <div>{row.usbManufacturer}</div>
              </Tippy>
            ),
            sortable: true,
            width: "180px",
          },
        ]
        : []),
      ...(rowData?.some((row) => row.usbProductId)
        ? [
          {
            name: "Product ID",
            selector: (row) => row.usbProductId,
            sortable: true,
          },
        ]
        : []),
      ...(rowData?.some((row) => row.usbVendorId)
        ? [
          {
            name: "Vendor ID",
            selector: (row) => row.usbVendorId,
            sortable: true,
          },
        ]
        : []),
      ...(rowData?.some(
        (row) => Array.isArray(row.openTcpPorts) && row.openTcpPorts.length > 0
      )
        ? [
          {
            name: "Open Ports",
            selector: (row) =>
              Array.isArray(row.openTcpPorts)
                ? row.openTcpPorts.join(", ")
                : "N/A",
            cell: (row) => (
              <Tippy
                content={
                  Array.isArray(row.openTcpPorts)
                    ? row.openTcpPorts.join(", ")
                    : "N/A"
                }
              >
                <div>
                  {Array.isArray(row.openTcpPorts)
                    ? row.openTcpPorts.join(", ")
                    : "N/A"}
                </div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),

      ...(rowData?.some((row) => row.tpmStatus)
        ? [
          {
            name: "TPM Status",
            selector: (row) => (
              <Tippy maxWidth="none" content={row.tpmStatus}>
                <div>{row.tpmStatus}</div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),

        ...(rowData?.some((row) => row.updatedAt)
        ? [
          {
            name: "Timestamp",
            selector: (row) => (
              <Tippy maxWidth="none" content={formatTimestamp(row.updatedAt)}>
                <div>{formatTimestamp(row.updatedAt)}</div>
              </Tippy>
            ),
            sortable: true,
          },
        ]
        : []),

      {
        name: "Action",
        cell: (row) => (
          <div className="flex justify-center items-center">
            <Button
              className="btn rounded-md border-0 d-flex fs10 gap-3 mx-auto px-2 text-light bg-dark text-center justify-center align-items-center"
              onClick={() =>
                navigate(routeUrl, {
                  state: {
                    basicInfo: row,
                    pcInfoId: row?.pc_Id,
                    title: titleState
                  },
                })
              }
            >
              <p className="m-0 p-0 fs-12">Details</p>
              <i className="fas fa-long-arrow-alt-right"></i>
            </Button>
          </div>
        ),
        ignoreRowClick: true,
        allowoverflow: true,
      },
    ],
    [currentPage, pageSize, navigate, routeUrl, rowData]
  );

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const exportToCSV = () => {
    if (networkData && networkData.length > 0) {
      // Determine which columns have data
      const hasData = (key) => networkData?.some((item) => item[key]);
      const headers = [
        { label: "Local Address", key: "localAddress" },
        { label: "Local Port", key: "localPort" },
        { label: "Foreign Address", key: "foreignAddress" },
        { label: "Foreign Port", key: "foreignPort" },
      ].filter((header) => hasData(header.key));

      const csvData = [
        headers.map((header) => header.label),
        ...networkData.map((item) =>
          headers.map((header) => item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `NetworkViolations.csv`);
    }
  };

  function exportToPDF() {
    if (networkData && networkData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`NetworkViolations`, 14, 22);

      // Determine which columns have data
      const hasData = (key) => networkData?.some((item) => item[key]);
      const headers = [
        { label: "Local Address", key: "localAddress" },
        { label: "Local Port", key: "localPort" },
        { label: "Foreign Address", key: "foreignAddress" },
        { label: "Foreign Port", key: "foreignPort" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      networkData.forEach((item) => {
        const rowData = headers.map((header) => item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for all cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
        // Conditional column width using columnStyles
        columnStyles: {
          [tableColumn.indexOf('Hardening Issues')]: {
            cellWidth: 100,
            halign: "left",
          },
        },
      });

      // Save the PDF
      doc.save(`NetworkViolations.pdf`);
    }
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={rowData || []}
        customStyles={currentStyles}
        pagination
        paginationServer
        paginationTotalRows={totalDocuments}
        paginationDefaultPage={currentPage}
        paginationPerPage={pageSize}
        onChangePage={(page) => {
          handlePageChange(page);
        }}
        onChangeRowsPerPage={(newPageSize) => {
          handlePageSizeChange(newPageSize);
        }}
        noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
      />

      {/* Modal */}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Established Network Connections List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* DataTable to display API response */}
          <DataTable
            columns={columnsList}
            data={networkData}
            customStyles={currentStyles}
            pagination
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-primary text-white"
            onClick={exportToPDF}
          >
            <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
          </button>
          <button
            type="button"
            className="btn btn-sm btn-success text-white"
            onClick={exportToCSV}
          >
            <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
          </button>
          <Button
            variant="btn btn-secondary btn-sm"
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomTableLinuxUsb;
