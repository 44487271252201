import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../assets/img/Excellogo.png";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { notifyApi } from "../../api/axiosSet";
import { saveAs } from "file-saver";
import { useSkinMode } from "../../components/SkinModeContext";

function AllEndPoint() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasT, setShowOffcanvasT] = useState(false);
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };
  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    divider: {
			default: '#fffff',
		},
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
      highlight: {
        backgroundColor: '#6e8ea6', // Custom color for row highlight
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
    
  };
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });

  const { user } = useSelector((state) => state.authSlice);
  useEffect(() => {
    if (user?.isPasswordUpdated === false) {
      navigate("/update-profile");
    }
  }, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async () => {
    try {
      const response = await notifyApi.get(
        `/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}?page=${pageNumber}&limit=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      setTotalDocuments(response.data.totalDevices);
      const devices = response.data.devices;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user, pageNumber, pageSize]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await notifyApi.get(
          `/all-department/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    visibleColumns.hostname && {
      name: "Hostname",
      selector: (row) => (
        <Tippy content={row.hostname}>
          <div>{row.hostname}</div>
        </Tippy>
      ),
      sortable: true,
    },
    visibleColumns.serialNumber && {
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      sortable: true,
    },

    {
      name: "IP Address",
      selector: (row) => {
        const ipAddresses = row.ipAddress ? row.ipAddress.split(" ") : ["NA"];
        return (
          <Tippy content={ipAddresses.join(", ")}>
            <div>
              {ipAddresses.map((ip, index) => (
                <div key={index}>{ip.trim() || "NA"}</div>
              ))}
            </div>
          </Tippy>
        );
      },
      sortable: true,
      width: "190px",
    },    
    {
      name: "Type",
      selector: (row) => (
        <Tippy content={row.endpoint_type || "NA"}>
          <div>{row.endpoint_type || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Phase",
      selector: (row) => (
        <Tippy content={row.phase || "NA"}>
          <div>{row.phase || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
    },
    visibleColumns.updatedAt && {
      name: "Sync Time",
      selector: (row) => formatTimestamp(row.updatedAt) || "NA",
      sortable: true,
    },
    visibleColumns.departmentName && {
      name: "Associated With",
      selector: (row) => (
        <Tippy content={row.department || "NA"}>
          <div>{row.department || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
      width: "280px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn rounded-md d-flex align-items-center border-0 fs10 gap-2 mx-auto px-3 text-light bg-dark"
            onClick={() =>
              navigate("/linux-client-Details", {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.pcId,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">Details</p>
            <i className="fas fa-long-arrow-alt-right"></i>
          </Button>
        </div>
      ),
      sortable: false,
    },
  ];
  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      // Determine which columns have data
      const hasData = (key) =>
        key === "department" ? true : columnData.some((item) => item[key]);

      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Type", key: "endpoint_type" },
        { label: "Phase", key: "phase" },
        { label: "Sync Time", key: "updatedAt" },
        { label: "Associated With", key: "department" },
      ].filter((header) => hasData(header.key));

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail);
            const value = Object.values(detail);
            return `${key}:${value}`;
          })
          .join(" | ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      const formatMultipleIpAddresses = (ipAddresses) => {
        if (typeof ipAddresses === "string") {
          return ipAddresses;
        }
        return ipAddresses
          .map((ipAddress) => ipAddress)
          .join(" ")
          .replace(/ /g, " | ");
      };

      const formatUsersPasswordAge = (usersPasswordAge) => {
        if (!usersPasswordAge || usersPasswordAge.length === 0) return "N/A"; // Handle empty or undefined case

        return usersPasswordAge
          .map(
            (user) => `${user.userType}:${user.userName}:${user.passwordAge}`
          )
          .join(" | ");
      };

      const csvData = [
        headers.map((header) => header.label),
        ...columnData.map((item) =>
          headers.map((header) =>
            header.key === "ipAddress"
              ? formatMultipleIpAddresses(item[header.key])
              : header.key === "updatedAt"
              ? formatTimestamp(item[header.key])
              : header.key === "usersPasswordAge"
              ? formatUsersPasswordAge(item[header.key])
              : header.key === "sharedDirectories"
              ? formatSharedDirectories(item[header.key])
              : header.key === "avDetails"
              ? formatAvDetails(item[header.key])
              : header.key === "service"
              ? formatOsDetails(item[header.key])
              : header.key === "department"
              ? item[header.key] || "NA"
              : item[header.key]
          )
        ),
      ];
      // Add header information
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Header with organization name and export date/time
      const headerInfo = [
        `Organization: ${orgName}`,
        `Exported: ${exportDateTime}`,
      ];

      // Combine header information and CSV data
      const csvString = [
        headerInfo.join(","),
        "",
        csvData.map((row) => row.join(",")).join("\n"),
      ].join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `all_endpoints.csv`);
    }
  };

  function exportToPDF() {
    if (columnData && columnData.length > 0) {
      // Set the orientation to landscape
      const doc = new jsPDF({
        orientation: "landscape", // Force landscape orientation
      });

      // Organization name and export date/time
      const orgName = user?.data?.user?.organization || "Organization Name";
      const exportDateTime = formatTimestamp(new Date());

      // Add header with org name on the left and export date/time on the right
      doc.setFontSize(12);
      doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
      doc.text(
        `Exported: ${exportDateTime}`,
        doc.internal.pageSize.getWidth() - 14,
        15,
        { align: "right" }
      ); // Right-aligned

      // Add title
      doc.setFontSize(15);
      doc.text(`All Endpoints`, 14, 22);

      const hasData = (key) =>
        key === "department" ? true : columnData.some((item) => item[key]);
      const headers = [
        { label: "Hostname", key: "hostname" },
        { label: "Serial Number", key: "serialNumber" },
        { label: "IP Address", key: "ipAddress" },
        { label: "Type", key: "endpoint_type" },
        { label: "Phase", key: "phase" },
        { label: "Sync Time", key: "updatedAt" },
        { label: "Associated With", key: "department" },
      ].filter((header) => hasData(header.key));

      const tableColumn = headers.map((header) => header.label);
      const tableRows = [];

      // Function to format the avDetails array
      const formatAvDetails = (avDetails) => {
        return avDetails
          .map((detail) => {
            return `AV Name: ${detail.avName}, Current Version: ${detail.currentAVVersion}, Expected Version: ${detail.expectedAVVersion}`;
          })
          .join("; ");
      };

      const formatOsDetails = (service) => {
        return service
          .map((detail) => {
            const key = Object.keys(detail).find((k) => k.startsWith("clamav"));
            const avName = key ? key : "Unknown AV";
            const avStatus = detail[key] || "Status not available";

            return `AV Name: ${avName}, Status: ${avStatus}`;
          })
          .join("; ");
      };

      const formatSharedDirectories = (sharedDirectories) => {
        return sharedDirectories
          .map((directory) => {
            const permissions = directory.permission;
            return `Name: ${directory.name}, Path: ${directory.path}, Owner: ${permissions.owner}, Group: ${permissions.group}, Other: ${permissions.other}`;
          })
          .join("; ");
      };

      columnData.forEach((item) => {
        const rowData = headers.map((header) =>
          header.key === "updatedAt"
            ? formatTimestamp(item[header.key])
            : header.key === "sharedDirectories"
            ? formatSharedDirectories(item[header.key])
            : header.key === "usersPasswordAge"
            ? item[header.key]
                .map(
                  (user) =>
                    `${user.userType} :${user.userName}: ${user.passwordAge}`
                )
                .join("; ")
            : header.key === "avDetails"
            ? formatAvDetails(item[header.key])
            : header.key === "service"
            ? formatOsDetails(item[header.key])
            : header.key === "department"
            ? item[header.key] || "NA"
            : Array.isArray(item[header.key])
            ? item[header.key].join(", ")
            : item[header.key]
        );
        tableRows.push(rowData);
      });

      // Add table with borders for rows and columns
      doc.autoTable({
        startY: 30,
        head: [tableColumn],
        body: tableRows,
        styles: {
          cellPadding: 3, // Padding for cells
          lineColor: [44, 62, 80], // Border color (RGB)
          lineWidth: 0.1, // Border width
        },
        headStyles: {
          fillColor: [52, 73, 94], // Header background color
          textColor: 255, // Header text color
          halign: "center", // Center align header text
          lineWidth: 0.5, // Border width for header
        },
        bodyStyles: {
          lineColor: [44, 62, 80], // Row border color
          lineWidth: 0.1, // Border width for rows
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240], // Background color for alternate rows
        },
      });

      // Save the PDF
      doc.save(`all_endpoints.pdf`);
    }
  }

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };
  const handleTableClick = () => {
    setShowOffcanvasT(true);
  };
  const handleCheckboxChange = (columnName) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const deviceIds = selectedRows.map((row) => row.pcId);

    // Construct the data object based on which fields are selected
    const data = { deviceIds };

    if (selectedDepartment) {
      data.departmentId = selectedDepartment.value;
    }
    if (selectedSection) {
      data.sectionId = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSectionId = selectedSubsection.value;
    }

    try {
      const response = await notifyApi.post(
        `/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Close Offcanvas and update DataTable data
      setShowOffcanvas(false);
      fetchData();
      setSelectedRows([]);
      setSections([]);
      setSubsections([]);
      setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error(error);

      console.error("Error submitting form:", error);
      // Handle error response
    }
  };

  return (
    <React.Fragment>
      <div
        className="main main-app p-3 p-lg-4"
        style={mainStyle}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 text-dark fw-bolder p-0 m-0">All Endpoints</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total Endpoints: {totalDocuments}
            </p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF()}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV()}
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="d-flex align-items-center justify-content-between gap-2">
            {/* <button
              type="button"
              className="btn btn-info text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Manage label
            </button> */}
            <button
              type="button"
              className="btn btn-info text-white"
              onClick={handleControlClick}
              disabled={selectedRows.length === 0}
            >
              Assign Endpoint
            </button>
          </div>

          {/* <button
            type="button"
            className="btn btn-info text-white"
            onClick={handleTableClick}
          >
            <i className="fa-solid fa-eye"></i> Table View
          </button> */}
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData || []}
              pagination
              customStyles={currentStyles}
              paginationServer
              selectableRows
              clearSelectedRows={toggleClearRows}
              selectableRowsHighlight
              // highlightOnHover
              onSelectedRowsChange={handleSelectedRowsChange}
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={
                <p className="p-0 my-2 me-auto">No Data Found</p>
              }
            />
          )}
        </div>

        {/* Offcanvas for displaying selected rows */}
        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Selected Devices:{" "}
              <span className="badge rounded-pill text-bg-secondary text-white">
                {selectedRows.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row) => (
                <>
                  <li key={row.pcId}>{row.hostname}</li>
                  {/* <li key={row.pcId}>{row.pcId}</li> */}
                </>
              ))}
            </ul>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="departments" className="form-label">
                  Departments
                </label>
                <Select
                  options={departments.map((dept) => ({
                    label: dept.departmentName,
                    value: dept._id,
                  }))}
                  onChange={handleDepartmentChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sections" className="form-label">
                  Sections
                </label>
                <Select
                  options={sections.map((section) => ({
                    label: section.sectionName,
                    value: section._id,
                  }))}
                  onChange={handleSectionChange}
                  isDisabled={!selectedDepartment}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subsections" className="form-label">
                  Subsections
                </label>
                <Select
                  options={subsections.map((subSection) => ({
                    label: subSection.subSectionName,
                    value: subSection._id,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedSubsection(selectedOption)
                  }
                  isDisabled={!selectedSection}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          show={showOffcanvasT}
          onHide={() => setShowOffcanvasT(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Table Columns</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Offcanvas.Title className="fs-6">Visible Columns</Offcanvas.Title>
            <ul className="list-group">
              {Object.keys(visibleColumns).map((column) => (
                <li className="list-group-item" key={column}>
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    id={`checkbox-${column}`}
                    checked={visibleColumns[column]}
                    onChange={() => handleCheckboxChange(column)}
                  />
                  <label
                    className="form-check-label stretched-link"
                    htmlFor={`checkbox-${column}`}
                  >
                    {column.replace(/([A-Z])/g, " $1").trim()}{" "}
                    {/* Formatting column names */}
                  </label>
                </li>
              ))}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
}

export default AllEndPoint;
