// toastSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: false,
  toastMessage: "",
  toastTitle: "",
  toastColor: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToaster: (state, action) => {
      state.showToast = true;
      state.toastTitle = action.payload.title;
      state.toastMessage = action.payload.message;
      state.toastColor = action.payload.color;
    },
    hideToaster: (state) => {
      state.showToast = false;
      state.toastTitle = "";
      state.toastMessage = "";
      state.toastColor = "";
    },
  },
});

export const { showToaster, hideToaster } = toastSlice.actions;
export const selectToastState = (state) => state.toast;

export default toastSlice.reducer;
