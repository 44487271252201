import React, { useEffect, useState, useRef } from "react";
import HistoryCard from "../../components/common/HistoryCard";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Col, Row } from "react-bootstrap";
// import "../../assets/css/scroll-animation.css"
import CustomButton from "../../components/common/CustomButton";
import CustomModal from "../../components/CustomModal";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import CustomAccordian from "../../components/common/CustomAccordian";
import PageLoader from "../../components/common/Loader/PageLoader";
import useFetchOsLinux from "../../hooks/linuxhooks/useFetchOsLinux";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { showToaster } from "../../feature/slices/toastSlice";
import { Dropdown, Menu } from "antd";
import { Button } from "antd/es/radio";
import GenratePdf from "../../components/common/GenratePdf";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "../../scss/DonutChart.scss";
import { toHumanReadable } from "../../components/common/toHumanReadable";
import { useSkinMode } from "../../components/SkinModeContext";

import ReactApexChart from "react-apexcharts";

const chunkArray = (arr, numChunks) => {
  const chunkSize = Math.ceil(arr.length / numChunks);
  return Array.from({ length: numChunks }, (_, i) =>
    arr.slice(i * chunkSize, i * chunkSize + chunkSize)
  );
};

export const EqualColumns = ({
  linuxCisData,
  findRefForCIS,
  checkIfAutoOpenCIS,
}) => {
  const sortedObj = Object.keys(linuxCisData)
    .sort((a, b) => {
      const readableA = toHumanReadable(a).toLowerCase();
      const readableB = toHumanReadable(b).toLowerCase();
      return readableA.localeCompare(readableB);
    })
    .reduce((acc, key) => {
      acc[key] = linuxCisData[key];
      return acc;
    }, {});

  const entries = Object.entries(sortedObj).filter(
    ([category]) =>
      category !== "cisConfigureIptablesSoftwaresInfo" &&
      category !== "cisConfigureUfwInfo"
  );

  const columns = chunkArray(entries, 3);

  return (
    <div className="row pb-4">
      {columns.map((column, colIndex) => (
        <div className="col" key={colIndex}>
          {column.map(
            ([category, data], index) =>
              category !== "cisConfigureIptablesSoftwaresInfo" &&
              category !== "cisConfigureUfwInfo" && (
                <div ref={findRefForCIS(category)} key={index}>
                  <CustomAccordian
                    key={index}
                    accordianHeader={toHumanReadable(category)}
                    autoOpen={checkIfAutoOpenCIS(category)}
                  >
                    {category === "cisFilesystemIntegrityInfo" ? (
                      Object.entries(data).map(
                        ([subCategory, subData], subIndex) => (
                          <CustomAccordian
                            key={subIndex}
                            accordianHeader={toHumanReadable(subCategory)}
                          >
                            {Object.entries(subData).map(
                              ([key, value], innerIndex) => (
                                <div
                                  key={innerIndex}
                                  className="d-flex align-items-center py-1 justify-content-between"
                                >
                                  <p className="p-0 m-0 fs-11 fw-bold text-dark">
                                    {toHumanReadable(key)}
                                  </p>
                                  <p
                                    className={`p-0 m-0 fs-11 fw-semibold ${
                                      value === "True"
                                        ? "text-success"
                                        : value === "False"
                                        ? "text-danger"
                                        : "text-secondary"
                                    }`}
                                  >
                                    {value}
                                  </p>
                                </div>
                              )
                            )}
                          </CustomAccordian>
                        )
                      )
                    ) : category === "cisFilesystemConfigurationInfo" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Mount Name</th>
                            <th>Is Configured</th>
                            <th>Is Disabled</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold">
                          {data?.map((partition, subIndex) => (
                            <tr
                              className="p-0 m-0 fs-11 fw-semibold"
                              key={subIndex}
                            >
                              <td>{partition.mountName}</td>
                              <td
                                className={`${
                                  partition.isConfigured === "True"
                                    ? "text-success"
                                    : partition.isConfigured === "False"
                                    ? "text-danger"
                                    : "text-secondary"
                                }`}
                              >
                                {partition.isConfigured}
                              </td>
                              <td
                                className={`${
                                  partition.isDisabled === "True"
                                    ? "text-success"
                                    : partition.isDisabled === "False"
                                    ? "text-danger"
                                    : "text-secondary"
                                }`}
                              >
                                {partition.isDisabled}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : category === "cisAuditPartitionsInfo" ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Mount Point</th>
                            <th>Mounted</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((partition, subIndex) => (
                            <tr
                              className="p-0 m-0 fs-11 fw-semibold"
                              key={subIndex}
                            >
                              <td>{partition.mountPoint}</td>
                              <td
                                className={`${
                                  partition?.isMounted === "True"
                                    ? "text-success"
                                    : partition?.isMounted === "False"
                                    ? "text-danger"
                                    : "text-secondary"
                                }`}
                              >
                                {partition.isMounted}
                              </td>
                              <td>
                                <ul>
                                  {Object.entries(partition.mountOptions).map(
                                    ([key, value], ind) => (
                                      <li key={ind}>
                                        {key}:
                                        <p
                                          className={`p-0 m-0 fs-11 fw-semibold ${
                                            value === "True"
                                              ? "text-success"
                                              : value === "False"
                                              ? "text-danger"
                                              : "text-secondary"
                                          }`}
                                        >
                                          {value.toString()}{" "}
                                        </p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : category === "cisConfigureFirewallRulesInfo" ||
                      category === "cisConfigureIptablesSoftwaresInfo" ? (
                      Object.entries(data).map(
                        ([subCategory, subData], subIndex) => (
                          <CustomAccordian
                            key={subIndex}
                            accordianHeader={toHumanReadable(subCategory)}
                          >
                            {typeof subData === "object" && subData !== null ? (
                              Object.keys(subData).length === 0 ? (
                                <div className="d-flex align-items-center py-1 justify-content-between">
                                  <p className="p-0 m-0 fs-11 fw-bold  text-dark">
                                    No Data
                                  </p>
                                </div>
                              ) : (
                                Object.entries(subData).map(
                                  ([key, value], innerIndex) => (
                                    <div
                                      key={innerIndex}
                                      className="d-flex align-items-center justify-content-between py-1"
                                    >
                                      <p className="p-0 m-0 fs-11 fw-bold text-dark">
                                        {toHumanReadable(key)}
                                      </p>
                                      {typeof value === "object" &&
                                      value !== null ? (
                                        // Handle nested object
                                        Object.entries(value).map(
                                          (
                                            [nestedKey, nestedValue],
                                            nestedIndex
                                          ) => (
                                            <div
                                              key={nestedIndex}
                                              className="d-flex align-items-center py-1 justify-content-between"
                                            >
                                              <p className="p-0 m-0 fs-11 fw-bold text-dark">
                                                {toHumanReadable(nestedKey)}
                                              </p>
                                              <p
                                                className={`p-0 m-0 fs-11 fw-semibold ${
                                                  nestedValue === "True"
                                                    ? "text-success"
                                                    : nestedValue === "False"
                                                    ? "text-danger"
                                                    : "text-secondary"
                                                }`}
                                              >
                                                {nestedValue || "No Data"}
                                              </p>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <p
                                          className={`p-0 m-0 fs-11 fw-semibold ${
                                            value === "True"
                                              ? "text-success"
                                              : value === "False"
                                              ? "text-danger"
                                              : "text-secondary"
                                          }`}
                                        >
                                          {value || "No Data"}
                                        </p>
                                      )}
                                    </div>
                                  )
                                )
                              )
                            ) : (
                              <div className="d-flex align-items-center py-1 justify-content-between">
                                <p className="p-0 m-0 fs-11 fw-bold text-dark">
                                  {toHumanReadable(subCategory)}
                                </p>
                                <p
                                  className={`p-0 m-0 fs-11 fw-semibold ${
                                    subData === "True"
                                      ? "text-success"
                                      : subData === "False"
                                      ? "text-danger"
                                      : "text-secondary"
                                  }`}
                                >
                                  {subData || "No Data"}
                                </p>
                              </div>
                            )}
                          </CustomAccordian>
                        )
                      )
                    ) : (
                      Object.entries(data).map(([key, value], innerIndex) => (
                        <div
                          key={innerIndex}
                          className="d-flex align-items-center py-1 justify-content-between"
                        >
                          <p className="p-0 m-0 fs-11 fw-bold  text-dark">
                            {toHumanReadable(key)}
                          </p>
                          <p
                            className={`p-0 m-0 fs-11 fw-semibold ${
                              value === "True"
                                ? "text-success"
                                : value === "False"
                                ? "text-danger"
                                : "text-secondary"
                            }`}
                          >
                            {typeof value === "object"
                              ? Object.values(value).join(", ")
                              : value}
                          </p>
                        </div>
                      ))
                    )}
                  </CustomAccordian>
                </div>
              )
          )}
        </div>
      ))}
    </div>
  );
};

function LinuxClientDetail() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const [skin, setSkin] = useState(currentSkin);
  const [handleNICModal, setHandleNICModal] = useState(false);
  const [handlePortModal, setHandlePortModal] = useState(false);
  const [handleAntiVirusModal, setHandleAntiVirusModal] = useState(false);
  const [handleUsbHistoryModal, setHandleUsbHistoryModal] = useState(false);
  const navigate = useNavigate();

  const [handleBlueToothInfoModal, setHandleBlueToothInfoModal] =
    useState(false);
  const [
    handleBlueToothInfoConnectedModal,
    setHandleBlueToothInfoConnectedModal,
  ] = useState(false);

  const [handleInstalledPage, setHandleInstalledPage] = useState(1);
  const [handleInstalledRowPage, setHandleRowPage] = useState(5);

  const [handleServicePage, setHandleServicePage] = useState(1);
  const [handleServiceRowPage, setHandleServiceRowPage] = useState(10);

  const [handleStartUpPage, setHandleStartUpPage] = useState(1);
  const [handleStartUpRowPage, setHandleStartUpRowPage] = useState(10);

  const [establishedConnectionPage, setEstablishedConnectionPage] = useState(1);
  const [establishedConnectionRowPage, setEstablishedConnectionRowPage] =
    useState(10);

  const [highCpuUsagePage, setHighCpuUsagePage] = useState(1);
  const [highCpuUsageRowPage, setHighCpuUsageRowPage] = useState(10);

  const [ramUsagePage, setRamUsagePage] = useState(1);
  const [ramUsageRowPage, setRamUsageRowPage] = useState(10);

  const [sharedDirPage, setSharedDirPage] = useState(1);
  const [sharedDirRowPage, setSharedDirRowPage] = useState(10);

  const [internalHardDrivePage, setInternalHardDrivePage] = useState(10);
  const [internalHardDriveRowPage, setInternalHardDriveRowPage] = useState(10);

  const [handleEventsTriggeredPage, setHandleEventsTriggeredPage] =
    useState(10);
  const [handleEventsTriggeredRowPage, setHandleEventsTriggeredRowPage] =
    useState(10);

  const [handleUsbFilePage, setHandleUsbFilePage] = useState(1);
  const [handleUsbFileRowPage, setHandleUsbFileRowPage] = useState(5);

  const [avInfoPage, setAVInfoPage] = useState(1);
  const [avInfoRowPage, setAVInfoRowPage] = useState(5);

  const [usbHistoryPage, setUsbHistoryPage] = useState(1);
  const [usbHistoryRowPage, setUsbHistoryRowPage] = useState(5);
  const textColor = "#AAA";
  const location = useLocation();
  const { pcInfoId, timeTrigger, legacyId } = location?.state;
  const { state } = useLocation();

  const dispatch = useDispatch();
  const {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    serviceProgrameData,
    startUpProgrameData,
    recentEventData,
    linuxCisData,
    establishedConnection,
    highCpuusage,
    ramUsage,
    securityControls,
    accountDetail,
    osPatchInformation,
    sharedDir,
    internalHardDrive,
    logError,
    usbFileData,
    auditScore,
    avCards,
    opticalDriveInfo,
    nonWhitelistApps,
    nonWhitelistServices,
    fileIntegrityInfo,
    adminConfig,
    blacklistedIPAddress,
    blacklistedNICIPAddress,
    blacklistedOpenPorts,
  } = useFetchOsLinux(pcInfoId, timeTrigger, legacyId);
  const [fileDark, setFileDark] = useState();

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };

  if (skinMode === "dark") {
    document.body.classList.add("dark-skin"); // Add a class to the body
  } else {
    document.body.classList.remove("dark-skin"); // Remove it when it's not dark mode
  }

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const avNotInstalledRef = useRef(null);
  const osnotpatchedRef = useRef(null);
  const edrNotInstalledRef = useRef(null);
  const usbViolationRef = useRef(null);
  const unRecoogRef = useRef(null);
  const unNetworkRef = useRef(null);
  const unTpmRef = useRef(null);
  const processNotHardenRef = useRef(null);
  const oldAdminpsdRef = useRef(null);
  const zenNetwokNotInsRef = useRef(null);
  const unknowServiceRef = useRef(null);
  const bluetoothTableRef = useRef(null);
  const shareDirectorysRef = useRef(null);
  const hardwareInfoRef = useRef(null);
  const highCpuUsageRef = useRef(null);
  const highRamUsageRef = useRef(null);
  const fileIntegrityFailedRef = useRef(null);
  const osNotHardenedRef = useRef(null);
  const secureBootRef = useRef(null);
  const fipsNotEnabledRef = useRef(null);
  const opticalDriveRef = useRef(null);
  const usbHistoryRef = useRef(null);
  const unRecogSoftwareRef = useRef(null);
  const [series] = useState([70]); // Series for the chart data
  const [options] = useState({
    chart: {
      height: "200px", // Reduced height to 250
      width: "50%", //
      type: "radialBar", // Type of chart
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%", // Make the center hollow
        },
      },
    },
    colors: ["#FF0000"], // Set the color of the radial bar to red (#FF0000)
    labels: ["score"], // Label for the chart
  });
  let activeRef = null;

  const scrollToSection = (ref) => {
    if (ref.current) {
      if (activeRef && activeRef.current) {
        activeRef.current.classList.remove("red-text");
      }

      const targetPosition =
        ref.current.getBoundingClientRect().top + window.scrollY - 90;
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 800;
      let startTime = null;

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easing = easeInOutCubic(progress);

        window.scrollTo(0, startPosition + distance * easing);

        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        } else {
          ref.current.classList.add("scroll-border", "red-text");
          setTimeout(() => {
            ref.current.classList.remove("scroll-border", "red-text");
            activeRef = null;
          }, 2000);
        }
      };

      const easeInOutCubic = (t) => {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      };
      requestAnimationFrame(animation);
    }
  };

  const handleScroll = () => {
    if (state?.title === "AV Not Installed") {
      scrollToSection(avNotInstalledRef);
    } else if (state?.title === "OS Not Patched") {
      scrollToSection(osnotpatchedRef);
    } else if (state?.title === "Firewall Off") {
      scrollToSection(edrNotInstalledRef);
    } else if (state?.title === "AV Malfunction") {
      scrollToSection(avNotInstalledRef);
    } else if (state?.title === "AV Not Updated") {
      scrollToSection(avNotInstalledRef);
    } else if (state?.title === "EDR Not Installed") {
      scrollToSection(edrNotInstalledRef);
    } else if (state?.title === "USB Violations") {
      scrollToSection(usbViolationRef);
    } else if (state?.title === "Unrecognized IP Address") {
      scrollToSection(unRecoogRef);
    } else if (state?.title === "Unknown Network Comm") {
      scrollToSection(unNetworkRef);
    } else if (state?.title === "TPM Not Available") {
      scrollToSection(unTpmRef);
    } else if (state?.title === "Secure Boot Not Enabled") {
      scrollToSection(secureBootRef);
    } else if (state?.title === "Old Admin Passwords") {
      scrollToSection(oldAdminpsdRef);
    } else if (state?.title === "Multiple Root Privilege Account") {
      scrollToSection(oldAdminpsdRef);
    } else if (state?.title === "ZENworks Not Installed") {
      scrollToSection(zenNetwokNotInsRef);
    } else if (state?.title === "Certificate Not Available") {
      scrollToSection(zenNetwokNotInsRef);
    } else if (state?.title === "Unknown Services") {
      scrollToSection(unknowServiceRef);
    } else if (state?.title === "Wi-Fi Enabled") {
      scrollToSection(unRecoogRef);
    } else if (state?.title === "Bluetooth Enabled") {
      scrollToSection(bluetoothTableRef);
    } else if (state?.title === "RDP Enabled") {
      scrollToSection(edrNotInstalledRef);
    } else if (state?.title === "FIPS Not Enabled") {
      scrollToSection(fipsNotEnabledRef);
    } else if (state?.title === "Shared Folders Detected") {
      scrollToSection(shareDirectorysRef);
    } else if (state?.title === "Process Not Hardened") {
      scrollToSection(processNotHardenRef);
    } else if (state?.title === "BIOS Battery Unserviceable") {
      scrollToSection(hardwareInfoRef);
    } else if (state?.title === "High CPU Usage") {
      scrollToSection(highCpuUsageRef);
    } else if (state?.title === "High RAM Usage") {
      scrollToSection(highRamUsageRef);
    } else if (state?.title === "Unwanted Open Ports") {
      scrollToSection(unRecoogRef);
    } else if (state?.title === "File Integrity Check Failed") {
      scrollToSection(fileIntegrityFailedRef);
    } else if (state?.title === "OS Not Hardened") {
      scrollToSection(osNotHardenedRef);
    } else if (state?.title === "CD/DVD Drive Enabled") {
      scrollToSection(opticalDriveRef);
    } else if (state?.title === "Unrecognized Software") {
      scrollToSection(zenNetwokNotInsRef);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleScroll();
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  const findRefForCIS = (category) => {
    const titleState = state?.title;
    if (!titleState) {
      return null;
    }
    if (
      category === "cisProcessHardeningInfo" &&
      titleState === "TPM Not Available"
    ) {
      return unTpmRef;
    }
    if (
      category === "cisAdditionalProcessHardeningInfo" &&
      titleState === "Process Not Hardened"
    ) {
      return processNotHardenRef;
    }
    if (
      category === "cisSecureBootSettingsInfo" &&
      titleState === "Secure Boot Not Enabled"
    ) {
      return secureBootRef;
    }
    if (
      category === "cisNetworkConfigurationInfo" &&
      titleState === "FIPS Not Enabled"
    ) {
      return fipsNotEnabledRef;
    }
    return null;
  };

  const checkIfAutoOpenCIS = (category) => {
    const titleState = state?.title;
    if (!titleState) {
      return false;
    }
    if (
      category === "cisProcessHardeningInfo" &&
      titleState === "TPM Not Available"
    ) {
      return true;
    }
    if (
      category === "cisAdditionalProcessHardeningInfo" &&
      titleState === "Process Not Hardened"
    ) {
      return true;
    }
    if (
      category === "cisSecureBootSettingsInfo" &&
      titleState === "Secure Boot Not Enabled"
    ) {
      return true;
    }
    if (
      category === "cisNetworkConfigurationInfo" &&
      titleState === "FIPS Not Enabled"
    ) {
      return true;
    }
    return false;
  };

  if (logError) {
    dispatch(
      showToaster({
        title: "Log",
        message: "Not log Found",
        color: "warning",
      })
    );
  }

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } catch {
      return timestamp;
    }
  };

  function convertDateFormat(dateStr) {
    const regex = new RegExp(
      `^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)\\s(\\d{1,2})\\s` +
        `(January|February|March|April|May|June|July|August|September|October|November|December)\\s` +
        `(\\d{4})\\s(0?[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])\\s(AM|PM)\\s` +
        `(IST|GMT|UTC|[A-Z]{3})$`
    );

    const monthMap = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const match = dateStr.match(regex);
    if (!match) return dateStr;

    const [, , day, month, year, hour, minute, second, period] = match;

    // Convert hour to 24-hour format
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) hour24 += 12;
    if (period === "AM" && hour24 === 12) hour24 = 0;

    const pad = (num) => num.toString().padStart(2, "0");

    return `${pad(day)}-${monthMap[month]}-${year} ${pad(hour24)}:${pad(
      minute
    )}:${pad(second)}`;
  }

  const handleMenuClick = (e, record) => {};

  const menuAvService = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record.services?.map((serviceObj, index) => {
          const serviceName = Object.keys(serviceObj)[0]; // Extract service name
          const serviceStatus = serviceObj[serviceName]; // Extract service status
          return (
            <Menu.Item key={index}>
              {serviceName}:
              <span
                className={`badge rounded-pill ${
                  serviceStatus === "active"
                    ? "text-bg-success text-white"
                    : "text-bg-danger text-white"
                }`}
                disabled
              >
                {serviceStatus}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  console.log(state?.title, "state?.titlestate?.titlestate?.titlestate?.title");

  const customGutter = {
    "--bs-gutter-x": "8px",
  };

  const tooltipData = {
    basicInfo:
      "Displays endpoint identifiers (serial number, ID, hostname), user details (currently logged in user, user type) and time of cybersecurity audit. Helps easy identification of the endpoint in the network.",
    hardwareInfo:
      "Displays make and model of computer/endpoint, and its hardware details (motherboard, CPU, RAM, hard disk, BIOS battery, and TPM).",
    osInfo:
      "Displays operating system details (OS name, version, architecture, and installation date).",
    networkInfo:
      "Displays network information (IP addresses, MAC address, DNS), NIC card details, list of open ports, and NTP status. Helps to identify type of network—wired or Wi-Fi—the endpoint is currently connected to.",
    securityControls:
      "Displays status of security controls (firewall, EDR, NAC) and status of RDP/SSH. Helps to measure security posture of the endpoint.",
    bluetoothDetails:
      "Displays Bluetooth status, list of paired devices (name, MAC address), and list of connected devices (name, MAC address).",
    usbUsageHistory:
      "Lists USB storage devices (pen drives, hard disks, mobiles) connected to the endpoint. Helps to identify the USB device (name, serial number, make, model, VID, and PID) and its date/time of insertion.",
    avInfo:
      "Lists installed antivirus software with status, services, and version details. Helps to monitor the protection offered by AV.",
    usbFileHistory:
      "Lists files transferred from host to USB devices. Helps to identify exfiltrated files (file path, file type, hash) with the type of operation and its date/time.",
    installedApplications:
      "Lists all installed applications (name, version) with its date of installation. Helps to identify any unauthorized application installed.",
    servicesList:
      "Lists all services (name, description) with its status. Helps to identify any unauthorized service installed.",
    startupPrograms:
      "Lists all programs set to run at startup. Helps to identify any unauthorized program trying to achieve persistence.",
    establishedConnection:
      "Lists established connections (local IP address, local port, remote IP address, remote port) at the time of audit. Highlights the connections made with unauthorized subnets.",
    osPatchInfo:
      "Lists OS patch details (OS name, version, and date applied). Helps to monitor the patch status of the endpoint.",
    userAccounts:
      "Lists users (name and privilege type) of the endpoint. Helps to identify users whose password must change as per policy.",
    highCpuUsage:
      "Lists processes (process name, PID, CPU usage percentage) with high CPU usage.",
    recentEvents:
      "Lists 5 most recent events (type, timestamp) triggered on the endpoint.",
    highRamUsage:
      "Lists processes (process name, PID, RAM usage percentage) with high RAM usage.",
    internalHardDrive:
      "Lists internal hard drives (drive, size, used space, used percentage, available space, and mount point) on the endpoint.",
    sharedDirectoryInfo:
      "Lists shared directory information (directory names, paths, permissions).",
    opticalDriveInfo:
      "Lists optical drive information (description, product, vendor, and physical ID).",
    fileIntegrityInfo:
      "Lists integrity of selected files (host type, file path, file type, actual file hash, expected file hash, and its last modified date). Helps to identify files whose integrity has changed.",
    securityConfigHardeningStatus:
      "Displays security configurations and hardening status mapped to CIS (Center for Internet Security) benchmarks, including compliance levels and identified vulnerabilities. Helps to measure security posture of the endpoint.",
  };
  const [currentPercent, setCurrentPercent] = useState(0);
  const percent = "10";
  useEffect(() => {
    // Smooth transition to the new percentage
    const interval = setInterval(() => {
      if (currentPercent < percent) {
        setCurrentPercent((prev) => Math.min(prev + 1, percent)); // Increment percentage smoothly
      } else if (currentPercent > percent) {
        setCurrentPercent((prev) => Math.max(prev - 1, percent)); // Decrement percentage smoothly
      }
    }, 10); // Update every 10ms

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [currentPercent, percent]);

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4" style={mainStyle}>
        {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb fs-sm mb-1 d-flex">
            <li className="breadcrumb-item">
              {location.pathname === "/" ? (
                <span className="active">Dashboard</span>
              ) : (
                <NavLink exact to="/">
                  Dashboard
                </NavLink>
              )}
            </li>
            <li className="breadcrumb-item">
              {location.pathname === "/linux-dash" ? (
                <span className="active">Linux Dashboard</span>
              ) : (
                <button
                  onClick={() => navigate("/linux-dash")}
                  className="btn btn-link p-0 m-0"
                >
                  Linux Dashboard
                </button>
              )}
            </li>
            <li className="breadcrumb-item">
              {location.pathname === "/endpoints-list" ? (
                <span className="active">{state?.title}</span>
              ) : (
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-link p-0 m-0"
                >
                  {state?.title }
                </button>
              )}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {location.pathname === "/linux-client-Details" ? (
                <span className="active">Endpoint Details</span>
              ) : (
                <button
                  onClick={() => window.location.reload()}
                  className="btn btn-link p-0 m-0"
                >
                  Endpoint Details
                </button>
              )}
            </li>
          </ol>
        </nav> */}

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb fs-sm mb-1 d-flex">
            <li className="breadcrumb-item">
              {location.pathname === "/" ? (
                <span className="active">Dashboard</span>
              ) : (
                <NavLink exact to="/">
                  Dashboard
                </NavLink>
              )}
            </li>
            <li className="breadcrumb-item">
              {location.pathname === "/linux-dash" ? (
                <span className="active">Linux Dashboard</span>
              ) : (
                <button
                  onClick={() => navigate("/linux-dash")}
                  className="btn btn-link p-0 m-0"
                >
                  Linux Dashboard
                </button>
              )}
            </li>
            {state?.title && (
              <li className="breadcrumb-item">
                {location.pathname === "/endpoints-list" ? (
                  <span className="active">{state.title}</span>
                ) : (
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-link p-0 m-0"
                  >
                    {state.title}
                  </button>
                )}
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {location.pathname === "/linux-client-Details" ? (
                <span className="active text-secondary">Endpoint Details</span>
              ) : (
                <button
                  onClick={() => window.location.reload()}
                  className="btn btn-link p-0 m-0"
                >
                  Endpoint Details
                </button>
              )}
            </li>
          </ol>
        </nav>

        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center justify-content-center gap-3">
            <span className="d-flex align-items-center justify-content-center fs-sm-normal ps-2">
              <Link to="/" className="shadow" style={{ borderRadius: "25px" }}>
                <img src={linuxIcon} alt="Window-logo" width={55} height={55} />
              </Link>

              <p className="mx-2 fs-18 m-0 p-0">|</p>
              <p className="fs-18 fw-bolder p-0 m-0 ">Endpoint Hostname:</p>
            </span>

            <p className="p-0 m-0 fs-16 fw-bold text-primary">
              {basicInfoData?.[0]?.Hostname || "Client Detail"}
            </p>
            <div>
              {/* <div style={{ width: "117px", height: "115px" }}>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="radialBar"
                  height={160}
                />
              </div> */}

              <div id="specificChart" className="donut-size">
                <div className="pie-wrapper">
                  <span className="label">
                    <span className="num">{currentPercent}%</span>
                    {/* <span className="smaller">%</span> */}
                  </span>
                  <div className="pie">
                    <div
                      className="left-side half-circle"
                      style={{
                        transform: `rotate(${(currentPercent / 100) * 180}deg)`,
                      }}
                    ></div>
                    <div
                      className="right-side half-circle"
                      style={{
                        transform: `rotate(${(currentPercent / 100) * 180}deg)`,
                      }}
                    ></div>
                  </div>
                  <div className="shadow"></div>
                </div>
              </div>
              <div id="html-dist"></div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-4">
            <GenratePdf
              osData={osData}
              osloading={osloading}
              networkData={networkData}
              basicInfoData={basicInfoData}
              antivirusInfoData={antivirusInfoData}
              hardwareInfoData={hardwareInfoData}
              usbInfoData={usbInfoData}
              bluetoothInfoData={bluetoothInfoData}
              installedProgrameData={installedProgrameData}
              serviceProgrameData={serviceProgrameData}
              startUpProgrameData={startUpProgrameData}
              recentEventData={recentEventData}
              linuxCisData={linuxCisData}
              establishedConnection={establishedConnection}
              highCpuusage={highCpuusage}
              ramUsage={ramUsage}
              securityControls={securityControls}
              accountDetail={accountDetail}
              osPatchInformation={osPatchInformation}
              sharedDir={sharedDir}
              internalHardDrive={internalHardDrive}
              logError={logError}
              usbFileData={usbFileData}
              avCards={avCards}
              opticalDriveInfo={opticalDriveInfo}
            />

            <div className="d-flex justify-content-center align-items-center">
              <Button
                className="btn-white   rounded-md d-flex align-items-center gap-2"
                onClick={() => navigate(-1)}
              >
                <i
                  className="ri-arrow-left-line fs-18 lh-1 mr-2"
                  style={{ verticalAlign: "middle", marginRight: "3px" }}
                ></i>
                <span>Back</span>
              </Button>
            </div>
          </div>
        </div>
        {osloading ? (
          <PageLoader />
        ) : (
          <>
            <div class="row" style={customGutter}>
              <div class="col">
                <HistoryCard
                  headerName={"Basic Information"}
                  description={tooltipData["basicInfo"]}
                >
                  {basicInfoData?.length > 0 ? (
                    basicInfoData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className=" m-0 fw-medium text-end fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div
                              className="p-0 m-0 fw-medium text-dark fs-11 text-end col-6"
                              style={{
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {value || "NA"}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Basic Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard
                  headerName={"Hardware Information"}
                  description={tooltipData["hardwareInfo"]}
                >
                  {hardwareInfoData?.length > 0 ? (
                    hardwareInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item)?.map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            {key === "RAM" ? (
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu className="p-2 card-one shadow-md">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-dark text-end">
                                        Total
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.totalRAM || "NA"}
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between ">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end text-dark">
                                        Used
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end text-dark">
                                        {hardwareInfoData[1]?.ramData
                                          ?.usedRAM || "NA"}
                                      </p>
                                    </div>

                                    {/* <div className="d-flex align-items-center justify-content-between">
                                            <p className="p-0 m-0 fw-medium fs-10 fw-semibold text-end">
                                              Available
                                            </p>
                                            <p className="p-0 m-0  fs-10 fw-semibold text-end">
                                              {hardwareInfoData[1]?.ramData
                                                ?.availableRAM || "NA"}
                                            </p>
                                          </div> */}
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end text-dark">
                                        Free
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end text-dark">
                                        {hardwareInfoData[1]?.ramData
                                          ?.availableRAM || "NA"}
                                      </p>
                                    </div>
                                  </Menu>
                                }
                              >
                                <p
                                  className="p-0 m-0 fw-medium fs-11 text-primary  w-50 text-end text-dark"
                                  style={{ cursor: "pointer" }}
                                >
                                  {value}
                                </p>
                              </Dropdown>
                            ) : key === "BIOS Battery State" ? (
                              <div
                                className="p-0 m-0 fw-medium fs-11 text-end text-dark"
                                style={{
                                  color:
                                    value === "Unserviceable"
                                      ? "red"
                                      : "inherit",
                                }}
                              >
                                {value}
                              </div>
                            ) : (
                              <div className="p-0 m-0 fw-medium fs-11 text-end text-dark">
                                {value}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Hardware Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard
                  headerName={"OS Information"}
                  description={tooltipData["osInfo"]}
                >
                  {osData?.length > 0 ? (
                    osData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <p
                              className={` m-0 fw-medium text-end fs-11 ${
                                value === true ? "text-success" : "text-dark"
                              }`}
                            >
                              {typeof value === "boolean"
                                ? value.toString()
                                : value}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Os Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div class="col" ref={unRecoogRef}>
                <HistoryCard
                  headerName={"Network Information"}
                  description={tooltipData["networkInfo"]}
                  paddingBottom={true}
                >
                  {/* <p className="fs-9 p-0 my-2 text-primary fw-semibold">
                        Current Network
                      </p> */}

                  {networkData?.length > 0 ? (
                    networkData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item)?.map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 text-dark">
                              <strong>{key}</strong>
                            </p>
                            <div className="fw-medium fs-11 text-end col-6 text-dark">
                              {key === "DNS Server" ? (
                                <Dropdown
                                  trigger={["click"]}
                                  overlay={
                                    <Menu className="card-one shadow-md">
                                      {value?.map((v, i) => (
                                        <Menu.Item key={i}>
                                          <span className="fw-medium fs-11 fw-semibold text-end text-dark">
                                            {v}
                                          </span>
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  }
                                  className="p-0 m-0"
                                >
                                  <p
                                    className="fw-medium fs-11 text-primary text-end ml-4rem-important text-dark"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {value[0]}
                                  </p>
                                </Dropdown>
                              ) : key === "IP Address" ? (
                                <div>
                                  {value
                                    ? value.split(" ").map((ip, i) => (
                                        <p
                                          className="p-0 0 fw-medium m-0 fs-11 text-dark"
                                          key={i}
                                          style={{
                                            color:
                                              blacklistedIPAddress?.includes(ip)
                                                ? "red"
                                                : "inherit",
                                          }}
                                          // className="m-0"
                                        >
                                          {ip}
                                        </p>
                                      ))
                                    : "NA"}
                                </div>
                              ) : key === "Network Category" &&
                                state?.title === "Wi-Fi Enabled" &&
                                value === "Wifi" ? (
                                <div
                                  className="p-0 0 fw-medium m-0 fs-11 text-dark"
                                  style={{ color: "red" }}
                                >
                                  {value || "NA"}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}

                        <div className="d-flex align-items-center gap-2 justify-content-between my-3">
                          <CustomButton
                            buttonTitle={"Veiw Open Ports"}
                            handleClick={() => setHandlePortModal(true)}
                          />
                          <CustomButton
                            buttonTitle={"View NIC Cards"}
                            handleClick={() => setHandleNICModal(true)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Network Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>
            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={avNotInstalledRef}>
                <HistoryCard
                  headerName={"AV Information"}
                  description={tooltipData["avInfo"]}
                >
                  <div className="pb-3">
                    <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) => index + 1,
                          width: "70px",
                        },
                        {
                          name: "Name",
                          selector: (v, index) => (
                            <Tippy content={v?.name}>
                              <div>{v?.name}</div>
                            </Tippy>
                          ),
                          width: "110px",
                        },
                        {
                          name: "Status",
                          selector: (v) => (
                            <span
                              className={`badge rounded-pill ${
                                v.installed === true
                                  ? "text-bg-success text-white"
                                  : "text-bg-danger text-white"
                              }`}
                              disabled
                            >
                              {v.installed === true ? "Active" : "InActive"}
                            </span>
                          ),
                          width: "120px",
                        },
                        {
                          name: "Version",
                          selector: (v) => v?.version,
                          cell: (row) => {
                            const avDetailsFromConfig = adminConfig?.avDetails;
                            for (
                              let i = 0;
                              i < avDetailsFromConfig?.length;
                              i++
                            ) {
                              if (
                                avDetailsFromConfig[i]?.avName === row?.name
                              ) {
                                return (
                                  <Tippy content={row?.version}>
                                    <div
                                      style={{
                                        color:
                                          avDetailsFromConfig[i]?.avVersion !==
                                            row?.version && "red",
                                      }}
                                    >
                                      {row?.version}
                                    </div>
                                  </Tippy>
                                );
                              }
                            }
                            return (
                              <Tippy content={row?.version}>
                                <div>{row?.version}</div>
                              </Tippy>
                            );
                          },
                          width: "120px",
                        },

                        {
                          name: "Services",
                          selector: (row) => row?.services,
                          cell: (row) => {
                            const avArray = row?.services || [];

                            if (avArray?.length === 1) {
                              const serviceName = Object.keys(avArray[0])[0];
                              const serviceStatus = avArray[0][serviceName];
                              return (
                                <span
                                  style={
                                    serviceStatus !== "active"
                                      ? { color: "red" }
                                      : {}
                                  }
                                >
                                  {serviceName}: {serviceStatus}
                                </span>
                              );
                            } else if (avArray?.length > 1) {
                              return (
                                <Dropdown
                                  overlay={menuAvService(row)}
                                  trigger={["click"]}
                                >
                                  <Button className="bg-transparent border-0 p-0 m-0">
                                    {avArray.map((serviceObj, index) => {
                                      const serviceName =
                                        Object.keys(serviceObj)[0];
                                      const serviceStatus =
                                        Object.values(serviceObj)[0];
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline",
                                            color:
                                              serviceStatus !== "active"
                                                ? "red"
                                                : "inherit",
                                          }}
                                        >
                                          {serviceName}
                                          {index !== avArray.length - 1
                                            ? ", "
                                            : ""}
                                        </div>
                                      );
                                    })}
                                  </Button>
                                </Dropdown>
                              );
                            } else {
                              return <span>NA</span>;
                            }
                          },
                          width: "340px", // Adjust width if necessary
                        },
                      ]}
                      data={avCards[0]}
                      pagination
                      paginationPerPage={5}
                      customStyles={currentStyles}
                      onChangePage={(page) => {
                        setAVInfoPage(page);
                      }}
                      onChangeRowsPerPage={(page) => setAVInfoRowPage(page)}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">AV is not available</p>
                      }
                    />
                  </div>
                </HistoryCard>
              </div>
              <div class="col">
                <HistoryCard
                  headerName={"Security Controls"}
                  description={tooltipData["securityControls"]}
                >
                  {securityControls?.length > 0 ? (
                    securityControls?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) =>
                          key === "Firewall Status" && value === "OFF" ? (
                            <div
                              key={index2}
                              className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                            >
                              <p className="p-0 fw-medium m-0 fs-11 col-4 tex-dark">
                                <strong className="p-0 fw-medium m-0 fs-11 col-4 tex-dark">
                                  {key}
                                </strong>
                              </p>
                              <div
                                className="p-0 m-0 fw-medium fs-11 text-end col-6 "
                                style={{ color: "red" }}
                              >
                                <p className="p-0 m-0 fw-medium fs-11 text-end col-6 text-dark">
                                  {" "}
                                  {value || "NA"}{" "}
                                </p>
                              </div>
                            </div>
                          ) : (key === "RDP Status" || key === "SSH Status") &&
                            value === "Enabled" ? (
                            <div
                              key={index2}
                              className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                            >
                              <p className="p-0 fw-medium m-0 fs-11 col-4 text-dark">
                                <strong>{key}</strong>
                              </p>
                              <div
                                className="p-0 m-0 fw-medium fs-11 text-end col-6 text-dark"
                                style={{ color: "red" }}
                              >
                                {value || "NA"}
                              </div>
                            </div>
                          ) : (
                            <div
                              key={index2}
                              className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                            >
                              <p className="p-0 fw-medium m-0 fs-11 col-4 text-dark">
                                <strong>{key}</strong>
                              </p>
                              <div className="p-0 m-0 fw-medium fs-11 text-end col-6 text-dark">
                                {value || "NA"}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">
                        Security Controls Info is not available
                      </p>
                    </div>
                  )}
                </HistoryCard>
              </div>
              <div className="col">
                <HistoryCard
                  headerName={"Bluetooth Details"}
                  description={tooltipData["bluetoothDetails"]}
                >
                  {bluetoothInfoData[0]?.length > 0 ? (
                    bluetoothInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) =>
                          key === "Bluetooth Status" && value === "On" ? (
                            <div
                              key={index2}
                              className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                            >
                              <p className="p-0 fw-medium m-0 fs-11 text-dark">
                                <strong>{key}</strong>
                              </p>
                              <p
                                className="p-0 m-0 fw-medium fs-11 text-end text-dark"
                                style={{ color: "red" }}
                              >
                                {value}
                              </p>
                            </div>
                          ) : (
                            <div
                              key={index2}
                              className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                            >
                              <p className="p-0 fw-medium m-0 fs-11 text-dark">
                                <strong>{key}</strong>
                              </p>
                              <p className="p-0 m-0 fw-medium fs-11 text-end text-dark">
                                {value}
                              </p>
                            </div>
                          )
                        )}
                        <div className="d-flex w-100 gap-2 align-items-center justify-content-between mt-5">
                          <CustomButton
                            buttonTitle={"Paired Devices History"}
                            handleClick={() =>
                              setHandleBlueToothInfoModal(true)
                            }
                          />
                          <CustomButton
                            buttonTitle={"Connected Devices History"}
                            handleClick={() =>
                              setHandleBlueToothInfoConnectedModal(true)
                            }
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Bluetooth Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>
            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={usbViolationRef}>
                <HistoryCard
                  headerName={"USB Usage History"}
                  description={tooltipData["usbUsageHistory"]}
                >
                  <div>
                    <DataTable
                      fixedHeader={true}
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (usbHistoryPage - 1) * usbHistoryRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                        // {
                        //   name: "S. No.",
                        //   selector: (_, index) => index + 1,
                        //   width: "70px",

                        // },
                        {
                          name: "Date/Time",
                          selector: (v) => (
                            <Tippy content={v?.time}>
                              <div>{v?.time}</div>
                            </Tippy>
                          ),
                          width: "160px",
                        },
                        {
                          name: "Device Name",
                          selector: (v) => v?.usbName,
                          cell: (row) => {
                            const flag =
                              row?.falsePositive === false &&
                              row?.is_Linked === false &&
                              (row?.IsadminActionDone === "pending" ||
                                row?.IsadminActionDone === "inProgress");
                            return (
                              <Tippy content={row?.usbName}>
                                <div
                                  style={{
                                    color: flag ? "red" : "inherit",
                                  }}
                                >
                                  {row?.usbName}
                                </div>
                              </Tippy>
                            );
                          },
                          width: "140px",
                        },
                        {
                          name: "Type",
                          selector: (v) => (
                            <Tippy content={v?.interfaceType}>
                              <div>{v?.interfaceType}</div>
                            </Tippy>
                          ),
                          width: "60px",
                        },
                        {
                          name: "Manufacturer",
                          selector: (v) => (
                            <Tippy content={v?.usbManufacturer}>
                              <div>{v?.usbManufacturer}</div>
                            </Tippy>
                          ),
                          width: "120px",
                        },
                        {
                          name: "Serial Number",
                          selector: (v) => v?.usbSerialNumber,
                          cell: (row) => {
                            const flag =
                              row?.falsePositive === false &&
                              row?.is_Linked === false &&
                              (row?.IsadminActionDone === "pending" ||
                                row?.IsadminActionDone === "inProgress");
                            return (
                              <Tippy content={row?.usbSerialNumber}>
                                <div
                                  style={{
                                    color: flag ? "red" : "inherit",
                                  }}
                                >
                                  {row?.usbSerialNumber}
                                </div>
                              </Tippy>
                            );
                          },
                          width: "200px",
                        },
                        {
                          name: "Vendor ID",
                          selector: (v) => (
                            <Tippy content={v?.usbVendorId}>
                              <div>{v?.usbVendorId}</div>
                            </Tippy>
                          ),
                        },
                        {
                          name: "Product ID",
                          selector: (v) => v?.usbProductId,
                        },
                      ]}
                      data={usbInfoData[1]?.usbHistory}
                      pagination
                      paginationPerPage={5}
                      onChangePage={(page) => {
                        setUsbHistoryPage(page);
                      }}
                      customStyles={currentStyles}
                      onChangeRowsPerPage={(page) => setUsbHistoryRowPage(page)}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          USB History is not available
                        </p>
                      }
                    />
                  </div>
                </HistoryCard>
              </div>
              <div className="col-6" ref={usbHistoryRef}>
                <HistoryCard
                  headerName={"USB File Transfer History"}
                  description={tooltipData["usbFileHistory"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleUsbFilePage - 1) * handleUsbFileRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Date",
                        selector: (v) => (
                          <Tippy content={v?.timeStamp}>
                            <div>{v?.timeStamp}</div>
                          </Tippy>
                        ),
                        width: "200px",
                      },
                      {
                        name: "EventType",
                        selector: (v) => v?.eventType,
                        width: "100px",
                      },

                      {
                        name: "Path",
                        selector: (v) => (
                          <Tippy content={v?.path ? v?.path : "NA"}>
                            <div>{v?.path ? v?.path : "NA"}</div>
                          </Tippy>
                        ),
                      },
                      // {
                      //   name: "Destination",
                      //   selector: (v) => (
                      //     <Tippy content={v?.destinationPath}>
                      //       <div>
                      //         {v?.destinationPath ? v?.destinationPath : "NA"}
                      //       </div>
                      //     </Tippy>
                      //   ),
                      // },
                      {
                        name: "File Type",
                        selector: (v) => (
                          <Tippy content={v?.fileType || "NA"}>
                            <div>{v.fileType || "NA"}</div>
                          </Tippy>
                        ),
                        width: "180px",
                      },
                      {
                        name: "File Hash",
                        selector: (v) => (
                          <Tippy content={v?.fileHash || "NA"}>
                            <div>{v?.fileHash || "NA"}</div>
                          </Tippy>
                        ),
                        width: "300px",
                      },
                    ]}
                    data={usbFileData}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={handleUsbFileRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      setHandleUsbFilePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleUsbFileRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        File transfer history not available.
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={zenNetwokNotInsRef}>
                <HistoryCard
                  headerName={"Installed Applications"}
                  description={tooltipData["installedApplications"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (v, index) =>
                          (handleInstalledPage - 1) * handleInstalledRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Applications",
                        selector: (v) => (
                          <Tippy content={v?.name}>
                            <div
                              style={{
                                color: nonWhitelistApps?.includes(v?._id)
                                  ? "red"
                                  : "inherit",
                              }}
                            >
                              {v?.name}
                            </div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Version",
                        selector: (v) => (
                          <Tippy content={v?.version || "NA"}>
                            <div>{v?.version || "NA"}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Date",
                        selector: (v) => (
                          <Tippy content={convertDateFormat(v?.date) || "NA"}>
                            <div>{convertDateFormat(v?.date) || "NA"}</div>
                          </Tippy>
                        ),
                      },
                    ]}
                    data={installedProgrameData}
                    customStyles={currentStyles}
                    pagination
                    highlightOnHover
                    paginationPerPage={10} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      setHandleInstalledPage(page);
                    }}
                    onChangeRowsPerPage={(page) => setHandleRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Installed Programme is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6" ref={unknowServiceRef}>
                <HistoryCard
                  headerName={"Services List"}
                  description={tooltipData["servicesList"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleServicePage - 1) * handleServiceRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v) => (
                          <Tippy content={v?.displayName}>
                            <div
                              style={{
                                color: nonWhitelistServices?.includes(v?._id)
                                  ? "red"
                                  : "",
                              }}
                            >
                              {v?.displayName || "NA"}
                            </div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Description",
                        selector: (v) => (
                          <Tippy content={v?.description}>
                            <div>{v?.description}</div>
                          </Tippy>
                        ),
                      },
                      { name: "Status", selector: (v) => v?.status },
                    ]}
                    data={serviceProgrameData}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={handleServiceRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      setHandleServicePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleServiceRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Service program info is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6">
                <HistoryCard
                  headerName={"Startup Programs List"}
                  description={tooltipData["startupPrograms"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (handleStartUpPage - 1) * handleStartUpRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      { name: "Program Name", selector: (v, i) => v },
                    ]}
                    data={startUpProgrameData}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={handleStartUpRowPage}
                    onChangePage={(page) => setHandleStartUpPage(page)}
                    onChangeRowsPerPage={(page) =>
                      setHandleStartUpRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Startup program info is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>

              <div className="col-6" ref={unNetworkRef}>
                <HistoryCard
                  headerName={"Established Connection"}
                  description={tooltipData["establishedConnection"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (establishedConnectionPage - 1) *
                            establishedConnectionRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Local Address",
                        selector: (v, i) => (
                          <Tippy content={v?.localAddress}>
                            <div>{v?.localAddress}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Local Port",
                        selector: (v, i) => v?.localPort,
                      },
                      {
                        name: "Foreign Address",
                        selector: (v, i) => (
                          <Tippy content={v?.foreignAddress}>
                            <div style={{ color: v?.isOutside ? "red" : "" }}>
                              {v?.foreignAddress}
                            </div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Foreign Port",
                        selector: (v, i) => v?.foreignPort,
                      },
                    ]}
                    data={establishedConnection}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={establishedConnectionRowPage}
                    onChangePage={(page) => setEstablishedConnectionPage(page)}
                    onChangeRowsPerPage={(page) =>
                      setEstablishedConnectionRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Established Connection is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={osnotpatchedRef}>
                <HistoryCard
                  headerName={"OS Patch Information"}
                  description={tooltipData["osPatchInfo"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v, i) => (
                          <Tippy content={v?.name}>
                            <div>{v?.name}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Version",
                        selector: (v, i) => v?.version,
                        cell: (row) => {
                          const osDetailsFromConfig = adminConfig?.osDetails;
                          for (
                            let i = 0;
                            i < osDetailsFromConfig?.length;
                            i++
                          ) {
                            if (osDetailsFromConfig[i]?.osName === row?.name) {
                              return (
                                <Tippy content={row?.version}>
                                  <div
                                    style={{
                                      color:
                                        osDetailsFromConfig[i]?.osVersion !==
                                        row?.version?.split(" ")[0]
                                          ? "red"
                                          : "inherit",
                                    }}
                                  >
                                    {row?.version}
                                  </div>
                                </Tippy>
                              );
                            }
                          }
                          return (
                            <Tippy content={row?.version}>
                              <div>{row?.version}</div>
                            </Tippy>
                          );
                        },
                      },
                      {
                        name: "Date Applied",
                        selector: (v, i) => (
                          <Tippy content={formatTimestamp(v?.date)}>
                            <div>{formatTimestamp(v?.date)}</div>
                          </Tippy>
                        ),
                      },
                    ]}
                    data={osPatchInformation}
                    customStyles={currentStyles}
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        OS Patch is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>

              <div className="col-6" ref={oldAdminpsdRef}>
                <HistoryCard
                  headerName={"User Accounts"}
                  description={tooltipData["userAccounts"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "User Name",
                        selector: (v) => (
                          <Tippy content={v?.userName}>
                            <div>{v?.userName}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Type",
                        selector: (v) => (
                          <div
                            style={{
                              color:
                                accountDetail[0]?.adminAccountsAreMultiple
                                  ?.multipleAdminCount &&
                                accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                                  v?.userName
                                )
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            {accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                              v?.userName
                            )
                              ? "Admin"
                              : "User"}
                          </div>
                        ),
                      },
                      {
                        name: "Password Age",
                        selector: (v) => v?.passwordAge,
                        cell: (row) => (
                          <Tippy content={row?.passwordAge}>
                            <div
                              style={{
                                color:
                                  row?.passwordAge >
                                  adminConfig?.passwordThresholdAge
                                    ? "red"
                                    : "inherit",
                              }}
                            >
                              {row?.passwordAge}
                            </div>
                          </Tippy>
                        ),
                      },
                    ]}
                    data={accountDetail[0]?.usersPasswordAge || []}
                    pagination
                    paginationPerPage={handleServiceRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      setHandleServicePage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleServiceRowPage(page)
                    }
                    customStyles={currentStyles}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        User Account Detail is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={highCpuUsageRef}>
                <HistoryCard
                  headerName={"High CPU Usage"}
                  description={tooltipData["highCpuUsage"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (highCpuUsagePage - 1) * highCpuUsageRowPage +
                          index +
                          1,
                        width: "70px",
                      },
                      {
                        name: "Process Name",
                        selector: (v, i) => (
                          <Tippy content={v?.name}>
                            <div style={{ color: "red" }}>{v?.name}</div>
                          </Tippy>
                        ),
                        width: "350px",
                      },
                      { name: "PID", selector: (v, i) => v?.pid },
                      {
                        name: "Usage Percent",
                        selector: (v, i) => `${v?.cpuPercent} %`,
                      },
                    ]}
                    data={highCpuusage}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={highCpuUsageRowPage}
                    onChangePage={(page) => setHighCpuUsagePage(page)}
                    onChangeRowsPerPage={(page) => setHighCpuUsageRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Process with high CPU usage not found.
                      </p>
                    }
                  />
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard
                  headerName={"Recent Events Triggered"}
                  description={tooltipData["recentEvents"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Event Type",
                        selector: (v) => (
                          <Tippy content={v?.eventTriggerType}>
                            <div>{v?.eventTriggerType}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Timestamp",
                        selector: (v) => (
                          <Tippy
                            content={v?.serverTimestamp.replace(", ", " ")}
                          >
                            <div>{v?.serverTimestamp.replace(", ", " ")}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "SnapShot",
                        selector: (v) => "NA",
                      },
                    ]}
                    data={recentEventData[0]}
                    pagination
                    paginationPerPage={handleEventsTriggeredRowPage} // Adjust the number of rows per page as needed
                    onChangePage={(page) => {
                      setHandleEventsTriggeredPage(page);
                    }}
                    onChangeRowsPerPage={(page) =>
                      setHandleEventsTriggeredRowPage(page)
                    }
                    customStyles={currentStyles}
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Recent Events Triggered is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={highRamUsageRef}>
                <HistoryCard
                  headerName={"High RAM Usage"}
                  description={tooltipData["highRamUsage"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (ramUsagePage - 1) * ramUsageRowPage + index + 1,
                        width: "70px",
                      },
                      {
                        name: "Process Name",
                        selector: (v, i) => (
                          <Tippy content={v?.name}>
                            <div style={{ color: "red" }}>{v?.name}</div>
                          </Tippy>
                        ),
                        width: "350px",
                      },
                      { name: "PID", selector: (v, i) => v?.pid },
                      {
                        name: "Usage Percent",
                        selector: (v, i) => `${v?.memoryPercent} %`,
                      },
                    ]}
                    data={ramUsage}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={ramUsageRowPage}
                    onChangePage={(page) => setRamUsagePage(page)}
                    onChangeRowsPerPage={(page) => setRamUsageRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Process with high RAM usage not found.
                      </p>
                    }
                  />
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard
                  headerName={"Internal Hard Drive"}
                  description={tooltipData["internalHardDrive"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) => index + 1,
                        width: "70px",
                      },
                      {
                        name: "Drive",
                        selector: (v) => (
                          <Tippy content={v?.fileSystem}>
                            <div>{v?.fileSystem}</div>
                          </Tippy>
                        ),
                        // width: "350px",
                      },
                      {
                        name: "Size",
                        selector: (v) => {
                          const totalSpace =
                            parseFloat(v?.size).toFixed(2) || 0;
                          return `${totalSpace} GB`;
                        },
                      },
                      {
                        name: "Used",
                        selector: (v) => {
                          const usedSpace = parseFloat(v?.used).toFixed(2) || 0;
                          return `${usedSpace} GB`;
                        },
                      },
                      {
                        name: "Used Percent",
                        selector: (v) => {
                          const usedPercent =
                            parseFloat(v?.usedPercent).toFixed(2) || 0;
                          return `${usedPercent}%`;
                        },
                      },
                      {
                        name: "Available",
                        selector: (v) => {
                          const availSpace =
                            parseFloat(v?.available).toFixed(2) || 0;
                          return `${availSpace} GB`;
                        },
                      },
                      {
                        name: "Mount Point",
                        selector: (v) => (
                          <Tippy content={v?.mounted}>
                            <div>{v?.mounted}</div>
                          </Tippy>
                        ),
                      },
                    ]}
                    data={internalHardDrive}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={internalHardDrivePage}
                    onChangePage={(page) => setInternalHardDrivePage(page)}
                    onChangeRowsPerPage={(page) =>
                      setInternalHardDriveRowPage(page)
                    }
                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        Established Connection is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col-6" ref={shareDirectorysRef}>
                <HistoryCard
                  headerName={"Shared Directory Information"}
                  description={tooltipData["sharedDirectoryInfo"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "S. No.",
                        selector: (_, index) =>
                          (sharedDirPage - 1) * sharedDirRowPage + index + 1,
                        width: "70px",
                      },
                      {
                        name: "Name",
                        selector: (v, i) => (
                          <Tippy content={v?.name}>
                            <div style={{ color: "red" }}>{v?.name}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Path",
                        selector: (v, i) => (
                          <Tippy content={v?.path}>
                            <div>{v?.path}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Permissions",
                        selector: (v, i) => (
                          <Tippy
                            content={`Owner : ${
                              v?.permission?.owner || "N/A"
                            }, Group : ${
                              v?.permission?.group || "N/A"
                            }, Other : ${v?.permission?.other || "N/A"}`}
                          >
                            <div>
                              Owner : {v?.permission?.owner || "N/A"}, Group :{" "}
                              {v?.permission?.group || "N/A"}, Other :{" "}
                              {v?.permission?.other || "N/A"}
                            </div>
                          </Tippy>
                        ),
                      },
                    ]}
                    data={sharedDir}
                    customStyles={currentStyles}
                    pagination
                    paginationPerPage={sharedDirRowPage}
                    onChangePage={(page) => setSharedDirPage(page)}
                    onChangeRowsPerPage={(page) => setSharedDirRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Shared directory data is not available
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
              <div className="col-6" ref={opticalDriveRef}>
                <HistoryCard
                  headerName={"Optical Drive Information"}
                  description={tooltipData["opticalDriveInfo"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "Description",
                        selector: (v) => (
                          <Tippy content={v?.description}>
                            <div style={{ color: "red" }}>{v?.description}</div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Product",
                        selector: (v) => <div>{v?.product}</div>,
                      },
                      {
                        name: "Vendor",
                        selector: (v) => <div>{v?.vendor}</div>,
                      },
                      {
                        name: "Physical Id",
                        selector: (v) => <div>{v?.physicalId}</div>,
                      },
                    ]}
                    data={opticalDriveInfo}
                    customStyles={currentStyles}
                    // pagination
                    // paginationPerPage={sharedDirRowPage}
                    // onChangePage={(page) => setSharedDirPage(page)}
                    // onChangeRowsPerPage={(page) => setSharedDirRowPage(page)}
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        Optical drive data not available.
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>

            <div className="row mt-2" style={customGutter}>
              <div className="col" ref={fileIntegrityFailedRef}>
                <HistoryCard
                  headerName={"File Integrity Info"}
                  description={tooltipData["fileIntegrityInfo"]}
                >
                  <DataTable
                    columns={[
                      {
                        name: "Sl no",
                        selector: (v, i) => (
                          <div
                            // style={{
                            //   color:
                            //     v?.expectedHash !== "NA" &&
                            //     v?.actualHash !== "NA" &&
                            //     v?.expectedHash !== v?.actualHash
                            //       ? "red"
                            //       : "black",
                            // }}

                            style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                          >
                            {i + 1}
                          </div>
                        ),
                        width: "70px",
                      },
                      {
                        name: "Host Type",
                        selector: (v) => (
                          <div
                          style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                          >
                            {v?.hostType}
                          </div>
                        ),
                        width: "100px",
                      },
                      {
                        name: "File Path",
                        selector: (v) => (
                          <Tippy content={v?.filePath}>
                            <div
                              // style={{
                              //   color:
                              //     v?.expectedHash !== "NA" &&
                              //     v?.actualHash !== "NA" &&
                              //     v?.expectedHash !== v?.actualHash
                              //       ? "red"
                              //       : "black",
                              // }}
                              style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                            >
                              {v?.filePath}
                            </div>
                          </Tippy>
                        ),
                      },
                      {
                        name: "Acutal File Hash",
                        selector: (v) => (
                          <Tippy content={v?.actualHash}>
                            <div
                              // style={{
                              //   color:
                              //     v?.expectedHash !== "NA" &&
                              //     v?.actualHash !== "NA" &&
                              //     v?.expectedHash !== v?.actualHash
                              //       ? "red"
                              //       : "black",
                              // }}
                              style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                            >
                              {v?.actualHash}
                            </div>
                          </Tippy>
                        ),
                        width: "550px",
                      },
                      {
                        name: "Expected File Hash",
                        selector: (v) => (
                          <Tippy
                            content={
                              <span className="w-full">{v?.expectedHash}</span>
                            }
                          >
                            <div
                              // style={{
                              //   color:
                              //     v?.expectedHash !== "NA" &&
                              //     v?.actualHash !== "NA" &&
                              //     v?.expectedHash !== v?.actualHash
                              //       ? "red"
                              //       : "black",
                              // }}
                              style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                            >
                              {v?.expectedHash}
                            </div>
                          </Tippy>
                        ),
                        width: "550px",
                      },
                      {
                        name: "File Type",
                        selector: (v) => (
                          <Tippy
                            content={v?.fileType}
                            style={{ textOverflow: "ellipsis" }}
                          >
                            <div
                              // style={{
                              //   color:
                              //     v?.expectedHash !== "NA" &&
                              //     v?.actualHash !== "NA" &&
                              //     v?.expectedHash !== v?.actualHash
                              //       ? "red"
                              //       : "black",
                              // }}
                              style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                            >
                              {v?.fileType}
                            </div>
                          </Tippy>
                        ),
                        width: "100px",
                      },
                      {
                        name: "Last Modified",
                        selector: (v) => (
                          <Tippy content={v?.lastModified}>
                            <div
                              // style={{
                              //   color:
                              //     v?.expectedHash !== "NA" &&
                              //     v?.actualHash !== "NA" &&
                              //     v?.expectedHash !== v?.actualHash
                              //       ? "red"
                              //       : "black",
                              // }}

                              style={{
                              color: (() => {
                                const isHashMismatch =
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash === "NA") || // expectedHash exists, but actualHash is "NA"
                                  (v?.actualHash !== "NA" &&
                                    v?.expectedHash === "NA") || // actualHash exists, but expectedHash is "NA"
                                  (v?.expectedHash !== "NA" &&
                                    v?.actualHash !== "NA" &&
                                    v?.expectedHash !== v?.actualHash); // Hashes don't match
                                return isHashMismatch ? "red" : "";
                              })(),
                            }}
                            >
                              {v?.lastModified}
                            </div>
                          </Tippy>
                        ),
                        width: "200px",
                      },
                    ]}
                    data={fileIntegrityInfo}
                    customStyles={currentStyles}
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2 me-auto">
                        File Integrity data not available.
                      </p>
                    }
                  />
                </HistoryCard>
              </div>
            </div>
            <div className="row mt-2" style={customGutter}>
              <div className="col" ref={osNotHardenedRef}>
                <HistoryCard
                  headerName={"Security Configurations Hardening Status"}
                  description={tooltipData["securityConfigHardeningStatus"]}
                >
                  {!linuxCisData || Object.keys(linuxCisData).length === 0 ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="p-0 m-0 fs-11 fw-bold">No Data</p>
                    </div>
                  ) : (
                    <EqualColumns
                      linuxCisData={linuxCisData}
                      findRefForCIS={findRefForCIS}
                      checkIfAutoOpenCIS={checkIfAutoOpenCIS}
                    />
                  )}
                </HistoryCard>
              </div>
            </div>
          </>
        )}
      </div>

      {/* PORT MODAL */}
      <CustomModal
        modelTitle={"Open Ports"}
        show={handlePortModal}
        handleClose={() => setHandlePortModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Open Ports {networkData[1]?.totalOpenPorts?.length}
        </p>
        {networkData[1]?.totalOpenPorts?.map((v, index) => (
          <div
            className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
            key={index}
          >
            <p className="p-0 m-0">#{index + 1}</p>
            <p
              className="p-0 m-0"
              style={{
                color: blacklistedOpenPorts?.includes(v) ? "red" : "inherit",
              }}
            >
              {v}
            </p>
          </div>
        ))}
      </CustomModal>

      {/* PORT MODAL END */}

      {/* NIC MODAL */}

      <CustomModal
        modelTitle={"NIC Details"}
        show={handleNICModal}
        handleClose={() => setHandleNICModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total NIC {networkData[1]?.totalNICCards?.length}
        </p>
        {networkData[1]?.totalNICCards?.map((v, index) => (
          <div
            className="shadow p-2 rounded-md my-2 d-flex flex-column gap-1"
            key={index}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Name</p>
              <p className="p-0 m-0 fs-10">{v?.description}</p>
            </div>
            <div className="d-flex  align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Product</p>
              <p className="p-0 m-0 fs-10">{v?.product}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Vendor</p>
              <p className="p-0 m-0 fs-10">{v?.vendor}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">Mac Address</p>
              <p className="p-0 m-0 fs-10">{v?.macAddress}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-10">IP Address</p>
              <p
                className="p-0 m-0 fs-10"
                style={{
                  color: blacklistedNICIPAddress?.includes(v?.ipAddress)
                    ? "red"
                    : "inherit",
                }}
              >
                {v?.ipAddress}
              </p>
            </div>
          </div>
        ))}
      </CustomModal>

      {/* NIC MODAL END */}
      {/* ANTIVIRUS MODAL */}

      <CustomModal
        modelTitle={"NIC Details"}
        show={handleAntiVirusModal}
        handleClose={() => setHandleAntiVirusModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Av Service {antivirusInfoData[1]?.length}
        </p>
        {antivirusInfoData[1]?.map((v, index) => (
          <div className="my-3 shadow p-1 rounded-md" key={index}>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.service}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Status</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.status}
              </p>
            </div>
          </div>
        ))}
      </CustomModal>

      <CustomModal
        modelTitle={"USB History"}
        show={handleUsbHistoryModal}
        handleClose={() => setHandleUsbHistoryModal(false)}
        customWidth="lg"
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Result {usbInfoData[1]?.usbHistory?.length}
        </p>

        <DataTable
          fixedHeader={true}
          columns={[
            {
              name: "Date/Time",
              selector: (v, index) => v?.time,
              width: "150px",
            },
            {
              name: "Device Name",
              selector: (v, index) => v?.usbName,
              width: "150px",
            },
            { name: "Type", selector: (v) => v?.interfaceType },
            { name: "Manufacturer", selector: (v) => v?.usbManufacturer },
            {
              name: "S. No.",
              selector: (v) => v?.usbSerialNumber,
              width: "220px",
            },
            { name: "Vendor ID", selector: (v) => v?.usbVendorId },
            { name: "Product ID", selector: (v) => v?.usbProductId },
          ]}
          data={usbInfoData[1]?.usbHistory}
          pagination
          paginationPerPage={10} // Adjust the number of rows per page as needed
          onChangePage={(page) => {
            setUsbHistoryPage(page);
          }}
          onChangeRowsPerPage={(page) => setUsbHistoryRowPage(page)}
          noDataComponent={
            <p className="p-0 my-2  me-auto">USB History is not available</p>
          }
        />
      </CustomModal>

      {/* USB HISTORY END */}

      {/* BLUETOOTH HISTORY */}
      <CustomModal
        modelTitle={"Paired Devices"}
        show={handleBlueToothInfoModal}
        handleClose={() => setHandleBlueToothInfoModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Devices {bluetoothInfoData[1]?.blueToothPariedDevice?.length}
        </p>
        {bluetoothInfoData[1]?.blueToothPariedDevice?.map((v, index) => (
          <div
            className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
            key={index}
          >
            <p className="p-0 m-0 fs-11 fw-bold">{index + 1}</p>
            <p className="p-0 m-0 fs-11">{v?.name}</p>
            <p className="p-0 m-0 fs-11">{v?.macAddress}</p>
          </div>
        ))}
      </CustomModal>

      <CustomModal
        modelTitle={"Connected Devices"}
        show={handleBlueToothInfoConnectedModal}
        handleClose={() => setHandleBlueToothInfoConnectedModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Devices {bluetoothInfoData[1]?.blueToothConnectedDevice?.length}
        </p>
        {bluetoothInfoData[1]?.blueToothConnectedDevice?.map((v, index) => (
          <div
            className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
            key={index}
          >
            <p className="p-0 m-0 fs-11 fw-bold">{index + 1}</p>
            <p className="p-0 m-0 fs-11">{v?.name}</p>
            <p className="p-0 m-0 fs-11">{v?.macAddress}</p>
          </div>
        ))}
      </CustomModal>

      {/* <CustomToaster /> */}

      {/* BLUETOOTH HISTORYENd */}
    </React.Fragment>
  );
}

export default LinuxClientDetail;
