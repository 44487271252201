import React from "react";

import ServerityOkIcon from "../../../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../DashBoard/detailCard";
import { Col, Row } from "react-bootstrap";
import {
  SAFE_WINDOW_CLIENT,
  UNRESPONSIVE_WINDOWS,
  UNSAFE_WINDOW_CLIENTS,
  WINDOW_ALL_CLIINT,
} from "../../../../api/endPoints/windowAlertEndPoint";

function WindowClients(windowSummary) {
  console.log(windowSummary, "IN THE SUMMARY");

  return (
    <Row className="g-3">
      <Col xl="3">
        <DetailCard
          severityName={"Total WindowsEndpoints"}
          severityIcon={ServerityOkIcon}
          severityNumber={windowSummary?.windowsData?.totalClients || 0}
          cardIcon={windowOkIcon}
          severityColor={"primary"}
          apiUrl={WINDOW_ALL_CLIINT}
          routeUrl={windowSummary?.windowsData?.totalClients === 0 ? undefined : "/serverity-Alerts"}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Safe WindowsEndpoints"}
          severityIcon={ServerityGreenIcon}
          severityNumber={windowSummary?.windowsData?.totalSafeClients || 0}
          cardIcon={windowGreenIcon}
          severityColor={"success"}
          apiUrl={SAFE_WINDOW_CLIENT}
          routeUrl={windowSummary?.windowsData?.totalSafeClients === 0 ? undefined : "/serverity-Alerts"}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unsafe WindowsEndpoints"}
          severityIcon={ServerityRedIcon}
          severityNumber={
            windowSummary?.windowsData?.totalUnsafeClients || 0
          }
          cardIcon={windowRedIcon}
          severityColor={"danger"}
          apiUrl={UNSAFE_WINDOW_CLIENTS}
          routeUrl={windowSummary?.windowsData?.totalUnsafeClients === 0 ? undefined : "/serverity-Alerts"}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unresponsive WindowsEndpoints"}
          severityIcon={ServerityYellowIcon}
          severityNumber={windowSummary?.windowsData?.totalUnresponsiveClients || 0}
          cardIcon={windowOYellowIcon}
          severityColor={"warning"}
          apiUrl={UNRESPONSIVE_WINDOWS}
          routeUrl={windowSummary?.windowsData?.totalUnresponsiveClients === 0 ? undefined : "/serverity-Alerts"}
        />
      </Col>
    </Row>
  );
}

export default WindowClients;
