import axios from "axios";
const createInstanceWithCancelToken = (baseURL) => {
  const instance = axios.create({
    baseURL,
    // timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.CancelToken = axios.CancelToken;
  instance.isCancel = axios.isCancel;

  instance.getBaseURL = () => baseURL;

  return instance;
};

const userApi = createInstanceWithCancelToken(process.env.REACT_APP_BASE_URL);
const notifyApi = createInstanceWithCancelToken(process.env.REACT_APP_NOTIFY_URL);
const summaryApi = createInstanceWithCancelToken(process.env.REACT_APP_SUMMARY_URL);
const windowsApi = createInstanceWithCancelToken(process.env.REACT_APP_WINDOW_URL);
const linuxApi = createInstanceWithCancelToken(process.env.REACT_APP_LINUX_URL);
const macApi = createInstanceWithCancelToken(process.env.REACT_APP_MAC_URL);
const androidApi = createInstanceWithCancelToken(process.env.REACT_APP_ANDROID_URL);

const ubuntuDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_LINUX_URL}/download/ubuntu`);
const rhelDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_LINUX_URL}/seal/download/rhel`);
const centosDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_LINUX_URL}/download/centos`);
const macDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_MAC_URL}/download`);
const windowsDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_WINDOW_URL}/download/msi`);
const chromeosDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_ANDROID_URL}/download`);
const androidDownloadApi = createInstanceWithCancelToken(`${process.env.REACT_APP_ANDROID_URL}/download`);

const setupInterceptors = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiInstance.interceptors.response.use(
    (response) => {
      // Modify the response config before it is resolved
      return response;
    },
    (error) => {
      // Handle response errors
      return Promise.reject(error);
    }
  );
};

// Set up interceptors for each instance
setupInterceptors(userApi);
setupInterceptors(notifyApi);
setupInterceptors(summaryApi);
setupInterceptors(windowsApi);
setupInterceptors(linuxApi);
setupInterceptors(macApi);
setupInterceptors(androidApi);
setupInterceptors(ubuntuDownloadApi);
setupInterceptors(rhelDownloadApi);
setupInterceptors(centosDownloadApi);
setupInterceptors(macDownloadApi);
setupInterceptors(windowsDownloadApi);
setupInterceptors(chromeosDownloadApi);
setupInterceptors(androidDownloadApi);

export { userApi, notifyApi, summaryApi, windowsApi, linuxApi, macApi, androidApi, ubuntuDownloadApi, rhelDownloadApi, centosDownloadApi, macDownloadApi, windowsDownloadApi, chromeosDownloadApi, androidDownloadApi };