import axios from "axios";

// Create an instance of Axios with custom configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Set your API base URL
  // timeout: 5000, // Set the timeout for requests (in milliseconds)
  headers: {
    "Content-Type": "application/json",
    // Add other default headers if needed
  },
});

// You can also intercept requests or responses globally
api.interceptors.request.use(
  (config) => {
    // You can modify the request config before it is sent
    // For example, you can add authentication tokens here
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // You can modify the response config before it is resolved
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default api;
