import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { hideToaster } from "../../feature/slices/toastSlice";
import notification from "../../assets/sound/notifyMe.mp3";

function CustomToaster() {
  const dispatch = useDispatch();
  const {
    showToast = true,
    toastTitle,
    toastMessage,
    toastColor,
  } = useSelector((store) => store.toastSlice);

  useEffect(() => {
    const toastTypes = ["success", "error", "info", "warning", "dark"];

    if (showToast) {
      const validToastColor = toastTypes.includes(toastColor)
        ? toastColor
        : "default";

      try {
        // const audio = new Audio(notification);
        // audio.play().catch((error) => console.log("AudioPlayError", error));
      } catch (error) {
        console.log("AudioSetupError", error);
      }

      if (validToastColor !== "default") {
        toast[validToastColor](
          <div>
            <strong>{toastTitle}</strong>
            <div>{toastMessage}</div>
          </div>
        );
      } 
      else {
        toast(
          <div>
            <strong>{toastTitle}</strong>
            <div>{toastMessage}</div>
          </div>
        );
      }
      dispatch(hideToaster());
    }
  }, [showToast, toastTitle, toastMessage, toastColor, dispatch]);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default CustomToaster;
