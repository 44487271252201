import React from "react";

import ServerityOkIcon from "../../../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../DashBoard/detailCard";
import { Col, Row } from "react-bootstrap";
import {
  TOTAL_LINUX_CLIENT,
  TOTAL_SAFE_LINUX,
  TOTAL_UNRESPONSIVE_LINUX,
  TOTAL_UNSAFE_LINUX,
} from "../../../../api/endPoints/linuxAlertEndPoint";

function LinuxClients(linuxSummary) {
  return (
    <Row className="g-3">
      <Col xl="3">
        <DetailCard
          severityName={"Total Linux Endpoints"}
          severityIcon={ServerityOkIcon}
          severityNumber={linuxSummary?.data?.totalClients || 0}
          cardIcon={windowOkIcon}
          severityColor={"primary"}
          apiUrl={TOTAL_LINUX_CLIENT}
          routeUrl={"/linux-serverity"}
          cardIndex={0}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Safe Linux Endpoints"}
          severityIcon={ServerityGreenIcon}
          severityNumber={linuxSummary?.data?.totalSafeClients || 0}
          cardIcon={windowGreenIcon}
          severityColor={"success"}
          apiUrl={TOTAL_SAFE_LINUX}
          routeUrl={"/linux-serverity"}
          cardIndex={1}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unsafe Linux Endpoints"}
          severityIcon={ServerityRedIcon}
          severityNumber={linuxSummary?.data?.totalUnsafeClients || 0}
          cardIcon={windowRedIcon}
          severityColor={"danger"}
          apiUrl={TOTAL_UNSAFE_LINUX}
          routeUrl={"/linux-serverity"}
          cardIndex={2}
        />
      </Col>
      <Col xl="3">
        <DetailCard
          severityName={"Unresponsive Linux Endpoints"}
          severityIcon={ServerityYellowIcon}
          severityNumber={linuxSummary?.data?.totalUnresponsivelients || 0}
          cardIcon={windowOYellowIcon}
          severityColor={"warning"}
          apiUrl={TOTAL_UNRESPONSIVE_LINUX}
          routeUrl={"/linux-serverity"}
          cardIndex={3}
        />
      </Col>
    </Row>
  );
}

export default LinuxClients;
