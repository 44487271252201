// import React, { useEffect, useState } from "react";
// import { Button, Card, Col, Nav, Row } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import Footer from "../layouts/Footer";
// import Header from "../layouts/Header";

// import PageLoader from "../components/common/Loader/PageLoader";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../feature/slices/AuthSlice";
// export default function AllNotification() {
//   const navigate = useNavigate();

//   const [loading, setLoading] = useState(true);
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);

//   const [windowCount, setWindowCount] = useState(0);
//   const [linuxCount, setLinuxCount] = useState(0);
//   const [androidCount, setAndroidCount] = useState(0);
//   const [macCount, setMacCount] = useState(0);
//   const [chromeOsCount, setChromeOsCount] = useState(0);

//   const [windowCountRisk, setwindowCountRisk] = useState(0);
//   const [windowCountUnRisk, setwindowCountUnRisk] = useState(0);

//   const [linuxCountRisk, setLinuxCountRisk] = useState(0);
//   const [linuxCountUnRisk, setLinuxCountUnRisk] = useState(0);

//   const [androidCountRisk, setAndroidCountRisk] = useState(0);
//   const [androidCountUnRisk, setAndroidUnCountRisk] = useState(0);

//   const [chromeCountRisk, setChromeCountRisk] = useState(0);
//   const [chromeCountUnRisk, setChromeCountUnRisk] = useState(0);

//   const [macCountRisk, setMacCountRisk] = useState(0);
//   const [macCountUnRisk, setMacCountUnRisk] = useState(0);

//   const [totalNotificationCount, setTotalNotificationCount] = useState(0);
//   const [dataNotification, setDataNotification] = useState(0);

//    console.log(totalNotificationCount, "totalNotificationCount");

//   const [summoryCount, setSummoryCount] = useState({});
//   const dispatch = useDispatch();

//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);

//   var windows_unsafe_per =
//     (windowCountRisk / (windowCountRisk + windowCountUnRisk)) * 100;
//   var linux_unsafe_per =
//     (linuxCountRisk / (linuxCountRisk + linuxCountUnRisk)) * 100;
//   var android_unsafe_per =
//     (androidCountRisk / (androidCountRisk + androidCountUnRisk)) * 100;
//   var chrome_unsafe_per =
//     (chromeCountRisk / (chromeCountRisk + chromeCountUnRisk)) * 100;
//   var macc_unsafe_per = (macCountRisk / (macCountRisk + macCountUnRisk)) * 100;

//   console.log(
//     windows_unsafe_per,
//     linux_unsafe_per,
//     android_unsafe_per,
//     chrome_unsafe_per,
//     macc_unsafe_per,
//     "line------------"
//   );
//   console.log(
//     chromeCountRisk,
//     chromeCountUnRisk,
//     "chromeCountRisk,chromeCountUnRisk"
//   );
//   console.log(chrome_unsafe_per, "chrome_unsafe_per");
//   useEffect(() => {
//     if (!isAuthenticated) {
//       // User not authenticated, redirect to login page
//       navigate("/login");
//     } else {
//       // Check token expiration time

//       const tokenExpirationTimer = setTimeout(() => {
//         // Token has expired, log the user out
//         dispatch(logout()); // Dispatch logout action to clear user data and token
//         navigate("/signin");
//       }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

//       return () => clearTimeout(tokenExpirationTimer);
//     }
//   }, [isAuthenticated, user, navigate, dispatch]);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);

//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   useEffect(() => {
//     let isMounted = true; // Variable to track mount status

//     const fetchData = () => {
//       axios
//         .get(
//           `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         )

//         .then((res) => {
//           if (isMounted) {
//             console.log(res?.data, "rrrrrrrrrrrrrrrrrrrrrrrrr");
//             // setChromeCountRisk(
//             //   res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount
//             // );
//             // setMacCountRisk(res?.data?.mac?.UnsafemacClients);
//             // setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount);
//             // setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients);
//             // setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients);

//             // setChromeCountUnRisk(
//             //   res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount
//             // );
//             // setMacCountUnRisk(res?.data?.mac?.SafemacClients);
//             // setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount);
//             // setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients);
//             // setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients);

//             // // setTotalDashboard(res?.data?.totalDevicesCount);

//             // setWindowCount(res?.data?.windows?.getTotalPCCount);
//             // setLinuxCount(res?.data?.linux?.getTotalPCCount);
//             // setAndroidCount(res?.data?.android?.androidDevicesCount);
//             // setMacCount(res?.data?.mac?.macPcCount);
//             // setChromeOsCount(
//             //   res?.data?.chromeOsSummaryInfo?.chromeDevicesCount
//             // );
//           }
//         })
//         .catch((error) => {
//           if (error.response) {
//             const statusCode = error.response.status;
//             switch (statusCode) {
//               case 404:
//                 console.log("Resource not found (404)");
//                 break;
//               case 500:
//                 console.log("Internal server error (500)");
//                 break;
//               case 504:
//                 console.log("Gateway timeout (504)");
//                 break;
//               default:
//                 console.log(`Error: ${statusCode}`);
//             }
//           } else if (error.request) {
//             console.log("No response received");
//           } else {
//             console.log("Error", error.message);
//           }
//         })
//         .finally(() => {
//           if (isMounted) {
//             setLoading(false);
//             // Schedule the next fetchData call after 50 seconds
//             // setTimeout(fetchData, 50000);
//           }
//         });
//     };

//     fetchData(); // Initial fetchData call
//   function updateNotification() {
//     axios
//     .get(
//       `${process.env.REACT_APP_NOTIFY_URL}/get-all-notifications/by/${user?.data?.user?.userId}`,
//       //  `${process.env.REACT_APP_NOTIFY_URL}/get-all-alert/by/${user?.data?.user?.userId}`
//       {
//         headers: {
//           Authorization: `Bearer ${user?.data?.accessToken}`,
//         },
//       }
//     )
//     .then((response) => {

//       const data = response?.data?.notifications;
//       setTotalNotificationCount(response?.data?.notifications?.length);
//       setDataNotification(data)
//       console.log(data,'datadatadatadatadata')
//       // let trigger_TYPE =
//       //   response?.data?.notifications?.[0]?.eventTriggerType;
//       // let host_name = response?.data?.notifications?.[0]?.hostname;

//       // let Alert_TYPE = response?.data?.notifications?.[0].alertType;

//       // if (mounted) {
//       //   console.log(
//       //     "Notification count changed from",
//       //     totalNotificationCount,
//       //     "to",
//       //     response?.data?.notifications?.length
//       //   );

//       //   if (
//       //     totalNotificationCount !== -1 &&
//       //     totalNotificationCount !== response?.data?.notifications?.length
//       //   ) {
//       //     let title = "";
//       //     let message = "";
//       //     let color = "";

//       //     if (trigger_TYPE === "LINUX_USB_TRIGGER") {
//       //       title = "USB Trigger";
//       //       message = `Unauthorized USB used in ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "LINUX_BLUETOOTH_TRIGGER") {
//       //       title = "Bluetooth Trigger";
//       //       message = `Bluetooth device used in ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "MAC_BLUETOOTH_TRIGGER") {
//       //       title = "Bluetooth Trigger";
//       //       message = `Bluetooth device used in ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "LINUX_INSTALLATION_TRIGGER") {
//       //       title = "New device connected";
//       //       message = `Cyberauditor  installed on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_INSTALLATION_TRIGGER") {
//       //       title = "New device connected";
//       //       message = `Cyberauditor  installed on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "WINDOWS_MANUAL_TRIGGER") {
//       //       title = "Manual trigger";
//       //       message = `Manual trigger installed on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "INSTALLATION_TRRIGGER") {
//       //       title = "Installed trigger";
//       //       message = `Installed trigger  on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "USER_LOGON_TRIGGER") {
//       //       title = "Logon trigger";
//       //       message = `Logon trigger  on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "LINUX_SPECIFIC_TIME_TRIGGER") {
//       //       title = "Linux specific time";
//       //       message = `Linux specific time trigger  on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "LINUX_USB_TRIGGER") {
//       //       title = "Linux trigger";
//       //       message = `Linux trigger  on ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "MAC_SPECIFIC_INTERVAL_TRIGGER") {
//       //       title = "Mac specific interval trigger";
//       //       message = `interval trigger on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_USB_TRIGGER") {
//       //       title = "USB trigger";
//       //       message = `USB trigger  on ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "LINUX_DAILY_TRIGGER") {
//       //       title = "Daily trigger";
//       //       message = `Daily trigger   on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_DAILY_TRIGGER") {
//       //       title = "Daily trigger";
//       //       message = `Daily trigger   on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "LINUX_INTERVAL_TRIGGER") {
//       //       title = "Interval trigger";
//       //       message = `Interval trigger  on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "LINUX_LOGON_TRIGGER") {
//       //       title = "Logon trigger";
//       //       message = `Logon trigger on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "LINUX_NETWORK_TRIGGER") {
//       //       title = "Network trigger";
//       //       message = `Network trigger on ${host_name}`;
//       //       color = "danger";
//       //     }
//       //     if (trigger_TYPE === "WINDOWS_USB_TRIGGER") {
//       //       title = "USB Trigger";
//       //       message = `Unauthorized USB  used in ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_BLUETOOTH_TRIGGER") {
//       //       title = "Bluetooth Trigger";
//       //       message = `Bluetooth device  used in ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_INSTALLATION_TRIGGER") {
//       //       title = "New device connected";
//       //       message = `Cyberauditor installed on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_MANUAL_TRIGGER") {
//       //       title = "Manual trigger";
//       //       message = `Manual trigger installed on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "WINDOWS_DEFENDER_SCAN_TRIGGER") {
//       //       title = "Defender scan";
//       //       message = `Defender scanned at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_DEFENDER_MALWARE_TRIGGER") {
//       //       title = "Defender MALWARE";
//       //       message = `Defender malware at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_FIREWALL_TRIGGER") {
//       //       title = "WINDOWS FIREWALL";
//       //       message = `Windows firewall at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_SPECIFIC_INTERVAL_TRIGGER") {
//       //       title = "WINDOWS FIREWALL";
//       //       message = `Windows firewall at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MissedLog") {
//       //       title = "Missed log";
//       //       message = `Missed log at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_NETWORK_TRIGGER") {
//       //       title = "MAC NETWORK TRIGGER";
//       //       message = `Mac Network at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "MAC_LOGON_TRIGGER") {
//       //       title = " LOGON";
//       //       message = ` logon at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "WINDOWS_LOGON_TRIGGER") {
//       //       title = " LOGON";
//       //       message = ` logon at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "WINDOWS_DAILY_TRIGGER") {
//       //       title = "Daily ";
//       //       message = `Daily trigger at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "WINDOWS_10_MINUTES_TRIGGER") {
//       //       title = "10 Min Trigger";
//       //       message = ` 10 Min Trigger at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "USB_TRIGGER") {
//       //       title = "USB trigger";
//       //       message = ` USB trigger at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "WINDOWS_NETWORK_CHANGE_TRIGGER") {
//       //       title = "Network trigger";
//       //       message = ` Network trigger at ${host_name}`;
//       //       color = "danger";
//       //     }
//       //     if (trigger_TYPE === "APP_INSTALL") {
//       //       title = "APP Installed";
//       //       // message = `Unauthorized USB {NAME} used in ${host_name}`;
//       //       message = `New app installed  ${host_name}`;
//       //       color = "warning";
//       //     } else if (trigger_TYPE === "APP_UNINSTALL") {
//       //       title = "Bluetooth Trigger";
//       //       message = `Bluetooth device  used in ${host_name}`;
//       //       color = "warning";
//       //     } else if (trigger_TYPE === "NETWORK_STATE_CHANGE") {
//       //       title = "Network state changed";
//       //       message = `Network state changed on ${host_name}`;
//       //       color = "warning";
//       //     } else if (trigger_TYPE === "ROUTINE") {
//       //       title = "ROUTINE";
//       //       message = `Routing on this ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "SERVICE_STARTUP") {
//       //       title = "Service start-up";
//       //       message = `Service start-up on ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "INSTALLATION") {
//       //       title = "Installation set";
//       //       message = `Installation at ${host_name}`;
//       //       color = "success";
//       //     } else if (trigger_TYPE === "DEVELOPER_SETTING_CHANGED") {
//       //       title = "Developer setting changed";
//       //       message = `Developer setting changed at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "USB_CONNECTED") {
//       //       title = "USB connected";
//       //       message = `USB connected at ${host_name}`;
//       //       color = "danger";
//       //     } else if (trigger_TYPE === "BLACKLIST_APP_INSTALL") {
//       //       title = "BLACKLIST app installed";
//       //       message = `BLACKLIST app installed at ${host_name}`;
//       //       color = "danger";
//       //     } else if (Alert_TYPE === "WATCHDOG_VERSION_ALERT") {
//       //       title = "WATCHDOG VERSION ALERT";
//       //       message = `Watch log version alert at${host_name}`;
//       //       color = "danger";
//       //     } else if (Alert_TYPE === "WATCHDOG_TRIGGER") {
//       //       title = "Watchdog trigger";
//       //       message = `Watchdog trigger on ${host_name}`;
//       //       color = "danger";
//       //     } else if (Alert_TYPE === "WATCHDOG_UPDATE_ALERT") {
//       //       title = "WATCHDOG UPDATE ALERT";
//       //       message = `Watchdog Update alert on ${host_name}`;
//       //       color = "danger";
//       //     } else if (Alert_TYPE === "WATCHDOG_SERVICE_ALERT") {
//       //       title = "WATCHDOG UPDATE ALERT";
//       //       message = `Watchdog Update alert on ${host_name}`;
//       //       color = "danger";
//       //     }

//       //     dispatch(
//       //       showToaster({
//       //         title: title || "NA",
//       //         message: message || "NA",
//       //         color: color || "success",
//       //       })
//       //     );
//       //   }
//       //   // setNotificationData(response.data.notifications);
//       // }
//     })
//     .catch((error) => {
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           // that falls out of the range of 2xx
//           const statusCode = error.response.status;
//           switch (statusCode) {
//             case 404:
//               console.log("Resource not found (404)");
//               break;
//             case 500:
//               console.log("Internal server error (500)");
//               break;
//             case 504:
//               console.log("Gateway timeout (504)");
//               break;
//             default:
//               console.log(`Error: ${statusCode}`);
//           }
//         } else if (error.request) {
//           // The request was made but no response was received
//           console.log("No response received");
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           console.log("Error", error.message);
//         }
//       });
//   }

//   updateNotification()
//     // Cleanup function to set isMounted to false when component unmounts
//     return () => {
//       isMounted = false;
//     };
//   }, [user]);


  
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       {loading ? (
//         <div className={`overlay ${loading ? "active" : ""}`}>
//           <PageLoader className="loader" />
//         </div>
//       ) : (
//         <div className="main main-app p-3 p-lg-4">
//           <div className="d-md-flex align-items-center justify-content-between mb-4">
//             <div>
//               <h4 className="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">
//                 Welcome to Notification
//               </h4>
//             </div>
//             <div className="d-flex gap-2 mt-3 mt-md-0">
//               {/* <Button
//                 variant=""
//                 className="btn-white d-flex align-items-center gap-2"
//               >
//                 <i className="ri-share-line fs-18 lh-1"></i>Share
//               </Button> */}
//               {/* <Button
//                 variant=""
//                 className="btn-white d-flex align-items-center gap-2"
//               >
//                 <i className="ri-printer-line fs-18 lh-1"></i>Print
//               </Button> */}
//               <Button
//                 variant="primary"
//                 className="d-flex align-items-center gap-2 fw-bold"
//               >
//               Total: {totalNotificationCount}
//               </Button>
//             </div>
//           </div>
//           <Row className="g-3">
//        {
//         dataNotification.map((item,i) =>{
//           return(
//             <div class="list-group" key={i}>
//               <a
//                 href="#"
//                 class="list-group-item list-group-item-action shadow p-2 mb-2 bg-body-tertiary"
//                 aria-current="true"
//               >
//                 <div class="d-flex w-100 justify-content-between">
//                   <h5 class="mb-1">{item.hostname}</h5>
//                   <small>{item.timeStamp}</small>
//                 </div>
//                 <p class="mb-1">{item.eventTriggerType}</p>
//                 <small>{item.notificationType}</small>
//               </a>
             
              
//             </div>
//           )
//         })
//        }
           
//           </Row>

//           <Footer />
//         </div>
//       )}
//     </React.Fragment>
//   );
// }
// import React, { useEffect, useState } from "react";
// import { Button, Card, Col, Nav, Row, Form } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import Footer from "../layouts/Footer";
// import Header from "../layouts/Header";
// import PageLoader from "../components/common/Loader/PageLoader";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../feature/slices/AuthSlice";

// export default function AllNotification() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);

//   const [totalNotificationCount, setTotalNotificationCount] = useState(0);
//   const [dataNotification, setDataNotification] = useState([]);
//   const [filteredNotifications, setFilteredNotifications] = useState([]);
//   const [search, setSearch] = useState("");
//   const [filterDate, setFilterDate] = useState("");
  
//   console.log(filterDate,'filterDate')
//   const [filterEventTriggerType, setFilterEventTriggerType] = useState("");

//   const dispatch = useDispatch();
//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate("/login");
//     } else {
//       const tokenExpirationTimer = setTimeout(() => {
//         dispatch(logout());
//         navigate("/signin");
//       }, 24 * 60 * 60 * 1000);

//       return () => clearTimeout(tokenExpirationTimer);
//     }
//   }, [isAuthenticated, user, navigate, dispatch]);

//   const switchSkin = (skin) => {
//     const btnWhite = document.getElementsByClassName("btn-white");
//     for (const btn of btnWhite) {
//       if (skin === "dark") {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       } else {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   useEffect(() => {
//     let isMounted = true;
//     const fetchData = () => {
//       axios
//         .get(
//           `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         )
//         .then((res) => {
//           if (isMounted) {
//             // Your existing code for setting state from response data
//           }
//         })
//         .catch((error) => {
//           if (error.response) {
//             const statusCode = error.response.status;
//             switch (statusCode) {
//               case 404:
//                 console.log("Resource not found (404)");
//                 break;
//               case 500:
//                 console.log("Internal server error (500)");
//                 break;
//               case 504:
//                 console.log("Gateway timeout (504)");
//                 break;
//               default:
//                 console.log(`Error: ${statusCode}`);
//             }
//           } else if (error.request) {
//             console.log("No response received");
//           } else {
//             console.log("Error", error.message);
//           }
//         })
//         .finally(() => {
//           if (isMounted) {
//             setLoading(false);
//           }
//         });
//     };

//     fetchData();
//     const updateNotification = () => {
//       axios
//         .get(
//           `${process.env.REACT_APP_NOTIFY_URL}/get-all-notifications/by/${user?.data?.user?.userId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user?.data?.accessToken}`,
//             },
//           }
//         )
//         .then((response) => {
//           const data = response?.data?.notifications;
//           setTotalNotificationCount(response?.data?.notifications?.length);
//           setDataNotification(data);
//           setFilteredNotifications(data);
//         })
//         .catch((error) => {
//           if (error.response) {
//             const statusCode = error.response.status;
//             switch (statusCode) {
//               case 404:
//                 console.log("Resource not found (404)");
//                 break;
//               case 500:
//                 console.log("Internal server error (500)");
//                 break;
//               case 504:
//                 console.log("Gateway timeout (504)");
//                 break;
//               default:
//                 console.log(`Error: ${statusCode}`);
//             }
//           } else if (error.request) {
//             console.log("No response received");
//           } else {
//             console.log("Error", error.message);
//           }
//         });
//     };

//     updateNotification();
//     return () => {
//       isMounted = false;
//     };
//   }, [user]);

//   useEffect(() => {
//     const filtered = dataNotification.filter((notification) => {
//       return (
//         (filterDate ? notification.timeStamp.startsWith(filterDate) : true) &&
//         (filterEventTriggerType ? notification.eventTriggerType === filterEventTriggerType : true) &&
//         (search
//           ? notification.hostname.toLowerCase().includes(search.toLowerCase()) ||
//             notification.eventTriggerType.toLowerCase().includes(search.toLowerCase()) ||
//             notification.notificationType.toLowerCase().includes(search.toLowerCase())
//           : true)
//       );
//     });
//     setFilteredNotifications(filtered);
//   }, [filterDate, filterEventTriggerType, search, dataNotification]);

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       {loading ? (
//         <div className={`overlay ${loading ? "active" : ""}`}>
//           <PageLoader className="loader" />
//         </div>
//       ) : (
//         <div className="main main-app p-3 p-lg-4">
//           <div className="d-md-flex align-items-center justify-content-between mb-4">
//             <div>
//               <h4 className="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">
//                 Welcome to Notification
//               </h4>
//             </div>
//             <div className="d-flex gap-2 mt-3 mt-md-0">
//               <Button variant="primary" className="d-flex align-items-center gap-2 fw-bold">
//                 Total: {totalNotificationCount}
//               </Button>
//             </div>
//           </div>

//           <Row className="g-3 mb-3">
//             <Col md={4}>
//               <Form.Group controlId="filterDate">
//                 <Form.Label>Filter by Date</Form.Label>
//                 <Form.Control
//                   type="date"
//                   value={filterDate}
//                   onChange={(e) => setFilterDate(e.target.value)}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="filterEventTriggerType">
//                 <Form.Label>Filter by Event Trigger Type</Form.Label>
//                 <Form.Control
//                   as="select"
//                   value={filterEventTriggerType}
//                   onChange={(e) => setFilterEventTriggerType(e.target.value)}
//                 >
//                   <option value="">All</option>
//                   <option value="LINUX_USB_TRIGGER">LINUX_USB_TRIGGER</option>
//                   <option value="MAC_USB_TRIGGER">MAC_USB_TRIGGER</option>
//                   <option value="WINDOWS_USB_TRIGGER">WINDOWS_USB_TRIGGER</option>
//                   <option value="LINUX_BLUETOOTH_TRIGGER">LINUX_BLUETOOTH_TRIGGER</option>
//                   <option value="WINDOWS_BLUETOOTH_TRIGGER">WINDOWS_BLUETOOTH_TRIGGER</option>
//                   <option value="MAC_BLUETOOTH_TRIGGER">MAC_BLUETOOTH_TRIGGER</option>
//                   <option value="LINUX_INSTALLATION_TRIGGER">LINUX_INSTALLATION_TRIGGER</option>
//                   <option value="MAC_INSTALLATION_TRIGGER">MAC_INSTALLATION_TRIGGER</option>
//                   <option value="WINDOWS_MANUAL_TRIGGER">WINDOWS_MANUAL_TRIGGER</option>
//                   {/* Add more options as needed */}
//                 </Form.Control>
//               </Form.Group>
//             </Col>
//             <Col md={4}>
//               <Form.Group controlId="search">
//                 <Form.Label>Search</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Search notifications"
//                   value={search}
//                   onChange={(e) => setSearch(e.target.value)}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Row className="g-3">
//             {filteredNotifications.map((item, i) => (
//               <div className="list-group" key={i}>
//                 <a
//                   href="#"
//                   className="list-group-item list-group-item-action shadow p-2 mb-2 bg-body-tertiary"
//                   aria-current="true"
//                 >
//                   <div className="d-flex w-100 justify-content-between">
//                     <h5 className="mb-1">{item.hostname}</h5>
//                     <small>{item.timeStamp}</small>
//                   </div>
//                   <p className="mb-1">{item.eventTriggerType}</p>
//                   <small>{item.notificationType}</small>
//                 </a>
//               </div>
//             ))}
//           </Row>

//           <Footer />
//         </div>
//       )}
//     </React.Fragment>
//   );
// }


import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
// import Header from "../layouts/Header";
import PageLoader from "../components/common/Loader/PageLoader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";

export default function AllNotification() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [dataNotification, setDataNotification] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [search, setSearch] = useState("");
  const [filterDate, setFilterDate] = useState("");

 
  const [filterEventTriggerType, setFilterEventTriggerType] = useState("");

  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  useEffect(() => {
    if (!isAuthenticated) {
      // navigate("/login");
    } else {
      const tokenExpirationTimer = setTimeout(() => {
        dispatch(logout());
        // navigate("/signin");
      }, 24 * 60 * 60 * 1000);

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const switchSkin = (skin) => {
    const btnWhite = document.getElementsByClassName("btn-white");
    for (const btn of btnWhite) {
      if (skin === "dark") {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      } else {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )
        .then((res) => {
          if (isMounted) {
            // Your existing code for setting state from response data
          }
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 404:
                console.log("Resource not found (404)");
                break;
              case 500:
                console.log("Internal server error (500)");
                break;
              case 504:
                console.log("Gateway timeout (504)");
                break;
              default:
                console.log(`Error: ${statusCode}`);
            }
          } else if (error.request) {
            console.log("No response received");
          } else {
            console.log("Error", error.message);
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    };

    fetchData();
    const updateNotification = () => {
      axios
        .get(
          `${process.env.REACT_APP_NOTIFY_URL}/get-all-notifications/by/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )
        .then((response) => {
          const data = response?.data?.notifications;
          setTotalNotificationCount(response?.data?.notifications?.length);
          setDataNotification(data);
          setFilteredNotifications(data);
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 404:
                console.log("Resource not found (404)");
                break;
              case 500:
                console.log("Internal server error (500)");
                break;
              case 504:
                console.log("Gateway timeout (504)");
                break;
              default:
                console.log(`Error: ${statusCode}`);
            }
          } else if (error.request) {
            console.log("No response received");
          } else {
            console.log("Error", error.message);
          }
        });
    };

    updateNotification();
    return () => {
      isMounted = false;
    };
  }, [user]);

  // useEffect(() => {
  //   const filtered = dataNotification?.filter((notification) => {
  //     const notificationDate = new Date(notification?.timeStamp).toISOString().split("T")[0];
  //     return (
  //       (filterDate ? notificationDate === filterDate : true) &&
  //       (filterEventTriggerType ? notification.eventTriggerType === filterEventTriggerType : true) &&
  //       (search
  //         ? notification.hostname.toLowerCase().includes(search.toLowerCase()) ||
  //           notification.eventTriggerType.toLowerCase().includes(search.toLowerCase()) ||
  //           notification.notificationType.toLowerCase().includes(search.toLowerCase())
  //         : true)
  //     );
  //   });
  //   setFilteredNotifications(filtered);
  // }, [filterDate, filterEventTriggerType, search, dataNotification]);
  useEffect(() => {
    const filtered = dataNotification?.filter((notification) => {
      const timeStamp = notification?.timeStamp;
      console.log(timeStamp,'timeStamp')
      if (!timeStamp) return false; // Skip notifications without a timestamp
  
      const notificationDate = new Date(timeStamp);
      if (isNaN(notificationDate)) {
        console.error("Invalid date:", timeStamp);
        return false; // Skip invalid dates
      }
  
      const formattedDate = notificationDate.toISOString().split("T")[0];
  
      return (
        (filterDate ? formattedDate === filterDate : true) &&
        (filterEventTriggerType ? notification.eventTriggerType === filterEventTriggerType : true) &&
        (search
          ? notification.hostname?.toLowerCase().includes(search.toLowerCase()) ||
            notification.eventTriggerType?.toLowerCase().includes(search.toLowerCase()) ||
            notification.notificationType?.toLowerCase().includes(search.toLowerCase())
          : true)
      );
    });
  
    setFilteredNotifications(filtered);
  }, [filterDate, filterEventTriggerType, search, dataNotification]);
  
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">
                Welcome to Notification
              </h4>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Button variant="primary" className="d-flex align-items-center gap-2 fw-bold">
                Total: {totalNotificationCount}
              </Button>
            </div>
          </div>

          <Row className="g-3 mb-3">
          <Col md={4}>
              <Form.Group controlId="search">
                <Form.Label>Search</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search notifications"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group controlId="filterDate">
                <Form.Label>Filter by Date</Form.Label>
                <Form.Control
                  type="date"
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </Form.Group>
            </Col> */}
            <Col md={4}>
              <Form.Group controlId="filterEventTriggerType">
                <Form.Label>Filter by Event Trigger Type</Form.Label>
                <Form.Control
                  as="select"
                  value={filterEventTriggerType}
                  onChange={(e) => setFilterEventTriggerType(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="LINUX_USB_TRIGGER">LINUX_USB_TRIGGER</option>
                  <option value="LINUX_BLUETOOTH_TRIGGER">LINUX_BLUETOOTH_TRIGGER</option>
                  <option value="MAC_BLUETOOTH_TRIGGER">MAC_BLUETOOTH_TRIGGER</option>
                  <option value="LINUX_INSTALLATION_TRIGGER">LINUX_INSTALLATION_TRIGGER</option>
                  <option value="MAC_INSTALLATION_TRIGGER">MAC_INSTALLATION_TRIGGER</option>
                  <option value="WINDOWS_MANUAL_TRIGGER">WINDOWS_MANUAL_TRIGGER</option>
                  {/* Add more options as needed */}
                </Form.Control>
              </Form.Group>
            </Col>
            
          </Row>

          <Row className="g-3">
            {filteredNotifications.map((item, i) => (
              <div className="list-group" key={i}>
                <a
                  href="#"
                  className="list-group-item list-group-item-action shadow p-2 mb-2"
                  aria-current="true"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{item.hostname}</h5>
                    <small>{item.timeStamp}</small>
                  </div>
                  <p className="mb-1">{item.eventTriggerType}</p>
                  <small>{item.notificationType}</small>
                </a>
              </div>
            ))}
          </Row>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
