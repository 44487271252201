import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import {useSkinMode } from "../../../components/SkinModeContext"

function CustomTableAndroid({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
  titleState
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
    console.log("Click on menu item:", e, "for record:", record);
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        name: "AndroidID",
        // selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        selector: (row) => row.androidId,
      },
      {
        name: "chromeOS Version",
        selector: (row) => row.chromeOSVersion,
        sortable: true,
      },
      {
        name: "Brand Name",
        selector: (row) => row.brandName,
        sortable: true,
      },
      {
        name: "Device IP",
        selector: (row) => row.deviceIp,
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-1 text-light bg-dark" // Changed px-2 to px-1
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.androidId,
                  title:titleState
                },
              })
            }
          >
            <p className="m-0 p-0 fs-10">Details</p> 
            <i className="fas fa-long-arrow-alt-right ps-2"></i> 
          </Button>
        ),
        ignoreRowClick: true,
        allowoverflow: false,
        width:'120px'
      }  
      
    ],
    [currentPage, pageSize, navigate, routeUrl]
  );
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#D7E3E8",
      },
    },
  };


  return (
    <DataTable
      columns={columns}
      data={rowData || []}
      pagination
      paginationServer
      customStyles={currentStyles}
      paginationTotalRows={totalDocuments}
      paginationDefaultPage={currentPage}
      paginationPerPage={pageSize}
      onChangePage={(page) => {
        console.log("Page", page);
        handlePageChange(page);
      }}
      onChangeRowsPerPage={(newPageSize) => {
        console.log("Page Size", newPageSize);
        handlePageSizeChange(newPageSize);
      }}
      //   customStyles={customStyles} // Add custom styles here
      noDataComponent={
        <p
          className="p-0 my-2 me-auto text"
          style={{ textAlign: "center", width: "100%" }}
        >
          No Data Found
        </p>
      }
    />
  );
}

export default CustomTableAndroid;
