// import React, { Children, useEffect, useState } from "react";
// import { Button } from "react-bootstrap";

// function HistoryCard({
//   headerName,
//   reloadFunction = () => {},
//   infoButton,
//   paddingBottom = false,
//   children,
//   firewallOffRef
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   return (
//     <div
//       className={` card card-one p-1 allCardEffect shadow  my-2 rounded-md py-3  ${paddingBottom && "pb-0"}`}
//     >
//       <div className="d-flex border-bottom border-1 px-3 py-2 align-items-center justify-content-between"   ref={firewallOffRef}>
//         <span className="m-0 p-0 fs-16 text-primary-dark fw-bolder">
//           {headerName}
//         </span>
//         <span className="d-flex align-items-center justify-content-center gap-3">
//           {/* <Button className="p-0 m-0 text-secondary bg-transparent border-0">
//         <i className="fas fa-sync"></i>
//         </Button> */}
//           {infoButton && (
//             <Button className="p-0 m-0 bg-transparent text-secondary border-0">
//               <i className="fas fa-info-circle"></i>
//             </Button>
//           )}
//         </span>
//       </div>
//       <div className="px-3">{children}</div>
//     </div>
//   );
// }

// export default HistoryCard;
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSkinMode } from "../../components/SkinModeContext";

function HistoryCard({
  headerName,
  children,
  description,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { skinMode } = useSkinMode(); // Access the skin mode state

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const [showInfo, setShowInfo] = useState(false);
  let hoverTimeout = null;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setShowInfo(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowInfo(false);
  };

  return (
    <div
      className="card card-one p-2 allCardEffect shadow rounded-md position-relative"
    >
      <div className="d-flex border-bottom border-1 px-3 py-2 align-items-center justify-content-between">
        <span className="m-0 p-0 fs-16  fw-bolder text-dark">
          {headerName}
        </span>
        <span className="d-flex align-items-center justify-content-center gap-3">
          <Button
            className="bg-transparent border-0 position-absolute opacity-1 transition-opacity p-0"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ top: headerName === "Security Configurations Hardening Status" ? "10px" : "2%", right: "2%", opacity: "0.6" }}
          >
            <i
              className="fa fa-info-circle"
              style={{ pointerEvents: 'auto', fontSize: "12px" ,color: skinMode === 'dark' ? '#3ccece' : '',  }}
            ></i>
          </Button>
          {showInfo && description && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: headerName === "Security Configurations Hardening Status" ? "20px" : "7%",
                  right: "45px",
                  transform: "translateY(-50%)",
                  background: "#565556",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  zIndex: "1000",
                  width: "300px",
                  wordWrap: "break-word",
                }}
              >
                <p className="p-1 m-0 rounded fs-12">{description}</p>
              </div>

              <i
                className="fa-solid fa-caret-right"
                style={{
                  fontSize: "24px",
                  color: "#565556",
                  position: "absolute",
                  top: headerName === "Security Configurations Hardening Status" ? "15px" :"5%",
                  right: "38px",
                  zIndex: "1000",
                  transform: "translateY(-50%)",
                }}
              ></i>
            </>
          )}
        </span>
      </div>
      <div className="">{children}</div>
    </div>
  );
}

export default HistoryCard;
