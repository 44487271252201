import React, { useEffect, useState } from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

import cardIcon from "../../../assets/img/ServerityIcon/yellowAdmin.png";

function AdminCard({
  dashNumber,
  dashTitle,
  riskNumber,
  riskPerc,
  dashRoute,
  handleRoute,
  adminCardIcon,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [hoverMe, setHoverMe] = useState(false);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <div
      className={`outerCard ${hoverMe ? "hoverMe" : ""}`}
      onClick={handleRoute}
      onMouseLeave={() => setHoverMe(false)}
      onMouseEnter={() => setHoverMe(true)}
    >
      <div className="px-3 pt-3 card-one  pe-auto  border d-flex flex-column align-items-center justify-content-center gap-2">
        <p className="m-0 p-0 fs-18 fw-bolder  lh-3 ff-secondary py-2 text-center">
          {dashTitle}
        </p>
        <span className="d-flex align-items-center justify-content-center my-2">
          <p className="mx-2 m-0 p-0 fs-18 lh-3 fw-bolder ff-numerals">
            {dashNumber || 0}
          </p>
        </span>

        <img />
        <img src={adminCardIcon} width={300} />
      </div>
    </div>
  );
}

export default AdminCard;
