import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../api/axiosSet";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css"; // optional
import { useDispatch } from "react-redux";
import { setUser } from "../../feature/slices/AuthSlice";
import { DownOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

function UpdateProfile() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authSlice);
  const [skin, setSkin] = useState(currentSkin);

  const [showPassword, setShowPassword] = useState(false);

  const [newUserName, setNewUserName] = useState(
    user?.data?.user?.firstName || ""
  );
  const [newUserEmail, setNewUserEmail] = useState(
    user?.data?.user?.email || ""
  );
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserRank, setNewUserRank] = useState(user?.data?.user?.rank || "");
  const [newUserBranch, setNewUserBranch] = useState(
    user?.data?.user?.branch || ""
  );
  const [newUserAppointment, setNewUserAppointment] = useState(
    user?.data?.user?.appointment || ""
  );
  const [newUserStation, setNewUserStation] = useState(
    user?.data?.user?.station || ""
  );
  const [newUserCommand, setNewUserCommand] = useState(
    user?.data?.user?.command || ""
  );
  const [errors, setErrors] = useState({});

  const invalidCharacters = /[<>'"]/;
  const hasSpecialChars = /[^a-zA-Z0-9\s]/;
  const hasNumbers = /[0-9]/;
  const hasLowercase = /[a-z]/;
  const hasUppercase = /[A-Z]/;

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (newUserName && newUserName.length > 50) {
      errors.newUserName = "Name must be less than 50 characters.";
      isValid = false;
    }
    if (newUserAppointment && newUserAppointment.length > 50) {
      errors.newUserAppointment =
        "Appointment must be less than 50 characters.";
      isValid = false;
    }
    if (newUserStation && newUserStation.length > 50) {
      errors.newUserStation = "Station must be less than 50 characters.";
      isValid = false;
    }

    if (user?.isPasswordUpdated === false) {
      if (!newUserPassword) {
        errors.newUserPassword = "Password is required.";
        isValid = false;
      } else if (newUserPassword.length < 8) {
        errors.newUserPassword = "Password must be at least 8 characters.";
        isValid = false;
      } else if (newUserPassword.length > 50) {
        errors.newUserPassword = "Password must less than 50 characters.";
        isValid = false;
      } else if (!hasSpecialChars.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain special characters.";
        isValid = false;
      } else if (!hasLowercase.test(newUserPassword)) {
        errors.newUserPassword =
          "Password must contain at least one lowercase letter.";
        isValid = false;
      } else if (!hasUppercase.test(newUserPassword)) {
        errors.newUserPassword =
          "Password must contain at least one uppercase letter.";
        isValid = false;
      } else if (!hasNumbers.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one number.";
        isValid = false;
      } else if (invalidCharacters.test(newUserPassword)) {
        errors.newUserPassword = "Password contains invalid characters.";
        isValid = false;
      }
    }

    if (user?.isPasswordUpdated === true && newUserPassword) {
      if (newUserPassword.length < 8) {
        errors.newUserPassword = "Password must be at least 8 characters.";
        isValid = false;
      } else if (newUserPassword.length > 50) {
        errors.newUserPassword = "Password must less than 50 characters.";
        isValid = false;
      } else if (!hasSpecialChars.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain special characters.";
        isValid = false;
      } else if (!hasLowercase.test(newUserPassword)) {
        errors.newUserPassword =
          "Password must contain at least one lowercase letter.";
        isValid = false;
      } else if (!hasUppercase.test(newUserPassword)) {
        errors.newUserPassword =
          "Password must contain at least one uppercase letter.";
        isValid = false;
      } else if (!hasNumbers.test(newUserPassword)) {
        errors.newUserPassword = "Password must contain at least one number.";
        isValid = false;
      } else if (invalidCharacters.test(newUserPassword)) {
        errors.newUserPassword = "Password contains invalid characters.";
        isValid = false;
      }
    }

    if (
      newUserName &&
      (hasSpecialChars.test(newUserName) || hasNumbers.test(newUserName))
    ) {
      errors.newUserName =
        "Name must not contain special characters or numbers.";
      isValid = false;
    }
    if (newUserStation && invalidCharacters.test(newUserStation)) {
      errors.newUserStation = "Station contains invalid characters.";
      isValid = false;
    }

    if (newUserAppointment && invalidCharacters.test(newUserAppointment)) {
      errors.newUserAppointment = "Appointment contains invalid characters.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const branchValues = [
    "F(P)",
    "AE(L)",
    "AE(M)",
    "Edm",
    "Adm",
    "Lgs",
    "Accts",
    "Met",
    "Tech",
    "Non-Tech",
  ];
  const rankValues = [
    "Air Chief Mshl",
    "Air Mshl",
    "AVM",
    "Gp Capt",
    "Wg Cdr",
    "Sqn Ldr",
    "Flt Lt",
    "Flg Offr",
    "MWO",
    "WO",
    "JWO",
    "SGT",
    "CPL",
    "LAC",
    "AC",
  ];

  const commandValues = [
    "Air HQ",
    "WAC",
    "EAC",
    "CAC",
    "SWAC",
    "TC",
    "MC",
    "AFC",
    "SAC",
  ];

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const data = {
      fname: newUserName,
      email: newUserEmail,
      rank: newUserRank,
      branch: newUserBranch,
      appointment: newUserAppointment,
      station: newUserStation,
      command: newUserCommand,
    };
    if (newUserPassword) {
      data.password = newUserPassword;
    }
    try {
      const response = await userApi.post(
        `/update-profile/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const resData = response.data;
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setTimeout(() => {
        dispatch(
          setUser({
            ...user,
            data: {
              ...user?.data,
              user: {
                ...user?.data?.user,
                firstName: resData?.user?.fname,
                rank: resData?.user?.rank,
                branch: resData?.user?.branch,
                appointment: resData?.user?.appointment,
                station: resData?.user?.station,
                command: resData?.user?.command,
              },
            },
            isPasswordUpdated: true,
          })
        );
        navigate("/");
      }, 2000);
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error?.response?.data?.message);
      // Handle error response
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
  };
  const handleSelect = (eventKey) => {
    setNewUserRank(eventKey);
  };

  return (
    <React.Fragment>
      {/* <div className="main main-app">
        <div
          className="d-flex justify-content-center min-vh-100"
          style={{ backgroundColor: "aliceblue" }}
        >
          <div className="w-50 mt-5">
            <div className="mb-3 text-center">
              <h1 className="fs-5">Edit Profile</h1>
            </div>
            <div className="card p-4 border rounded shadow">
              <div>
                <form onSubmit={handleProfileUpdate}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputService" className="form-label">
                      Service No.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputService"
                      aria-describedby="nameHelp"
                      value={newUserEmail}
                      style={{ opacity: 0.8 }}
                      disabled
                    />
                    {errors.newUserEmail && (
                      <div className="text-danger">{errors.newUserEmail}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleSelectRank" className="form-label">
                      Rank
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectRank"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserRank(e.target.value)}
                      >
                        {newUserRank ? (
                          <option className="dropdown-item" value={newUserRank}>
                            {newUserRank}
                          </option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {rankValues &&
                          rankValues.map(
                            (rank, index) =>
                              rank !== newUserRank && (
                                <option key={index} value={rank}>
                                  {rank}
                                </option>
                              )
                          )}
                      </select>
                      <DownOutlined
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                    {errors.newUserRank && (
                      <div className="text-danger">{errors.newUserRank}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="nameHelp"
                      value={newUserName}
                      maxLength={50}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                    {errors.newUserName && (
                      <div className="text-danger">{errors.newUserName}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleSelectBranch" className="form-label">
                      Branch / Trade
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectBranch"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserBranch(e.target.value)}
                      >
                        {newUserBranch ? (
                          <option value={newUserBranch}>{newUserBranch}</option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {branchValues &&
                          branchValues.map(
                            (branch, index) =>
                              branch !== newUserBranch && (
                                <option key={index} value={branch}>
                                  {branch}
                                </option>
                              )
                          )}
                      </select>
                      <DownOutlined
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                    {errors.newUserBranch && (
                      <div className="text-danger">{errors.newUserBranch}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputAppoint" className="form-label">
                      Appointment
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputAppoint"
                      aria-describedby="nameHelp"
                      value={newUserAppointment}
                      maxLength={50}
                      onChange={(e) => setNewUserAppointment(e.target.value)}
                    />
                    {errors.newUserAppointment && (
                      <div className="text-danger">
                        {errors.newUserAppointment}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputStation" className="form-label">
                      Station
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputStation"
                      aria-describedby="nameHelp"
                      value={newUserStation}
                      maxLength={50}
                      onChange={(e) => setNewUserStation(e.target.value)}
                    />
                    {errors.newUserStation && (
                      <div className="text-danger">{errors.newUserStation}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label
                      htmlFor="exampleSelectCommand"
                      className="form-label"
                    >
                      Command
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control"
                        id="exampleSelectCommand"
                        aria-describedby="nameHelp"
                        onChange={(e) => setNewUserCommand(e.target.value)}
                      >
                        {newUserCommand ? (
                          <option value={newUserCommand}>
                            {newUserCommand}
                          </option>
                        ) : (
                          <option value="">--Not Set--</option>
                        )}
                        {commandValues &&
                          commandValues.map(
                            (command, index) =>
                              command !== newUserCommand && (
                                <option key={index} value={command}>
                                  {command}
                                </option>
                              )
                          )}
                      </select>
                      <DownOutlined
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputPass" className="form-label">
                      New Password
                      {user?.isPasswordUpdated === false ? " *" : ""}
                    </label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="exampleInputPass"
                        aria-describedby="emailHelp"
                        value={newUserPassword}
                        maxLength={50}
                        onChange={(e) => setNewUserPassword(e.target.value)}
                      />
                      {!showPassword ? (
                        <EyeOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        />
                      )}
                    </div>
                    {errors.newUserPassword && (
                      <div className="text-danger">
                        {errors.newUserPassword}
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-center mt-3">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Update Profile</p>
            {/* <p className="text-danger p-0 m-0 fw-semibold">Total Endpoints:</p> */}
          </span>
        </div>

        <div className="card rounded cardStyle p-3">
          <form onSubmit={handleProfileUpdate} className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="profilePicture" className="form-label">
                Profile Picture
              </label>
              <input
                type="file"
                className="form-control"
                id="profilePicture"
                accept="image/*"
                // onChange={handleProfilePictureChange}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleInputService" className="form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputService"
                aria-describedby="nameHelp"
                value={newUserEmail}
                style={{ opacity: 0.8 }}
                disabled
              />
              {errors.newUserEmail && (
                <div className="text-danger">{errors.newUserEmail}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleSelectRank" className="form-label">
                Role
              </label>
              <div className="position-relative">
                <select
                  className="form-control"
                  id="exampleSelectRank"
                  aria-describedby="nameHelp"
                  onChange={(e) => setNewUserRank(e.target.value)}
                >
                  {newUserRank ? (
                    <option className="dropdown-item" value={newUserRank}>
                      {newUserRank}
                    </option>
                  ) : (
                    <option value="">--Not Set--</option>
                  )}
                  {rankValues &&
                    rankValues.map(
                      (rank, index) =>
                        rank !== newUserRank && (
                          <option key={index} value={rank}>
                            {rank}
                          </option>
                        )
                    )}
                </select>
                <DownOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                  }}
                />
              </div>
              {errors.newUserRank && (
                <div className="text-danger">{errors.newUserRank}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleInputName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputName"
                aria-describedby="nameHelp"
                value={newUserName}
                maxLength={50}
                onChange={(e) => setNewUserName(e.target.value)}
              />
              {errors.newUserName && (
                <div className="text-danger">{errors.newUserName}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleSelectBranch" className="form-label">
                Branch / Trade
              </label>
              <div className="position-relative">
                <select
                  className="form-control"
                  id="exampleSelectBranch"
                  aria-describedby="nameHelp"
                  onChange={(e) => setNewUserBranch(e.target.value)}
                >
                  {newUserBranch ? (
                    <option value={newUserBranch}>{newUserBranch}</option>
                  ) : (
                    <option value="">--Not Set--</option>
                  )}
                  {branchValues &&
                    branchValues.map(
                      (branch, index) =>
                        branch !== newUserBranch && (
                          <option key={index} value={branch}>
                            {branch}
                          </option>
                        )
                    )}
                </select>
                <DownOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                  }}
                />
              </div>
              {errors.newUserBranch && (
                <div className="text-danger">{errors.newUserBranch}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleInputAppoint" className="form-label">
                Appointment
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputAppoint"
                aria-describedby="nameHelp"
                value={newUserAppointment}
                maxLength={50}
                onChange={(e) => setNewUserAppointment(e.target.value)}
              />
              {errors.newUserAppointment && (
                <div className="text-danger">{errors.newUserAppointment}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleInputStation" className="form-label">
                Station
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputStation"
                aria-describedby="nameHelp"
                value={newUserStation}
                maxLength={50}
                onChange={(e) => setNewUserStation(e.target.value)}
              />
              {errors.newUserStation && (
                <div className="text-danger">{errors.newUserStation}</div>
              )}
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleSelectCommand" className="form-label">
                Command
              </label>
              <div className="position-relative">
                <select
                  className="form-control"
                  id="exampleSelectCommand"
                  aria-describedby="nameHelp"
                  onChange={(e) => setNewUserCommand(e.target.value)}
                >
                  {newUserCommand ? (
                    <option value={newUserCommand}>{newUserCommand}</option>
                  ) : (
                    <option value="">--Not Set--</option>
                  )}
                  {commandValues &&
                    commandValues.map(
                      (command, index) =>
                        command !== newUserCommand && (
                          <option key={index} value={command}>
                            {command}
                          </option>
                        )
                    )}
                </select>
                <DownOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="exampleInputPass" className="form-label">
                New Password {user?.isPasswordUpdated === false ? "*" : ""}
              </label>
              <div className="position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="exampleInputPass"
                  aria-describedby="emailHelp"
                  value={newUserPassword}
                  maxLength={50}
                  onChange={(e) => setNewUserPassword(e.target.value)}
                />
                {!showPassword ? (
                  <EyeOutlined
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                )}
              </div>
              {errors.newUserPassword && (
                <div className="text-danger">{errors.newUserPassword}</div>
              )}
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UpdateProfile;
