import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { linuxApi, summaryApi } from "../../api/axiosSet";
import "../../scss/_column-name.scss";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import PageLoader from "../../components/common/Loader/PageLoader";
import { Link } from "react-router-dom";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import Chart from "react-apexcharts";
import Test from "./Test";
import { useSkinMode } from "../../components/SkinModeContext";

const data = {
  Marketing: {
    sections: {
      Campaigns: {
        subSections: [
          "Email Campaigns",
          "Social Media Ads",
          "Influencer Collaborations",
        ],
      },
      Analytics: {
        subSections: ["Traffic Analysis", "Conversion Rates"],
      },
      Branding: {
        subSections: ["Logo Design", "Brand Guidelines", "Merchandise"],
      },
    },
  },
  Sales: {
    sections: {
      LeadGeneration: {
        subSections: ["Cold Calling", "Email Outreach", "Networking Events"],
      },
      SalesStrategy: {
        subSections: ["Market Research", "Sales Forecasting"],
      },
      CustomerRelationship: {
        subSections: ["Client Meetings", "Feedback Surveys"],
      },
    },
  },
  Development: {
    sections: {
      Frontend: {
        subSections: ["React Development", "UI/UX Design"],
      },
      Backend: {
        subSections: ["API Development", "Database Management"],
      },
      DevOps: {
        subSections: ["CI/CD Pipelines", "Cloud Services"],
      },
    },
  },
};

export default function OrganizationView() {
  const { skinMode } = useSkinMode(); // Access the skin mode state
  const { user } = useSelector((state) => state.authSlice);
  const roleName = user?.data?.user?.roleName;
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const userRoleName = user?.data?.user?.roleName;

  const [loading, setLoading] = useState(true);
  const [columnDataDepartment, setColumnDataDepartment] = useState([]);
  console.log(columnDataDepartment, "columnDataDepartment");
  const [columnDataSection, setColumnDataSection] = useState([]);
  const [columnDataSubsection, setColumnDataSubsection] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const [isDepartmentVisible, setDepartmentVisible] = useState(true);
  const [isSectionVisible, setSectionVisible] = useState(true);
  const [isSubsectionVisible, setSubsectionVisible] = useState(true);

  const darkModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "rgb(51, 51, 51)", // Light background for header
      },
    },
    headRow: {
      style: {
        backgroundColor: "#192030", // Dark background for header
        color: "#fff", // White text in header
      },
    },
    rows: {
      style: {
        backgroundColor: "#192030", // Dark background for rows
        color: "#fff", // White text in rows
      },
      highlight: {
        backgroundColor: '#6e8ea6', // Custom color for row highlight
      },
    },
    pagination: {
      style: {
        backgroundColor: "#192030", // Dark background for pagination
        color: "#fff", // White text in pagination
      },
    },
  };
  // Define the custom styles for light mode (default)
  const lightModeStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8", // Light background for header
      },
    },
  };
  const currentStyles = skinMode === "dark" ? darkModeStyles : lightModeStyles;
  const mainStyle = skinMode === "dark" ? {} : { backgroundColor: "#ECF9FF" };

  const hideSectionTable = () => {
    setSectionVisible(false);
    setSubsectionVisible(false);
    setTimeout(() => {
      setColumnDataSection([]);
      setColumnDataSubsection([]);
      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
    }, 500);
  };

  const hideSubsectionTable = () => {
    setSubsectionVisible(false);
    setTimeout(() => {
      setColumnDataSubsection([]);
      setSelectedSection(null);
      setSelectedSubsection(null);
    }, 500);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "13px",
        backgroundColor: "#D7E3E8",
      },
    },
  };

  const conditionalRowStylesDept = [
    {
      when: (row) =>
        selectedDepartment && row?._id === selectedDepartment?.value,
      style: {
        backgroundColor: skinMode === "dark" ?  '#6e8ea6' :"#dff1fa",
      },
    },
  ];

  const conditionalRowStylesSec = [
    {
      when: (row) => selectedSection && row?.section === selectedSection?.value,
      style: {
        // backgroundColor: "#d3ebf7",
        backgroundColor: skinMode === "dark" ?  '#6e8ea6' :"#dff1fa",
      },
    },
  ];

  const navigateToDashboard = (row, type) => {
    function getFilteredData() {
      if (type === "department") {
        let state = {
          selectedDepartment: {
            label: row?.departmentName,
            value: row?._id,
          },
        };
        return state;
      } else if (type === "section") {
        let state = {
          selectedDepartment: selectedDepartment && {
            label: selectedDepartment?.label,
            value: selectedDepartment?.value,
          },
          selectedSection: {
            label: row?.sectionName,
            value: row?.section,
          },
        };
        return state;
      } else if (type === "subSection") {
        let state = {
          selectedDepartment: selectedDepartment && {
            label: selectedDepartment?.label,
            value: selectedDepartment?.value,
          },
          selectedSection: selectedSection && {
            label: selectedSection?.label,
            value: selectedSection?.value,
          },
          selectedSubsection: {
            label: row?.subSectionName,
            value: row?.subSection,
          },
        };
        return state;
      } else {
        return null;
      }
    }
    navigate("/", { state: getFilteredData() });
  };

  const fetchInitialTableData = async () => {
    try {
      let url = `/pcinfo/org-dashboard/${user?.data?.user?.userId}`;
      if (
        userRoleName === "Super Admin" ||
        userRoleName === "Organization Admin" ||
        userRoleName === "Department Admin"
      ) {
        url = `/pcinfo/org-department-dashboard/${user?.data?.user?.userId}`;
      } else if (userRoleName === "Section Admin") {
        url = `/pcinfo/org-section-dashboard/${user?.data?.user?.userId}`;
      } else if (userRoleName === "subSection Admin") {
        url = `/pcinfo/org-sub-section-dashboard/${user?.data?.user?.userId}`;
      }

      const response = await linuxApi.get(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });
      if (
        userRoleName === "Super Admin" ||
        userRoleName === "Organization Admin" ||
        userRoleName === "Department Admin"
      ) {
        setColumnDataDepartment(response?.data?.data || []);
      } else if (userRoleName === "Section Admin") {
        setColumnDataSection(response?.data?.data || []);
      } else if (userRoleName === "subSection Admin") {
        setColumnDataSubsection(response?.data?.data || []);
      } else {
        setColumnDataDepartment([]);
        setColumnDataSection([]);
        setColumnDataSubsection([]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchSectionTableData = async () => {
    try {
      if (!selectedDepartment) {
        return;
      }
      let url = `/pcinfo/org-section-dashboard/${user?.data?.user?.userId}?department=${selectedDepartment?.value}`;
      const response = await linuxApi.get(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });
      if (response?.data?.data?.length === 0) {
        setSelectedDepartment(null);
      }
      setSectionVisible(true);
      setColumnDataSection(response?.data?.data || []);
      setColumnDataSubsection([]);
      setSelectedSection(null);
      setSelectedSubsection(null);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchSubsectionTableData = async () => {
    try {
      if (!selectedSection) {
        return;
      }

      let url = `/pcinfo/org-sub-section-dashboard/${user?.data?.user?.userId}`;
      if (selectedDepartment && selectedSection) {
        url += `?department=${selectedDepartment?.value}&section=${selectedSection?.value}`;
      } else if (selectedDepartment) {
        url += `?department=${selectedDepartment?.value}`;
      } else if (selectedSection) {
        url += `?section=${selectedSection.value}`;
      } else {
        return;
      }
      const response = await linuxApi.get(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      });
      if (response?.data?.data?.length === 0) {
        setSelectedSection(null);
      }
      setSubsectionVisible(true);
      setColumnDataSubsection(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchInitialTableData();
  }, []);

  useEffect(() => {
    if (selectedDepartment) fetchSectionTableData();
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedSection) fetchSubsectionTableData();
  }, [selectedSection]);

  const commonColumns = [
    {
      name: "Total",
      selector: (row) => row?.total,
      sortable: true,
    },
    {
      name: "Compliant",
      selector: (row) => row?.safe,
      sortable: true,
    },
    {
      name: "Non-Compliant",
      selector: (row) => row?.unsafe,
      sortable: true,
    },
    {
      name: "Unresponsive",
      selector: (row) => row?.unresponsive,
      sortable: true,
    },
  ];
  const columnsDepartment = [
    {
      name: "Department",
      selector: (row) => (
        <div
          className="org-view-table-column-name"
          onClick={() => {
            setSelectedDepartment({
              label: row?.departmentName,
              value: row?._id,
            });
          }}
        >
          {row?.departmentName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "department")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="fa fa-long-arrow-alt-right"></i>
          </Button>
        </div>
      ),
    },
  ];

  const columnsSection = [
    {
      name: "Section",
      selector: (row) => (
        <div
          className="org-view-table-column-name"
          onClick={() => {
            setSelectedSection({
              label: row?.sectionName,
              value: row?.section,
            });
          }}
        >
          {row?.sectionName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "section")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="fa fa-long-arrow-alt-right"></i>
          </Button>
        </div>
      ),
    },
  ];

  const columnsSubsection = [
    {
      name: "Sub-Section",
      selector: (row) => (
        <div
        // onClick={() => {
        //     setSelectedSubsection(row?.subSection);
        // }}
        >
          {row?.subSectionName || "NA"}
        </div>
      ),
      sortable: true,
    },
    ...commonColumns,
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-center">
          <Button
            className="btn d-flex align-items-center border-0 px-2 gap-2 text-light bg-dark btn-small"
            style={{ borderRadius: "5px" }}
            onClick={() => navigateToDashboard(row, "subSection")}
          >
            <p className="m-0 p-0 fs-11"> View Dashboard</p>
            <i className="fa fa-long-arrow-alt-right"></i>
          </Button>
        </div>
      ),
    },
  ];
  const [lineGraph, setLineGraph] = useState([]);
  const [monthGraph, setMonthGraph] = useState([]);
  const [monthGraphTwo, setMonthGraphTwo] = useState([]);
  const prevDeptResponseRef = useRef(null);
  const prevSectionRespRef = useRef(null);
  const [view, setView] = useState("Department");
  const prevResponseRef = useRef(null);
  const prevMonthResponseRef = useRef(null);

  const [deptSafeUnsafe, setDeptSafeUnsafe] = useState([]);

  const [sectionSafeUnsafe, setSectionSafeUnsafe] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [totalPcs, setTotalPcs] = useState(0);

  const [departmentGraph, setDepartmentGraph] = useState([]);
  const [sectionGraph, setSectionGraph] = useState([]);
  const [currentData, setCurrentData] = useState(
    Object.keys(data).map((department) => ({ name: department, value: 1 }))
  );
  const [currentSections, setCurrentSections] = useState(null);
  const [currentSubSections, setCurrentSubSections] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("Departments");

  const departmentNames = Object.keys(data);

  console.log(departmentNames, "departmentNames");

  const fetchData = () => {
    let url = `/graph-data/${user?.data?.user?.userId}`;
    if (selectedDepartment && selectedSection && selectedSubsection) {
      url += `?department=${selectedDepartment.value}&section=${selectedSection.value}&subSection=${selectedSubsection.value}`;
    } else if (selectedDepartment && selectedSection) {
      url += `?department=${selectedDepartment.value}&section=${selectedSection.value}`;
    } else if (selectedDepartment) {
      url += `?department=${selectedDepartment.value}`;
    }
    summaryApi
      .get(url, {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      })
      .then((response) => {
        const departmentCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .departmentCounts;
        const sectionCount =
          response.data.getSafeAndUnsafeCountsByDepartmentAndSection
            .sectionCounts;
        const newResponse = response.data.response.sevenDayData;
        const monthResponse = response.data.graphData.monthGraph;
        if (
          JSON.stringify(prevResponseRef.current) !==
          JSON.stringify(newResponse)
        ) {
          setLineGraph(newResponse);
          prevResponseRef.current = newResponse;
        }

        if (
          JSON.stringify(prevMonthResponseRef.current) !==
          JSON.stringify(monthResponse)
        ) {
          setMonthGraph(monthResponse);
          prevMonthResponseRef.current = monthResponse;
        }

        if (
          JSON.stringify(prevDeptResponseRef.current) !==
          JSON.stringify(departmentCount)
        ) {
          setDeptSafeUnsafe(departmentCount);
          setDepartmentGraph(departmentCount);
          prevDeptResponseRef.current = departmentCount;
        }

        if (
          JSON.stringify(prevSectionRespRef.current) !==
          JSON.stringify(sectionCount)
        ) {
          setSectionSafeUnsafe(sectionCount);
          setSectionGraph(sectionCount);
          prevSectionRespRef.current = sectionCount;
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPieChartInfo = async () => {
    try {
      const response = await summaryApi.get(
        `/pie-chart/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setTotalPcs(response?.data?.totalPCCount);
      setChartSeries([
        response?.data?.safeLinuxClients?.count,
        response?.data?.unsafeLinuxClients?.count,
      ]);
      setTimeout(() => setLoading(false), 500);
    } catch {
      setChartSeries([0, 0, 0]);
    }
  };

  useEffect(() => {
    fetchPieChartInfo();
    fetchData();
  }, [selectedDepartment]);

  const pieChartOptions = {
    chart: {
      type: "pie",
    },
    labels: ["Compliant", "Non-compliant"],
    colors: ["#28a745", "#dc3545"],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const cleanData = (data, type) =>
    data.map((item) => ({
      ...item,
      [type]: item[type] === "Unknown" ? "N/A" : item[type],
    }));

  // Simulate fetching the data and setting the state
  useEffect(() => {
    setDepartmentGraph(cleanData(departmentGraph, "department"));
    setSectionGraph(cleanData(sectionGraph, "section"));
  }, []);

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "bar",
      toolbar: { show: false },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: true, speed: 500 }, // Increased speed for smoother transitions
      },
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          if (currentData) {
            handleDepartmentClick(departmentNames[dataPointIndex]);
          } else if (currentSections) {
            handleSectionClick(currentSections[dataPointIndex].name);
          }
        },
      },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: { top: -20, left: 5, bottom: 20 },
    },
    states: {
      hover: { filter: { type: "none" } },
      active: { filter: { type: "none" } },
    },
    colors: currentData
      ? ["#506fd9", "#6b82ff"] // Colors for departments
      : currentSections
      ? ["#f77e53", "#ff9a76"] // Colors for sections
      : currentSubSections
      ? ["#34c38f", "#5ddab4"] // Colors for subsections
      : ["#506fd9", "#e5e9f2"], // Default colors
    plotOptions: {
      bar: {
        columnWidth: "10%",
        endingShape: "rounded",
        dataLabels: { position: "top" },
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
    },
    xaxis: {
      categories: currentData
        ? currentData.map((d) => d.name)
        : currentSections
        ? currentSections.map((s) => s.name)
        : currentSubSections
        ? currentSubSections.map((sb) => sb.name)
        : [],
      labels: {
        rotate: -45,
        style: { colors: "#6e7985", fontSize: "12px", fontWeight: "500" },
        offsetY: 10,
      },
    },
    tooltip: { enabled: true },
    legend: { show: true },
  };

  const handleDepartmentClick = (department) => {
    setTimeout(() => {
      setCurrentSections(
        Object.keys(data[department].sections).map((section) => ({
          name: section,
          value: 1,
        }))
      );
      setCurrentData(null);
      setCurrentDepartment(department);
      setCurrentTitle(department); // Update title to current department
    }, 300); // Delay to add a smoother transition
  };

  const handleSectionClick = (section) => {
    const subSections = data[currentDepartment].sections[section].subSections;

    setTimeout(() => {
      setCurrentSubSections(
        subSections.map((sub) => ({ name: sub, value: 1 }))
      );
      setCurrentSections(null);
      setCurrentTitle(section); // Update title to current section
    }, 300); // Delay for smooth transition
  };




  return (
    <React.Fragment>
      {!loading ? (
        <>
          <div
            className="main main-app p-3 p-lg-4 d-flex flex-column gap-4"
          style={mainStyle} // Apply background color conditionally
          >
            <div className="d-md-flex align-items-center justify-content-between">
              <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
                <Link
                  to="/"
                  className="shadow"
                  style={{ borderRadius: "25px" }}
                >
                  <img
                    src={linuxIcon}
                    alt="Window-logo"
                    width={55}
                    height={55}
                  />
                </Link>
                <p className="mx-2 fs-18 m-0 p-0">|</p>
                <h4 class="main-title mb-0 fs-20 fw-bolder p-0 m-0 ">
                  Organization Overview
                </h4>
              </span>
            </div>

            <div className="d-flex align-items-centerjustify-content-between card gap-3 card-one p-4 flex-col allCardEffect shadow">
              <div className="col-xl">
                <p className="fs-18 text-dark fw-bolder p-0 m-0">
                  {totalPcs !== 0 ? (
                    <>
                      Total Endpoints: <span>{totalPcs || 0}</span>
                    </>
                  ) : (
                    "No endpoints mapped yet."
                  )}
                </p>
              </div>
              {totalPcs !== 0 &&
                (roleName === "Super Admin" ||
                  roleName === "Organization Admin") && (
                  <div className="row">
                    <div className="col-8">
                      <Test />
                    </div>

                    <div className="col-4  m-auto card rounded cardStyle">
                      <ReactApexChart
                        options={pieChartOptions}
                        series={chartSeries}
                        height={250}
                        type="pie"
                      />
                    </div>
                  </div>
                )}
            </div>

            {totalPcs !== 0 &&
              columnDataDepartment &&
              columnDataDepartment.length > 0 && (
                <div
                  className={`card rounded cardStyle cardStyleOrgView ${
                    isDepartmentVisible ? "table-visible" : "table-hidden"
                  }`}
                >
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <DataTable
                      columns={columnsDepartment}
                      selectableRowsHighlight
              // highlightOnHover
                      data={columnDataDepartment || []}
                      customStyles={currentStyles}
                      conditionalRowStyles={conditionalRowStylesDept}
                      noDataComponent={
                        <p className="p-0 my-2 me-auto">No Data Found</p>
                      }
                    />
                  )}
                </div>
              )}

            {totalPcs !== 0 &&
              columnDataSection &&
              columnDataSection.length > 0 && (
                <div
                  className={`card rounded cardStyle cardStyleOrgView position-relative ${
                    isSectionVisible ? "table-visible" : "table-hidden"
                  }`}
                >
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      <DataTable
                        columns={columnsSection}
                        data={columnDataSection || []}
                        // customStyles={customStyles}
                        customStyles={currentStyles}
                        conditionalRowStyles={conditionalRowStylesSec}
                        noDataComponent={
                          <p className="p-0 my-2 me-auto">No Data Found</p>
                        }
                      />
                      {userRoleName !== "Section Admin" && (
                        <div
                          className="d-flex align-items-center justify-content-center rounded-circle m-2 p-2 bg-danger shadow-lg position-absolute top-0 end-0"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={hideSectionTable}
                        >
                          <i className="fa-solid fa-minus text-white"></i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

            {totalPcs !== 0 &&
              columnDataSubsection &&
              columnDataSubsection.length > 0 && (
                <div
                  className={`card rounded cardStyle cardStyleOrgView position-relative ${
                    isSubsectionVisible ? "table-visible" : "table-hidden"
                  }`}
                >
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      <DataTable
                        columns={columnsSubsection}
                        data={columnDataSubsection || []}
                        // customStyles={customStyles}
                        customStyles={currentStyles}
                        noDataComponent={
                          <p className="p-0 my-2 me-auto">No Data Found</p>
                        }
                      />
                      {userRoleName !== "subSection Admin" && (
                        <div
                          className="d-flex align-items-center justify-content-center rounded-circle m-2 p-2 bg-danger shadow-lg position-absolute top-0 end-0"
                          style={{
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={hideSubsectionTable}
                        >
                          <i className="fa-solid fa-minus text-white"></i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
          </div>
        </>
      ) : (
        <div
          className="main main-app p-3 p-lg-4 d-flex flex-column gap-4"
        >
          <PageLoader />
        </div>
      )}
    </React.Fragment>
  );
}
